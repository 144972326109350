import React, { useEffect, useRef, useState } from "react";
import JobApplyContent from "./JobApplyContent";
import JobApplyNewContent from "./JobApplyNewContent";
import JobApplyHeader from "./JobApplyHeader";
import GoToTop from "../../common/GoToTop";
import { applyJob } from "../../../api/candidate/applyJob";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { recapplyJob } from "../../../api/recruiter/candidate/applyJob";
import { freeapplyJob } from "../../../api/freelance/candidate/applyJob";

const JobPreview = () => {
  // return job apply main page
  const [state, setState] = useState(false);
  const [loader, setLoader] = useState(false);

  try {
    document.getElementById("replacetitleimg").innerText =
      "Job Posting Preview";
    document.getElementById("postjobid").style.display = "none";
  } catch { }

  const param = useParams();
  const id = param.reqID;
  const statusName = param.status;
  const applyRef = useRef();

  const [information, setInformation] = useState({
    firstJob: "",
    secondJob: "",
  });

  const [radio, setRadio] = useState(0);
  const [applyStatus, setApplyStatus] = useState(false);

  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);

  const handleApplyJob = async () => {
    if (applyRef.current) {
      applyRef.current.setAttribute("disabled", "disabled");
    }
    setLoader(true);
    let data = {
      actionAnswer: radio,
    };
    const response = isrecruiterCandidate
      ? await recapplyJob(token, id, data)
      : isFreelance
        ? await freeapplyJob(token, id, data)
        : await applyJob(token, id, data);
    if (response.status === 200) {
      setApplyStatus(true);
      applyRef.current.removeAttribute("disabled");
      setLoader(false);
    } else {
      applyRef.current.removeAttribute("disabled");
    }
  };

  useEffect(() => {
    async function callUserDetails() {
      try {
        if (statusName) {
          setApplyStatus(true);
        }
      } catch { }
    }
    callUserDetails();
  }, [statusName]);

  return (
    <div className={"col mx-lg-5 mx-2 pb-5"}>
      <div className={"row pt-3"}>
        <div
          className={"container pl-4 pb-3 background-colored-primary-white  border-radius-20px"}
        >
          {/*first row*/}
          <JobApplyHeader applyStatus={applyStatus} statusName={statusName} />
          {/*second row*/}
          {applyStatus ? (
            <JobApplyContent />
          ) : (
            <>
              {state ? (
                <JobApplyNewContent
                  information={information}
                  setInformation={setInformation}
                  radio={radio}
                  setRadio={setRadio}
                />
              ) : (
                <JobApplyContent />
              )}
            </>
          )}
          {/*jobapplyfooter*/}
          <div className={"row d-flex justify-content-end"}>
            <div className={"col-auto "}>
              {/* {applyStatus ? (
                <button
                  type={"button"}
                  className={"btn btn-success mb-2 applyalignment font-weight-500 border-radius-05rem  applied-btn-margin-css"}
                  ref={applyRef}
                >
                  {statusName ? statusName : "Applied"}
                </button>
              ) : ( */}
              <>
                {(!applyStatus && state) ? (
                  <button
                    type={"button"}
                    className={"btn uploadbtn mb-2 applyalignment border-radius-05rem apply-job-btncss"}

                    ref={applyRef}
                    onClick={(e) => {
                      handleApplyJob(e);
                    }}
                  >
                    {loader ? (
                      <i
                        className="fa fa-refresh fa-spin apply-job-btnloadercss"
                      ></i>
                    ) : (
                      ""
                    )}
                    APPLY JOB
                  </button>
                ) : !applyStatus && (
                  <button
                    type={"button"}
                    className={"btn uploadbtn mb-2 applyalignment apply-job-btncss border-radius-05rem"}

                    onClick={() => {
                      setState(true);
                    }}
                  >
                    APPLY JOB
                  </button>
                )}
              </>
              {/* ) */}
              {/* } */}
            </div>
          </div>
        </div>
      </div>
      <GoToTop />
    </div>
  );
};

export default JobPreview;
