import { CLIENTBUILDREQUIREMENT } from "../ENVIRONMENT";
// api for submit buildresume security clearence
async function postBuildRequirementSecurity(token, data,id) {
  return await fetch(CLIENTBUILDREQUIREMENT+`/${id}/securityclearance`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
       "Authorization": `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}
export { postBuildRequirementSecurity };
