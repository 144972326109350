import React from "react";
import { useSelector } from "react-redux";

const ClientSnapshotDiv = (props) => {
  // return snapshop page div wrapper for data div
  const token = useSelector((state) => state.token);

  const SaveDisplay = (event) => {
    // for save button
    const parentNode = event.target.parentNode;
    parentNode.lastChild.style.display = "inline";
    event.target.style.display = "none";

    const input = parentNode.getElementsByTagName("input");
    for (let i = 0; i < input.length; i++) {
      input[i].disabled = false;
    }

    const select = parentNode.getElementsByTagName("select");
    for (let i = 0; i < select.length; i++) {
      select[i].disabled = false;
    }

    const textArea = parentNode.getElementsByTagName("textarea");
    for (let i = 0; i < textArea.length; i++) {
      textArea[i].disabled = false;
    }

    if (props.hasOwnProperty("updateEditor")) {
      props.updateEditor(false)
    }
  };
  const editDisplay = async (event) => {
    // for edit button
    const parentNode = event.target.parentNode;
    event.target.style.display = "none";
    event.target.parentNode.firstChild.style.display = "inline";

    if (
      props.hasOwnProperty("postData") &&
      props.hasOwnProperty("parsedData") &&
      props.hasOwnProperty("id")
    ) {
      try {
        if (props.hasOwnProperty("social")) {
          Object.keys(props.parsedData).map((key) => {
            if (!props.parsedData[key]) delete props.parsedData[key];
          });
        }
        await props.postData(token, props.parsedData, props.id);
      } catch { }
    }
    // parentNode.lastChild.style.display = "inline";
    // event.target.style.display = "none";

    const input = parentNode.getElementsByTagName("input");
    for (let i = 0; i < input.length; i++) {
      input[i].disabled = true;
    }

    const select = parentNode.getElementsByTagName("select");
    for (let i = 0; i < select.length; i++) {
      select[i].disabled = true;
    }

    const textArea = parentNode.getElementsByTagName("textarea");
    for (let i = 0; i < textArea.length; i++) {
      textArea[i].disabled = true;
    }
    if (props.hasOwnProperty("updateEditor")) {
      props.updateEditor(true)
    }
  };
  return (
    <div className={"pl-1 mr-lg-5"}>
      <div className={"row  pr-lg-5 mx-lg-3 pt-3"}>
        <div
          className={"w-100 pb-4 pt-3 pr-4 contentholder background-colored-primary-white border-radius-10px clientSnapshotDiv-container-css"}
        >
          <i
            className="fas fa-edit  editbtn float-right font-weight-500 cursor-pointer font-size-16px"

            onClick={SaveDisplay}
          ></i>
          {props.children}
          <button
            type={"button"}
            className={"btn float-right save  mt-3 px-4 py-1 d-none"}

            onClick={editDisplay}
          >
            save
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ClientSnapshotDiv);
