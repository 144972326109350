import React from "react";
import { useSelector } from "react-redux";

const SnapshotDiv = (props) => {
  // return snapshop page div wrapper for data div
  const token = useSelector((state) => state.token);

  const SaveDisplay = (event) => {
    // for save button
    const parentNode = event.target.parentNode;
    parentNode.lastChild.style.display = "inline";
    event.target.style.display = "none";

    const input = parentNode.getElementsByTagName("input");
    for (let i = 0; i < input.length; i++) {
      input[i].disabled = false;
    }

    const select = parentNode.getElementsByTagName("select");
    for (let i = 0; i < select.length; i++) {
      select[i].disabled = false;
    }

    const textArea = parentNode.getElementsByTagName("textarea");
    for (let i = 0; i < textArea.length; i++) {
      textArea[i].disabled = false;
    }
  };
  const editDisplay = async (event) => {
    // for edit button
    const parentNode = event.target.parentNode;
    event.target.style.display = "none";
    event.target.parentNode.firstChild.style.display = "inline";

    if (
      props.hasOwnProperty("postData") &&
      props.hasOwnProperty("parsedData")
    ) {
      try {
        if (props.hasOwnProperty("social")) {
          Object.keys(props.parsedData).map((key) => {
            if (!props.parsedData[key]) delete props.parsedData[key];
          });
        }
        await props.postData(token, props.parsedData);
      } catch {}
    }
    const input = parentNode.getElementsByTagName("input");
    for (let i = 0; i < input.length; i++) {
      input[i].disabled = true;
    }

    const select = parentNode.getElementsByTagName("select");
    for (let i = 0; i < select.length; i++) {
      select[i].disabled = true;
    }

    const textArea = parentNode.getElementsByTagName("textarea");
    for (let i = 0; i < textArea.length; i++) {
      textArea[i].disabled = true;
    }
  };
  return (
    <div className={"pl-1 pr-5 mr-5"}>
      <div className={"row  pr-5 mx-3 pt-3"}>
        <div
          className={"w-100 pb-4 pt-3 pr-4 contentholder boxshadow-0-12-13-rgb border-radius-10px background-colored-primary-white"}
      
        >
          <i
            className="fas fa-edit  editbtn float-right font-weight-500 cursor-pointer font-size-16px"
            onClick={SaveDisplay}
          ></i>
          {props.children}
          <button
            type={"button"}
            className={"btn float-right save  mt-3 px-4 py-1 d-none"}
           
            onClick={editDisplay}
          >
            save
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SnapshotDiv);
