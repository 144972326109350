import { RECRUITERCLIENTREQUIREMENT } from "../../ENVIRONMENT";

// api for recruiter duplicate requirement
async function recduplicateRequirement(token, id) {
  const response = await fetch(
    RECRUITERCLIENTREQUIREMENT + `/duplicate/${id}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export { recduplicateRequirement };
