import React from "react";
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import UploadResume from './UploadResume';
import UserRegistrationForm from './UserRegistrationForm';
import BuildResume from './Resume-Builder/BuildResume';
import JobRequirment1 from './JobRequirment/JobRequirment1';
import JobRequirment2 from './JobRequirment/JobRequirment2';
import JobRequirment3 from './JobRequirment/JobRequirment3';
import JobRequirment4 from './JobRequirment/JobRequirment4';
import JobRequirment5 from './JobRequirment/JobRequirment5';
import JobRequirmentPreview from "./JobRequirment/JobRequirmentPreview";

const DashboardHome = () => {
  // return dashboardhome for new user
  const dispatch = useDispatch();
  dispatch({ type: "newusertrue" });
  const tablink = [
    "/freelance/dashboard/postjob",
    "/freelance/dashboard/postskill",
    "/freelance/dashboard/postacademic",
    "/freelance/dashboard/postsecurity",
    "/freelance/dashboard/postdescription",
    "/freelance/dashboard/postjobpreview",
    "/freelance/dashboard/editjob",
  ];
  try {
    document.getElementById("replacetitleimg").innerText = "Dashboard";
  } catch { }

  return (
    <React.Fragment>
      <Switch>
        <Route path={"/freelance/dashboard/register"}>
          <UserRegistrationForm link={"/freelance/dashboard/uploadresume"} />
        </Route>
        <Route path={"/freelance/dashboard/uploadresume"}>
          <UploadResume />
        </Route>
        <Route path={"/freelance/dashboard/buildresume"}>
          <BuildResume />
        </Route>
        <Route
          path={[
            "/freelance/dashboard/postjob/:refId",
            "/freelance/dashboard/postjob",
          ]}
        >
          <JobRequirment1 link={tablink} />
        </Route>
        <Route
          path={[
            "/freelance/dashboard/editjob/:id",
            "/freelance/dashboard/editjob",
          ]}
        >
          <JobRequirment1 link={tablink} />
        </Route>
        <Route
          path={[
            "/freelance/dashboard/postskill/:id/:refId",
            "/freelance/dashboard/postskill/:id",
          ]}
        >
          <JobRequirment2 link={tablink} />
        </Route>
        <Route
          path={[
            "/freelance/dashboard/postacademic/:id/:refId",
            "/freelance/dashboard/postacademic/:id",
          ]}
        >
          <JobRequirment3 link={tablink} />
        </Route>
        <Route
          path={[
            "/freelance/dashboard/postsecurity/:id/:refId",
            "/freelance/dashboard/postsecurity/:id",
          ]}
        >
          <JobRequirment4 link={tablink} />
        </Route>
        <Route
          path={[
            "/freelance/dashboard/postdescription/:id/:refId",
            "/freelance/dashboard/postdescription/:id",
          ]}
        >
          <JobRequirment5 link={tablink} />
        </Route>
        <Route
          path={[
            "/freelance/dashboard/postjobpreview/:id",
            "/freelance/dashboard/postjobpreview",
          ]}
        >
          <JobRequirmentPreview />
        </Route>
      </Switch>
    </React.Fragment>
  );
}
export default React.memo(DashboardHome);