import { CANDIDATEACADEMIC } from "../ENVIRONMENT";

async function postAcademic(token, data) {
  // api for submit candidate buildresume academic
  const response = await fetch(CANDIDATEACADEMIC, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
  return response;
}

async function getAcademic(token) {
  // api for candidate buildresume academic details
  const response = await fetch(CANDIDATEACADEMIC, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}
export { postAcademic, getAcademic };
