import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPermanentData } from "../../api/candidate/permanentData";
import { PERMANENTDATA } from "../../api/ENVIRONMENT";
import { toTitleCase } from "../../function/common/convertToTitleCase";

const PermanentData = () => {
  // return permanent data div which appear during buildresume
  const [information, setInformation] = useState({
    name: " ",
    candidateId: " ",
    resumeTitle: " ",
    email: " ",
    rate: " ",
    rateType: " ",
    location: " ",
    employmentType: " ",
    mobile: " ",
  });
  const token = useSelector((state) => state.token);
  const candidate = useSelector((state) => state.candidate);
  useEffect(() => {
    async function callItSelf() {
      try {
        const response = await getPermanentData(
          PERMANENTDATA.replace("candidate", candidate),
          token
        );
        if (response.status === 200) {
          let payload = await response.json();
          payload = payload.payload;
          if (payload.location.length > 20)
            payload.location = payload.location + "...";
          else if (payload.resumeTitle.length > 20) {
            payload.resumeTitle = payload.resumeTitle + "...";
          }
          Object.keys(payload).map((key) => {
            if (!payload[key]) payload[key] = " ";
          });
          setInformation({ ...information, ...payload });
        }
      } catch { }
    }
    callItSelf()
  }, []);

  return (
    <div className="pt-5">
      <div className={"row  pl-3 pr-4 py-5"}>
        <div
          className="pt-3 pb-3 pr-2 pl-0 background-colored-Pale-gray border-radius-10px candidate-perrmanentData-container"
        >
          <div className={"col"}>
            <div className={"row staticdatafontheader pl-4"}>
              Permanent Data &#62;
            </div>
            <div className={"row staticdatafontusername  mt-1"}>
              <div className={"col"}>
                {information.firstName &&
                  information.firstName.charAt(0).toUpperCase() +
                  information.firstName.slice(1).toLowerCase()}
                &nbsp;
                {information.middleName === "null"
                  ? ""
                  : information.middleName &&
                  information.middleName.charAt(0).toUpperCase() +
                  information.middleName.slice(1).toLowerCase()}
                &nbsp;
                {information.lastName &&
                  information.lastName.charAt(0).toUpperCase() +
                  information.lastName.slice(1).toLowerCase()}
              </div>
            </div>

            <div className={"row staticdatacommonfont mt-2"}>
              <div className={"col text-center"} >
                {information.candidateId}
              </div>
            </div>
            <div className={"row staticdatacommonfont mt-2"}>
              <div className={"col text-center"} >
                <span className="colored-Purple-Indigo">
                  {toTitleCase(information.resumeTitle)}
                </span>
              </div>
            </div>
            <div className={"staticdatacommonfont candidate-perrmanentData-email-wraper-div"}>
              <div

              >
                <span className="colored-Slate-gray" >
                  Email:{information.email}
                </span>
              </div>
            </div>
            <div className={"row mt-3 pl-3"}>
              <div className={"col-3 pl-0"}>
                <img
                  src="../../../icon/candidate/dashboard/dollar.png"
                  alt={"pound"}
                  className={"pl-2 pr-2 pt-2 pb-2 colored-Mint-green border-radius-10px"}
                  // style={{ backgroundColor: "#2BC155", borderRadius: "10px" }}
                />
              </div>
              <div className={"col"}>
                <div
                className="colored-Deep-slate font-weight-500 font-size-14px"
                
                >
                  ${information.rate}
                </div>
                <div
                 
                  className={"resumepreviewname colored-Slate-gray"}
                >
                  Per{" "}
                  {information.rateType &&
                    toTitleCase(information.rateType.substring(0, 4))}
                </div>
              </div>
            </div>
            <div className={"row mt-2 pl-3"}>
              <div className={"col-3 pl-0"}>
                <img
                  src="../../../icon/candidate/dashboard/whitelocation.png"
                  alt={"whitelocationicon"}
                  className={"pl-2 pr-2 pt-2 pb-2 background-colored-Sunset-orange border-radius-10px"}
                 
                />
              </div>
              <div className={"col"}>
                <div
                className="font-weight-500 font-size-14px olored-Deep-slate"
             
                >
                  {toTitleCase(information.location)}
                </div>
              </div>
            </div>
            <div className={"row mt-2 pl-2"}>
              <div className={"col-3 pl-0"}>
                <img
                  src="../../../icon/common/calendar.png"
                  alt={"smallcalendar"}
                  className={"pl-1 pr-1 pt-1 pb-1 background-colored-Pale-sky-blue border-radius-10px candidate-perrmanentData-calender-icon"}
             
                />
              </div>
              <div className={"col mt-3"}>
                <div
                className="font-weight-500 font-size-14px colored-Deep-slate"
              
                >
                  {information.employmentType &&
                    information.employmentType.replace(/_/g, " ")}
                </div>
              </div>
            </div>
            <div className={"row mt-2 pl-3"}>
              <div className={"col-3 pl-0"}>
                <img
                  src="../../../icon/candidate/dashboard/call.png"
                  alt={"call"}
                  className={"pl-2 pr-2 pt-2 pb-2 background-colored-Lavender-mist border-radius-10px"}
                 
                />
              </div>
              <div className={"col mt-2"}>
                <div
                className="colored-Graphite-gray font-weight-500 font-size-12px"
                >
                  {information.mobile}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(PermanentData);
