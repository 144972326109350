import "../../styles/app.scss";
import React from "react";
import { Link } from "react-router-dom";

const Register = () => (
  // after successful signup this page will be return
  <div className="main-container" style={{ height: "100vh" }}>
    <div className="col-md-12 h-100">
      <div className="row h-100">
        <div className="col-lg-5" style={{ background: "#F7F7FC" }}>
          <div className="row pt-5 pl-5">
            <div className="col-md-10">
              <label
                style={{
                  fontWeight: "bold",
                  fontSize: "39px",
                  fontFamily: "Mulish",
                  color: "#11142D",
                }}
              >
                Manage your job and team in easy way
              </label>
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-md">
              <img
                src="/icon/Authentication/Register.jpg"
                alt="Registration"
                className="img-fluid mx-auto d-block p-4 rounded-circle"
              />
            </div>
          </div>
        </div>
        <div
          className="col-lg-7"
          style={{ background: "#FFFFFF", fontFamily: "Mulish" }}
        >
          <div className="row pl-5 pt-5">
            <div className="col-md">
              <img
                src="/icon/Authentication/DirectHire_Dark_logo.png"
                className="img-fluid"
                alt="dollar"
                width="35%"
              />
            </div>
          </div>
          <div className="row pt-5 pl-5">
            <div className="col-md pt-5">
              <label
                style={{
                  fontWeight: "bold",
                  fontSize: "31px",
                  color: "#11142D",
                }}
              >
                Registration completed
              </label>
            </div>
          </div>
          <div
            style={{ fontWeight: "normal", fontSize: "16px", color: "#92929D" }}
          >
            <div className="row pl-5 pr-5 pt-3">
              <div className="col-md-9">
                <label>
                  We have sent an email with a confirmation link, please click
                  on the link to activate your account
                </label>
              </div>
            </div>
            <div className="row pt-2 pl-5 pr-5">
              <div className="col-md">
                <label>
                  If you did not receive a confirmation email, please check your
                  spam folder.
                </label>
              </div>
            </div>
            <div className={"row pt-2 pl-5"}>
              <div className={"col-md"}>
                <label>
                  {" "}
                  If you need assistance, please{" "}
                  <label style={{ color: "#3F9AE0" }}>contact us</label>
                </label>
              </div>
            </div>
          </div>
          <div className="row pt-3 pl-5 pb-5">
            <div className="col-md">
              <Link
                to={"/user/login"}
                className={"btn mr-5 saveregister px-4 py-2"}
              >
                <label>Continue to Login</label>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default React.memo(Register);
