import { RECRUITERCLIENTREQUIREMENT } from "../../ENVIRONMENT";

// api for recruiter delete requirement
async function recdeleteRequirment(token, id) {
  const response = await fetch(RECRUITERCLIENTREQUIREMENT + `/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}
// api for recruiter delete associate requirement
async function recdeleteAssociatedRequirment(token, id, data) {
  const response = await fetch(RECRUITERCLIENTREQUIREMENT + `/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
  return response;
}

export { recdeleteRequirment, recdeleteAssociatedRequirment };
