import { FREELANCECANDIDATEDETAILS } from "../../ENVIRONMENT";

// api for freelance candidatelist sorting
async function freecandidateListSorting(token, currentPage, name, order) {
  const response = await fetch(
    FREELANCECANDIDATEDETAILS + `?limit=6&page=${currentPage}&${name}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export { freecandidateListSorting };
