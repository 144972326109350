import React from "react";
import { Show, Hide } from "../../../function/common/HideShow";
const JobApplyNewContent = (props) => {
  // return jobapply nextbutton new content

  try {
    document.getElementsByClassName("applyalignment")[0].style.cssText =
      "margin-top:0px";
  } catch { }

  const information = props.information;
  const setInformation = props.setInformation;
  const radio = props.radio;
  const setRadio = props.setRadio;

  function handleInformation(event, setInformation) {
    const { name, value } = event.currentTarget;
    setInformation(
      state => {
        return { ...state, [name]: value }
      }
    )
  }
  function handleRadio(e) {

    // Grab the nodeName and value from
    // the clicked element
    const { nodeName, value } = e.target;

    // Because we're using event delegation (attaching
    // an event listener to a parent element and capturing
    // events from child elements as they "bubble up" the DOM)
    // we need to check to see if the clicked element is an input
    if (nodeName === 'INPUT') {

      // Set the state with the input value
      setRadio(value);
    }
  }


  return (
    <React.Fragment>
      <form className="ml-4">
        <div className={"row  pt-5 "}>
          <div className={"col-md"}>
            <div
              className={"btn globalfontrule font-size-08 border-radius-05rem colored-lavender-gray To-avoid-duplicate-text "}

            >
              To avoid duplicate submission please answer the following
            </div>
          </div>
        </div>
        <div className={"row pt-4 pl-2"}>
          <div className={"col-md-6 pr-0"}>
            <div className={"ml-0"}>
              <label className={"pr-3 parafont"}>
                In Last 3 months have you applied for similar job
              </label>
              {/* <input type="text" className={"previewfield"} 
            name={"firstJob"}
            value={information.firstJob}
            onChange={(event)=>{handleInformation(event,setInformation)}}/> */}
            </div>
          </div>
          <div className={"col-md form-inline"} onChange={handleRadio}>
            <input
              type="radio"
              className="resumeradio"
              id="customRadio"
              name="applied"
              value="yes"
            />
            <label
              className="dashboardforminputtext pl-2 pr-3"
              htmlFor="customRadio"
            >
              Yes
            </label>
            <input
              type="radio"
              className="resumeradio"
              id="customRadio0"
              name="applied"
              value="no"
            />
            <label className="dashboardforminputtext pl-2" htmlFor="customRadio0">
              No
            </label>
          </div>
        </div>
        {/* <div id={"similarjob"}>
        <div className={"row pt-4 pl-5"}>
          <div className={"col-md-6 pr-0"}>
            <div className={"ml-4"}>
              <label className={"pr-3 parafont"}>
                Did you applied for Similar job in
              </label>
              <input type="text" className={"previewfield"} 
              name={"secondJob"}
              value={information.secondJob}
              onChange={(event)=>{handleInformation(event,setInformation)}}/>
            </div>
          </div>
        </div>
        <div className={"row pt-4 pl-5 ml-1"}>
          <div className={"pl-4"}>
            <label className={"pr-3 parafont"}>
              Please select the client name following
            </label>
          </div>
        </div>
        <div onChange={handleRadio}>
        <div className={"row pt-2 pl-5 ml-3"}>
          <div className={"col-md form-inline"}>
            <input
              type="radio"
              className="resumeradio"
              id="customRadio"
              name="client"
              value="client 1"
            />
            <label
              className="dashboardforminputtext pl-2 pr-3"
              htmlFor="customRadio"
            >
              Client 1
            </label>
          </div>
        </div>
        <div className={"row pt-2 pl-5 ml-3"}>
          <div className={"col-md form-inline"}>
            <input
              type="radio"
              className="resumeradio"
              id="customRadio"
              name="client"
              value="client 2"
            />
            <label
              className="dashboardforminputtext pl-2 pr-3"
              htmlFor="customRadio"
            >
              Client 2
            </label>
          </div>
        </div>
        <div className={"row pt-2 pl-5 ml-3"}>
          <div className={"col-md form-inline"}>
            <input
              type="radio"
              className="resumeradio"
              id="customRadio"
              name="client"
              value="client 3"
            />
            <label
              className="dashboardforminputtext pl-2 pr-3"
              htmlFor="customRadio"
            >
              Client 3
            </label>
          </div>
        </div>
        <div className={"row pt-2 pl-5 ml-3"}>
          <div className={"col-md form-inline"}>
            <input
              type="radio"
              className="resumeradio"
              id="customRadio"
              name="client"
              value="client 4"
            />
            <label
              className="dashboardforminputtext pl-2 pr-3"
              htmlFor="customRadio"
            >
              Client 4
            </label>
          </div>
        </div>
        </div>
      </div> */}
      </form>
    </React.Fragment>
  );
};

export default React.memo(JobApplyNewContent);
