import { verifyValidate } from "../recruiter/checkRequired";
import { freeregisterForm } from "../../api/freelance/registerForm";
import { toast } from "react-toastify";

function submitProfile(
  event,
  history,
  setLoader,
  savebtnRef,
  error,
  information,
  setError,
  addmore,
  addMoreInformation,
  setAddMoreError,
  token
) {
  event.preventDefault();
  let registrationValue = {
    companyName: information.companyName,
    feinNumber: information.fein,
    website: information.companyWebsite,
    workPhone: information.workPhone,
    fax: information.fax,
    firstName: information.firstName,
    middleName: information.middleName,
    lastName: information.lastName,
    mobile: information.mobileNumber,
    requirementTitle: information.title,
    logo: information.logo,
    profilePicture: information.profilePicture,
    addresses: [
      {
        addressLine1: information.addressLine1,
        addressLine2: information.addressLine2,
        zipCode: information.zipCode,
        city: information.city,
        state: information.state,
        county: information.county,
        country: information.country,
        isPrimary: information.isPrimaryAddress,
      },
    ],
  };
  if (addmore) {
    const pushData = registrationValue.addresses.push({
      addressLine1: addMoreInformation.addressLine1,
      addressLine2: addMoreInformation.addressLine2,
      zipCode: addMoreInformation.zipCode,
      city: addMoreInformation.city,
      state: addMoreInformation.state,
      county: addMoreInformation.county,
      country: addMoreInformation.country,
      isPrimary: !information.isPrimaryAddress,
    });
    registrationValue = { addresses: pushData, ...registrationValue };
  }
  let filteredValue = {};
  Object.keys(registrationValue)
    .filter((key) => {
      const value = registrationValue[key];
      if (value || value === false) {
        return true;
      } else {
        return false;
      }
    })
    .map((key) => {
      filteredValue = { [key]: registrationValue[key], ...filteredValue };
    });
  let flag = true;
  if (
    !verifyValidate(
      information,
      setError,
      addmore,
      addMoreInformation,
      setAddMoreError
    )
  )
    return;
  const result = Object.values(error)
    .map((data) => {
      if (data) return true;
      return false;
    })
    .some((data) => {
      if (data) return true;
      return false;
    });
  if (addmore) {
    flag = Object.keys(addMoreInformation)
      .map((key) => {
        if (key !== "addressLine2" && key !== "county")
          return addMoreInformation[key] ? true : false;
      })
      .includes(false);
    flag = !flag;
  }
  if (!result && flag) {
    if (savebtnRef.current) {
      savebtnRef.current.setAttribute("disabled", "disabled");
    }
    setLoader(true);
    freeregisterForm(token, filteredValue).then((res) => {
      if (res.status === 200) {
        savebtnRef.current.removeAttribute("disabled");
        toast.success("Saved");
      } else {
        savebtnRef.current.removeAttribute("disabled");
        toast.error("please check all fields");
      }
      setLoader(false);
    });
  }
}
export { submitProfile };
