import { verifyValidate } from "./checkRequired";
import { registerForm } from "../../api/recruiter/registerForm";
import { toast } from "react-toastify";
import { freeregisterForm } from "../../api/freelance/registerForm";

// validation field
function validateRegistration(event, setInfo, setError) {
  // validate field to check wherther meet requirement or not
  let { name, value } = event.currentTarget;
  if (
    name === "workPhone" ||
    name === "fax" ||
    name === "zipCode" ||
    name === "fein"
  ) {
    //  for number validation
    setError((state) => {
      const result = value.replace(/\D/g, "")
        if (name === "zipCode") {
          if (result.length !== 0 && result.length !== 5)
            return { ...state, [name]: "Must be 5 digits" };
          return { ...state, [name]: "" };
        }
        else {
          return { ...state, [name]: "" };
        }
    });
  } 
  // else if (name === "mobileNumber") {
  //   setError((state) => {
  //     if (value.replace(/\s/g, "").length !== 10)
  //       return { ...state, [name]: "length should exact 10" };
  //     return { ...state, [name]: "" };
  //   });
  // } 
  else {
    // for text validation
    if (
      /\d/.test(value) &&
      name !== "companyName" &&
      name !== "addressLine1" &&
      name !== "addressLine2" &&
      name !== "companyWebsite" &&
      name !== "email" &&
      name !== "title"
    ) {
      setError((state) => {
        return { ...state, [name]: "Numbers & special characters are not allowed" };
      });
    }
    // else if (name === "companyName") {
    //   if (/[^a-zA-Z0-9\s.-]/.test(value))
    //     setError((state) => {
    //       return { ...state, [name]: "Symbol not allowed" };
    //     });
    //   else
    //     setError((state) => {
    //       return { ...state, [name]: "" };
    //     });
    // }
    else if (
      name === "firstName" ||
      name === "middleName" ||
      name === "lastName" ||
      name === "city" ||
      name === "county" ||
      name === "state" ||
      name === "country"
    ) {
      if (/[^a-zA-Z\s.-]/.test(value))
        setError((state) => {
          return { ...state, [name]: "Numbers & special characters are not allowed" };
        });
      else
        setError((state) => {
          return { ...state, [name]: "" };
        });
    } else {
      // email validation
      if (name === "email") {
        setError((state) => {
          if (
            /^([a-zA-Z0-9_+.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
              value
            )
          )
            return { ...state, [name]: "" };
          return { ...state, [name]: "Please enter valid email" };
        });
      } 
      else if (name === "title") {
        setError((state) => {
        if (
          /^[ A-Za-z0-9#.+-]*$/.test(
            value
          )
        )
          return { ...state, [name]: "" };
        return { ...state, [name]: "Special characters (#, +, -, .) are only allowed" };
        })
      } 
      else if (name === "companyWebsite" && value) {
        setError((state) => {
          if (
            /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/.test(
              value
            )
          )
            return { ...state, [name]: "" };
          return { ...state, [name]: "Enter valid URL" };
        });
      } else {
        setError((state) => {
          return { ...state, [name]: "" };
        });
      }
    }
  }
  if(name.endsWith("zipCode")) {
    setInfo((state) => {
      return { ...state, [name]: value.replace(/\D/g,"") };
    });
  }
  else {
    setInfo((state) => {
      return { ...state, [name]: value };
    });
  }
}

function submit(
  event,
  history,
  error,
  information,
  setError,
  addmore,
  addMoreInformation,
  setAddMoreError
) {
  event.preventDefault();
  let flag = true;
  if (
    !verifyValidate(
      information,
      setError,
      addmore,
      addMoreInformation,
      setAddMoreError
    )
  )
    return;
  const result = Object.values(error)
    .map((data) => {
      if (data) return true;
      return false;
    })
    .some((data) => {
      if (data) return true;
      return false;
    });
  if (addmore) {
    flag = Object.keys(addMoreInformation)
      .map((key) => {
        if (key !== "addressLine2" && key !== "county")
          return addMoreInformation[key] ? true : false;
      })
      .includes(false);
    flag = !flag;
  }
  if (!result && flag) return true;
}

//proflie
function submitProfile(
  event,
  history,
  setLoader,
  savebtnRef,
  error,
  information,
  setError,
  addmore,
  addMoreInformation,
  setAddMoreError,
  token,
  isFreelance
) {
  event.preventDefault();
  let registrationValue = {
    companyName: information.companyName,
    feinNumber: information.fein,
    website: information.companyWebsite,
    workPhone: information.workPhone,
    fax: information.fax,
    firstName: information.firstName,
    middleName: information.middleName,
    lastName: information.lastName,
    mobile: information.mobileNumber,
    requirementTitle: information.title,
    logo: information.logo,
    profilePicture: information.profilePicture,
    addresses: [
      {
        addressLine1: information.addressLine1,
        addressLine2: information.addressLine2,
        zipCode: information.zipCode,
        city: information.city,
        state: information.state,
        county: information.county,
        country: information.country,
        isPrimary: information.isPrimaryAddress,
      },
    ],
  };
  if (addmore) {
    const pushData = registrationValue.addresses.push({
      addressLine1: addMoreInformation.addressLine1,
      addressLine2: addMoreInformation.addressLine2,
      zipCode: addMoreInformation.zipCode,
      city: addMoreInformation.city,
      state: addMoreInformation.state,
      county: addMoreInformation.county,
      country: addMoreInformation.country,
      isPrimary: !information.isPrimaryAddress,
    });
    registrationValue = { addresses: pushData, ...registrationValue };
  }
  let filteredValue = {};
  Object.keys(registrationValue)
    .filter((key) => {
      const value = registrationValue[key];
      if (value || value === false) {
        return true;
      } else {
        return false;
      }
    })
    .map((key) => {
      filteredValue = { [key]: registrationValue[key], ...filteredValue };
    });
  let flag = true;
  if (
    !verifyValidate(
      information,
      setError,
      addmore,
      addMoreInformation,
      setAddMoreError
    )
  )
    return;
  const result = Object.values(error)
    .map((data) => {
      if (data) return true;
      return false;
    })
    .some((data) => {
      if (data) return true;
      return false;
    });
  if (addmore) {
    flag = Object.keys(addMoreInformation)
      .map((key) => {
        if (key !== "addressLine2" && key !== "county")
          return addMoreInformation[key] ? true : false;
      })
      .includes(false);
    flag = !flag;
  }
  if (!result && flag) {
    if (savebtnRef.current) {
      savebtnRef.current.setAttribute("disabled", "disabled");
    }
    setLoader(true);
    const response = isFreelance
      ? freeregisterForm(token, filteredValue)
      : registerForm(token, filteredValue);
    response.then((res) => {
      if (res.status === 200) {
        savebtnRef.current.removeAttribute("disabled");
        setLoader(false);
        toast.success("Saved");
      } else {
        savebtnRef.current.removeAttribute("disabled");
        setLoader(false);
        toast.error("please check all fields");
      }
    });
  }
}
export { validateRegistration, submit, submitProfile };
