import { CLIENTACADEMIC } from "../ENVIRONMENT";

function academicList(token) {
  // api for client academic list details
  const response = fetch(CLIENTACADEMIC, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { academicList };
