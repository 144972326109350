import { CLIENTDASHBOARDREQUIREMENT } from "../ENVIRONMENT";

// api for client download requirement
async function downloadRequirement(token, id) {
  const response = await fetch(CLIENTDASHBOARDREQUIREMENT + `/download/${id}`, {
    observe: "response",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
      "Content-Type": "text/csv",
    },
  });
  return response;
}

export { downloadRequirement };
