const MatchingJobSelectAllData = (event) => {
  // header checkbox select all body checkbox if we click on it
  const chkbtnobj = document.getElementsByClassName("checkbx");
  if (chkbtnobj[0].checked) {
    for (let obj of chkbtnobj) {
      obj.checked = true;
    }
    for (let obj of document.getElementsByClassName("box")) {
      obj.style.cssText = "background-color:rgba(196, 196, 196, 0.3);";
    }
    for (let obj of document.getElementsByClassName("hidebox")) {
      obj.style.cssText = "display:none";
    }
    for (let obj of document.getElementsByClassName("showbox")) {
      obj.style.cssText = "display:inline";
    }
  } else {
    for (let obj of chkbtnobj) {
      obj.checked = false;
    }
    for (let obj of document.getElementsByClassName("box")) {
      obj.style.cssText = "background-color:white";
    }
    for (let obj of document.getElementsByClassName("hidebox")) {
      obj.style.cssText = "display:inline";
    }
    for (let obj of document.getElementsByClassName("showbox")) {
      obj.style.cssText = "display:none";
    }
  }
};
export { MatchingJobSelectAllData };
