import { LOCATION } from "../ENVIRONMENT";
// api for location
function locationList(token) {
  const response = fetch(LOCATION, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}
// api for search locationlist 
function locationListSearch(token, data) {
  const response = fetch(LOCATION + `?q=${data}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { locationList, locationListSearch };
