import { CLIENTDASHBOARDREQUIREMENT } from "../ENVIRONMENT";

// api for client duplicate requirement
async function duplicateRequirement(token, id) {
  const response = await fetch(
    CLIENTDASHBOARDREQUIREMENT + `/duplicate/${id}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export { duplicateRequirement };
