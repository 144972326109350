import React from "react";

const ViewDetails = (props) => {
  return (
    <div className={"col mx-5 mt-5"}>
      <div className="w-100 pt-4 pb-0 px-5 contentholder">
        <label
          className="font-weight-500 viewDetails-textcss-design colored-charcoal-gray "

        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores ipsa,
          possimus esse, similique et ratione temporibus deserunt quia adipisci,
          aliquid quos maiores iste architecto provident illum iure quis sit
          officia.
          <br /> Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam
          eum ab saepe, odit dolorum neque vel voluptatem ea illo, suscipit
          ipsum cum delectus praesentium perferendis harum! Beatae eveniet
          asperiores libero!
          <br /> Lorem ipsum, dolor sit amet consectetur adipisicing elit.
          Minima molestiae voluptatibus, eveniet soluta veritatis dolores ullam
          ad explicabo magni necessitatibus optio, fugit sequi. Ad veritatis
          ipsa dolorum repellendus, beatae autem.
        </label>
      </div>
    </div>
  );
};

export default React.memo(ViewDetails);
