import { verifyValidate } from "./checkRequired";
import { notificationRegister, register } from "../../api/candidate/register.js";
import { updateProfileDetails } from "../../api/candidate/updateProfileDetails.js";
import { getProfileDetails } from "../../api/candidate/getProfileDetails";
import { snapshot } from "../../api/candidate/snapshot";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postBuildResumeSkill } from "../../api/candidate/buildResumeSkill";
import { getSkillParseData } from "./getSkillParseData";

// validation field
function validateRegistration(event, setInfo, setError) {
  // validate field to check wherther meet requirement or not
  let { name, value } = event.currentTarget;
  setError((state) => {
    if (name === "mobileNumber" || name === "phone") {
      if (value.replace(/\s/g, "").length !== 10)
        return { ...state, [name]: "Must be 10 digits" };
      return { ...state, [name]: "" };
    } else if (
      name === "SSNNumber" ||
      name === "zipCode" ||
      name === "empzipCode" ||
      name === "fein"
    ) {
      //  for number validation

      // if (/\D/.test(value)) {
      //   return { ...state, [name]: "alphabet not allowed" };
      // } else {
        const result = value.replace(/\D/g, "")
        if (name === "empzipCode" || name === "zipCode") {
          if (result.length !== 0 && result.length !== 5)
            return { ...state, [name]: "Must be 5 digits" };
          return { ...state, [name]: "" };
        } else if (name === "SSNNumber") {
          if (result.length !== 0 && result.length !== 4)
            return { ...state, [name]: "Must be 4 digits" };
          return { ...state, [name]: "" };
        } else if (name === "fein") {
          if (value.length !== 9)
            return { ...state, [name]: "Must be 9 digits" };
          return { ...state, [name]: "" };
        } else {
          return { ...state, [name]: "" };
        }
      // }
    } else if (name === "website") {
      // email validation

      if (name === "website") {
        if (
           ///(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
            //(/^www\.(.*)\.com$/).test(
            /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/.test(
            value
          )
        )
          return { ...state, [name]: "" };
        return { ...state, [name]: "Enter valid URL" };
      } else {
        return { ...state, [name]: "" };
      }
    } else {
      // for text validation
      if (
        /\d/.test(value) &&
        name !== "mobileNumber" &&
        name !== "addressLine1" &&
        name !== "addressLine2" &&
        name !== "empaddressLine1" &&
        name !== "empaddressLine2" &&
        name !== "email" &&
        name !== "companyName" &&
        name !== "resumeTitle" &&
        name !== "currentLocation"
      ) {
        return { ...state, [name]: "Numbers & special characters are not allowed" };
      } else if (
        name === "firstName" ||
        name === "middleName" ||
        name === "lastName" ||
        name === "city" ||
        name === "state" ||
        name === "county" ||
        name === "country" ||
        name === "empcity" ||
        name === "empstate" ||
        name === "empcounty" ||
        name === "empcountry" ||
        name === "preferredLocation" ||
        name === "contactPerson"
      ) {
        if (/[^a-zA-Z\s]/.test(value))
          return { ...state, [name]: "Numbers & special characters are not allowed" };
          else return { ...state, [name]: "" };
      } else {
        // email validation
        if (name === "email") {
          if (
            /^([a-zA-Z0-9+_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
              value
            )
          )
            return { ...state, [name]: "" };
          return { ...state, [name]: "Please enter valid email ID" };
        } 
        else if (name === "resumeTitle") {
          if (
            /^[ A-Za-z0-9#.+-]*$/.test(
              value
            )
          )
            return { ...state, [name]: "" };
          return { ...state, [name]: "Special characters (#, +, -, .) are only allowed" };
        } 
      else {
          return { ...state, [name]: "" };
        }
      }
    }
  });
  if(name.endsWith("zipCode")) {
    setInfo((state) => {
      return { ...state, [name]: value.replace(/\D/g,"") };
    });
  }
  else if(name === "currentLocation") {
    setInfo((state) => {
      return { ...state, [name]: value.replace(/[^a-zA-Z\s.-]/,"") };
    });
  }
  else {
    setInfo((state) => {
      return { ...state, [name]: value };
    });
  }
}

const rateValError = [
  "W2Witherror",
    "W2Withouterror",
    "c2herror",
    "c2cerror",
    "W2Hourlyerror",
    "W2Hourlywithouterror",
    "1099error",
    "1099Withouterror"
];

const rateValCheckbox = [
  "W2WithCheck",
  "W2WithoutCheck",
  "C2HCheck",
  "C2CCheck",
  "W2HourlyWithCheck",
  "W2HourlyWithoutCheck",
  "N1099WithCheck",
  "N1099WithoutCheck"
];

async function submit(
  event,
  btnRef,
  history,
  setLoader,
  error,
  setErrorFlag,
  information,
  setError,
  addmore,
  addMoreInformation,
  setAddMoreError,
  visibility,
  primaryInformation,
  setPrimaryError,
  setSecondaryError,
  primaryError,
  secondaryError,
  visible,
  secondaryVisible,
  secondaryInfo,
  date,
  date0,
  date1,
  date2,
  secondaryDate,
  secondaryDate0,
  secondaryDate1,
  secondaryDate2,
  token
) {
  event.preventDefault();
  console.log(error)
  const target = event.target;
  const rateError = document.getElementById("rateerror").innerHTML
  try {
    if(target.workauth.value === "Select") {
      document.getElementById("workautherror").innerHTML ="Please enter the work authorization"
      document.getElementsByClassName("selectworkauth")[0].style.cssText = "border: 1px solid red"
      document.getElementById("employmenterror").innerHTML ="Please enter the employment type"
      document.getElementsByClassName("selectemploy")[0].style.cssText = "border: 1px solid red"
    }
    if(target.duration.value === "Select") {
      document.getElementById("rateTypeerror").innerHTML ="Please enter the rate type"
      document.getElementsByClassName("selectrateType")[0].style.cssText = "border: 1px solid red"
    }
   if(target.rate.value === "") {
      document.getElementById("rateerror").innerHTML ="Please enter the rate"
      document.getElementsByClassName("ratefield")[0].style.cssText = "border: 1px solid red"
    }
    const rateTableError = rateValError.map((key) => {
      const field = document.getElementsByClassName(`${key}`)[0].innerHTML
      if(field !== ""){
        return false;
      }
      else {
        return true
      }
    })
    var rateTableCheckBox = [];
    rateValCheckbox.map((key) => {
       rateTableCheckBox = [...rateTableCheckBox,document.getElementsByName(`${key}`)[0].checked]
    })
    const TotalCheckValue = rateTableCheckBox.every(value => value === false)
    if(TotalCheckValue && target.rate.value !== "" && rateError === "") {
      document.getElementsByClassName("totalCheckError")[0].innerHTML = "Select at least one checkbox"
    }
    const relocation = target.relocation.value === "Yes" ? true : false;
    let registerData = {
      firstName: information.firstName,
      middleName: information.middleName,
      lastName: information.lastName,
      mobile: information.mobileNumber,
      resumeTitle: information.resumeTitle,
      ssnNumber: information.SSNNumber,
      haveAnEmployer: visibility,
      workAuthorization: target.workauth.value,
      preferredEmployment: target.taxterm.value,
      rateType: target.duration.value,
      rate: +target.rate.value,
      currentLocation: information.currentLocation,
      reLocationPreference: relocation,
      travelPreference: target.travellocation.value,

      addresses: [
        {
          addressLine1: information.addressLine1,
          addressLine2: information.addressLine2,
          zipCode: information.zipCode,
          city: information.city,
          state: information.state,
          county: information.county,
          country: information.country,
          isPrimary: information.isPrimaryAddress,
        },
      ],
    };
    if(relocation) {
       registerData = {
        preferredLocation: information.preferredLocation,
        ...registerData
      }
    }
    if (target.W2WithCheck.checked) {
      registerData = {
        W2With: +target.W2With.value,
        ...registerData,
      };
    }
    if (target.W2WithoutCheck.checked) {
      registerData = {
        W2Without: +target.W2Without.value,
        ...registerData,
      };
    }
    if (target.W2HourlyWithCheck.checked) {
      registerData = {
        W2HourlyWith: +target.W2HourlyWith.value,
        ...registerData,
      };
    }
    if (target.W2HourlyWithoutCheck.checked) {
      registerData = {
        W2HourlyWithout: +target.W2HourlyWithout.value,
        ...registerData,
      };
    }
    if (target.C2HCheck.checked) {
      registerData = {
        C2H: +target.C2H.value,
        ...registerData,
      };
    }
    if (target.C2CCheck.checked) {
      registerData = {
        C2C: +target.C2C.value,
        ...registerData,
      };
    }
    if (target.N1099WithCheck.checked) {
      registerData = {
        N1099With: +target.N1099With.value,
        ...registerData,
      };
    }
    if (target.N1099WithoutCheck.checked) {
      registerData = {
        N1099Without: +target.N1099Without.value,
        ...registerData,
      };
    }
    if (visibility) {
      registerData = {
        companyName: information.companyName,
        feinNumber: information.fein,
        website: information.website,
        contactPerson: information.contactPerson,
        contactEmail: information.email,
        contactPhone: information.phone,
        zipCode: information.empzipCode,
        addressLine1: information.empaddressLine1,
        addressLine2: information.empaddressLine2,
        city: information.empcity,
        county: information.empcounty,
        state: information.empstate,
        country: information.empcountry,
        ...registerData,
      };
    }
    if (addmore) {
      registerData.addresses.push({
        addressLine1: addMoreInformation.addressLine1,
        addressLine2: addMoreInformation.addressLine2,
        zipCode: addMoreInformation.zipCode,
        city: addMoreInformation.city,
        state: addMoreInformation.state,
        county: addMoreInformation.county,
        country: addMoreInformation.country,
        isPrimary: !information.isPrimaryAddress,
      });
    }
    let temp = registerData.addresses[0];
    if (!temp.addressLine2) delete temp.addressLine2;
    if (!temp.county) delete temp.county;
    try {
      temp = registerData.addresses[1];
      if (!temp.addressLine2) delete temp.addressLine2;
      if (!temp.county) delete temp.county;
    } catch {}
    let filteredValue = {};
    Object.keys(registerData)
      .filter((key) => {
        const value = registerData[key];
        if (
          registerData.workAuthorization === "Select" &&
          (key === "workAuthorization" ||
            key === "preferredEmployment" ||
            key === "rateType" ||
            key === "rate")
        )
          return false;
        else if (value || value === false) return true;
        return false;
      })
      .map((key) => {
        filteredValue = { [key]: registerData[key], ...filteredValue };
      });
    let flag = true;
    if (
      !verifyValidate(
        event,
        information,
        setError,
        addmore,
        addMoreInformation,
        setAddMoreError,
        visibility
      ) 
    )
      return;
    const result = Object.values(error)
      .map((data) => {
        if (data) return true;
        return false;
      })
      .some((data) => {
        if (data) return true;
        return false;
      });
    if (addmore) {
      flag = Object.keys(addMoreInformation)
        .map((key) => {
          if (key !== "addressLine2" && key !== "county")
            return addMoreInformation[key] ? true : false;
        })
        .includes(false);
      flag = !flag;
    }

    // //skill validation
    Object.keys(primaryInformation).map((key) => {
      setPrimaryError((state) => {
        if (!visible.one && /0$/.test(key)) return state;
        if (!visible.two && /1$/.test(key)) return state;
        if (!visible.three && /2$/.test(key)) return state;
        if (primaryInformation[key] === "") {
          return {
            ...state,
            [key]: `Please enter the ${key.replace(/\d$/, "")} `,
          };
        } else return state;
      });
      return null;
    });
    const flag1 = Object.keys(primaryError)
      .map((key) => {
        if (!visible.one && /0$/.test(key)) return null;
        if (!visible.two && /1$/.test(key)) return null;
        if (!visible.three && /2$/.test(key)) return null;
        return primaryError[key] !== "" ? true : false;
      })
      .includes(true);
    const flag2 = Object.keys(primaryInformation)
      .map((key) => {
        if (!visible.one && /0$/.test(key)) return null;
        if (!visible.two && /1$/.test(key)) return null;
        if (!visible.three && /2$/.test(key)) return null;
        return primaryInformation[key] !== "" ? true : false;
      })
      .includes(false);

    
    setSecondaryError((state) => {
      if (!secondaryVisible.one) state = { ...state, secondarySkill0: "" };
      if (!secondaryVisible.two) state = { ...state, secondarySkill1: "" };
      if (!secondaryVisible.three) state = { ...state, secondarySkill2: "" };
      return state;
    });

    const primaryResult = Object.keys(secondaryError)
      .map((key) => {
        return secondaryError[key] ? true : false;
      })
      .includes(true);

    //skill
    const parseData = getSkillParseData(
      primaryInformation,
      secondaryInfo,
      secondaryVisible,
      date,
      date0,
      date1,
      date2,
      secondaryDate,
      secondaryDate0,
      secondaryDate1,
      secondaryDate2
    );
    var valueArr = parseData.primarySkill.map(function (item) {
      return item.skill.toLowerCase();
    });
    var isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) != idx;
    });
    if (parseData.secondarySkill) {
      var valueSecArr = parseData.secondarySkill.map(function (item) {
        return item.skill.toLowerCase();
      });
      var isSecDuplicate = valueSecArr.some(function (item, idx) {
        return valueSecArr.indexOf(item) != idx;
      });
      var isBothDuplicate = parseData.primarySkill.filter((o1) =>
        parseData.secondarySkill.some(
          (o2) => o1.skill.toLowerCase() === o2.skill.toLowerCase()
        )
      );
    }

    if (!result && flag && !flag1 && !flag2 && !primaryResult && rateError === "" && !rateTableError.includes(false) && !TotalCheckValue) {
      if (btnRef.current) {
        btnRef.current.setAttribute("disabled", "disabled");
      }
      if (isDuplicate) {
        document.getElementsByClassName("primaryerr")[0].innerText =
          "Duplicate primary skills are not allowed";
        btnRef.current.removeAttribute("disabled");
      } else if (isSecDuplicate) {
        document.getElementsByClassName("primaryerr")[0].innerText =
          "Duplicate secondary skills are not allowed";
        btnRef.current.removeAttribute("disabled");
      } else if (isBothDuplicate && isBothDuplicate.length !== 0) {
        document.getElementsByClassName("primaryerr")[0].innerText =
          "Duplicate skills are not allowed";
        btnRef.current.removeAttribute("disabled");
      } else {
        try {
          setLoader(true);
          const profileResponse = await getProfileDetails(token);
          if (profileResponse.status === 200) {
            const data = await profileResponse.json();
            const payload = data.payload;
            if (payload.resumeTitle === null) {
              //console.log("yes");
            }
            const response =
              payload.resumeTitle === null
                ? await notificationRegister(token, filteredValue)
                : await register(token, filteredValue);
            const Primaryresponse = await postBuildResumeSkill(
              token,
              parseData
            );
            if (response.status === 200 && Primaryresponse.status === 200) {
              history.push("/candidate/dashboard/uploadresume");
            } else if (response.status === 422) {
              document.getElementsByClassName("primaryerr")[0].innerText =
                "please fill all required fields";
              setLoader(false);
              btnRef.current.removeAttribute("disabled");
            } else if (response.status === 400) {
              btnRef.current.removeAttribute("disabled");
              setLoader(false);
              const msg = await response.json();
              const message = msg.message;
              if (message === "Please enter corporate mail id") {
                setError({
                  ...error,
                  email: message,
                });
                document.getElementsByClassName("primaryerr")[0].innerText =
                  "please fill all required fields";
              }
            } else if (Primaryresponse.status === 422) {
              btnRef.current.removeAttribute("disabled");
              setLoader(false);
              const msg = await Primaryresponse.json();
              const payload = msg.payload;
              const message = payload.primarySkill.children[0].skill.message;
              document.getElementsByClassName("primaryerr")[0].innerText =
                message;
            } else {
              btnRef.current.removeAttribute("disabled");
              setLoader(false);
            }
          }
        } catch {}
      }
    }
    setErrorFlag(true);
  } catch {}
}

async function submitProfile(
  event,
  token,
  history,
  setLoader,
  savebtnRef,
  error,
  information,
  setError,
  addmore,
  addMoreInformation,
  setAddMoreError,
  visibility,
  primaryInformation,
  setPrimaryError,
  setSecondaryError,
  primaryError,
  secondaryError,
  visible,
  secondaryVisible,
  secondaryInfo,
  date,
  date0,
  date1,
  date2,
  secondaryDate,
  secondaryDate0,
  secondaryDate1,
  secondaryDate2
) {
  event.preventDefault();
  let flag = true;
  
  const target = event.target;
  const workAuth = target.workauth.value;
  const taxTerm = target.taxterm.value;
  const duration = target.duration.value;
  const rate = +target.rate.value;
  const travelLocation = target.travellocation.value;
  const relocation = target.relocation.value === "Yes" ? true : false;
  const rateError = document.getElementById("rateerror").innerHTML
  if(target.workauth.value === "Select") {
    document.getElementById("workautherror").innerHTML ="Please enter the work authorization"
    document.getElementsByClassName("selectworkauth")[0].style.cssText = "border: 1px solid red"
    document.getElementById("employmenterror").innerHTML ="Please enter the employment type"
    document.getElementsByClassName("selectemploy")[0].style.cssText = "border: 1px solid red"
  }
  if(target.duration.value === "Select") {
    document.getElementById("rateTypeerror").innerHTML ="Please enter rate type"
    document.getElementsByClassName("selectrateType")[0].style.cssText = "border: 1px solid red"
  }
 if(target.rate.value === "") {
    document.getElementById("rateerror").innerHTML ="Please enter the rate"
    document.getElementsByClassName("ratefield")[0].style.cssText = "border: 1px solid red"
  }
  const rateTableError = rateValError.map((key) => {
    const field = document.getElementsByClassName(`${key}`)[0].innerHTML
    if(field !== ""){
      return false;
    }
    else {
      return true
    }
  })

  var rateTableCheckBox = [];
    rateValCheckbox.map((key) => {
       rateTableCheckBox = [...rateTableCheckBox,document.getElementsByName(`${key}`)[0].checked]
    })
    const TotalCheckValue = rateTableCheckBox.every(value => value === false)
    if(TotalCheckValue && target.rate.value !== "" && rateError === "") {
      document.getElementsByClassName("totalCheckError")[0].innerHTML = "Select at least one checkbox"
    }
    
  if (
    !verifyValidate(
      event,
      information,
      setError,
      addmore,
      addMoreInformation,
      setAddMoreError,
      visibility
    )
  )
    return;

  const result = Object.values(error)
    .map((data) => {
      if (data) return true;
      return false;
    })
    .some((data) => {
      if (data) return true;
      return false;
    });
  if (addmore) {
    flag = Object.keys(addMoreInformation)
      .map((key) => {
        if (key !== "addressLine2" && key !== "county")
          return addMoreInformation[key] ? true : false;
      })
      .includes(false);
    flag = !flag;
  }

  // another start from here
  
  let registerData = {
    firstName: information.firstName,
    middleName: information.middleName,
    lastName: information.lastName,
    mobile: information.mobileNumber,
    resumeTitle: information.resumeTitle,
    ssnNumber: information.SSNNumber,

    haveAnEmployer: visibility,

    workAuthorization: workAuth,
    preferredEmployment: taxTerm,
    rateType: duration,
    rate: rate,

    currentLocation: information.currentLocation,
    reLocationPreference: relocation,
    travelPreference: travelLocation,
    profilePicture: information.profilePicture,

    addresses: [
      {
        addressLine1: information.addressLine1,
        addressLine2: information.addressLine2,
        zipCode: information.zipCode,
        city: information.city,
        state: information.state,
        county: information.county,
        country: information.country,
        isPrimary: information.isPrime,
      },
    ],
  };
  if(relocation) {
    registerData = {
     preferredLocation: information.preferredLocation,
     ...registerData
   }
 }
  if (target.W2WithCheck.checked) {
    registerData = {
      W2With: +target.W2With.value,
      ...registerData,
    };
  }
  if (target.W2WithoutCheck.checked) {
    registerData = {
      W2Without: +target.W2Without.value,
      ...registerData,
    };
  }
  if (target.W2HourlyWithCheck.checked) {
    registerData = {
      W2HourlyWithBenefits: +target.W2HourlyWith.value,
      ...registerData,
    };
  }
  if (target.W2HourlyWithoutCheck.checked) {
    registerData = {
      W2HourlyWithoutBenefits: +target.W2HourlyWithout.value,
      ...registerData,
    };
  }
  if (target.C2HCheck.checked) {
    registerData = {
      C2H: +target.C2H.value,
      ...registerData,
    };
  }
  if (target.C2CCheck.checked) {
    registerData = {
      C2C: +target.C2C.value,
      ...registerData,
    };
  }
  if (target.N1099WithCheck.checked) {
    registerData = {
      N1099With: +target.N1099With.value,
      ...registerData,
    };
  }
  if (target.N1099WithoutCheck.checked) {
    registerData = {
      N1099Without: +target.N1099Without.value,
      ...registerData,
    };
  }

  if (visibility) {
    registerData = {
      companyName: information.companyName,
      feinNumber: information.fein,
      website: information.website,
      contactPerson: information.contactPerson,
      contactEmail: information.email,
      contactPhone: information.phone,
      zipCode: information.empzipCode,
      addressLine1: information.empaddressLine1,
      addressLine2: information.empaddressLine2,
      city: information.empcity,
      county: information.empcounty,
      state: information.empstate,
      country: information.empcountry,
      ...registerData,
    };
  }
  if (addmore) {
    const pushData = registerData.addresses.push({
      addressLine1: addMoreInformation.addressLine1,
      addressLine2: addMoreInformation.addressLine2,
      zipCode: addMoreInformation.zipCode,
      city: addMoreInformation.city,
      state: addMoreInformation.state,
      county: addMoreInformation.county,
      country: addMoreInformation.country,
      isPrimary: !information.isPrime,
    });
    registerData = {
      addresses: pushData,
      ...registerData,
    };
  }
  let filteredValue = {};
  Object.keys(registerData)
    .filter((key) => {
      const value = registerData[key];
      if (
        registerData.workAuthorization === "Select" &&
        (key === "workAuthorization" ||
          key === "preferredEmployment" ||
          key === "rateType" ||
          key === "rate")
      )
        return false;
      else if (value || value === false) return true;
      return false;
    })
    .map((key) => {
      filteredValue = { [key]: registerData[key], ...filteredValue };
    });
  //skill validation
  Object.keys(primaryInformation).map((key) => {
    setPrimaryError((state) => {
      if (!visible.one && /0$/.test(key)) return state;
      if (!visible.two && /1$/.test(key)) return state;
      if (!visible.three && /2$/.test(key)) return state;
      if (primaryInformation[key] === "") {
        return {
          ...state,
          [key]: `Please enter the ${key.replace(/\d$/, "")} `,
        };
      } else return state;
    });
    return null;
  });
  const flag1 = Object.keys(primaryError)
    .map((key) => {
      if (!visible.one && /0$/.test(key)) return null;
      if (!visible.two && /1$/.test(key)) return null;
      if (!visible.three && /2$/.test(key)) return null;
      return primaryError[key] !== "" ? true : false;
    })
    .includes(true);
  const flag2 = Object.keys(primaryInformation)
    .map((key) => {
      if (!visible.one && /0$/.test(key)) return null;
      if (!visible.two && /1$/.test(key)) return null;
      if (!visible.three && /2$/.test(key)) return null;
      return primaryInformation[key] !== "" ? true : false;
    })
    .includes(false);

  setSecondaryError((state) => {
    if (!secondaryVisible.one) state = { ...state, secondarySkill0: "" };
    if (!secondaryVisible.two) state = { ...state, secondarySkill1: "" };
    if (!secondaryVisible.three) state = { ...state, secondarySkill2: "" };
    return state;
  });

  const primaryResult = Object.keys(secondaryError)
    .map((key) => {
      return secondaryError[key] ? true : false;
    })
    .includes(true);

  //skill
  const parseData = getSkillParseData(
    primaryInformation,
    secondaryInfo,
    secondaryVisible,
    date,
    date0,
    date1,
    date2,
    secondaryDate,
    secondaryDate0,
    secondaryDate1,
    secondaryDate2
  );
  var valueArr = parseData.primarySkill.map(function (item) {
    return item.skill.toLowerCase();
  });
  var isDuplicate = valueArr.some(function (item, idx) {
    return valueArr.indexOf(item) != idx;
  });
  if (parseData.secondarySkill) {
    var valueSecArr = parseData.secondarySkill.map(function (item) {
      return item.skill.toLowerCase();
    });
    var isSecDuplicate = valueSecArr.some(function (item, idx) {
      return valueSecArr.indexOf(item) != idx;
    });
    var isBothDuplicate = parseData.primarySkill.filter((o1) =>
      parseData.secondarySkill.some(
        (o2) => o1.skill.toLowerCase() === o2.skill.toLowerCase()
      )
    );
  }
  if (!result && flag && !flag1 && !flag2 && !primaryResult && rateError === "" && !rateTableError.includes(false) && !TotalCheckValue) {
    if (savebtnRef.current) {
      savebtnRef.current.setAttribute("disabled", "disabled");
    }
    if (isDuplicate) {
      document.getElementsByClassName("profileErr")[0].innerText =
        "Duplicate primary skills are not allowed";
      savebtnRef.current.removeAttribute("disabled");
    } else if (isSecDuplicate) {
      document.getElementsByClassName("profileErr")[0].innerText =
        "Duplicate secondary skills are not allowed";
      savebtnRef.current.removeAttribute("disabled");
    } else if (isBothDuplicate && isBothDuplicate.length !== 0) {
      document.getElementsByClassName("profileErr")[0].innerText =
        "Duplicate skills are not allowed";
      savebtnRef.current.removeAttribute("disabled");
    } else {
      try {
        setLoader(true);
        const response = await register(token, filteredValue);
        const Primaryresponse = await postBuildResumeSkill(token, parseData);
        if (response.status === 200 && Primaryresponse.status === 200) {
          savebtnRef.current.removeAttribute("disabled");
          setLoader(false);
          toast.success("Saved");
          const profileResponse = await getProfileDetails(token);
          if (profileResponse.status === 200) {
            const data = await profileResponse.json();
            const payload = data.payload;
            if (payload.rate.length !== 0) {
              await snapshot(token);
            }
          }
        } else if (response.status === 422) {
          document.getElementsByClassName("profileErr")[0].innerText =
            "please fill all required fields";
          setLoader(false);
          savebtnRef.current.removeAttribute("disabled");
        } else if (response.status === 400) {
          savebtnRef.current.removeAttribute("disabled");
          setLoader(false);
          const msg = await response.json();
          const message = msg.message;
          if (message === "Please enter corporate mail id") {
            setError({
              ...error,
              email: message,
            });
          }
        } else if (Primaryresponse.status === 422) {
          savebtnRef.current.removeAttribute("disabled");
          setLoader(false);
          const msg = await Primaryresponse.json();
          const payload = msg.payload;
          const message = payload.primarySkill.children[0].skill.message;
          document.getElementsByClassName("profileErr")[0].innerText = message;
        } else {
          savebtnRef.current.removeAttribute("disabled");
          setLoader(false);
        }
      } catch {}
    }
  }
}

// build resume 1 =>skill
function buildResumeSkill(event, setInformation, setError, flag) {
  let { name, value } = event.currentTarget;
  setInformation((state) => {
    return { ...state, [name]: value };
  });

  setError((state) => {
    // if (
    //   /\d/.test(value.trim()) &&
    //   !(name.startsWith("year") || name.startsWith("month"))
    // )
    //   return { ...state, [name]: "number not allowed " };
    if (
      /[^a-zA-Z0-9\s.+#-]/.test(value.trim()) &&
      !(name.startsWith("year") || name.startsWith("month"))
    )
      return { ...state, [name]: "Special characters (#, +, -, .) are only allowed" };
    return { ...state, [name]: "" };
  });
}

function secondarySkill(event, setInfo, setError) {
  const { name, value } = event.currentTarget;
  setInfo((state) => {
    return { ...state, [name]: value };
  });
  setError((state) => {
    // if (/\d/.test(value)) return { ...state, [name]: "number not allowed" };
    if (/[^a-zA-Z0-9\s.+#-]/.test(value))
      return { ...state, [name]: "Special characters (#, +, -, .) are only allowed" };
    return { ...state, [name]: "" };
  });
}

// build resume 2 => experience
function buildResumeExperience(event, setInformation, setError, information) {
  let { name, value } = event.currentTarget;
  if(name.startsWith("location")) {
    setInformation((state) => {
      return { ...state, [name]: value.replace(/[^a-zA-Z\s.-]/,"") };
    });
  }
  else {
    setInformation((state) => {
      return { ...state, [name]: value };
    });
  }
  setError((state) => {
    if (name.startsWith("domain")) {
      if (/\d/.test(value.trim())) {
        return { ...state, [name]: "Numbers & special characters are not allowed" };
      }
      else if (/[^a-zA-Z\s]/.test(value.trim())) {
        return { ...state, [name]: "Numbers & special characters are not allowed" };
      }
      else return { ...state, [name]: "" };
    }

    if(name.startsWith("job")) {
      if (/[^a-zA-Z0-9\s.+#-]/.test(value.trim())) {
        return { ...state, [name]: "Special characters (#, +, -, .) are only allowed" };
      }
      else return { ...state, [name]: "" };
    }

    // if (
    //   /[^a-zA-Z\s.-]/.test(value.trim()) &&
    //   !(name.startsWith("year") || name.startsWith("month"))
    // )
    //   return { ...state, [name]: "symbol not allowed" };
    return { ...state, [name]: "" };
  });
  
}

// for build resume 3=> academic and certification
function buildAcademic(event, error, setError, information, setInformation) {
  const { name, value } = event.currentTarget;
  setInformation((state) => {
    return { ...state, [name]: value };
  });
  setError((state) => {
    //if (/\d\s/.test(value)) return { ...state, [name]: "number not allowed" };
    if(name.startsWith("specialization")){
      if (/[^a-zA-Z0-9\s.+#-]/.test(value.trim()))
      return { ...state, [name]: "Special characters (#, +, -, .) are only allowed" };
    return { ...state, [name]: "" };
    }
    return { ...state, [name]: "" };
  });
}

function buildCertificate(event, setError, setInformation) {
  const { name, value } = event.currentTarget;
  setInformation((state) => {
    return { ...state, [name]: value };
  });
  setError((state) => {
    // if (/[^a-zA-Z\s.-]/.test(value))
    //   return { ...state, [name]: "symbol not allowed" };
    // if (/\d/.test(value)) return { ...state, [name]: "number not allowed" };
    // return { ...state, [name]: "" };
    if(name.startsWith("certificate")) {
      if (/[^a-zA-Z0-9\s.+#-]/.test(value))
      return { ...state, [name]: "Special characters (#, +, -, .) are only allowed" };
    return { ...state, [name]: "" };
    }
    return { ...state, [name]: "" };
  });
}

// build resume 4 security clearance
function securityClearance(
  event,
  error,
  setError,
  information,
  setInformation
) {
  const { name, value } = event.currentTarget;
  setInformation((state) => {
    return { ...state, [name]: value };
  });
  setError((state) => {
    if (/\d\s/.test(value)) return { ...state, [name]: "number not allowed" };
    else if (
      /[^a-zA-Z\s.-_]/.test(value) &&
      !(name.startsWith("year") || name.startsWith("month"))
    )
      return { ...state, [name]: "symbol not allowed" };
    return { ...state, [name]: "" };
  });
}

export {
  validateRegistration,
  submit,
  submitProfile,
  buildResumeSkill,
  buildResumeExperience,
  buildAcademic,
  securityClearance,
  secondarySkill,
  buildCertificate,
};
