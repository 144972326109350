import React from "react";
import { durationData } from "./getDurationData";

const MinMaxDuration = ({ minMaxDuration }) => {
  // return data of ratecard dropdown button
  const tmp = minMaxDuration;
  const minMaxData = durationData
    .map((value, index) => {
      value = Object.keys(value)[0];
      if (minMaxDuration && index === 0) {
        return tmp;
      }
      if (value !== minMaxDuration) return value;
    })
    .filter((data) => (data ? true : false))
    .map((data, index) => {
      return (
        <option key={index} value={data}>
          {data} Months
        </option>
      );
    });
  return minMaxData;
};

export { MinMaxDuration };
