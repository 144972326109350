import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { dashboardJobCount } from "../api/client/dashboardJobCount";
import { freedashboardJobCount } from "../api/freelance/client/dashboardJobCount";
import { recdashboardJobCount } from "../api/recruiter/client/dashboardJobCount";
import { viewMore } from "../function/common/viewmore";

const ClientDashcard = () => {
  // return dashboard card group which generated by data variable
  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);
  const dispatch = useDispatch();
  const [count, setCount] = useState({
    totalRequirements: "",
    interviewSchedule: "",
    activeJobs: "",
    shortlistedCandidates: "",
    waitingCandidates: "",
    offerRoleout: "",
  });

  useEffect(() => {
    async function callUserDetails() {
      try {
        const response = isrecruiterCandidate
          ? await recdashboardJobCount(token)
          : isFreelance
            ? await freedashboardJobCount(token)
            : await dashboardJobCount(token);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setCount({
            ...count,
            totalRequirements: payload.totalRequirements,
            interviewSchedule: payload.interviewSchedule,
            activeJobs: payload.activeJobs,
            shortlistedCandidates: payload.shortlistedCandidates,
            waitingCandidates: payload.waitingCandidates,
            offerRoleout: payload.offerRoleout,
          });
        }
      } catch { }
    }
    callUserDetails();
  }, []);

  const data = [
    {
      count: count.totalRequirements,
      countColor: "#7E73B5",
      progressColor: "#7E73B5",
      icon: "../../icon/common/suitcase2.png",
      iconColor: "#F6F4FF",
      message: "Total Requirements",
    },
    {
      count: count.interviewSchedule,
      countColor: "#7E73B5",
      progressColor: "#7E73B5",
      icon: "../../icon/common/suitcase2.png",
      iconColor: "#F6F4FF",
      message: "Interviews Scheduled",
    },
    {
      count: count.activeJobs,
      countColor: "#7E73B5",
      progressColor: "#7E73B5",
      icon: "../../icon/common/suitcase2.png",
      iconColor: "#F6F4FF",
      message: "Active Jobs",
    },
    {
      count: count.shortlistedCandidates,
      countColor: "#7E73B5",
      progressColor: "#7E73B5",
      icon: "../../icon/common/suitcase2.png",
      iconColor: "#F6F4FF",
      message: "Shortlisted candidates",
    },
    {
      count: count.waitingCandidates,
      countColor: "#7E73B5",
      progressColor: "#7E73B5",
      icon: "../../icon/common/suitcase2.png",
      iconColor: "#F6F4FF",
      message: "Waiting Candidates",
    },
    {
      count: count.offerRoleout,
      countColor: "#7E73B5",
      progressColor: "#7E73B5",
      icon: "../../icon/common/suitcase2.png",
      iconColor: "#F6F4FF",
      message: "Offer Roleout",
    },
  ];

  return data.map((data, index) => (
    <div key={index} className="col-lg-4 col-md-6 col-sm-12 pt-4">
      <Link
        to={`/client/requirements?title=${data.message.includes("Interviews")
          ? data.message.split(" ")[1].toLowerCase()
          : data.message.split(" ")[0].toLowerCase()
          }`}
        onClick={() => viewMore(dispatch)}
      >
        <div className="col-md-12 p-3 viewcontent background-colored-primary-white border-radius-10px clientDashcard-container-boxShadow-css">
          <div className="row">
            <div className="col-md-1 col-2 col-21">
              <div
                className="clientDashcard-container-progressbar-css"
                style={{
                  background: data.progressColor,
                }}
              ></div>
            </div>
            <div className="col-md-8 col-8 px-0">
              <div className="pb-1">
                <Link
                  to={`/client/requirements?title=${data.message.includes("Interviews")
                    ? data.message.split(" ")[1].toLowerCase()
                    : data.message.split(" ")[0].toLowerCase()
                    }`}
                  onClick={() => viewMore(dispatch)}
                >
                  <label className="font-weight-normal font-size-20px colored-lavender-gray">
                    {data.message}
                  </label>
                </Link>
              </div>
              <label
                className="mb-0 font-weight-600 font-size-32px"
                style={{
                  color: data.countColor,
                }}
              >
                {data.count}
              </label>
              <br />
            </div>
            <div className="col-md-2 col-2 my-4 pl-0 col-21">
              <div>
                <span
                  className="clientDashcard-container-image-css"
                  style={{ background: data.iconColor }}
                >
                  <img src={data.icon} alt="case" height={24} width={30} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  ));
}
export default ClientDashcard;
