import { CLIENTINTERVIEWSCHEDULE } from "../ENVIRONMENT";

// api for client interview schedule
async function interviewSchedule(token, data) {
  return await fetch(CLIENTINTERVIEWSCHEDULE, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}

export { interviewSchedule };
