import React, { useState, useEffect, useRef } from "react";
import { CandidateMatchingData } from "./candidateMatchingData";

const CandidateMatchingJobs = () => {
  return (
    <div className="mx-2">
      <div
        className={"row mt-3 align-items-center"}
        style={{
          borderRadius: "20px 20px 0 0",
          boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
          backgroundColor: "white",
        }}
      >
        <div className={"col py-4"}>
          {/*heading box*/}
          <div className={"row pl-3 pr-5"}>
            <div className={"col-2"}>
              <font className={"headingfont pr-0"}>Client ID</font>
            </div>
            <div className={"col-1 hidebox"}>
              <font className={"headingfont"}>Req ID</font>
              {/* <img
                src="../../icon/candidate/matchingjob/align.png"
                alt={"align"}
                className={"img-fluid mr-4"}
             /> */}
            </div>
            <div className={"col-2"}>
              <font className={"headingfont ml-5"}>Position</font>
            </div>
            <div className={"col-1 hidebox pl-0 pr-0"}>
              <font className={"headingfont"}>Matching</font>
              {/* <img
                  src="../../icon/candidate/matchingjob/align.png"
                  alt={"align"}
                  className={"img-fluid"}
                  /> */}
            </div>
            <div className={"col-1 pr-0 hidebox"}>
              <font className={"headingfont"}>SourcedBy</font>
            </div>
            <div className={"col-1 pr-0 hidebox"}>
              <font className={"headingfont"}>Rate</font>
              {/* <img
                src="../../icon/candidate/matchingjob/align.png"
                alt={"align"}
                className={"img-fluid"}
                /> */}
            </div>
            <div className={"col-2 pr-0 hidebox"}>
              <font className={"headingfont"}>Interview Type</font>
            </div>
            <div className={"col-1 pl-0 pr-0 hidebox"}>
              <font className={"headingfont"}>Location</font>
            </div>
            <div className={"col-1 pl-0 hidebox"}>
              <select
                className={"form-select statusdropdown px-2 pl-0 headingfont"}
              // onChange={handleStatusFilter}
              // value={statusValue}
              >
                <option value={""}>Status</option>
                <option value={"interview"}>Scheduled</option>
                <option value={"shortlisted"}>Shortlisted</option>
                <option value={"offer"}>Offered</option>
                <option value={"reject"}>Rejected</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <CandidateMatchingData />
      <div
        className={"row align-items-center"}
        style={{
          borderRadius: "0 0 20px 20px",
          boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
          backgroundColor: "white",
          marginTop: "1px",
        }}
      >
        <div className={"col py-2"}></div>
      </div>
    </div>
  );
};

export default React.memo(CandidateMatchingJobs);
