import { FREELANCECANDIDATEAPPLICATION } from "../../ENVIRONMENT";

// api for freelance application search
async function freeapplicationSearch(token, data, currentPage) {
  const response = await fetch(
    FREELANCECANDIDATEAPPLICATION + `?q=${data}&limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export { freeapplicationSearch };
