import "../../styles/app.scss";
import "reactjs-popup/dist/index.css";
import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Setting from "./Setting";
import DashboardHome from "./DashboardHome";
import NavbarSidebar from "../../common-component/NavbarSidebar";
import NavbarTopbar from "../../common-component/NavbarTopbar";
import NavbarSidebarForNewUserCandidate from "../../common-component/NavbarSidebarForNewUserCandidate";
import Message from "../../common-component/Message";
import Profile from "./Profile";
import { getUserInfo } from "../../api/common/userInfo.js";
import Notification from "../../common-component/Notification";
import { counting } from "../../api/common/countNotifications";
import ViewDetails from "../../common-component/ViewDetails";
import { notification } from "../../api/common/notification";
import { SOCKET_URL } from "../../api/ENVIRONMENT";
import decode from "jwt-decode";
import io from "socket.io-client"

const Mainmenu = () => {
  // return mainmenu page from where all freelance page are loaded
  const [userInfo, setUserInfo] = useState({
    candidateId: "",
    firstName: "",
    lastName: "",
    profilePicture: "",
  });
  const isnewuser = useSelector((state) => state.isnewuser);
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const socketVal = useSelector((state) => state.socket);

  useEffect(() => {
    async function callUserDetails() {
      const response = await getUserInfo(token);
      const status = response.status;
      let data = await response.json();
      data = data.payload;
      if (status === 200) {
        const id = Object.keys(data).filter((key) => {
          if (key.endsWith("Id") && key !== "userId") return true;
          return false;
        });
        setUserInfo({
          candidateId: data[id],
          firstName:
            data.firstName.charAt(0).toUpperCase() +
            data.firstName.slice(1).toLowerCase(),
          lastName:
            data.lastName.charAt(0).toUpperCase() +
            data.lastName.slice(1).toLowerCase(),
          profilePicture: data.profilePicture,
        });
      }
    }
    callUserDetails();
  }, []);

  const [countNotification, setCountNotification] = useState();
  const [message, setMessage] = useState([]);
  // count notification
  useEffect(() => {
    async function notifyCount() {
      try {
        const response = await counting(token);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setCountNotification(payload.count);
        }
        const notificationRespose = await notification(token, 1);
        if (notificationRespose.status === 200) {
          const data = await notificationRespose.json();
          const payload = data.payload;
          setMessage(payload);
        }
      } catch { }
    }
    notifyCount();
  }, []);

  useEffect(() => {
    if (socketVal.length === 0) {
      const socket = io.connect(SOCKET_URL, {
        auth: {
          userId: decode(token).uuid
        }
      });
      dispatch({ type: "setsocket", value: socket });
    }
  }, [])

  return (
    <div className={"container-fluid"}>
      <div className={"row"}>
        {/*first bar*/}
        {isnewuser ? (
          <NavbarSidebarForNewUserCandidate
            homelocation={"/freelance/dashboard/register"}
          />
        ) : (
          <NavbarSidebar homelocation={"/freelance/dashboardview"} />
        )}
        {/*second bar*/}
        <div className={"col"}>
          <NavbarTopbar
            candidateId={userInfo.candidateId}
            firstName={userInfo.firstName}
            lastName={userInfo.lastName}
            profilePicture={userInfo.profilePicture}
            profilelocation={"/freelance/profile"}
            settinglocation={"/freelance/setting"}
            allnotification={"/freelance/notification"}
            count={countNotification}
            message={message}
          />
          {/*dashboard page views goes here*/}
          <div
            className={"row h-100 stick"}
            style={{
              // backgroundColor: "#F6F4FF",
              borderRadius: "38px 0px 0px 0px",
            }}
          >
            <Route path={"/freelance/dashboard"}>
              <DashboardHome />
            </Route>
            <Route path={"/freelance/message"}>
              <Message />
            </Route>
            <Route path={"/freelance/profile"}>
              <Profile />
            </Route>
            <Route path={"/freelance/setting"}>
              <Setting />
            </Route>
            <Route path={"/freelance/notification"}>
              <Notification viewdetails={"/freelance/viewdetails"} />
            </Route>
            <Route path={"/freelance/viewdetails"}>
              <ViewDetails />
            </Route>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Mainmenu);
