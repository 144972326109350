import { CLIENTBUILDREQUIREMENT } from "../ENVIRONMENT";

// api for client rate card
async function getRatecardDetails(token, data) {
  const response = await fetch(CLIENTBUILDREQUIREMENT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
  return response;
}

export { getRatecardDetails };
