import React, { useEffect, useState } from "react";

const CandidateFilter = (props) => {
  const [locationModal, setModal] = useState(false);
  const showModal = () => {
    setModal(true);
  };
  const hideModal = () => {
    setModal(false);
  };
  const filterToggleShow = () => {
    document.getElementById("sidebaricon").style.pointerEvents = "auto";
    // document.getElementsByClassName(
    //   "filtertogglebutton"
    // )[0].style.pointerEvents = "auto";
    const hide = document.getElementsByClassName("hide")[0].style.display;
    console.log(hide)
    if (hide === "none") {
      const sidebar = document.getElementsByClassName("sidebar")[0];
      sidebar.style.cssText = " min-width:100px !important;padding-left:0px";
      const companylogo = document.getElementsByClassName("companylogo")[0];
      companylogo.style.cssText =
        "width:165px;height:auto;position:relative;";
      const container = document.getElementsByClassName("stick")[0];
      container.style.cssText =
        "margin-left:100px;border-radius:38px 0px 0px 0px";
      document.getElementsByClassName('humbergerIcon')[0].style.cssText = "transform:translateX(-663%) scale(0.8);cursor:pointer ";
      document.getElementById('replacetitleimg').style.cssText = 'margin-left:-11rem !important';

    } else {
    }
  };
  const [location, setLocation] = useState([]);

  //list of location
  useEffect(() => {
    try {
      if (props.location.length !== 0) {
        setLocation(props.location);
      }
    } catch { }
  }, [props.location]);

  //list of rateCards
  const workAuthorizationData = [
    { workAuth: "US_CITIZEN" },
    { workAuth: "GREEN_CARD" },
    { workAuth: "GC_EAD" },
    { workAuth: "H1B" },
    { workAuth: "H4_EAD" },
    { workAuth: "OPT" },
    { workAuth: "OPT-STEM" },
    { workAuth: "CPT" },
    { workAuth: "TN_VISA" },
    { workAuth: "TN_EAD" },
    { workAuth: "E3" },
    { workAuth: "APPROVED_I_140" },
  ];

  const handleInformation = props.handleInformation;
  return (
    <div
      className={"modal  right"}
      id={"modalfilter"}
      role={"dialog"}
      data-backdrop="false"
      aria-labelledby="myModalLabel2"
    >
      <div className={"modal-dialog modal-lg "}>
        <div className={"modal-content pr-2"}>
          <div className={"scrollmodal"}>
            <div className="row">
              <div className="col-md-1">
                <button
                  className="clearbtn"
                  data-dismiss={"modal"}
                  onClick={filterToggleShow}
                >
                  &times;
                </button>
              </div>
              <div className={"col-md-1"}>
                <label className="ml-2 mt-2">FILTERS</label>
              </div>
              <div className={"col-md"}>
                <label
                  className={"float-right pr-2 headingfont mt-2"}
                  style={{ color: "red", fontSize: "12px" }}
                >
                  CLEAR ALL
                </label>
              </div>
            </div>
          </div>
          <div
            className={"modal-body"}
            style={{ backgroundColor: "#F9F9F9", borderRadius: "20px" }}
          >
            <form>
              <div className="row mx-1">
                <input
                  type="radio"
                  name="pay"
                  id="rad1"
                  className="modalradio"
                  value={"HOURLY"}
                  onClick={props.handleRateFilter}
                />
                <label htmlFor="rad1" className="skill pl-4">
                  Hourly
                </label>
                <br />
              </div>
              <div className="row mx-1">
                <input
                  type="radio"
                  name="pay"
                  id="rad1"
                  className="modalradio"
                  value={"YEARLY"}
                  onClick={props.handleRateFilter}
                />
                <label htmlFor="rad1" className="skill pl-4">
                  Yearly
                </label>
                <br />
              </div>
              <hr className={"divider"} />
              {/* for location */}
              <div className={"dropdownfilter"}>
                <h4>Location</h4>
                {location.slice(0, 5).map((location, index) => (
                  <li key={index} className="filterDirectorylisttitle">
                    {" "}
                    <input
                      type="checkbox"
                      id={location.city}
                      className="modalcheckbox ml-2"
                      value={location.city}
                      onClick={props.handleLocationFilter}
                    />
                    <label
                      htmlFor={location.city}
                      className="pl-4 modaltext mt-1"
                    >
                      {location.city}
                    </label>
                  </li>
                ))}
                <button
                  type="button"
                  className="btnshow pl-5"
                  onClick={showModal}
                >
                  + show more
                </button>

                <div
                  className={`modal popupsecondbox fade ${locationModal ? "show" : ""
                    }`}
                  aria-labelledby="myLargeModalLabel"
                  style={{
                    display: `${locationModal ? "block" : "none"}`,
                  }}
                  tabIndex="-1"
                  role="dialog"
                  id={"modalLocation"}
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <div className="input-group">
                          <div className="form-outline">
                            <input
                              type="search"
                              id="form1"
                              className="form-control"
                              placeholder="Search Location"
                              name={"locationData"}
                              onChange={handleInformation}
                            />
                          </div>
                        </div>
                        <button
                          type="button"
                          className="close"
                          onClick={hideModal}
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <ul className={"filterdirectory"}>
                          {location.slice(5).map((location, index) => (
                            <li
                              key={index}
                              className="filterDirectorylisttitle"
                            >
                              {" "}
                              <input
                                type="checkbox"
                                id={location.city}
                                name={""}
                                className="modalcheckbox ml-2 locationcheck"
                                value={location.city}
                                onClick={props.handleLocationFilter}
                              />
                              <label
                                htmlFor={location.city}
                                className="pl-4 modaltext mt-1"
                              >
                                {location.city}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* work authorization */}
              <div className={"dropdownfilter"}>
                <h4>Work Authorization</h4>
                {workAuthorizationData.map((data, index) => (
                  <li key={index} className="filterDirectorylisttitle">
                    {" "}
                    <input
                      type="checkbox"
                      id={data.workAuth}
                      className="modalcheckbox ml-2"
                      value={data.workAuth}
                      onClick={props.handleWorkAuthFilter}
                    />
                    <label
                      htmlFor={data.workAuth}
                      className="pl-4 modaltext mt-1"
                    >
                      {data.workAuth}
                    </label>
                  </li>
                ))}
              </div>
              {/* end work auth */}
              <hr className={"divider"} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CandidateFilter);
