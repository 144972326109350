import { RECRUITERCANDIDATEEDUCATION } from "../../ENVIRONMENT";

// api for recruiter education list
function receducationList(token) {
  const response = fetch(RECRUITERCANDIDATEEDUCATION, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { receducationList };
