import { MATCHINGJOBS } from "../ENVIRONMENT";

async function MatchingPagination(token, currentPage) {
  // api for candidate matching pagination
  const response = await fetch(MATCHINGJOBS + `?limit=6&page=${currentPage}`, {
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

async function matchingCardPagination(token, card, currentPage) {
  // api for candidate matchingjob card
  const response = await fetch(
    MATCHINGJOBS + `?card=${card}&limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export { MatchingPagination, matchingCardPagination };
