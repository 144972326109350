import { GETFREELANCE_RECRUITERUSERDETAILS } from "../ENVIRONMENT";

// api for freelance registration of new user
function freeregisterForm(token, userdetails) {
  return fetch(GETFREELANCE_RECRUITERUSERDETAILS, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(userdetails),
  });
}

function notificationFreeregisterForm(token, userdetails) {
  return fetch(GETFREELANCE_RECRUITERUSERDETAILS + "?initialStep=true", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(userdetails),
  });
}

export { freeregisterForm,notificationFreeregisterForm };
