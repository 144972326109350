import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import store,{persistor} from "./features/store";
import AdminLogin from "./components/admin/auth/AdminLogin";
import Login from "./components/authentication/Login";
import Candidate from "./components/candidate/Mainmenu";
import Client from "./components/client/Mainmenu";
import Recruiter from "./components/recruiter/Mainmenu";
import Freelance from "./components/freelance/Mainmenu";
import Admin from "./components/admin/navigation/Mainmenu";
import { PersistGate } from 'redux-persist/integration/react';
import { LinkedInCallback } from "react-linkedin-login-oauth2";

const App = () => {
  return (
    <Switch>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Route path={"/"} exact>
            <Redirect to={"/user/login"} />
          </Route>
          <Route path={"/user"}>
            <Login />
          </Route>
          <Route path={"/adminlogin"}>
            <AdminLogin />
          </Route>
          <Route path={"/candidate"}>
            <Candidate />
          </Route>
          <Route path={"/client"}>
            <Client />
          </Route>
          <Route path={"/recruiter"}>
            <Recruiter />
          </Route>
          <Route path={"/freelance"}>
            <Freelance />
          </Route>
          <Route path={"/admin"}>
            <Admin/>
          </Route>
          <Route exact path="/linkedin" component={LinkedInCallback} />
          </PersistGate>
        </Provider>
      </Switch>
  );
};

export default React.memo(App);
