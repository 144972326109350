import { FREELANCECLIENTDOMAIN } from "../../ENVIRONMENT";

// api for freelance domain list
function freedomainList(token) {
  const response = fetch(FREELANCECLIENTDOMAIN, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { freedomainList };
