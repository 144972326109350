import React, { useState, useEffect, useRef } from "react";
import AddMoreAddress from "../../common-component/AddMoreAddress";
import GoToTop from "../common/GoToTop";
import {
  validateRegistration,
  submit,
} from "../../function/client/validateField";
import { useHistory } from "react-router-dom";
import { getDetails } from "../../api/client/getDetails";
import { useSelector } from "react-redux";
import { CreateSignedUrlAPI, uploadImage } from "../../api/common/ImageUpload";
import { zipCode, zipCodeDetails } from "../../api/common/zipCode";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const UserRegistrationForm = () => {
  // return user registration page for new user
  const [loader, setLoader] = useState(false);
  let btnRef = useRef();
  const [addmore, setAddMore] = useState(false);
  const [addMoreInformation, setAddMoreInformation] = useState({
    zipCode: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    county: "",
    state: "",
    country: "",
  });

  const [addMoreError, setAddMoreError] = useState({
    zipCode: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    county: "",
    state: "",
    country: "",
  });
  const [information, setInformation] = useState({
    companyName: "",
    fein: "",
    companyWebsite: "",
    workPhone: "",
    fax: "",

    zipCode: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    county: "",
    state: "",
    country: "",

    isPrimaryAddress: true,
    logo: "",

    firstName: "",
    middleName: "",
    lastName: "",
    mobileNumber: "",
    title: "",
    email: "",
    //added
    resume: "",
  });

  const [error, setError] = useState({
    companyName: "",
    fein: "",
    companyWebsite: "",
    workPhone: "",
    fax: "",

    zipCode: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    county: "",
    state: "",
    country: "",

    firstName: "",
    middleName: "",
    lastName: "",
    mobileNumber: "",
    title: "",
    email: "",
  });
  const history = useHistory();
  const token = useSelector((state) => state.token);

  const [zipCodelist, setZipCodeList] = useState([]);
  const [show, setShow] = useState(false);

  async function handleCodeChange() {
    let data = information.zipCode;
    const response = await zipCode(token, data);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setZipCodeList(payload);
      if (payload.length === 0) {
        setShow(false);
        setError({
          ...error,
          zipCode: "please enter valid zipCode",
        });
      }
    }
  }

  useEffect(() => {
    handleCodeChange();
  }, [information.zipCode]);

  async function selectedPincode(zipCodeId, zipCode) {
    setShow(false);
    setInformation({
      ...information,
      zipCode: zipCode,
    });
    setError({
      ...error,
      zipCode: ""
    })
    const response = await zipCodeDetails(token, zipCodeId);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setInformation({
        ...information,
        zipCode: payload.zip,
        city: payload.city,
        state: payload.stateName,
        county: payload.countyName,
      });
      setError({
        ...error,
        zipCode: "",
        city: "",
        state: ""
      })
    }
  }

  // zipcode dropdown
  const ref = React.useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (show && ref.current && !ref.current.contains(event.target)) {
        document.getElementById("zipCodeDiv").style.display = "none";
      }
      document.body.removeEventListener("click", handleClickOutside);
    };
    document.body.addEventListener("click", handleClickOutside);
  }, [show]);

  function callBoth(event) {
    validateRegistration(event, setInformation, setError);
    setShow(true);
  }

  const [addzipCodelist, setAddZipCodeList] = useState([]);

  async function handleAddCodeChange() {
    let data = addMoreInformation.zipCode;
    const response = await zipCode(token, data);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setAddZipCodeList(payload);
    }
  }

  useEffect(() => {
    handleAddCodeChange();
  }, [addMoreInformation.zipCode]);

  // useEffect(() => {
  //   if (information.fax.length === 0 && information.mobileNumber.length === 0) {
  //     setError({ ...error, fax: "", mobileNumber: "" });
  //   } else if (information.fax.length === 0) {
  //     setError({ ...error, fax: "" });
  //   } else if (information.mobileNumber.length === 0) {
  //     setError({ ...error, mobileNumber: "" });
  //   } else if (information.fax.length !== 10) {
  //     setError({ ...error, fax: "length must be 10" });
  //   } else if (information.mobileNumber.replace(/\s/g, "").length !== 10) {
  //     setError({ ...error, mobileNumber: "length must be 10" });
  //   }
  // }, [information.fax, information.mobileNumber]);

  //get data
  // useEffect(() => {
  //   async function callUserDetails() {
  //     try {
  //       const response = await getDetails(token);
  //       if (response.status === 200) {
  //         const data = await response.json();
  //         const { firstName, lastName, corporateEmail } = data.payload;
  //         setInformation({
  //           ...information,
  //           firstName: firstName,
  //           lastName: lastName,
  //           email: corporateEmail,
  //           country: "United States",
  //         });
  //         setAddMoreInformation({
  //           ...addMoreInformation,
  //           country: "United States",
  //         });
  //       }

  //       if (!!!information.fein) {
  //         const response = await getDetails(token);
  //         if (response.status === 200) {
  //           const data = await response.json();
  //           const payload = data.payload;
  //           let primary = 0;
  //           let secondary = 1;
  //           if (payload.addresses.length > 1) {
  //             primary = payload.addresses[0].isPrimary ? 0 : 1;
  //             secondary = payload.addresses[1].isPrimary ? 0 : 1;
  //           }
  //           let primeAdd = payload.addresses[primary];
  //           try {
  //             //only to capture error whether it coming or not
  //             const tmp = primeAdd.isPrimary;
  //           } catch {
  //             primeAdd = { isPrimary: "" };
  //             primeAdd.isPrimary = information.isPrimaryAddress;
  //           }
  //           if (!!!information.fein) {
  //             setInformation({
  //               firstName: payload.firstName || "",
  //               middleName: payload.middleName || "",
  //               lastName: payload.lastName || "",
  //               mobileNumber: payload.mobile || "",
  //               title: payload.requirementTitle || "",
  //               email: payload.corporateEmail || "",
  //               companyName: payload.companyName,
  //               fein: payload.feinNumber || "",
  //               companyWebsite: payload.website || "",
  //               workPhone: payload.workPhone || "",
  //               fax: payload.fax || "",
  //               logo: payload.logo || "",
  //               addressLine1: primeAdd.addressLine1 || "",
  //               addressLine2: primeAdd.addressLine2 || "",
  //               zipCode: primeAdd.zipCode || "",
  //               city: primeAdd.city || "",
  //               state: primeAdd.state || "",
  //               county: primeAdd.county || "",
  //               country: primeAdd.country || "United States",
  //               isPrimaryAddress: primeAdd.isPrimary || "",
  //             });
  //             try {
  //               const addMoreAddr = payload.addresses[secondary];
  //               if (addMoreAddr.addressLine1) {
  //                 setAddMore(true);
  //                 setAddMoreInformation({
  //                   zipCode: addMoreAddr.zipCode || "",
  //                   addressLine1: addMoreAddr.addressLine1 || "",
  //                   addressLine2: addMoreAddr.addressLine2 || "",
  //                   city: addMoreAddr.city || "",
  //                   county: addMoreAddr.county || "",
  //                   state: addMoreAddr.state || "",
  //                   country: addMoreAddr.country || "United States",
  //                 });
  //               }
  //             } catch { }
  //           }
  //         }
  //       }
  //     } catch { }
  //   }
  //   callUserDetails();
  // }, []);
  useEffect(() => {
    async function callUserDetails() {
      try {
        const response = await getDetails(token);
        if (response.status === 200) {
          const data = await response.json();
          const { firstName, lastName, corporateEmail } = data.payload;

          setInformation(prev => ({
            ...prev,
            firstName: firstName || '',
            lastName: lastName || '',
            email: corporateEmail || '',
            country: 'United States',
          }));
          setAddMoreInformation(prev => ({
            ...prev,
            country: 'United States',
          }));
        }

        if (!information.fein) {
          const response = await getDetails(token);
          if (response.status === 200) {
            const data = await response.json();
            const payload = data.payload;
            let primary = 0;
            let secondary = 1;
            if (payload.addresses.length > 1) {
              primary = payload.addresses[0].isPrimary ? 0 : 1;
              secondary = payload.addresses[1].isPrimary ? 0 : 1;
            }
            let primeAdd = payload.addresses[primary] || {};
            try {
              // Capture error if it occurs
              const tmp = primeAdd.isPrimary;
            } catch {
              primeAdd = { isPrimary: information.isPrimaryAddress || '' };
            }
            if (!information.fein) {
              setInformation({
                firstName: payload.firstName || '',
                middleName: payload.middleName || '',
                lastName: payload.lastName || '',
                mobileNumber: payload.mobile || '',
                title: payload.requirementTitle || '',
                email: payload.corporateEmail || '',
                companyName: payload.companyName || '',
                fein: payload.feinNumber || '',
                companyWebsite: payload.website || '',
                workPhone: payload.workPhone || '',
                fax: payload.fax || '',
                logo: payload.logo || '',
                addressLine1: primeAdd.addressLine1 || '',
                addressLine2: primeAdd.addressLine2 || '',
                zipCode: primeAdd.zipCode || '',
                city: primeAdd.city || '',
                state: primeAdd.state || '',
                county: primeAdd.county || '',
                country: primeAdd.country || 'United States',
                isPrimaryAddress: primeAdd.isPrimary || '',
              });
              try {
                const addMoreAddr = payload.addresses[secondary] || {};
                if (addMoreAddr.addressLine1) {
                  setAddMore(true);
                  setAddMoreInformation({
                    zipCode: addMoreAddr.zipCode || '',
                    addressLine1: addMoreAddr.addressLine1 || '',
                    addressLine2: addMoreAddr.addressLine2 || '',
                    city: addMoreAddr.city || '',
                    county: addMoreAddr.county || '',
                    state: addMoreAddr.state || '',
                    country: addMoreAddr.country || 'United States',
                  });
                }
              } catch { }
            }
          }
        }
      } catch (error) {
        console.error('Failed to fetch user details:', error);
      }
    }
    callUserDetails();
  }, [token, information.fein]);

  const schema = yup.object().shape({
    picture: yup
      .mixed()
      .test("name", "Please select a file", (value) => {
        return value[0] && value[0].name !== "";
      })
      .test("fileSize", "File size < 5MB", (value, context) => {
        return value && value[0] && value[0].size <= 5000000;
      })
      .test("type", "Please upload a valid file", (value) => {
        return value && value[0] && (
          value[0].type === "image/jpeg" ||
          value[0].type === 'image/png' ||
          value[0].type === 'image/jpg'
        );
      })
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });


  function handleChange() {
    const file = document.getElementById("profileLogo").files[0];
    const obj = {
      for: "CLIENT",
      files: [
        {
          extension: file.name.split(".")[1],
          contentType: file.type,
          fileName: file.name,
        },
      ],
    };
    CreateSignedUrlAPI(token, obj)
      .then((result) => {
        const statusCode = result.status;
        const data = result.json();
        return Promise.all([statusCode, data]).then((res) => ({
          statusCode: res[0],
          data: res[1],
        }));
      })
      .then((res) => {
        if (res.statusCode === 200) {
          const promises = res.data?.payload?.signedUrls.map((url) => {
            uploadImage(url.signedUrl, file);
            return url;
          });
          Promise.all(promises).then((data) => {
            uploadImageListHandler(data);
          });
        }
      });
  }

  function uploadImageListHandler(items) {
    setTimeout(() => {
      setInformation({
        ...information,
        logo: items[0].fileUrl,
      });
    }, 1000);
  }
  useEffect(() => {
    //   console.log(document.getElementById("dashboardbtnimage"))
    // Update the DOM elements when the component mounts
    const replaceTitleImg = document.getElementById("replacetitleimg");
    const dashboardFont = document.getElementById("dashboardfont");
    const dashboardBtnImage = document.getElementById("dashboardbtnimage");
    const regiseterIcon = document.getElementById('regisetericon');
    const dashboardBtn = document.getElementById("dashboardbtn");
    const postJobId = document.getElementById("postjobid");

    if (replaceTitleImg) replaceTitleImg.innerText = "Registration";
    if (dashboardFont) dashboardFont.innerText = "Registration";
    if (dashboardBtnImage) dashboardBtnImage.style.display = "none";
    if (regiseterIcon) regiseterIcon.style.display = "block";
    if (dashboardBtn) dashboardBtn.style.cssText = "padding-left:19px; padding-top:13px !important ; padding-bottom:13px !important";
    if (postJobId) postJobId.style.display = "none";
    if (dashboardBtnImage) dashboardBtnImage.style.cssText = "display:none";

    const sidebarBtnForNewUser = document.getElementsByClassName("sidebarBtnForNewUser")[0];
    const navbarsidebarBtnForNewUser11 = document.getElementsByClassName("navbarsidebarBtnForNewUser11")[0];

    if (sidebarBtnForNewUser) {
      sidebarBtnForNewUser.style.cssText = "background: linear-gradient(90deg, rgb(86, 75, 182), rgb(140, 121, 194)); border-radius: 10px 0px 0px 10px;";
    }
    if (navbarsidebarBtnForNewUser11) {
      navbarsidebarBtnForNewUser11.style.cssText = "background: linear-gradient(90deg, rgb(86, 75, 182), rgb(140, 121, 194)); border-radius: 10px 0px 0px 10px;";
    }
    if (dashboardFont) dashboardFont.style.cssText = "font-weight: 600;color: rgb(255, 255, 255) !important;";
    if (dashboardBtnImage) dashboardBtnImage.style.cssText = "display:none";
  }, []);
  try {
    // document.getElementById("replacetitleimg").innerText = "Registration";
    // document.getElementById("dashboardfont").innerText = "Registration";
    // document.getElementById("dashboardbtnimage").style.display = "none";


    // document.getElementsByClassName("sidebarBtnForNewUser")[0].style.cssText = "background: linear-gradient(90deg, rgb(86, 75, 182), rgb(140, 121, 194)); border-radius: 10px 0px 0px 10px; ";
    // document.getElementsByClassName("navbarsidebarBtnForNewUser11")[0].style.cssText = "background: linear-gradient(90deg, rgb(86, 75, 182), rgb(140, 121, 194)); border-radius: 10px 0px 0px 10px; ";
    // document.getElementById("dashboardfont").style.cssText = "font-weight: 600;color: rgb(255, 255, 255) !important;";
    // document.getElementById('regisetericon').style.display = "block"
    // document.getElementById("dashboardbtn").style.cssText = "padding-left:25px";
    // document.getElementById("postjobid").style.display = "none";
    // document.getElementById("dashboardbtnimage").style.cssText = "display:none";

  } catch { }

  function formatPhoneNumber(event, setInformation) {
    const value = event.target.value;
    const input = value.replace(/\D/g, "").substring(0, 10);
    const firstLetter = input.substring(0, 1);
    const first = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);
    if (firstLetter === '0' || firstLetter === '1') {
      setError({
        ...error,
        mobileNumber: "Mobile number should not start with '0 or 1'"
      })
    }
    else {
      if (input.length !== 10 && input.length !== 0) {
        setError({
          ...error,
          mobileNumber: "Must be 10 digits"
        })
      }
      else {
        setError({
          ...error,
          mobileNumber: ""
        })
      }
    }
    if (input.length > 6) {
      setInformation({
        ...information,
        mobileNumber: `(${first}) ${middle}-${last}`,
      });
    } else if (input.length > 3) {
      setInformation({
        ...information,
        mobileNumber: `(${first}) ${middle}`,
      });
    } else if (input.length >= 0) {
      setInformation({
        ...information,
        mobileNumber: input,
      });
    }
  }

  function formatWorkPhoneNumber(event, setInformation) {
    const value = event.target.value;
    const input = value.replace(/\D/g, "").substring(0, 10);
    const firstLetter = input.substring(0, 1);
    const first = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);
    if (firstLetter === '0' || firstLetter === '1') {
      setError({
        ...error,
        workPhone: "Workphone number should not start with '0 or 1'"
      })
    }
    else {
      if (input.length !== 10 && input.length !== 0) {
        setError({
          ...error,
          workPhone: "Must be 10 digits"
        })
      }
      else {
        setError({
          ...error,
          workPhone: ""
        })
      }
    }
    if (input.length > 6) {
      setInformation({
        ...information,
        workPhone: `(${first}) ${middle}-${last}`,
      });
    } else if (input.length > 3) {
      setInformation({
        ...information,
        workPhone: `(${first}) ${middle}`,
      });
    } else if (input.length >= 0) {
      setInformation({
        ...information,
        workPhone: input,
      });
    }
  }

  function formatFaxNumber(event, setInformation) {
    const value = event.target.value;
    const input = value.replace(/\D/g, "").substring(0, 10);
    const firstLetter = input.substring(0, 1);
    const first = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);
    if (firstLetter === '0' || firstLetter === '1') {
      setError({
        ...error,
        fax: "Fax number should not start with '0 or 1'"
      })
    }
    else {
      if (input.length !== 10 && input.length !== 0) {
        setError({
          ...error,
          fax: "Must be 10 digits"
        })
      }
      else {
        setError({
          ...error,
          fax: ""
        })
      }
    }
    if (input.length > 6) {
      setInformation({
        ...information,
        fax: `(${first}) ${middle}-${last}`,
      });
    } else if (input.length > 3) {
      setInformation({
        ...information,
        fax: `(${first}) ${middle}`,
      });
    } else if (input.length >= 0) {
      setInformation({
        ...information,
        fax: input,
      });
    }
  }

  function formatFeinNumber(event, setInformation) {
    const value = event.target.value;
    const input = value.replace(/\D/g, "").substring(0, 9);
    const first = input.substring(0, 2);
    const last = input.substring(2, 9);
    const firstLetter = input.substring(0, 1);

    if (firstLetter === '0') {
      setError({
        ...error,
        fein: "Fein number should not start with '0'"
      })
    }
    else {
      if (input.length !== 9 && input.length !== 0) {
        setError({
          ...error,
          fein: "Must be 9 digits"
        })
      }
      else {
        setError({
          ...error,
          fein: ""
        })
      }
    }

    if (input.length > 2) {
      setInformation({
        ...information,
        fein: `${first}-${last}`,
      });
    } else if (input.length >= 0) {
      setInformation({
        ...information,
        fein: input,
      });
    }
  }

  return (
    <div className={"col mx-5"}>
      <form
        className={"form-group"}
        noValidate
        onSubmit={(event) =>
          submit(
            event,
            btnRef,
            history,
            setLoader,
            error,
            information,
            setError,
            addmore,
            addMoreInformation,
            setAddMoreError,
            token
          )
        }
      >
        <div className={"row pt-5"}>
          <div className={"col"}>
            {/*first box*/}
            <div className={"row px-5 pr-5 mx-3"}>
              <div className={"w-100 pt-4 pb-4 px-5 contentholder"}>
                <div className={"row"}>
                  <div className={"col-md"}>
                    <label
                      htmlFor={"cname"}
                      className={"dashboardforminputtext"}
                    >
                      Company Name
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"cname"}
                      name={"companyName"}
                      maxLength={50}
                      value={information.companyName}
                      style={{
                        border: `${error.companyName ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.companyName}
                    </font>
                  </div>
                </div>
                <div className={"row pt-2"}>
                  <div className={"col-md-5"}>
                    <label
                      htmlFor={"ffin"}
                      className={"dashboardforminputtext"}
                    >
                      Company FEIN<span className="colored-Bright-red">*</span>
                    </label>
                    <input
                      type={"text"}
                      placeholder={"xx-xxxxxxx"}
                      id={"ffin"}
                      name={"fein"}
                      maxLength={10}
                      value={information.fein}
                      style={{
                        border: `${error.fein ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        formatFeinNumber(event, setInformation)
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.fein}
                    </font>
                  </div>
                  <div className={"col-md-7"}>
                    <label
                      htmlFor={"cwebsite"}
                      className={"dashboardforminputtext"}
                    >
                      Company Website
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type here"}
                      id={"cwebsite"}
                      name={"companyWebsite"}
                      value={information.companyWebsite}
                      style={{
                        border: `${error.companyWebsite ? "1px solid red" : ""
                          }`,
                      }}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.companyWebsite}
                    </font>
                  </div>
                </div>
                <div className={"row pt-2"}>
                  <div className={"col-md-10"}>
                    <div className={"row"}>
                      <div className={"col-md-6"}>
                        <label
                          htmlFor={"workph"}
                          className={"dashboardforminputtext"}
                        >
                          Work Phone<span className="colored-Bright-red">*</span>
                        </label>
                        <input
                          type={"text"}
                          placeholder={" (XXX) XXX-XXXX"}
                          name={"workPhone"}
                          id={"workph"}
                          maxLength={14}
                          value={information.workPhone}
                          style={{
                            border: `${error.workPhone ? "1px solid red" : ""}`,
                          }}
                          className={
                            "form-control dashboardforminputfield shadow-none"
                          }
                          onChange={(event) =>
                            // validateRegistration(
                            //   event,
                            //   setInformation,
                            //   setError
                            // )
                            formatWorkPhoneNumber(event, setInformation)
                          }
                        />
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.workPhone}
                        </font>
                      </div>
                      <div className={"col-md"}>
                        <label
                          htmlFor={"mobile"}
                          className={"dashboardforminputtext"}
                        >
                          Fax{" "}
                        </label>
                        <input
                          type={"text"}
                          placeholder={" (XXX) XXX-XXXX"}
                          id={"mobile"}
                          name={"fax"}
                          maxLength={"14"}
                          value={information.fax}
                          style={{
                            border: `${error.fax ? "1px solid red" : ""}`,
                          }}
                          className={
                            "form-control dashboardforminputfield form-select shadow-none"
                          }
                          onChange={(event) =>
                            formatFaxNumber(event, setInformation)
                          }
                        />
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.fax}
                        </font>
                      </div>
                    </div>
                    <div className={"row "}>
                      <div className={"col-md-6 pt-3"}>
                        <label className={"dashboardforminputtext"}>
                          Upload Company Logo
                        </label>
                        <input
                          type={"text"}
                          name={"logo"}
                          className={
                            "form-control dashboardforminputfield  shadow-none"
                          }
                          placeholder={information.resume
                            ? information.resume.name
                            : "logo.jpg"}
                          id={"filename"}
                          disabled
                        />
                      </div>
                      <div className={"col-md-2"}>
                        <div className={"pt-5 pl-4"}>
                          <label className={"btn browsebtn"}>
                            <input
                              type="file"
                              accept=".jpg,.pdf,.jpeg"
                              style={{ opacity: 0, position: "absolute" }}
                              id={"profileLogo"}
                              name={"picture"}
                              {...register("picture")}
                              onChange={(event) => {
                                setInformation({
                                  ...information,
                                  resume: event.target.files[0]
                                })
                              }}
                            />
                            <span>Browse</span>
                          </label>
                        </div>
                      </div>
                      <div className={"col-md-3"}>
                        <div className={"pt-5 pl-5"}>
                          <button
                            type={"button"}
                            className={"btn uploadbtn"}
                            onClick={handleSubmit(handleChange)}
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"col-md pl-0 pr-0"}>
                    <img
                      src={
                        information.logo
                          ? information.logo
                          : "../../icon/candidate/dashboard/profilePicture.png"
                      }
                      alt="logo"
                      className={"w-100 h-100"}
                      style={{ transform: "scale(0.8)" }}
                    />
                  </div>
                </div>
                <div className={"col-md"}>
                  {errors.picture && (
                    <p className="errormsg">{errors.picture.message}</p>
                  )}
                  <label
                    className={"fileerr errormsg font-size-14px"}

                  ></label>
                </div>
              </div>
            </div>
            {/*second box*/}
            <div className={"row  px-5 pr-5 mx-3 pt-4"}>
              <div
                className={"w-100 pt-4 pb-0 px-5 contentholder"}
                id={"addmoreaddress"}
              // onClick={resetZip}
              >
                <div className={"row"}>
                  <div className={"col-md-3 colored-Slate-gray font-size-14px font-weight-500"}>
                    <label

                    >
                      Primary address
                    </label>
                  </div>
                </div>
                <div className={"row pt-2"}>
                  <div className={"col-md-4 input-placeholder"} ref={ref}>
                    <input
                      type={"text"}
                      list={"pincodes"}
                      name={"zipCode"}
                      value={information.zipCode}
                      style={{
                        border: `${error.zipCode ? "1px solid red" : ""}`,
                      }}
                      maxLength={"5"}
                      required
                      className={
                        "form-control dashboardforminputfield ziplocation shadow-none"
                      }
                      onChange={callBoth}
                      autoComplete="off"
                      onClick={() => {
                        setShow(!show);
                      }}
                    />
                    <div className={"placeholder"}>
                      Enter Zip Code<span>*</span>
                    </div>
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.zipCode}
                    </font>
                    <div
                      id={"zipCodeDiv"}
                      style={{
                        display: `${show && information.zipCode > 0 ? "block" : "none"
                          }`,
                      }}
                    >
                      {zipCodelist &&
                        zipCodelist.length > 0 &&
                        zipCodelist.map((employee) => (
                          <div
                            key={employee.id}
                            style={{ cursor: "pointer" }}
                            value={employee.zip}
                            onClick={() =>
                              selectedPincode(employee.id, employee.zip)
                            }
                          >
                            {employee.zip} - {employee.city}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className={"col-md input-placeholder"}>
                    <input
                      type={"text"}
                      name={"addressLine1"}
                      value={information.addressLine1}
                      style={{
                        border: `${error.addressLine1 ? "1px solid red" : ""}`,
                      }}
                      required
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <div className={"placeholder"}>
                      Address Line 1<span>*</span>
                    </div>
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.addressLine1}
                    </font>
                  </div>
                </div>
                <div className={"row pt-4"}>
                  <div className={"col-md"}>
                    <input
                      type={"text"}
                      placeholder={"Address Line 2"}
                      name={"addressLine2"}
                      value={information.addressLine2}
                      style={{
                        border: `${error.addressLine2 ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control dashboardforminputfield address shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.addressLine2}
                    </font>
                  </div>
                  <div className={"col-md-4 input-placeholder"}>
                    <input
                      type={"text"}
                      name={"city"}
                      value={information.city}
                      style={{
                        border: `${error.city ? "1px solid red" : ""}`,
                      }}
                      required
                      disabled
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    {
                      information.city === "" &&
                      <div className={"placeholder"}>
                        City<span>*</span>
                      </div>
                    }
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.city}
                    </font>
                  </div>
                </div>
                <div className={"row pt-4"}>
                  <div className={"col-md-4"}>
                    <input
                      type={"text"}
                      name={"county"}
                      value={information.county}
                      disabled
                      style={{
                        border: `${error.county ? "1px solid red" : ""}`,
                      }}
                      placeholder="County"
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.county}
                    </font>
                  </div>
                  <div className={"col-md-4 input-placeholder"}>
                    <input
                      type={"text"}
                      name={"state"}
                      value={information.state}
                      style={{
                        border: `${error.state ? "1px solid red" : ""}`,
                      }}
                      required
                      disabled
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    {
                      information.state === "" &&
                      <div className={"placeholder"}>
                        State<span>*</span>
                      </div>
                    }
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.state}
                    </font>
                  </div>
                  <div className={"col-md-4"}>
                    <input
                      type={"text"}
                      name={"country"}
                      value={information.country}
                      style={{
                        border: `${error.country ? "1px solid red" : ""}`,
                      }}
                      disabled
                      required
                      placeholder={"Country"}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.country}
                    </font>
                  </div>
                </div>

                {addmore ? (
                  <div className="row pt-2 pb-3"></div>
                ) : (
                  <div className={"row pr-5"}>
                    <div className={"col-md"}>
                      <div
                        className={
                          "btn float-right badge border-0 pt-2 pb-1 newskill background-colored-primary-white"
                        }

                        onClick={() => setAddMore(true)}
                      >
                        + Add Mailing Address
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {addmore && (
              <AddMoreAddress
                information={addMoreInformation}
                setInformation={setAddMoreInformation}
                error={addMoreError}
                setError={setAddMoreError}
                addmore={addmore}
                setAddMore={setAddMore}
                zipCodeList={addzipCodelist}
              />
            )}
            {/*<AddMoreAddress />*/}
            {/*third box*/}
            <div className={"row px-5 pr-5 mx-3 pt-4"}>
              <div className={"w-100 pt-3 pb-4 px-5 contentholder"}>
                <label className="font-weight-500  font-size-15px colored-Jet-gray"

                >
                  Contact Person
                </label>
                <div className={"row"}>
                  <div className={"col-md"}>
                    <label
                      htmlFor={"fname"}
                      className={"dashboardforminputtext"}
                    >
                      First Name<span className="colored-Bright-red">*</span>
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"fname"}
                      name={"firstName"}
                      maxLength={20}
                      value={information.firstName}
                      style={{
                        border: `${error.firstName ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control dashboardforminputfield names shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.firstName}
                    </font>
                  </div>
                  <div className={"col-md"}>
                    <label
                      htmlFor={"mname"}
                      className={"dashboardforminputtext"}
                    >
                      Middle Name
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"mname"}
                      name={"middleName"}
                      maxLength={20}
                      value={information.middleName}
                      style={{
                        border: `${error.middleName ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.middleName}
                    </font>
                  </div>
                  <div className={"col-md"}>
                    <label
                      htmlFor={"lname"}
                      className={"dashboardforminputtext"}
                    >
                      Last Name<span className="colored-Bright-red">*</span>
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"lname"}
                      name={"lastName"}
                      maxLength={20}
                      value={information.lastName}
                      style={{
                        border: `${error.lastName ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control dashboardforminputfield names shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.lastName}
                    </font>
                  </div>
                </div>
                <div className={"row pt-2"}>
                  <div className={"col-md"}>
                    <label htmlFor={"mno"} className={"dashboardforminputtext"}>
                      Mobile Number
                    </label>
                    <input
                      type={"text"}
                      placeholder={" (xxx) xxx-xxxx"}
                      id={"mno"}
                      maxLength={14}
                      name={"mobileNumber"}
                      value={information.mobileNumber}
                      style={{
                        border: `${error.mobileNumber ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control dashboardforminputfield mobile shadow-none"
                      }
                      onChange={(event) => {
                        formatPhoneNumber(event, setInformation);
                      }}
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.mobileNumber}
                    </font>
                  </div>
                  <div className={"col-md"}>
                    <label
                      htmlFor={"rtitle"}
                      className={"dashboardforminputtext"}
                    >
                      Title<span className="colored-Bright-red">*</span>
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"rtitle"}
                      name={"title"}
                      value={information.title}
                      style={{
                        border: `${error.title ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.title}
                    </font>
                  </div>
                  <div className={"col-md"}>
                    <label htmlFor={"ssn"} className={"dashboardforminputtext"}>
                      Corporate Email ID<span className="colored-Bright-red">*</span>
                    </label>
                    <input
                      type={"text"}
                      id={"ssn"}
                      placeholder={"Type Here"}
                      name={"email"}
                      value={information.email}
                      style={{
                        border: `${error.email ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control dashboardforminputfield mobile shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                      disabled
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.email}
                    </font>
                  </div>
                </div>
              </div>
            </div>
            {/*two button area of page last*/}
            <div className={"row mx-3 my-4"}>
              <div className={"col"}>
                <div className={"row float-right"}>
                  <button
                    type={"submit"}
                    ref={btnRef}
                    formNoValidate
                    className={"btn mr-5 my-2 uploadbtn"}
                  >
                    {loader ? (
                      <i
                        className="fa fa-refresh fa-spin userRegistration-loader-padding"
                      // style={{ padding: "3px 10px" }}
                      ></i>
                    ) : (
                      ""
                    )}
                    Save & Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <GoToTop />
    </div>
  );
};

export default React.memo(UserRegistrationForm);
