import { SUPERADMINCANDIDATELIST } from "../../ENVIRONMENT";

async function adminCandidateListPagination(token, currentPage) {
  // api for candidate list
  const response = await fetch(
    SUPERADMINCANDIDATELIST + `?limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
export { adminCandidateListPagination };
