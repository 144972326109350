import { UPLOADIMAGE } from "../ENVIRONMENT";
// api for upload image
function CreateSignedUrlAPI(token, imageDetails) {
  return fetch(UPLOADIMAGE, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(imageDetails),
  });
}

async function uploadImage(url, file) {
  return fetch(url, {
    method: "PUT",
    body: file,
  });
}

export { CreateSignedUrlAPI, uploadImage };
