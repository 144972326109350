import { CANDIDATESOCIAL } from "../ENVIRONMENT";

async function postBuildResumeSocial(token, data) {
  // api for submit candidate buildresume social
  return await fetch(CANDIDATESOCIAL, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}

async function getBuildResumeSocial(token) {
  // api for candidate buildresume social details
  return await fetch(CANDIDATESOCIAL, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
}
export { postBuildResumeSocial, getBuildResumeSocial };
