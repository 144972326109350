import { CLIENTDASHBOARDREQUIREMENT } from "../ENVIRONMENT";

// api for clientrequirement details
async function clientRequirementDetils(token) {
  const response = await fetch(CLIENTDASHBOARDREQUIREMENT, {
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}
export { clientRequirementDetils };
