import { ZIPCODE } from "../ENVIRONMENT";
// api for zipcode
async function zipCode(token, data) {
  const response = await fetch(ZIPCODE + `?pincode=${data}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}
// api for zipcode details
async function zipCodeDetails(token, data) {
  const response = await fetch(ZIPCODE + `/${data}/details`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { zipCode, zipCodeDetails };
