import { createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

let initialState = {
  socket: [],
  token: "",
  isnewuser: true,
  isbuildresume: false,
  isrecruiter: false,
  btnstate: { btn: "", txt: "", bg: "" },
  candidate: "candidate",
  client: "client",
  recruiterCandidate: false,
  isFreelance: false
};

function stateMonitor(state = initialState, action) {
  try {
    switch (action && action.type) {
      case "settoken":
        state = { ...state, token: action.value };
        return state;

      case "setsocket":
        state = { ...state, socket: action.value };
        return state;

      case "newusertrue":
        state = { ...state, isnewuser: true };
        return state;

      case "newuserfalse":
        state = { ...state, isnewuser: false };
        return state;

      case "buildresumetrue":
        state = { ...state, isbuildresume: true };
        return state;

      case "buildresumefalse":
        state = { ...state, isbuildresume: false };
        return state;

      case "recruitertrue":
        state = { ...state, isrecruiter: true };
        return state;

      case "recruiterfalse":
        state = { ...state, isrecruiter: false };
        return state;

      case "btnstate":
        state = {
          ...state,
          btnstate: {
            btn: action.value.btn,
            txt: action.value.txt,
            bg: action.value.bg,
          },
        };
        return state;

      case "candidate":
        state = { ...state, candidate: action.value };
        return state;

      case "client":
        state = { ...state, client: action.value };
        return state;

      case "recruiterCandidatetrue":
        state = { ...state, recruiterCandidate: true };
        return state;

      case "recruiterCandidatefalse":
        state = { ...state, recruiterCandidate: false };
        return state;

      case "freelancetrue":
        state = { ...state, isFreelance: true };
        return state;

      case "freelancefalse":
        state = { ...state, isFreelance: false };
        return state;

      default:
        return state;
    }
  } catch { }
}

const persistConfig = {
  key: 'persist-store',
  storage,
};

const persistedReducer = persistReducer(persistConfig, stateMonitor);
const store = createStore(persistedReducer, stateMonitor)
export const persistor = persistStore(store);
export default store;

