import { RECRUITERBUILDREQUIREMENT } from "../../../ENVIRONMENT";

// api for submit recruiter builrequirement academic
async function recpostBuildRequirementAcademic(token, data, id) {
  return await fetch(RECRUITERBUILDREQUIREMENT + `/${id}/academic`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}

export { recpostBuildRequirementAcademic };
