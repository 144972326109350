import { RECRUITERCANDIDATEMATCHINGJOBS } from "../../ENVIRONMENT";

// api for recruiter search
async function recSearch(token, data, page) {
  const response = await fetch(
    RECRUITERCANDIDATEMATCHINGJOBS + `?q=${data}&limit=6&page=${page}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
async function recSearchByCard(token, data, page,card) {
  // api for candidate search
  const response = await fetch(
    RECRUITERCANDIDATEMATCHINGJOBS + `?q=${data}&limit=6&page=${page}&card=${card}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}


export { recSearch,recSearchByCard };
