import { MESSAGE } from "../ENVIRONMENT";

// api for message list
async function messageList(token) {
  const response = await fetch(MESSAGE, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}
// api for single message list
async function singleMessageList(token, id) {
  const response = await fetch(MESSAGE + `/${id}/messages`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}
// api for send message
async function sendMessageList(token, id, data) {
  const response = await fetch(MESSAGE + `/${id}/messages`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
  return response;
}
// api for send message
async function markasReadMessage(token, id) {
  const response = await fetch(MESSAGE + `/${id}/markAsRead`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { messageList, singleMessageList, sendMessageList, markasReadMessage };
