import React, { useEffect, useRef, useState,forwardRef } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RateCard from "../../../common-component/RateCard";
import AddMoreAddress from "../../../common-component/AddMoreAddress";
import { buildResumeSkill, secondarySkill, validateRegistration } from "../../../function/candidate/validateField";
import { verifyValidate } from "../../../function/candidate/checkRequired";
import { zipCode, zipCodeDetails } from "../../../api/common/zipCode";
import { useParams } from "react-router-dom";
import {
  freecandidateProfile,
  FreeGetCandidateProfile,
  freeupdateCandidateProfile,
} from "../../../api/freelance/candidate/buildResume/candidateProfile";
import { locationListSearch } from "../../../api/common/locationList";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { freegetBuildResumeSkill, freepostBuildResumeSkill } from "../../../api/freelance/candidate/buildResume/buildResumeSkill";
import { getSkillParseData } from "../../../function/candidate/getSkillParseData";
import { setSkillData } from "../../../function/candidate/setSkillData";
import GetPrimaryYear from "../../../common-component/GetPrimaryYear";
import GetPrimaryMonth from "../../../common-component/GetPrimaryMonth";
import { Multiselect } from 'multiselect-react-dropdown';

const UserRegistrationForm = (props) => {
  const [loader, setLoader] = useState(false);
  const [addmore, setAddMore] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [showPreferredBox,setShowPreferredBox] = useState(true);
  const [addMoreInformation, setAddMoreInformation] = useState({
    zipCode: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    county: "",
    state: "",
    country: "United States",
  });

  const [addMoreError, setAddMoreError] = useState({
    zipCode: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    county: "",
    state: "",
    country: "",
  });
  const [information, setInformation] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    mobileNumber: "",
    resumeTitle: "",
    SSNNumber: "",

    zipCode: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    county: "",
    state: "",
    country: "United States",

    isPrimaryAddress: true,

    companyName: "",
    fein: "",
    website: "",
    contactPerson: "",
    email: "",
    phone: "",
    empzipCode: "",
    empaddressLine1: "",
    empaddressLine2: "",
    empcity: "",
    empcounty: "",
    empstate: "",
    empcountry: "United States",
    preferredLocation: [],
    currentLocation: "",
    uniqueId: "",
  });

  const [error, setError] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    mobileNumber: "",
    resumeTitle: "",
    SSNNumber: "",

    zipCode: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    county: "",
    state: "",
    country: "",

    companyName: "",
    fein: "",
    website: "",
    contactPerson: "",
    email: "",
    phone: "",
    empzipCode: "",
    empaddressLine1: "",
    empaddressLine2: "",
    empcity: "",
    empcounty: "",
    empstate: "",
    empcountry: "",
    preferredLocation: "",
    currentLocation: "",
  });

  const [visibility, setVisibility] = useState(false);
  useEffect(() => {
    if (visibility === false) {
      setInformation({
        ...information,
        companyName: "",
        fein: "",
        website: "",
        contactPerson: "",
        email: "",
        phone: "",
        empzipCode: "",
        empaddressLine1: "",
        empaddressLine2: "",
        empcity: "",
        empcounty: "",
        empstate: "",
        empcountry: "United States",
      });
      setError({
        ...error,
        companyName: "",
        fein: "",
        website: "",
        contactPerson: "",
        email: "",
        phone: "",
        empzipCode: "",
        empaddressLine1: "",
        empaddressLine2: "",
        empcity: "",
        empcounty: "",
        empstate: "",
        empcountry: "",
      });
    }
  }, [visibility]);

  const [travelPreference, setTravelPreference] = useState([
    "0%",
    "25%",
    "50%",
    "75%",
    "100%",
    "Occassional",
  ]);
  const [relocation, setRelocation] = useState(["Yes", "No"]);
  const [date, setDate] = useState(new Date());
  const [date0, setDate0] = useState(new Date());
  const [date1, setDate1] = useState(new Date());
  const [date2, setDate2] = useState(new Date());
  const [secondaryDate, setSecondaryDate] = useState(new Date());
  const [secondaryDate0, setSecondaryDate0] = useState(new Date());
  const [secondaryDate1, setSecondaryDate1] = useState(new Date());
  const [secondaryDate2, setSecondaryDate2] = useState(new Date());

  const [primaryInformation, setPrimaryInformation] = useState({
    month: "",
    year: "",
    month0: "",
    year0: "",
    month1: "",
    year1: "",
    month2: "",
    year2: "",
    primarySkill: "",
    primarySkill0: "",
    primarySkill1: "",
    primarySkill2: "",
  });
  const [primaryError, setPrimaryError] = useState({
    month: "",
    year: "",
    month0: "",
    year0: "",
    month1: "",
    year1: "",
    month2: "",
    year2: "",
    primarySkill: "",
    primarySkill0: "",
    primarySkill1: "",
    primarySkill2: "",
  });

  const [visible, setVisible] = useState({
    one: false,
    two: false,
    three: false,
  });

  const [secondaryInfo, setSecondaryInfo] = useState({
    secondarySkill: "",
    secondarySkillMonth: "",
    secondarySkillYear: "",
    secondarySkill0: "",
    secondarySkillMonth0: "",
    secondarySkillYear0: "",
    secondarySkill1: "",
    secondarySkillMonth1: "",
    secondarySkillYear1: "",
    secondarySkill2: "",
    secondarySkillMonth2: "",
    secondarySkillYear2: "",
  });
  const [secondaryError, setSecondaryError] = useState({
    secondarySkill: "",
    secondarySkill0: "",
    secondarySkill1: "",
    secondarySkill2: "",
  });

  const [secondaryVisible, setSecondaryVisible] = useState({
    one: false,
    two: false,
    three: false,
  });
  //handle primary error
  useEffect(() => {
    setPrimaryInformation((state) => {
      return {
        ...state,
        month0: "",
        year0: "",
        primarySkill0: ""
      };
    });
    setPrimaryError((state) => {
      return {
        ...state,
        month0: "",
        year0: "",
        primarySkill0: ""
      };
    });
  }, [visible.one]);

  useEffect(() => {
    setPrimaryInformation((state) => {
      return {
        ...state,
        month1: "",
        year1: "",
        primarySkill1: ""
      };
    });
    setPrimaryError((state) => {
      return {
        ...state,
        month1: "",
        year1: "",
        primarySkill1: ""
      };
    });
  }, [visible.two]);

  useEffect(() => {
    setPrimaryInformation((state) => {
      return {
        ...state,
        month2: "",
        year2: "",
        primarySkill2: ""
      };
    });
    setPrimaryError((state) => {
      return {
        ...state,
        month2: "",
        year2: "",
        primarySkill2: ""
      };
    });
  }, [visible.three]);

  //handle secondary error
  useEffect(() => {
    setSecondaryInfo((state) => {
      return {
        ...state,
        secondarySkill0: "",
        secondarySkillMonth0: "",
        secondarySkillYear0: ""
      };
    });
    setSecondaryError((state) => {
      return {
        ...state,
        secondarySkill0: ""
      };
    });
  }, [secondaryVisible.one]);

  useEffect(() => {
    setSecondaryInfo((state) => {
      return {
        ...state,
        secondarySkill1: "",
        secondarySkillMonth1: "",
        secondarySkillYear1: ""
      };
    });
    setSecondaryError((state) => {
      return {
        ...state,
        secondarySkill1: ""
      };
    });
  }, [secondaryVisible.two]);

  useEffect(() => {
    setSecondaryInfo((state) => {
      return {
        ...state,
        secondarySkill2: "",
        secondarySkillMonth2: "",
        secondarySkillYear2: ""
      };
    });
    setSecondaryError((state) => {
      return {
        ...state,
        secondarySkill2: ""
      };
    });
  }, [secondaryVisible.three]);

  //add primary skill
  function AddPrimarySkill() {
    setVisible((state) => {
      if (state.one === false && state.two) return { ...state, one: true };
      else if (state.two === false && state.three)
        return { ...state, two: true };
      else if (state.three === false) return { ...state, three: true };
      return state;
    });
  }
  //add secondary skill
  function addSecondarySkill() {
    setSecondaryVisible((state) => {
      if (!state.one) return { ...state, one: true };
      if (!state.two) return { ...state, two: true };
      if (!state.three) return { ...state, three: true };
      return state;
    });
  }

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      type="button"
      className="resumeformcalendarfield w-100"
      onClick={onClick}
      ref={ref}
    >
      <label style={{ marginTop: "10px" }}>{value}</label>
      <i
        className="fas fa-calendar-alt"
        style={{ fontSize: "20px", marginTop: "10px" }}
      ></i>
    </button>
  ));

  useEffect(() => {
    let primData = {};
    let secondData = {};
    if (!visible.one) {
      primData = { ...primData, month0: "", year0: "", primarySkill0: "" };
      setDate0(new Date());
    }
    if (!visible.two) {
      primData = { ...primData, month1: "", year1: "", primarySkill1: "" };
      setDate1(new Date());
    }
    if (!visible.three) {
      primData = { ...primData, month2: "", year2: "", primarySkill2: "" };
      setDate1(new Date());
    }

    // secondary visible
    if (!secondaryVisible.one) {
      secondData = {
        ...secondData,
        secondarySkill0: "",
        secondarySkillMonth0: "",
        secondarySkillYear0: "",
      };
      setSecondaryDate0(new Date());
    }
    if (!secondaryVisible.two) {
      secondData = {
        ...secondData,
        secondarySkill1: "",
        secondarySkillMonth1: "",
        secondarySkillYear1: "",
      };
      setSecondaryDate1(new Date());
    }
    if (!secondaryVisible.three) {
      secondData = {
        ...secondData,
        secondarySkill2: "",
        secondarySkillMonth2: "",
        secondarySkillYear2: "",
      };
      setSecondaryDate2(new Date());
    }
    setSecondaryInfo({ ...secondaryInfo, ...secondData });
    setPrimaryInformation({ ...primaryInformation, ...primData });
  }, [visible, secondaryVisible]);

  const dispatch = useDispatch();
  dispatch({ type: "buildresumefalse" });
  const token = useSelector((state) => state.token);
  let btnRef = useRef();
  const param = useParams();
  const id = param.id;

  try {
    document.getElementById("dashboardbtn").style.display = "none";
    document.getElementById("dashboardfont").innerText = "Registration";
    document.getElementById("replacetitleimg").innerText = "Registration";
  } catch {}

  const rateValError = [
    "W2Witherror",
      "W2Withouterror",
      "c2herror",
      "c2cerror",
      "W2Hourlyerror",
      "W2Hourlywithouterror",
      "1099error",
      "1099Withouterror"
  ];

  const rateValCheckbox = [
    "W2WithCheck",
    "W2WithoutCheck",
    "C2HCheck",
    "C2CCheck",
    "W2HourlyWithCheck",
    "W2HourlyWithoutCheck",
    "N1099WithCheck",
    "N1099WithoutCheck"
];

  async function submit(
    event,
    error,
    information,
    setError,
    addmore,
    addMoreInformation,
    setAddMoreError,
    visibility
  ) {
    event.preventDefault();
    const target = event.target;
    const rateError = document.getElementById("rateerror").innerHTML
    try {
      if(target.workauth.value === "Select") {
        document.getElementById("workautherror").innerHTML ="Please enter the work authorization"
        document.getElementsByClassName("selectworkauth")[0].style.cssText = "border: 1px solid red"
        document.getElementById("employmenterror").innerHTML ="Please enter the employment type"
        document.getElementsByClassName("selectemploy")[0].style.cssText = "border: 1px solid red"
      }
      if(target.duration.value === "Select") {
        document.getElementById("rateTypeerror").innerHTML ="Please enter the rate type"
        document.getElementsByClassName("selectrateType")[0].style.cssText = "border: 1px solid red"
      }
     if(target.rate.value === "") {
        document.getElementById("rateerror").innerHTML ="Please enter the rate"
        document.getElementsByClassName("ratefield")[0].style.cssText = "border: 1px solid red"
      }
      const rateTableError = rateValError.map((key) => {
        const field = document.getElementsByClassName(`${key}`)[0].innerHTML
        if(field !== ""){
          return false;
        }
        else {
          return true
        }
      })
      var rateTableCheckBox = [];
    rateValCheckbox.map((key) => {
       rateTableCheckBox = [...rateTableCheckBox,document.getElementsByName(`${key}`)[0].checked]
    })
    const TotalCheckValue = rateTableCheckBox.every(value => value === false)
    if(TotalCheckValue && target.rate.value !== "" && rateError === "") {
      document.getElementsByClassName("totalCheckError")[0].innerHTML = "Select at least one checkbox"
    }
      const relocation = target.relocation.value === "Yes" ? true : false;
      let registerData = {
        firstName: information.firstName,
        middleName: information.middleName,
        lastName: information.lastName,
        mobile: information.mobileNumber,
        resumeTitle: information.resumeTitle,
        ssnNumber: information.SSNNumber,
        haveAnEmployer: visibility,
        workAuthorization: target.workauth.value,
        preferredEmployment: target.taxterm.value,
        rateType: target.duration.value,
        rate: +target.rate.value,
        currentLocation: information.currentLocation,
        reLocationPreference: relocation,
        travelPreference: target.travellocation.value,

        addresses: [
          {
            addressLine1: information.addressLine1,
            addressLine2: information.addressLine2,
            zipCode: information.zipCode,
            city: information.city,
            state: information.state,
            county: information.county,
            country: information.country,
            isPrimary: information.isPrimaryAddress,
          },
        ],
      };
      if(relocation) {
        registerData = {
         preferredLocation: information.preferredLocation,
         ...registerData
       }
     }
      if (target.W2WithCheck.checked) {
        registerData = {
          W2With: +target.W2With.value,
          ...registerData,
        };
      }
      if (target.W2WithoutCheck.checked) {
        registerData = {
          W2Without: +target.W2Without.value,
          ...registerData,
        };
      }
      if (target.W2HourlyWithCheck.checked) {
        registerData = {
          W2HourlyWith: +target.W2HourlyWith.value,
          ...registerData,
        };
      }
      if (target.W2HourlyWithoutCheck.checked) {
        registerData = {
          W2HourlyWithout: +target.W2HourlyWithout.value,
          ...registerData,
        };
      }
      if (target.C2HCheck.checked) {
        registerData = {
          C2H: +target.C2H.value,
          ...registerData,
        };
      }
      if (target.C2CCheck.checked) {
        registerData = {
          C2C: +target.C2C.value,
          ...registerData,
        };
      }
      if (target.N1099WithCheck.checked) {
        registerData = {
          N1099With: +target.N1099With.value,
          ...registerData,
        };
      }
      if (target.N1099WithoutCheck.checked) {
        registerData = {
          N1099Without: +target.N1099Without.value,
          ...registerData,
        };
      }
      if (visibility) {
        registerData = {
          companyName: information.companyName,
          feinNumber: information.fein,
          website: information.website,
          contactPerson: information.contactPerson,
          contactEmail: information.email,
          contactPhone: information.phone,
          zipCode: information.empzipCode,
          addressLine1: information.empaddressLine1,
          addressLine2: information.empaddressLine2,
          city: information.empcity,
          county: information.empcounty,
          state: information.empstate,
          country: information.empcountry,
          ...registerData,
        };
      }
      if (addmore) {
        registerData.addresses.push({
          addressLine1: addMoreInformation.addressLine1,
          addressLine2: addMoreInformation.addressLine2,
          zipCode: addMoreInformation.zipCode,
          city: addMoreInformation.city,
          state: addMoreInformation.state,
          county: addMoreInformation.county,
          country: addMoreInformation.country,
          isPrimary: !information.isPrimaryAddress,
        });
      }
      let temp = registerData.addresses[0];
      if (!temp.addressLine2) delete temp.addressLine2;
      if (!temp.county) delete temp.county;
      try {
        temp = registerData.addresses[1];
        if (!temp.addressLine2) delete temp.addressLine2;
        if (!temp.county) delete temp.county;
      } catch {}
      let filteredValue = {};
      Object.keys(registerData)
        .filter((key) => {
          const value = registerData[key];
          if (
            registerData.workAuthorization === "Select" &&
            (key === "workAuthorization" ||
              key === "preferredEmployment" ||
              key === "rateType" ||
              key === "rate")
          )
            return false;
          else if (value || value === false) return true;
          return false;
        })
        .map((key) => {
          filteredValue = { [key]: registerData[key], ...filteredValue };
        });
      let flag = true;
      if (
        !verifyValidate(
          event,
          information,
          setError,
          addmore,
          addMoreInformation,
          setAddMoreError,
          visibility
        )
      )
        return;
      const result = Object.values(error)
        .map((data) => {
          if (data) return true;
          return false;
        })
        .some((data) => {
          if (data) return true;
          return false;
        });
      if (addmore) {
        flag = Object.keys(addMoreInformation)
          .map((key) => {
            if (key !== "addressLine2" && key !== "county")
              return addMoreInformation[key] ? true : false;
          })
          .includes(false);
        flag = !flag;
      }
       //skill validation
       Object.keys(primaryInformation).map((key) => {
        setPrimaryError((state) => {
          if (!visible.one && /0$/.test(key)) return state;
          if (!visible.two && /1$/.test(key)) return state;
          if (!visible.three && /2$/.test(key)) return state;
          if (primaryInformation[key] === "") {
            return {
              ...state,
              [key]: `Please enter the ${key.replace(/\d$/, "")} `,
            };
          } else return state;
        });
        return null;
      });
      const flag1 = Object.keys(primaryError)
        .map((key) => {
          if (!visible.one && /0$/.test(key)) return null;
          if (!visible.two && /1$/.test(key)) return null;
          if (!visible.three && /2$/.test(key)) return null;
          return primaryError[key] !== "" ? true : false;
        })
        .includes(true);
      const flag2 = Object.keys(primaryInformation)
        .map((key) => {
          if (!visible.one && /0$/.test(key)) return null;
          if (!visible.two && /1$/.test(key)) return null;
          if (!visible.three && /2$/.test(key)) return null;
          return primaryInformation[key] !== "" ? true : false;
        })
        .includes(false);

      setSecondaryError((state) => {
        if (!secondaryVisible.one) state = { ...state, secondarySkill0: "" };
        if (!secondaryVisible.two) state = { ...state, secondarySkill1: "" };
        if (!secondaryVisible.three) state = { ...state, secondarySkill2: "" };
        return state;
      });

      const primaryResult = Object.keys(secondaryError)
        .map((key) => {
          return secondaryError[key] ? true : false;
        })
        .includes(true);

      //skill
      const parseData = getSkillParseData(
        primaryInformation,
        secondaryInfo,
        secondaryVisible,
        date,
        date0,
        date1,
        date2,
        secondaryDate,
        secondaryDate0,
        secondaryDate1,
        secondaryDate2
      );
      var valueArr = parseData.primarySkill.map(function (item) {
        return item.skill.toLowerCase();
      });
      var isDuplicate = valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) != idx;
      });
      if (parseData.secondarySkill) {
        var valueSecArr = parseData.secondarySkill.map(function (item) {
          return item.skill.toLowerCase();
        });
        var isSecDuplicate = valueSecArr.some(function (item, idx) {
          return valueSecArr.indexOf(item) != idx;
        });
        var isBothDuplicate = parseData.primarySkill.filter((o1) =>
          parseData.secondarySkill.some(
            (o2) => o1.skill.toLowerCase() === o2.skill.toLowerCase()
          )
        );
      }
      if (!result && flag && !flag1 && !flag2 && !primaryResult && rateError === "" && !rateTableError.includes(false) && !TotalCheckValue) {
        if (btnRef.current) {
          btnRef.current.setAttribute("disabled", "disabled");
        }
        if (isDuplicate) {
          document.getElementsByClassName("freelanceErr")[0].innerText =
            "Duplicate primary skills are not allowed";
          btnRef.current.removeAttribute("disabled");
        } else if (isSecDuplicate) {
          document.getElementsByClassName("freelanceErr")[0].innerText =
            "Duplicate secondary skills are not allowed";
          btnRef.current.removeAttribute("disabled");
        } else if (isBothDuplicate && isBothDuplicate.length !== 0) {
          document.getElementsByClassName("freelanceErr")[0].innerText =
            "Duplicate skills are not allowed";
          btnRef.current.removeAttribute("disabled");
        }
        else{
          setLoader(true);
          try {
          if (!id) {
            const response = await freecandidateProfile(token, filteredValue);
            if (response.status === 200) {
              const data = await response.json();
              const payload = data.payload;
              setInformation({
                ...information,
                uniqueId: payload.uuid,
              });
              const primaryResponse = await freepostBuildResumeSkill(
                token,
                parseData,
                payload.uuid
              );
              if (primaryResponse.status === 200) {
                setRedirect(true);
              }
              if (primaryResponse.status === 422) {
                setLoader(false);
                btnRef.current.removeAttribute("disabled");
                const msg = await primaryResponse.json();
                const payload = msg.payload;
                const message =
                  payload.primarySkill.children[0].skill.message;
                document.getElementsByClassName(
                  "freelanceErr"
                )[0].innerText = message;
              }
            } else if(response.status === 400){
              btnRef.current.removeAttribute("disabled");
              setLoader(false);
              const msg = await response.json();
              const message = msg.message;
              if(message === "Please enter corporate mail id"){
              setError({
                ...error,
                email : message
                })
                document.getElementsByClassName("freelanceErr")[0].innerText =
                "please fill all required fields";
              }
            }else {
              btnRef.current.removeAttribute("disabled");
              setLoader(false);
              document.getElementsByClassName("freelanceErr")[0].innerText =
                "please fill all required fields";
            }
          } else {
            const response = await freeupdateCandidateProfile(
              token,
              id,
              filteredValue
            );
            if (response.status === 200) {
              const primaryResponse = await freepostBuildResumeSkill(
                token,
                parseData,
                id
              );
              if (primaryResponse.status === 200) {
                setRedirect(true);
              }
              if (primaryResponse.status === 422) {
                btnRef.current.removeAttribute("disabled");
                const msg = await primaryResponse.json();
                const payload = msg.payload;
                const message =
                  payload.primarySkill.children[0].skill.message;
                document.getElementsByClassName(
                  "freelanceErr"
                )[0].innerText = message;
              }
            }else if(response.status === 400){
              btnRef.current.removeAttribute("disabled");
              setLoader(false);
              const msg = await response.json();
              const message = msg.message;
              if(message === "Please enter corporate mail id"){
              setError({
                ...error,
                email : message
                })
               document.getElementsByClassName("freelanceErr")[0].innerText =
                "please fill all required fields";
              }
            } else {
              btnRef.current.removeAttribute("disabled");
              setLoader(false);
              document.getElementsByClassName("freelanceErr")[0].innerText =
                "please fill all required fields";
            }
          }
        } catch {}
        }
      }
    } catch {}
  }

  useEffect(() => {
    async function callItSelf() {
      try {
        if (!information.mobileNumber) {
          const profileResponse = await FreeGetCandidateProfile(token, id);
          if (profileResponse.status === 200) {
            const profileData = await profileResponse.json();
            const inf = profileData.payload;
            let primary = 0;
            let secondary = 1;
            if (inf.Addresses.length > 1) {
              primary = inf.Addresses[0].isPrimary ? 0 : 1;
              secondary = inf.Addresses[1].isPrimary ? 0 : 1;
            }
            let primAdd = inf.Addresses[primary];
            try {
              //only to capture error whether it coming or not
              const tmp = primAdd.isPrimary;
            } catch {
              primAdd = { isPrimary: "" };
              primAdd.isPrimary = information.isPrimaryAddress;
            }
            if (!information.mobileNumber) {
              let travelpref = inf.travelPreference;
              setVisibility(inf.haveAnEmployer);
              setRelocation(
                inf.reLocationPreference ? ["Yes", "No"] : ["No", "Yes"]
              );
              setShowPreferredBox(inf.reLocationPreference)
              if (!travelpref) travelpref = "Occassional";
              setTravelPreference(
                [travelpref].concat(
                  travelPreference.filter((data) => {
                    if (data === travelpref) return false;
                    return true;
                  })
                )
              );
              setInformation({
                firstName: inf.firstName || "",
                middleName: inf.middleName || "",
                lastName: inf.lastName || "",
                mobileNumber: inf.mobile || "",
                resumeTitle: inf.resumeTitle || "",
                SSNNumber: inf.ssnNumber || "",

                isPrimaryAddress: primAdd.isPrimary || false,
                zipCode: primAdd.zipCode || "",
                addressLine1: primAdd.addressLine1 || "",
                addressLine2: primAdd.addressLine2 || "",
                city: primAdd.city || "",
                county: primAdd.county || "",
                state: primAdd.state || "",
                country: primAdd.country || "United States",

                companyName: inf.companyName || "",
                fein: inf.feinNumber || "",
                website: inf.website || "",
                contactPerson: inf.contactPerson || "",
                email: inf.contactEmail || "",
                phone: inf.contactPhone || "",
                empzipCode: inf.zipCode || "",
                empaddressLine1: inf.addressLine1 || "",
                empaddressLine2: inf.addressLine2 || "",
                empcity: inf.city || "",
                empcounty: inf.county || "",
                empstate: inf.state || "",
                empcountry: inf.country || "United States",
                rate: inf.rate || "",
                rateType: inf.rateType || "",
                workAuthorization: inf.workAuthorization || "",
                preferredLocation: inf.preferredLocation || [],
                currentLocation: inf.currentLocation || "",
                preferredEmployment: inf.preferredEmployment || "",
                uniqueId: id,
              });
              try {
                const addMoreAddr = inf.Addresses[secondary];
                if (addMoreAddr.addressLine1) {
                  setAddMore(true);
                  setAddMoreInformation({
                    zipCode: addMoreAddr.zipCode || "",
                    addressLine1: addMoreAddr.addressLine1 || "",
                    addressLine2: addMoreAddr.addressLine2 || "",
                    city: addMoreAddr.city || "",
                    county: addMoreAddr.county || "",
                    state: addMoreAddr.state || "",
                    country: addMoreAddr.country || "United States",
                  });
                }
              } catch {}
            }
          }
        }
      } catch {}
    }
    callItSelf();
  }, []);

  //get details of primary skill
  useEffect(async () => {
    try {
      const response = await freegetBuildResumeSkill(token, id);
      if (response.status === 200) {
        let payload = await response.json();
        payload = payload.payload;
       try {
          setSkillData(
            payload,
            primaryInformation,
            setPrimaryInformation,
            secondaryInfo,
            setSecondaryInfo,
            setSecondaryDate,
            setSecondaryDate0,
            setSecondaryDate1,
            setSecondaryDate2,
            visible,
            setVisible,
            secondaryVisible,
            setSecondaryVisible,
            setDate,
            setDate0,
            setDate1,
            setDate2
          );
        } catch {}
     }
    } catch {}
  }, []);

  const [zipCodelist, setZipCodeList] = useState([]);
  const [show, setShow] = useState(false);

  async function handleCodeChange() {
    let data = information.zipCode;
    const response = await zipCode(token, data);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setZipCodeList(payload);
      if (payload.length === 0) {
        setShow(false);
        setError({
          ...error,
          zipCode: "please enter valid zipCode",
        });
      }
    }
  }

  useEffect(() => {
    handleCodeChange();
  }, [information.zipCode]);

  async function selectedPincode(zipCodeId, zipCode) {
    setShow(false);
    setInformation({
      ...information,
      zipCode: zipCode,
    });
    setError({
      ...error,
      zipCode:""
    })
    const response = await zipCodeDetails(token, zipCodeId);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setInformation({
        ...information,
        zipCode: payload.zip,
        city: payload.city,
        state: payload.stateName,
        county: payload.countyName,
      });
      setError({
        ...error,
        zipCode: "",
        city:"",
        state: ""
      })
    }
  }
  // zipcode dropdown
  const ref = React.useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (show && ref.current && !ref.current.contains(event.target)) {
        document.getElementById("zipCodeDiv").style.display = "none";
      }
      document.body.removeEventListener("click", handleClickOutside);
    };
    document.body.addEventListener("click", handleClickOutside);
  }, [show]);

  function callBoth(event) {
    validateRegistration(event, setInformation, setError);
    setShow(true);
  }

  const [addzipCodelist, setAddZipCodeList] = useState([]);
  async function handleAddCodeChange() {
    let data = addMoreInformation.zipCode;
    const response = await zipCode(token, data);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setAddZipCodeList(payload);
    }
  }

  useEffect(() => {
    handleAddCodeChange();
  }, [addMoreInformation.zipCode]);

  const [employerzipCodelist, setEmployerZipCodeList] = useState([]);
  const [employerShow, setEmployerShow] = useState(false);
  async function handleEmployerCodeChange() {
    let data = information.empzipCode;
    const response = await zipCode(token, data);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setEmployerZipCodeList(payload);
      if (payload.length === 0) {
        setEmployerShow(false);
        setError({
          ...error,
          empzipCode: "please enter valid zipCode",
        });
      }
    }
  }
  // emp zipcode dropdown
  const empref = React.useRef();
  useEffect(() => {
    const empHandleClickOutside = (event) => {
      if (
        employerShow &&
        empref.current &&
        !empref.current.contains(event.target)
      ) {
        document.getElementById("empzipCodeDiv").style.display = "none";
      }
      document.body.removeEventListener("click", empHandleClickOutside);
    };
    document.body.addEventListener("click", empHandleClickOutside);
  }, [employerShow]);

  useEffect(() => {
    handleEmployerCodeChange();
  }, [information.empzipCode]);

  async function selectedEmployerPincode(zipCodeId, zipCode) {
    setEmployerShow(false);
    setInformation({
      ...information,
      empzipCode: zipCode,
    });
    setError({
      ...error,
      empzipCode : ""
    })
    const response = await zipCodeDetails(token, zipCodeId);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setInformation({
        ...information,
        empzipCode: payload.zip,
        empcity: payload.city,
        empstate: payload.stateName,
        empcounty: payload.countyName,
      });
    }
  }

  function callEmployerBoth(event) {
    validateRegistration(event, setInformation, setError);
    setEmployerShow(true);
  }

  function formatPhoneNumber(event, setInformation) {
    const value = event.target.value;
    const input = value.replace(/\D/g,"").substring(0, 10);
    const firstLetter = input.substring(0,1);
    const first = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);
    if(firstLetter === '0' || firstLetter === '1') {
      setError({
        ...error,
        mobileNumber: "Mobile number should not start with '0 or 1'"
      })
    }
    else {
      if(input.length !== 10 && input.length !== 0) {
        setError({
            ...error,
            mobileNumber: "Must be 10 digits"
          })
      }
      else {
        setError({
          ...error,
          mobileNumber: ""
        })
      }
    }
    if (input.length > 6) {
      setInformation({
        ...information,
        mobileNumber: `(${first}) ${middle}-${last}`,
      });
    } else if (input.length > 3) {
      setInformation({
        ...information,
        mobileNumber: `(${first}) ${middle}`,
      });
    } else if (input.length >= 0) {
      setInformation({
        ...information,
        mobileNumber: input,
      });
    }
  }


  function empformatPhoneNumber(event, setInformation) {
    const value = event.target.value;
    const input = value.replace(/\D/g, "").substring(0, 10);
    const first = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);
    if(input.length !== 10 && input.length !== 0) {
      setError({
        ...error,
        phone: "length should exact 10"
      })
    }
    else {
      setError({
        ...error,
        phone: ""
      })
    }
    if (input.length > 6) {
      setInformation({
        ...information,
        phone: `${first} ${middle} ${last}`,
      });
    } else if (input.length > 3) {
      setInformation({
        ...information,
        phone: `${first} ${middle}`,
      });
    } else if (input.length >= 0) {
      setInformation({
        ...information,
        phone: input,
      });
    }
  }

  //preferredLocation integration
  const [citylist, setCityList] = useState([]);
  const [preferredcity,setPreferredCity] = useState([]);

  async function handlePreferredLocationChange(event) {
    const response = await locationListSearch(token, event);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setCityList(payload);
    } 
  }

  useEffect(() => {
    setError({
      ...error,
      preferredLocation: ""
    })
    setInformation({
      ...information,
      preferredLocation: preferredcity.map(obj => obj.city)
    })
  }, [preferredcity]);

  //currentLocation integration
  const [currentCitylist, setCurrentCityList] = useState([]);
  const [currentCityShow, setCurrentCityShow] = useState(false);

  async function handleCurrentLocationChange() {
    let data = information.currentLocation;
    const response = await locationListSearch(token, data);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setCurrentCityList(payload);
    } else if (response.status === 404) {
      setCurrentCityShow(false);
      setError({
        ...error,
        currentLocation: "please enter valid city",
      });
    }
  }

  useEffect(() => {
    handleCurrentLocationChange();
  }, [information.currentLocation]);

  async function selectedCurrentLocation(city) {
    setCurrentCityShow(false);
    setInformation({
      ...information,
      currentLocation: city,
    });
    setError({
      ...error,
      currentLocation: "",
    });
  }
  function checkCurrentLocationValidOne() {
    let currentFieldValue = information.currentLocation;
    let mapped = currentCitylist.map((data) => data.city);
    let found = mapped.includes(currentFieldValue);
    if (information.currentLocation.length !== 0) {
      if (found) {
        setError({
          ...error,
          currentLocation: "",
        });
      } else {
        setError({
          ...error,
          currentLocation: "please enter valid city",
        });
      }
    }
  }
  // for current loctaion outside click
  const curref = React.useRef();
  useEffect(() => {
    const curHandleClickOutside = (event) => {
      if (
        currentCityShow &&
        curref.current &&
        !curref.current.contains(event.target)
      ) {
        document.getElementById("curLocCodeDiv").style.display = "none";
        checkCurrentLocationValidOne();
      }
      document.body.removeEventListener("click", curHandleClickOutside);
    };
    document.body.addEventListener("click", curHandleClickOutside);
  }, [currentCityShow, currentCitylist, information.currentLocation]);

  return (
    <div className={"col mx-5"}>
      <form
        className={"form-group"}
        noValidate
        onSubmit={(event) =>
          submit(
            event,
            error,
            information,
            setError,
            addmore,
            addMoreInformation,
            setAddMoreError,
            visibility
          )
        }
      >
        <div className={"row pt-4"}>
          <div className={"col"}>
            {/*first box*/}
            <div className={"row px-5 pr-5 mx-3"}>
              <div className={"w-100 pt-4 pb-2 px-5 contentholder"}>
                <div className={"row"}>
                  <div className={"col-md"}>
                    <label
                      htmlFor={"fname"}
                      className={"dashboardforminputtext"}
                    >
                      First Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"fname"}
                      name={"firstName"}
                      maxLength={20}
                      value={information.firstName}
                      style={{
                        border: `${error.firstName ? "1px solid red" : ""}`,
                      }}
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                      className={
                        "form-control dashboardforminputfield names shadow-none"
                      }
                    />
                    <font
                      className={
                        "dashboardforminputtext text-danger text-align-center"
                      }
                    >
                      {error.firstName}
                    </font>
                  </div>
                  <div className={"col-md"}>
                    <label
                      htmlFor={"mname"}
                      className={"dashboardforminputtext"}
                    >
                      Middle Name
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"mname"}
                      name={"middleName"}
                      maxLength={20}
                      value={information.middleName}
                      style={{
                        border: `${error.middleName ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.middleName}
                    </font>
                  </div>
                  <div className={"col-md"}>
                    <label
                      htmlFor={"lname"}
                      className={"dashboardforminputtext"}
                    >
                      Last Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"lname"}
                      name={"lastName"}
                      maxLength={20}
                      value={information.lastName}
                      style={{
                        border: `${error.lastName ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control dashboardforminputfield names shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.lastName}
                    </font>
                  </div>
                </div>
                <div className={"row pt-2 pb-2"}>
                  <div className={"col-md"}>
                    <label htmlFor={"mno"} className={"dashboardforminputtext"}>
                      Mobile Number<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      placeholder={" (XXX) XXX-XXXX"}
                      id={"mno"}
                      name={"mobileNumber"}
                      maxLength={14}
                      value={information.mobileNumber}
                      style={{
                        border: `${error.mobileNumber ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) => {
                        formatPhoneNumber(event, setInformation);
                      }}
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.mobileNumber}
                    </font>
                  </div>
                  <div className={"col-md"}>
                    <label
                      htmlFor={"rtitle"}
                      className={"dashboardforminputtext"}
                    >
                      Resume Title<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"rtitle"}
                      name={"resumeTitle"}
                      value={information.resumeTitle}
                      style={{
                        border: `${error.resumeTitle ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.resumeTitle}
                    </font>
                  </div>
                  <div className={"col-md"}>
                    <label htmlFor={"ssn"} className={"dashboardforminputtext"}>
                      SSN Number
                    </label>
                    <label
                      style={{
                        position: "absolute",
                        left: "40px",
                        top: "44px",
                        color: "#000000",
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                      XXX-XX-
                    </label>
                    <input
                      type={"text"}
                      id={"ssn"}
                      name={"SSNNumber"}
                      placeholder={"Last 4 digits"}
                      value={information.SSNNumber}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      style={{
                        paddingLeft: "74px",
                        border: `${error.resumeTitle ? "1px solid red" : ""}`
                      }}
                      maxLength="4"
                      pattern="[0-9]*"
                      onChange={(e) => {
                        validateRegistration(e, setInformation, setError)
                        setInformation({
                          ...information,
                          SSNNumber: e.target.validity.valid ? e.target.value : information.SSNNumber
                        })
                      }}
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.SSNNumber}
                    </font>
                  </div>
                </div>
             </div>
            </div>
            {/*second box*/}
            <div className={"row px-5 pr-5 mx-3 pt-4"}>
              <div
                className={"w-100 pt-4 pb-0 px-5 contentholder"}
                id={"addmoreaddress"}
              >
                <div className={"row"}>
                  <div className={"col-md-3"}>
                    <label
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#5C5C5C",
                      }}
                    >
                      Primary Address
                    </label>
                  </div>
                </div>
                <div className={"row pt-2"}>
                  <div className={"col-md-4 input-placeholder"} ref={ref}>
                    <input
                      type={"text"}
                      maxLength={"5"}
                      className={
                        "form-control dashboardforminputfield ziplocation shadow-none"
                      }
                      name={"zipCode"}
                      value={information.zipCode}
                      style={{
                        border: `${error.zipCode ? "1px solid red" : ""}`,
                      }}
                      required
                      onChange={callBoth}
                      autoComplete={"off"}
                      onClick={() => {
                        setShow(!show);
                      }}
                    />
                    <div className={"placeholder"}>
                      Enter Zip Code<span>*</span>
                    </div>
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.zipCode}
                    </font>
                    <div
                      id={"zipCodeDiv"}
                      style={{
                        display: `${
                          show && information.zipCode > 0 ? "block" : "none"
                        }`,
                      }}
                    >
                      {zipCodelist &&
                        zipCodelist.length > 0 &&
                        zipCodelist.map((employee) => (
                          <div
                            key={employee.id}
                            style={{ cursor: "pointer" }}
                            value={employee.zip}
                            onClick={() =>
                              selectedPincode(employee.id, employee.zip)
                            }
                          >
                            {employee.zip} - {employee.city}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className={"col-md input-placeholder"}>
                    <input
                      type={"text"}
                      name={"addressLine1"}
                      value={information.addressLine1}
                      style={{
                        border: `${error.addressLine1 ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                      required
                    />
                    <div className={"placeholder"}>
                      Address Line 1<span>*</span>
                    </div>
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.addressLine1}
                    </font>
                  </div>
                </div>
                <div className={"row pt-3"}>
                  <div className={"col-md"}>
                    <input
                      type={"text"}
                      placeholder={"Address Line 2"}
                      value={information.addressLine2}
                      style={{
                        border: `${error.addressLine2 ? "1px solid red" : ""}`,
                      }}
                      name={"addressLine2"}
                      className={
                        "form-control dashboardforminputfield address shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.addressLine2}
                    </font>
                  </div>
                  <div className={"col-md-4 input-placeholder"}>
                    <input
                      type={"text"}
                      value={information.city}
                      style={{
                        border: `${error.city ? "1px solid red" : ""}`,
                      }}
                      name={"city"}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                      required
                      disabled
                    />
                    {
                      information.city === "" &&
                      <div className={"placeholder"}>
                      City<span>*</span>
                    </div>
                    }
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.city}
                    </font>
                  </div>
                </div>
                <div className={"row pt-3"}>
                  <div className={"col-md-4"}>
                    <input
                      type={"text"}
                      value={information.county}
                      style={{
                        border: `${error.county ? "1px solid red" : ""}`,
                      }}
                      name={"county"}
                      disabled
                      placeholder="County"
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.county}
                    </font>
                  </div>
                  <div className={"col-md-4 input-placeholder"}>
                    <input
                      type={"text"}
                      value={information.state}
                      style={{
                        border: `${error.state ? "1px solid red" : ""}`,
                      }}
                      name={"state"}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                      required
                      disabled
                    />
                    {
                      information.state === "" &&
                      <div className={"placeholder"}>
                      State<span>*</span>
                    </div>
                    }
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.state}
                    </font>
                  </div>
                  <div className={"col-md-4 input-placeholder"}>
                    <input
                      type={"text"}
                      value={information.country}
                      style={{
                        border: `${error.country ? "1px solid red" : ""}`,
                      }}
                      name={"country"}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                      required
                    />
                    <div className={"placeholder"}>
                      Country<span>*</span>
                    </div>
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.country}
                    </font>
                  </div>
                </div>
                {addmore ? (
                  <div className="row pt-2 pb-3"></div>
                ) : (
                  <div className={"row"}>
                    <div className={"col-md"}>
                      <div
                        className={
                          "btn float-right badge border-0 pt-3 newskill"
                        }
                        style={{ backgroundColor: "white" }}
                        onClick={() => setAddMore(true)}
                      >
                        + Add Mailing Address
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {addmore && (
              <AddMoreAddress
                information={addMoreInformation}
                setInformation={setAddMoreInformation}
                error={addMoreError}
                setError={setAddMoreError}
                addmore={addmore}
                setAddMore={setAddMore}
                zipCodeList={addzipCodelist}
              />
            )}
            {/*third box*/}
            {/* <div className={"row px-5 pr-5 mx-3 pt-4"}>
              <div
                className={"w-100 pt-4 pb-4 px-5 contentholder"}
                id={"addmoreaddress"}
              >
                <div className={"row"}>
                  <div className={"col-md form-inline"}>
                    <label className={"resumeformquerytext pr-3"}>
                      Do you have an Employer{" "}
                    </label>
                    <input
                      type="radio"
                      className="resumeradio"
                      id="customRadio"
                      name="registrationEmployer"
                      value="yes"
                      checked={visibility}
                      onClick={() => setVisibility(true)}
                    />
                    <label
                      className="dashboardforminputtext pl-2 pr-3"
                      htmlFor="customRadio"
                    >
                      Yes
                    </label>
                    <input
                      type="radio"
                      className="resumeradio"
                      id="customRadio0"
                      name="registrationEmployer"
                      value="no"
                      checked={!visibility}
                      onClick={() => setVisibility(false)}
                    />
                    <label
                      className="dashboardforminputtext pl-2"
                      htmlFor="customRadio0"
                    >
                      No
                    </label>
                  </div>
                </div>
                {visibility && (
                  <div id={"employerid"}>
                    <div className={"row pt-3"}>
                      <div className={"col-md-4 input-placeholder"}>
                        <input
                          type={"text"}
                          className={
                            "form-control dashboardforminputfield shadow-none"
                          }
                          name={"companyName"}
                          value={information.companyName}
                          style={{
                        border: `${error.companyName ? "1px solid red" : ""}`,
                      }}
                          onChange={(event) =>
                            validateRegistration(
                              event,
                              setInformation,
                              setError
                            )
                          }
                          required
                        />
                        <div className={"placeholder"}>
                          Company Name<span>*</span>
                        </div>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.companyName}
                        </font>
                      </div>
                      <div className={"col-md-4 input-placeholder"}>
                        <input
                          type={"text"}
                          className={
                            "form-control dashboardforminputfield shadow-none"
                          }
                          name={"fein"}
                          value={information.fein}
                          style={{
                        border: `${error.fein ? "1px solid red" : ""}`,
                      }}
                          onChange={(event) =>
                            validateRegistration(
                              event,
                              setInformation,
                              setError
                            )
                          }
                          required
                        />
                        <div className={"placeholder"}>
                          FEIN<span>*</span>
                        </div>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.fein}
                        </font>
                      </div>
                      <div className={"col-md-4"}>
                        <input
                          type={"text"}
                          className={
                            "form-control dashboardforminputfield shadow-none"
                          }
                          placeholder={"website"}
                        />
                      </div>
                    </div>
                    <div className={"row pt-3"}>
                      <div className={"col-md-4 input-placeholder"}>
                        <input
                          type={"text"}
                          className={
                            "form-control dashboardforminputfield shadow-none"
                          }
                          name={"contactPerson"}
                          value={information.contactPerson}
                          style={{
                        border: `${error.contactPerson ? "1px solid red" : ""}`,
                      }}
                          onChange={(event) =>
                            validateRegistration(
                              event,
                              setInformation,
                              setError
                            )
                          }
                          required
                        />
                        <div className={"placeholder"}>
                          Contact Person<span>*</span>
                        </div>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.contactPerson}
                        </font>
                      </div>
                      <div className={"col-md-4 input-placeholder"}>
                        <input
                          type={"text"}
                          className={
                            "form-control dashboardforminputfield shadow-none"
                          }
                          name={"email"}
                          value={information.email}
                          style={{
                        border: `${error.email ? "1px solid red" : ""}`,
                      }}
                          onChange={(event) =>
                            validateRegistration(
                              event,
                              setInformation,
                              setError
                            )
                          }
                          required
                        />
                        <div className={"placeholder"}>
                          Email<span>*</span>
                        </div>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.email}
                        </font>
                      </div>
                      <div className={"col-md-4 input-placeholder"}>
                        <input
                          type={"text"}
                          className={
                            "form-control dashboardforminputfield shadow-none"
                          }
                          name={"phone"}
                          value={information.phone}
                          style={{
                        border: `${error.phone ? "1px solid red" : ""}`,
                      }}
                      maxLength={12}
                      onChange={(event) =>{
                        empformatPhoneNumber(event,setInformation)
                      }}
                          required
                        />
                        <div className={"placeholder"}>
                          Phone<span>*</span>
                        </div>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.phone}
                        </font>
                      </div>
                    </div>
                    <div className={"row pt-3"}>
                      <div
                        className={"col-md-4 input-placeholder"}
                        ref={empref}
                      >
                        <input
                          type={"text"}
                          required
                          className={
                            "form-control dashboardforminputfield ziplocation shadow-none"
                          }
                          name={"empzipCode"}
                          value={information.empzipCode}
                          style={{
                        border: `${error.empzipCode ? "1px solid red" : ""}`,
                      }}
                          onChange={callEmployerBoth}
                          autoComplete={"off"}
                          onClick={() => {
                            setEmployerShow(!employerShow);
                          }}
                        />
                        <div className={"placeholder"}>
                          Enter ZIP code<span>*</span>
                        </div>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.empzipCode}
                        </font>
                        <div
                          id={"empzipCodeDiv"}
                          style={{
                            display: `${employerShow ? "block" : "none"}`,
                          }}
                        >
                          {employerzipCodelist &&
                            employerzipCodelist.length > 0 &&
                            employerzipCodelist.map((employee) => (
                              <div
                                key={employee.id}
                                style={{ cursor: "pointer" }}
                                value={employee.zip}
                                onClick={() =>
                                  selectedEmployerPincode(
                                    employee.id,
                                    employee.zip
                                  )
                                }
                              >
                                {employee.zip} - {employee.city}
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className={"col-md input-placeholder"}>
                        <input
                          type={"text"}
                          className={
                            "form-control dashboardforminputfield shadow-none"
                          }
                          name={"empaddressLine1"}
                          value={information.empaddressLine1}
                          style={{
                        border: `${error.empaddressLine1 ? "1px solid red" : ""}`,
                      }}
                          onChange={(event) =>
                            validateRegistration(
                              event,
                              setInformation,
                              setError
                            )
                          }
                          required
                        />
                        <div className={"placeholder"}>
                          Address Line1<span>*</span>
                        </div>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.empaddressLine1}
                        </font>
                      </div>
                    </div>
                    <div className={"row pt-3"}>
                      <div className={"col-md-8"}>
                        <input
                          type={"text"}
                          className={
                            "form-control dashboardforminputfield shadow-none"
                          }
                          placeholder={"Address Line2"}
                          name={"empaddressLine2"}
                          value={information.empaddressLine2}
                          style={{
                        border: `${error.empaddressLine2 ? "1px solid red" : ""}`,
                      }}
                          onChange={(event) =>
                            validateRegistration(
                              event,
                              setInformation,
                              setError
                            )
                          }
                        />
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.empaddressLine2}
                        </font>
                      </div>
                      <div className={"col-md-4 input-placeholder"}>
                        <input
                          type={"text"}
                          className={
                            "form-control dashboardforminputfield shadow-none"
                          }
                          name={"empcity"}
                          value={information.empcity}
                          style={{
                        border: `${error.empcity ? "1px solid red" : ""}`,
                      }}
                          onChange={(event) =>
                            validateRegistration(
                              event,
                              setInformation,
                              setError
                            )
                          }
                          required
                        />
                        <div className={"placeholder"}>
                          City<span>*</span>
                        </div>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.empcity}
                        </font>
                      </div>
                    </div>
                    <div className={"row pt-3"}>
                      <div className={"col-md-4"}>
                        <input
                          type={"text"}
                          className={
                            "form-control dashboardforminputfield shadow-none"
                          }
                          name={"empcounty"}
                          placeholder="County"
                          value={information.empcounty}
                          style={{
                        border: `${error.empcounty ? "1px solid red" : ""}`,
                      }}
                          onChange={(event) =>
                            validateRegistration(
                              event,
                              setInformation,
                              setError
                            )
                          }
                        />
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.empcounty}
                        </font>
                      </div>
                      <div className={"col-md-4 input-placeholder"}>
                        <input
                          type={"text"}
                          className={
                            "form-control dashboardforminputfield shadow-none"
                          }
                          name={"empstate"}
                          value={information.empstate}
                          style={{
                        border: `${error.empstate ? "1px solid red" : ""}`,
                      }}
                          onChange={(event) =>
                            validateRegistration(
                              event,
                              setInformation,
                              setError
                            )
                          }
                          required
                        />
                        <div className={"placeholder"}>
                          State<span>*</span>
                        </div>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.empstate}
                        </font>
                      </div>
                      <div className={"col-md-4"}>
                        <input
                          type={"text"}
                          className={
                            "form-control dashboardforminputfield shadow-none"
                          }
                          name={"empcountry"}
                          disabled
                          value={information.empcountry}
                          style={{
                        border: `${error.empcountry ? "1px solid red" : ""}`,
                      }}
                          onChange={(event) =>
                            validateRegistration(
                              event,
                              setInformation,
                              setError
                            )
                          }
                          required
                        />
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.empcountry}
                        </font>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div> */}
            {/*fourth box*/}
            <div className={"row px-5 mx-3 pt-4"}>
              <div className={"w-100  pb-2 px-4 pr-5 contentholder"}>
                <RateCard
                  {...{
                    workAuthorization: information.workAuthorization,
                    preferredEmployment: information.preferredEmployment,
                    rateType: information.rateType,
                    rate: information.rate,
                  }}
                />
              </div>
            </div>
            {/*sixth box*/}
            <div className={"row px-5 pr-5 mx-3 pt-4"}>
              <div className={"w-100 pt-3 pb-4 px-5 contentholder"}>
                <div className={"row"}>
                  <div className={"col-md-4"} ref={curref}>
                    <label
                      htmlFor={"relocate"}
                      className={"dashboardforminputtext"}
                    >
                      Current Location<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      id={"clocation"}
                      name={"currentLocation"}
                      value={information.currentLocation}
                      style={{
                        border: `${error.currentLocation ? "1px solid red" : ""}`,
                      }}
                      onChange={(event) => {
                        validateRegistration(event, setInformation, setError);
                        setCurrentCityShow(true);
                      }}
                      autoComplete={"off"}
                      onClick={() => {
                        setCurrentCityShow(!currentCityShow);
                        checkCurrentLocationValidOne();
                      }}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                    />
                    <font
                      className={
                        "dashboardforminputtext text-danger text-align-center"
                      }
                    >
                      {error.currentLocation}
                    </font>
                    <div
                      id={"curLocCodeDiv"}
                      style={{
                        display: `${
                          currentCityShow &&
                          information.currentLocation.length > 0
                            ? "block"
                            : "none"
                        }`,
                      }}
                    >
                      {currentCitylist &&
                        currentCitylist.length > 0 &&
                        currentCitylist.map((data, index) => (
                          <div
                            key={index}
                            style={{ cursor: "pointer" }}
                            value={data.city}
                            onClick={() => selectedCurrentLocation(data.city)}
                          >
                            {data.city}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className={"col-md-4"}>
                    <label
                      htmlFor={"preflocation"}
                      className={"dashboardforminputtext"}
                    >
                      Travel Preference
                    </label>
                    <select
                      className={
                        "form-control dashboardforminputfield form-select inputgray shadow-none"
                      }
                      id={"preflocation"}
                      name={"travellocation"}
                    >
                      {travelPreference.map((data, index) => {
                        return (
                          <option key={index} value={data}>
                            {data}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className={"row pt-1"}>
                  <div className={"col-md-4"}>
                    <label
                      htmlFor={"reloaction"}
                      className={"dashboardforminputtext"}
                    >
                      Relocation
                    </label>
                    <select
                      className={
                        "form-control dashboardforminputfield form-select inputgray shadow-none"
                      }
                      id={"relocation"}
                      name={"relocation"}
                      onChange={(e)=> {
                        if(e.target.value === "Yes") {
                          setShowPreferredBox(true)
                        }
                        else if(e.target.value === "No"){
                          setShowPreferredBox(false)
                          setError({
                            ...error,
                            preferredLocation: ""
                          })
                          setPreferredCity([])
                          setCityList([])
                        }
                      }}
                    >
                      {relocation.map((data, index) => {
                        return (
                          <option key={index} value={data}>
                            {data}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {
                    showPreferredBox &&
                    <div className={"col-md-4 input-placeholder"}>
                    <label
                      htmlFor={"preflocationrelocation"}
                      className={"dashboardforminputtext"}
                    >
                      Preferred Location<span style={{ color: "red" }}>*</span>
                    </label>
                    <Multiselect
                    options={citylist}
                    onSearch={(e)=>handlePreferredLocationChange(e)}
                    selectionLimit={5}
                    selectedValues={information.preferredLocation.map(obj=>{
                      return {city : obj}
                    })}
                    hidePlaceholder={true}
                    onSelect={(e)=>setPreferredCity(e)}
                    onRemove={(e)=>{setPreferredCity(e)}}
                    emptyRecordMsg={"No City Found"}
                    displayValue="city"
                    style={{
                      searchBox: {
                        border: `${error.preferredLocation ? "1px solid red" : "1px solid #C2C2C2"}`,
                        backgroundColor: "#FBFBFB",
                        height: "auto",
                        borderRadius: "8px",
                        fontSize: "14px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      },
                      option: {
                        fontSize: "12px",
                        color: "#000000",
                        fontWeight: "500",
                      },
                      chips: {
                        fontWeight: "500",
                      },
                    }}
                    id="preflocationrelocation" >
                  </Multiselect>
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.preferredLocation}
                    </font>
                    </div>
                  }
                </div>
              </div>
            </div>
            {/*seventh box*/}
            <div className={"row px-5 pr-5 mx-3 pt-4"}>
              <div className={"w-100 pb-4 pt-2 contentholder"}>
                <div className={"row  mx-4 pt-1"}>
                  <div className={"col-md"}>
                    <label htmlFor={"pskill"} className={"resumeforminputtext"}>
                      Primary Skill<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"pskill"}
                      className={
                        "form-control resumeforminputfield shadow-none "
                      }
                      name={"primarySkill"}
                      value={primaryInformation.primarySkill}
                      style={{
                        border: `${primaryError.primarySkill ? "1px solid red" : ""}`,
                      }}
                      onChange={(event) => {
                        buildResumeSkill(
                          event,
                          setPrimaryInformation,
                          setPrimaryError
                        );
                      }}
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {primaryError.primarySkill}
                    </font>
                  </div>
                  <div className={"col-md"}>
                    <label htmlFor={"pexp"} className={"resumeforminputtext"}>
                      Years Of Experience<span style={{ color: "red" }}>*</span>
                    </label>
                    <div
                      className={"dropdownforminputfield"}
                      style={{ display: "flex", flexDirection: "row",border:`${primaryError.year || primaryError.month ? "1px solid red" : ""}` }}
                    >
                      <select
                        className={
                          "form-control form-select dropgray shadow-none ml-0"
                        }
                        id={"pexp"}
                        name={"year"}
                        value={primaryInformation.year}
                        onChange={(event) => {
                          buildResumeSkill(
                            event,
                            setPrimaryInformation,
                            setPrimaryError
                          );
                        }}
                      >
                        <option value={""}>Year</option>
                        <GetPrimaryYear />
                      </select>
                      <select
                        className={
                          "form-control form-select dropgray1 shadow-none"
                        }
                        id={"pexp"}
                        name={"month"}
                        value={primaryInformation.month}
                        onChange={(event) => {
                          buildResumeSkill(
                            event,
                            setPrimaryInformation,
                            setPrimaryError
                          );
                        }}
                      >
                        <option value={""}>Month</option>
                        <GetPrimaryMonth />
                      </select>
                    </div>
                    <font className={"dashboardforminputtext text-danger"}>
                      {primaryError.year}
                    </font>
                    <font className={"dashboardforminputtext text-danger"}>
                      {primaryError.month}
                    </font>
                  </div>
                  <div className={"col-md"}>
                    <label
                      htmlFor={"lastused"}
                      className={"resumeforminputtext"}
                    >
                      Last Used<span style={{ color: "red" }}>*</span>
                    </label>

                    <DatePicker
                      selected={date}
                      required="true"
                      onChange={(date) => {
                        setDate(date);
                      }}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      maxDate={new Date()}
                      customInput={<CustomInput />}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/*duplicate here*/}
            {visible.one && (
              <div className={"row px-5 pr-5 my-2 mx-3 pt-1"}>
                <div className={"w-100 pt-1 pb-2 contentholder"}>
                  <div className={"row pt-3 mx-4"}>
                    <div className={"col-md"}>
                      <label
                        htmlFor={"pskill"}
                        className={"resumeforminputtext"}
                      >
                        Primary Skill<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type={"text"}
                        placeholder={"Type Here"}
                        id={"pskill"}
                        className={
                          "form-control resumeforminputfield shadow-none "
                        }
                        name={"primarySkill0"}
                        value={primaryInformation.primarySkill0}
                        style={{
                        border: `${primaryError.primarySkill0 ? "1px solid red" : ""}`,
                      }}
                        onChange={(event) => {
                          buildResumeSkill(
                            event,
                            setPrimaryInformation,
                            setPrimaryError,
                            true
                          );
                        }}
                      />
                      <font className={"dashboardforminputtext text-danger"}>
                        {primaryError.primarySkill0}
                      </font>
                    </div>
                    <div className={"col-md"}>
                      <label htmlFor={"pexp"} className={"resumeforminputtext"}>
                        Years Of Experience
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <div
                        className={"dropdownforminputfield"}
                        style={{ display: "flex", flexDirection: "row",border:`${primaryError.year0 || primaryError.month0 ? "1px solid red" : ""}` }}
                      >
                        <select
                          className={
                            "form-control form-select dropgray shadow-none ml-0"
                          }
                          id={"pexp"}
                          name={"year0"}
                          value={primaryInformation.year0}
                          onChange={(event) => {
                            buildResumeSkill(
                              event,
                              setPrimaryInformation,
                              setPrimaryError
                            );
                          }}
                        >
                          <option value={""}>Year</option>
                          <GetPrimaryYear />
                        </select>
                        <select
                          className={
                            "form-control form-select dropgray1 shadow-none"
                          }
                          id={"pexp"}
                          name={"month0"}
                          value={primaryInformation.month0}
                          onChange={(event) => {
                            buildResumeSkill(
                              event,
                              setPrimaryInformation,
                              setPrimaryError
                            );
                          }}
                        >
                          <option value={""}>Month</option>
                          <GetPrimaryMonth/>
                        </select>
                      </div>
                      <font className={"dashboardforminputtext text-danger"}>
                        {primaryError.year0}
                      </font>
                      <font className={"dashboardforminputtext text-danger"}>
                        {primaryError.month0}
                      </font>
                    </div>
                    <div className={"col-md"}>
                      <label
                        htmlFor={"lastused"}
                        className={"resumeforminputtext"}
                      >
                        Last Used<span style={{ color: "red" }}>*</span>
                      </label>
                      <DatePicker
                        selected={date0}
                        required="true"
                        onChange={(date) => {
                          setDate0(date);
                        }}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        maxDate={new Date()}
                        customInput={<CustomInput />}
                      />
                    </div>
                  </div>
                  <div className={"row"}>
                    <div className={"col-md"}>
                      <div
                        className={
                          "btn float-right badge border-0 mr-5 cancelskill"
                        }
                        onClick={() => {
                          {
                            setVisible((state) => {
                              return { ...state, one: false };
                            });
                          }
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/*duplicate here*/}
            {visible.two && (
              <div className={"row px-5 pr-5 my-2 mx-3 pt-1"}>
                <div className={"w-100 pt-1 pb-2 contentholder"}>
                  <div className={"row pt-3 mx-4"}>
                    <div className={"col-md"}>
                      <label
                        htmlFor={"pskill"}
                        className={"resumeforminputtext"}
                      >
                        Primary Skill<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type={"text"}
                        placeholder={"Type Here"}
                        id={"pskill"}
                        className={
                          "form-control resumeforminputfield shadow-none "
                        }
                        name={"primarySkill1"}
                        value={primaryInformation.primarySkill1}
                        style={{
                        border: `${primaryError.primarySkill1 ? "1px solid red" : ""}`,
                      }}
                        onChange={(event) => {
                          buildResumeSkill(
                            event,
                            setPrimaryInformation,
                            setPrimaryError,
                            true
                          );
                        }}
                      />
                      <font className={"dashboardforminputtext text-danger"}>
                        {primaryError.primarySkill1}
                      </font>
                    </div>
                    <div className={"col-md"}>
                      <label htmlFor={"pexp"} className={"resumeforminputtext"}>
                        Years Of Experience
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <div
                        className={"dropdownforminputfield"}
                        style={{ display: "flex", flexDirection: "row",border:`${primaryError.year1 || primaryError.month1 ? "1px solid red" : ""}` }}
                      >
                        <select
                          className={
                            "form-control form-select dropgray shadow-none ml-0"
                          }
                          id={"pexp"}
                          name={"year1"}
                          value={primaryInformation.year1}
                          onChange={(event) => {
                            buildResumeSkill(
                              event,
                              setPrimaryInformation,
                              setPrimaryError
                            );
                          }}
                        >
                          <option value={""}>Year</option>
                          <GetPrimaryYear />
                        </select>
                        <select
                          className={
                            "form-control form-select dropgray1 shadow-none"
                          }
                          id={"pexp"}
                          name={"month1"}
                          value={primaryInformation.month1}
                          onChange={(event) => {
                            buildResumeSkill(
                              event,
                              setPrimaryInformation,
                              setPrimaryError
                            );
                          }}
                        >
                          <option value={""}>Month</option>
                          <GetPrimaryMonth/>
                        </select>
                      </div>
                      <font className={"dashboardforminputtext text-danger"}>
                        {primaryError.year1}
                      </font>
                      <font className={"dashboardforminputtext text-danger"}>
                        {primaryError.month1}
                      </font>
                    </div>
                    <div className={"col-md"}>
                      <label
                        htmlFor={"lastused"}
                        className={"resumeforminputtext"}
                      >
                        Last Used<span style={{ color: "red" }}>*</span>
                      </label>
                      <DatePicker
                        selected={date1}
                        required="true"
                        onChange={(date) => {
                          setDate1(date);
                        }}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        maxDate={new Date()}
                        customInput={<CustomInput />}
                      />
                    </div>
                  </div>
                  <div className={"row"}>
                    <div className={"col-md"}>
                      <div
                        className={
                          "btn float-right badge border-0 mr-5 cancelskill"
                        }
                        onClick={() => {
                          setVisible((state) => {
                            return { ...state, two: false };
                          });
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/*duplicate here*/}
            {/*duplicate here*/}
            {visible.three && (
              <div className={"row px-5 pr-5 my-2 mx-3 pt-1"}>
                <div className={"w-100 pt-1 pb-2 contentholder"}>
                  <div className={"row pt-3 mx-4"}>
                    <div className={"col-md"}>
                      <label
                        htmlFor={"pskill"}
                        className={"resumeforminputtext"}
                      >
                        Primary Skill<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type={"text"}
                        placeholder={"Type Here"}
                        id={"pskill"}
                        className={
                          "form-control resumeforminputfield shadow-none "
                        }
                        name={"primarySkill2"}
                        value={primaryInformation.primarySkill2}
                        style={{
                        border: `${primaryError.primarySkill2 ? "1px solid red" : ""}`,
                      }}
                        onChange={(event) => {
                          buildResumeSkill(
                            event,
                            setPrimaryInformation,
                            setPrimaryError,
                            true
                          );
                        }}
                      />
                      <font className={"dashboardforminputtext text-danger"}>
                        {primaryError.primarySkill2}
                      </font>
                    </div>
                    <div className={"col-md"}>
                      <label htmlFor={"pexp"} className={"resumeforminputtext"}>
                        Years Of Experience
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <div
                        className={"dropdownforminputfield"}
                        style={{ display: "flex", flexDirection: "row",border:`${primaryError.year2 || primaryError.month2 ? "1px solid red" : ""}` }}
                      >
                        <select
                          className={
                            "form-control form-select dropgray shadow-none ml-0"
                          }
                          id={"pexp"}
                          name={"year2"}
                          value={primaryInformation.year2}
                          onChange={(event) => {
                            buildResumeSkill(
                              event,
                              setPrimaryInformation,
                              setPrimaryError
                            );
                          }}
                        >
                          <option value={""}>Year</option>
                          <GetPrimaryYear />
                        </select>
                        <select
                          className={
                            "form-control form-select dropgray1 shadow-none"
                          }
                          id={"pexp"}
                          name={"month2"}
                          value={primaryInformation.month2}
                          onChange={(event) => {
                            buildResumeSkill(
                              event,
                              setPrimaryInformation,
                              setPrimaryError
                            );
                          }}
                        >
                          <option value={""}>Month</option>
                          <GetPrimaryMonth/>
                        </select>
                      </div>
                      <font className={"dashboardforminputtext text-danger"}>
                        {primaryError.year2}
                      </font>
                      <font className={"dashboardforminputtext text-danger"}>
                        {primaryError.month2}
                      </font>
                    </div>
                    <div className={"col-md"}>
                      <label
                        htmlFor={"lastused"}
                        className={"resumeforminputtext"}
                      >
                        Last Used<span style={{ color: "red" }}>*</span>
                      </label>
                      <DatePicker
                        selected={date2}
                        required="true"
                        onChange={(date) => {
                          setDate2(date);
                        }}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        maxDate={new Date()}
                        customInput={<CustomInput />}
                      />
                    </div>
                  </div>
                  <div className={"row"}>
                    <div className={"col-md"}>
                      <div
                        className={
                          "btn float-right badge border-0 mr-5 cancelskill"
                        }
                        onClick={() => {
                          setVisible((state) => {
                            return { ...state, three: false };
                          });
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/*duplicate here*/}
            {!(visible.one && visible.two && visible.three) && (
              <div className={"row mt-0"}>
                <div className={"col-md"}>
                  <div
                    className={
                      "btn offset-sm-10 badge bg-transparent border-0  newskill"
                    }
                    style={{ background: "#F9F9F9" }}
                    onClick={AddPrimarySkill}
                  >
                    + Add More Skills
                  </div>
                </div>
              </div>
            )}
            {/*second box*/}
            <div className={"row px-5 pr-5 mx-3 pt-2"}>
              <div className={"w-100 pb-4 pt-2 contentholder"}>
                <div className={"row pt-2 mx-4"}>
                  <div className={"col-md"}>
                    <label
                      htmlFor={"Secskill"}
                      className={"resumeforminputtext"}
                    >
                      Secondary Skill
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"Secskill"}
                      className={
                        "form-control resumeforminputfield shadow-none"
                      }
                      name={"secondarySkill"}
                      value={secondaryInfo.secondarySkill}
                      style={{
                        border: `${secondaryError.secondarySkill ? "1px solid red" : ""}`,
                      }}
                      onChange={(event) => {
                        secondarySkill(
                          event,
                          setSecondaryInfo,
                          setSecondaryError
                        );
                      }}
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {secondaryError.secondarySkill}
                    </font>
                  </div>
                  <div className={"col-md"}>
                    <label htmlFor={"sexp"} className={"resumeforminputtext"}>
                      Years Of Experience
                    </label>
                    <div
                      className={"dropdownforminputfield"}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <select
                        className={
                          "form-control form-select dropgray shadow-none ml-0"
                        }
                        id={"pexp"}
                        value={secondaryInfo.secondarySkillYear}
                        onChange={(event) => {
                          setSecondaryInfo({
                            ...secondaryInfo,
                            secondarySkillYear: event.target.value,
                          });
                        }}
                      >
                        <option value={""}>Year</option>
                        <GetPrimaryYear />
                      </select>
                      <select
                        className={
                          "form-control form-select dropgray1 shadow-none"
                        }
                        id={"pexp"}
                        value={secondaryInfo.secondarySkillMonth}
                        onChange={(event) => {
                          setSecondaryInfo({
                            ...secondaryInfo,
                            secondarySkillMonth: event.target.value,
                          });
                        }}
                      >
                        <option value={""}>Month</option>
                        <GetPrimaryMonth/>
                      </select>
                    </div>
                  </div>
                  <div className={"col-md"}>
                    <label htmlFor={"sexp"} className={"resumeforminputtext"}>
                      Last Used
                    </label>
                    <DatePicker
                      selected={secondaryDate}
                      required="true"
                      onChange={(date) => {
                        setSecondaryDate(date);
                      }}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      maxDate={new Date()}
                      customInput={<CustomInput />}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/*duplicate here*/}
            {secondaryVisible.one && (
              <div className={"row px-5 pr-5 my-2 mx-3 pt-1"}>
                <div className={"w-100 pt-1 pb-2 contentholder"}>
                  <div className={"row pt-3 mx-4"}>
                    <div className={"col-md"}>
                      <label
                        htmlFor={"Secskill"}
                        className={"resumeforminputtext"}
                      >
                        Secondary Skill
                      </label>
                      <input
                        type={"text"}
                        placeholder={"Type Here"}
                        id={"Secskill"}
                        className={
                          "form-control resumeforminputfield shadow-none"
                        }
                        name={"secondarySkill0"}
                        value={secondaryInfo.secondarySkill0}
                        style={{
                        border: `${secondaryError.secondarySkill0 ? "1px solid red" : ""}`,
                      }}
                        onChange={(event) => {
                          secondarySkill(
                            event,
                            setSecondaryInfo,
                            setSecondaryError
                          );
                        }}
                      />
                      <font className={"dashboardforminputtext text-danger"}>
                        {secondaryError.secondarySkill0}
                      </font>
                    </div>
                    <div className={"col-md"}>
                      <label htmlFor={"sexp"} className={"resumeforminputtext"}>
                        Years Of Experience
                      </label>
                      <div
                        className={"dropdownforminputfield"}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <select
                          className={
                            "form-control form-select dropgray shadow-none ml-0"
                          }
                          id={"pexp"}
                          value={secondaryInfo.secondarySkillYear0}
                          onChange={(event) => {
                            setSecondaryInfo({
                              ...secondaryInfo,
                              secondarySkillYear0: event.target.value,
                            });
                          }}
                        >
                          <option value={""}>Year</option>
                          <GetPrimaryYear />
                        </select>
                        <select
                          className={
                            "form-control form-select dropgray1 shadow-none"
                          }
                          id={"pexp"}
                          value={secondaryInfo.secondarySkillMonth0}
                          onChange={(event) => {
                            setSecondaryInfo({
                              ...secondaryInfo,
                              secondarySkillMonth0: event.target.value,
                            });
                          }}
                        >
                          <option value={""}>Month</option>
                          <GetPrimaryMonth/>
                        </select>
                      </div>
                    </div>
                    <div className={"col-md"}>
                      <label htmlFor={"sexp"} className={"resumeforminputtext"}>
                        Last Used
                      </label>
                      <DatePicker
                        selected={secondaryDate0}
                        required="true"
                        onChange={(date) => {
                          setSecondaryDate0(date);
                        }}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        maxDate={new Date()}
                        customInput={<CustomInput />}
                      />
                    </div>
                  </div>
                  <div className={"row"}>
                    <div className={"col-md"}>
                      <div
                        className={
                          "btn float-right badge border-0 mr-5 cancelskill"
                        }
                        onClick={() => {
                          setSecondaryVisible((state) => {
                            return { ...state, one: false };
                          });
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/*second duplicate */}
            {secondaryVisible.two && (
              <div className={"row px-5 pr-5 my-2 mx-3 pt-1"}>
                <div className={"w-100 pt-1 pb-2 contentholder"}>
                  <div className={"row pt-3 mx-4"}>
                    <div className={"col-md"}>
                      <label
                        htmlFor={"Secskill"}
                        className={"resumeforminputtext"}
                      >
                        Secondary Skill
                      </label>
                      <input
                        type={"text"}
                        placeholder={"Type Here"}
                        id={"Secskill"}
                        className={
                          "form-control resumeforminputfield shadow-none"
                        }
                        name={"secondarySkill1"}
                        value={secondaryInfo.secondarySkill1}
                        style={{
                        border: `${secondaryError.secondarySkill1 ? "1px solid red" : ""}`,
                      }}
                        onChange={(event) => {
                          secondarySkill(
                            event,
                            setSecondaryInfo,
                            setSecondaryError
                          );
                        }}
                      />
                      <font className={"dashboardforminputtext text-danger"}>
                        {secondaryError.secondarySkill1}
                      </font>
                    </div>
                    <div className={"col-md"}>
                      <label htmlFor={"sexp"} className={"resumeforminputtext"}>
                        Years Of Experience
                      </label>
                      <div
                        className={"dropdownforminputfield"}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <select
                          className={
                            "form-control form-select dropgray shadow-none ml-0"
                          }
                          id={"pexp"}
                          value={secondaryInfo.secondarySkillYear1}
                          onChange={(event) => {
                            setSecondaryInfo({
                              ...secondaryInfo,
                              secondarySkillYear1: event.target.value,
                            });
                          }}
                        >
                          <option value={""}>Year</option>
                          <GetPrimaryYear />
                        </select>
                        <select
                          className={
                            "form-control form-select dropgray1 shadow-none"
                          }
                          id={"pexp"}
                          value={secondaryInfo.secondarySkillMonth1}
                          onChange={(event) => {
                            setSecondaryInfo({
                              ...secondaryInfo,
                              secondarySkillMonth1: event.target.value,
                            });
                          }}
                        >
                          <option value={""}>Month</option>
                          <GetPrimaryMonth/>
                        </select>
                      </div>
                    </div>
                    <div className={"col-md"}>
                      <label htmlFor={"sexp"} className={"resumeforminputtext"}>
                        Last Used
                      </label>
                      <DatePicker
                        selected={secondaryDate1}
                        required="true"
                        onChange={(date) => {
                          setSecondaryDate1(date);
                        }}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        maxDate={new Date()}
                        customInput={<CustomInput />}
                      />
                    </div>
                  </div>
                  <div className={"row"}>
                    <div className={"col-md"}>
                      <div
                        className={
                          "btn float-right badge border-0 mr-5 cancelskill"
                        }
                        onClick={() => {
                          setSecondaryVisible((state) => {
                            return { ...state, two: false };
                          });
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/*another duplicate */}
            {secondaryVisible.three && (
              <div className={"row px-5 pr-5 my-2 mx-3 pt-1"}>
                <div className={"w-100 pt-1 pb-2 contentholder"}>
                  <div className={"row pt-3 mx-4"}>
                    <div className={"col-md"}>
                      <label
                        htmlFor={"Secskill"}
                        className={"resumeforminputtext"}
                      >
                        Secondary Skill
                      </label>
                      <input
                        type={"text"}
                        placeholder={"Type Here"}
                        id={"Secskill"}
                        className={
                          "form-control resumeforminputfield shadow-none"
                        }
                        name={"secondarySkill2"}
                        value={secondaryInfo.secondarySkill2}
                        style={{
                        border: `${secondaryError.secondarySkill2 ? "1px solid red" : ""}`,
                      }}
                        onChange={(event) => {
                          secondarySkill(
                            event,
                            setSecondaryInfo,
                            setSecondaryError
                          );
                        }}
                      />
                      <font className={"dashboardforminputtext text-danger"}>
                        {secondaryError.secondarySkill2}
                      </font>
                    </div>
                    <div className={"col-md"}>
                      <label htmlFor={"sexp"} className={"resumeforminputtext"}>
                        Years Of Experience
                      </label>
                      <div
                        className={"dropdownforminputfield"}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <select
                          className={
                            "form-control form-select dropgray shadow-none ml-0"
                          }
                          id={"pexp"}
                          value={secondaryInfo.secondarySkillYear2}
                          onChange={(event) => {
                            setSecondaryInfo({
                              ...secondaryInfo,
                              secondarySkillYear2: event.target.value,
                            });
                          }}
                        >
                          <option value={""}>Year</option>
                          <GetPrimaryYear />
                        </select>
                        <select
                          className={
                            "form-control form-select dropgray1 shadow-none"
                          }
                          id={"pexp"}
                          value={secondaryInfo.secondarySkillMonth2}
                          onChange={(event) => {
                            setSecondaryInfo({
                              ...secondaryInfo,
                              secondarySkillMonth2: event.target.value,
                            });
                          }}
                        >
                          <option value={""}>Month</option>
                          <GetPrimaryMonth/>
                        </select>
                      </div>
                    </div>
                    <div className={"col-md"}>
                      <label htmlFor={"sexp"} className={"resumeforminputtext"}>
                        Last Used
                      </label>
                      <DatePicker
                        selected={secondaryDate2}
                        required="true"
                        onChange={(date) => {
                          setSecondaryDate2(date);
                        }}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        maxDate={new Date()}
                        customInput={<CustomInput />}
                      />
                    </div>
                  </div>
                  <div className={"row"}>
                    <div className={"col-md"}>
                      <div
                        className={
                          "btn float-right badge border-0 mr-5 cancelskill"
                        }
                        onClick={() => {
                          setSecondaryVisible((state) => {
                            return { ...state, three: false };
                          });
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/*end of duplicate*/}
            {!(
              secondaryVisible.one &&
              secondaryVisible.two &&
              secondaryVisible.three
            ) && (
              <div className={"row mt-0"}>
                <div className={"col-md"}>
                  <div
                    className={
                      "btn offset-sm-10 badge bg-transparent border-0  newskill"
                    }
                    style={{ background: "#F9F9F9" }}
                    onClick={addSecondarySkill}
                  >
                    + Add More Skills
                  </div>
                </div>
              </div>
            )}
            {/* error for rate card */}
            <div className="row pr-5">
              <div className="col-md offset-sm-9">
                <label
                  className={
                    "resumeforminputtext text-danger freelanceErr pl-1 mt-3"
                  }
                  style={{ fontWeight: "bold" }}
                ></label>
              </div>
            </div>
            {/*two button area of page last*/}
            <div className={"row mx-3 my-4"}>
              <div className={"col"}>
                <div className={"row float-right"}>
                  {redirect ? (
                    <Redirect to={`${props.link}/${information.uniqueId}`} />
                  ) : (
                    <button
                      type={"submit"}
                      formNoValidate
                      className={"btn mr-5 my-2 uploadbtn"}
                      ref={btnRef}
                    >
                      {loader ? (
                        <i
                          className = "fa fa-refresh fa-spin"
                          style={{ padding: "3px 5px" }}
                        ></i>
                      ) : (
                        ""
                      )}
                      Save & Continue
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default React.memo(UserRegistrationForm);
