const requiredList = [
  "fein",
  "workPhone",
  "zipCode",
  "addressLine1",
  "city",
  "state",
  "country",
  "firstName",
  "lastName",
  "title",
  "email",
];

const errorMsg = [
  "fein",
  "phoneNumber",
  "zipCode",
  "address",
  "city",
  "state",
  "country",
  "firstName",
  "lastName",
  "title",
  "email",
];

function verifyValidate(
  information,
  setError,
  addmore,
  addMoreInformation,
  setAddMoreError
) {
  // check if required empty form will not submit
  let flag = true;
  requiredList.map((value, index) => {
    const val = information[value];
    if (!val)
      setError((state) => {
        flag = false;
        return { ...state, [value]: `Please enter the ${errorMsg[index]}` };
      });
  });
  // check addmore div required validation also
  if (addmore) {
    requiredList.map((value, index) => {
      const val = addMoreInformation[value];
      if (!val)
        setAddMoreError((state) => {
          flag = false;
          return { ...state, [value]: `Please enter the ${errorMsg[index]}` };
        });
    });
  }
  const flagTwo = requiredList
    .map((value) => {
      return information[value] ? true : false;
    })
    .every((value) => {
      return value ? true : false;
    });
  return flag && flagTwo;
}

export { verifyValidate };
