import {
  RECRUITERCLIENTREQUIREMENT,
  RECRUITERCLIENTACTIVEJOBS,
  RECRUITERARCHIVEREQUIREMENTS,
} from "../../ENVIRONMENT";

// api for recruiter dashboard requirement
async function recDashboardRequirment(token, currentPage) {
  const response = await fetch(
    RECRUITERCLIENTREQUIREMENT + `?limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for recruiter dashboardcard requiremnet
async function recDashboardCardRequirment(token, currentPage, card) {
  const response = await fetch(
    RECRUITERCLIENTREQUIREMENT + `?limit=6&page=${currentPage}&card=${card}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for recruiter dashboard active jobs
async function recDashboardActiveJobs(token, currentPage) {
  const response = await fetch(
    RECRUITERCLIENTACTIVEJOBS + `?limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for recruiter archive requirement
async function recArchiveRequirements(token, currentPage) {
  const response = await fetch(
    RECRUITERARCHIVEREQUIREMENTS + `?limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export {
  recDashboardRequirment,
  recDashboardCardRequirment,
  recDashboardActiveJobs,
  recArchiveRequirements,
};
