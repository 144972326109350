import {RECRUITERSPECIFICCANDIDATEDETAILS} from "../../ENVIRONMENT"

// api for recruiter specific candidate details
async function recCandidateDetails(token, id) {
    const response = await fetch(RECRUITERSPECIFICCANDIDATEDETAILS + `/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    });
    return response;
  }

  // api for recruiter specific candidate matches
async function recSpecificCandidateMatches(token, id,currentPage) {
  const response = await fetch(RECRUITERSPECIFICCANDIDATEDETAILS + `/${id}/matchingjobs?limit=4&page=${currentPage}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

 // api for recruiter specific candidate applications
 async function recSpecificCandidateApplication(token,id,data,currentPage) {
  const response = await fetch(RECRUITERSPECIFICCANDIDATEDETAILS + `/${id}/matchingjobs?limit=4&page=${currentPage}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body:JSON.stringify(data)
  });
  return response;
}

export {recCandidateDetails,recSpecificCandidateMatches,recSpecificCandidateApplication};