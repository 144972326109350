import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import CandidateDashcard from "../../common-component/CandidateDashcard";
import MatchingjobCard from "../../common-component/candidateMatchingjobCard";
import GoToTop from "../common/GoToTop";
import { getPermanentDataDashboard } from "../../api/candidate/permanentDataDashboard";
import { toTitleCase } from "../../function/common/convertToTitleCase";
import { recgetPermanentDataDashboard } from "../../api/recruiter/candidate/permanentDataDashboard";
import { freegetPermanentDataDashboard } from "../../api/freelance/candidate/permanentDataDashboard";

const DashboardView = () => {
  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);
  const isrecruiter = useSelector((state) => state.isrecruiter);
  // permanent data
  const [information, setInformation] = useState({
    name: "",
    candidateId: "",
    resumeTitle: "",
    email: "",
    rate: "",
    rateType: "",
    location: "",
    employmentType: "",
    mobile: "",
  });

  // const socketVal = useSelector((state) => state.socket);

  // console.log("socket val dash",socketVal)

  // get call of permanent data
  useEffect(() => {
    async function callItSelf() {

      try {
        let response = isrecruiterCandidate
          ? await recgetPermanentDataDashboard(token)
          : isFreelance
            ? await freegetPermanentDataDashboard(token)
            : await getPermanentDataDashboard(token);
        if (response.status === 200) {
          const payload = await response.json();
          if (payload.payload.location.length > 20) {
            payload.payload.location = payload.payload.location + "...";
          } else if (payload.payload.resumeTitle.length > 20) {
            payload.payload.resumeTitle = payload.payload.resumeTitle + "...";
          }
          setInformation({ ...information, ...payload.payload });
        }
      } catch { }
    }
    callItSelf();
  }, []);
  // return dashboardview page for existing user to view their data
  const dispatch = useDispatch();
  dispatch({ type: "newuserfalse" });
  dispatch({ type: "buildresumefalse" });
  try {
    document.getElementById("replacetitleimg").innerText = "Dashboard";
  } catch { }

  return (
    <div className={"col mx-lg-3 mx-1"}>
      <div className={"col-lg-12 pr-3 pr-lg-0"}>
        <div className={"row pl-3 pl-lg-0"}>
          <div className={"col-lg-12 p-0"}>
            <div className={"row viewalignment pb-3"}>
              <CandidateDashcard />
            </div>
          </div>
          {/*permanent data*/}
          <div className={"d-none col-xl-3 pt-4"}>
            <div
              className={"p-2 colored-primary-white circular-border-radius"}
            >
              <div className={"row"}>
                <div className={"col-md"}>
                  <div className={"float-right"}>
                    <span
                      className={"pt-1 pb-1 pl-2 pr-2 candidate-Dashbaordview-Rating-css"}

                    >
                      <label className="colored-Bright-orange">&#9733; 4.3</label>
                    </span>
                  </div>
                </div>
              </div>
              <div className="center">
                <div className={"row"}>
                  <div className={"col-md"}>
                    <label className={"dashboardfontusername"}>
                      {information.firstName &&
                        information.firstName.charAt(0).toUpperCase() +
                        information.firstName.slice(1).toLowerCase()}
                      &nbsp;
                      {information.middleName === "null"
                        ? ""
                        : information.middleName &&
                        information.middleName.charAt(0).toUpperCase() +
                        information.middleName.slice(1).toLowerCase()}
                      &nbsp;
                      {information.lastName &&
                        information.lastName.charAt(0).toUpperCase() +
                        information.lastName.slice(1).toLowerCase()}
                    </label>
                  </div>
                </div>
                <div className={"row"}>
                  <div
                    className={"col-md font-weight-500 font-size-14px colored-Purple-Indigo dashboardResumeTitle"}
                  >
                    <label>{toTitleCase(information.resumeTitle)}</label>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-md pt-0"}>
                    <label className="staticdatacommonfont">
                      {information.candidateId}
                    </label>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-md"}>
                    <label className="dashboardfontusername">
                      Permanent Data
                    </label>
                  </div>
                </div>
                <div className={"row"}>
                  <div
                    className={"col-md colored-Slate-gray candidate-Dashbaordview-email-text-css candidate-Dashbaordview-email-text-css"}

                  >
                    <label className="staticdatacommonfont">
                      Email:{information.email}
                    </label>
                  </div>
                </div>
              </div>
              {isrecruiter ? (
                ""
              ) : (
                <div className={"row"}>
                  <div className={"col-3 pl-5 ml-3 mt-2"}>
                    <span
                      className={"pt-2 pb-2 pl-2 pr-2 border-radius-10px colored-Mint-green"}

                    >
                      <img
                        src="../icon/candidate/dashboard/dollar.png"
                        alt={"whitelocation"}
                      />
                    </span>
                  </div>
                  <div className={"col mt-1 ml-4"}>
                    <div
                      className="font-weight-500 font-size-14px colored-Deep-slate"

                    >
                      {information.rate}
                    </div>
                    <div

                      className={"resumepreviewname colored-Slate-gray"}
                    >
                      Per{" "}
                      {information.rateType &&
                        toTitleCase(information.rateType.substring(0, 4))}
                    </div>
                  </div>
                </div>
              )}
              <div className={"row mt-3"}>
                <div className={"col-3 pl-5 ml-3"}>
                  <span
                    className={"pt-2 pb-2 pl-2 pr-2 background-colored-Sunset-orange border-radius-10px"}

                  >
                    <img
                      src="../icon/candidate/dashboard/whitelocation.png"
                      alt={"whitelocation"}
                    />
                  </span>
                </div>
                <div className={"col-7 ml-4"} >
                  <div
                    className="font-weight-500 font-size-14px colored-Deep-slate candidate-Dashbaordview-information-title"
                  // style={{

                  //   textOverflow: "ellipsis",
                  //   overflow: "hidden",
                  //   whiteSpace: "pre-wrap",
                  //   wordWrap: "break-word",
                  // }}
                  >
                    {information.location.length !== 0 &&
                      toTitleCase(information.location)}
                  </div>
                </div>
              </div>
              {isrecruiter ? (
                <div className="row pt-2 pb-2"></div>
              ) : (
                <div className={"row mt-2"}>
                  <div className={"col-3 pl-5 ml-2 mr-2"}>
                    <img
                      src="../icon/common/calendar.png"
                      alt={"smallcalendar"}
                      className={"pl-1 pr-1 pt-1 pb-1 background-colored-Pale-sky-blue border-radius-10px candidate-Dashbaordview-calender-iconcss"}

                    />
                  </div>
                  <div className={"col-7 mt-3 ml-4 font-weight-500 font-size-14px colored-Graphite-gray"}>
                    <div
                    >
                      {information.employmentType &&
                        information.employmentType.replace(/_/g, " ")}
                    </div>
                  </div>
                </div>
              )}
              {information.mobile === null ? (
                <div className="row pt-3 pb-3"></div>
              ) : (
                <div className={"row mt-2"}>
                  <div className={"col-3 pl-5 ml-3 mr-1"}>
                    <img
                      src="../icon/candidate/dashboard/call.png"
                      alt={"call"}
                      className={"pl-2 pr-2 pt-2 pb-2 dashboard-information-mobile"}
                    />
                  </div>
                  <div className={"col pt-2 ml-4 font-weight-500 font-size-14px colored-Graphite-gray"}>
                    <div
                    >
                      {information.mobile}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={"row viewalignment pl-3 pl-lg-0"}>
        <MatchingjobCard />
      </div>
      <GoToTop />
    </div>
  );
};

export default React.memo(DashboardView);
