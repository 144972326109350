import "../../styles/app.scss";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { Data, clearFilter } from "../../function/candidate/MatchingJob";
import { MatchingJobSelectAllData } from "../../function/common/MatchingJobSelectAllData";
import GoToTop from "../common/GoToTop";
import Filter from "../../common-component/Filter";
import { useHistory } from "react-router-dom";
import { matchingCardPagination } from "../../api/candidate/matchingPagination";
import { recmatchingCardPagination } from "../../api/recruiter/candidate/matchingPagination";
import { freematchingCardPagination } from "../../api/freelance/candidate/matchingPagination";
import {
  matchingjobCardSorting,
  matchingjobSorting,
} from "../../api/candidate/matchingjobSorting";
import { Search, SearchByCard } from "../../api/candidate/search";
import { primaryList } from "../../api/candidate/primaryList";
import { secondaryList } from "../../api/candidate/secondaryList";
import {
  locationList,
  locationListSearch,
} from "../../api/common/locationList";
import {
  matchingCardFilter,
  matchingMultipleFilter,
} from "../../api/candidate/matchingFilter";
import { domainList } from "../../api/candidate/domainList";
import { educationList } from "../../api/candidate/educationList";
import { certificateList } from "../../api/candidate/certificateList";
import { useLocation } from "react-router-dom";
import { shortlistJobs } from "../../api/candidate/shortlistJobs";
import { recSearch, recSearchByCard } from "../../api/recruiter/candidate/search";
import { freeSearch, freeSearchByCard } from "../../api/freelance/candidate/search";
import {
  recmatchingCardFilter,
  recmatchingMultipleFilter,
} from "../../api/recruiter/candidate/matchingFilter";
import {
  freematchingCardFilter,
  freematchingMultipleFilter,
} from "../../api/freelance/candidate/matchingFilter";
import {
  recmatchingjobCardSorting,
  recmatchingjobSorting,
} from "../../api/recruiter/candidate/matchingjobSorting";
import {
  freematchingjobCardSorting,
  freematchingjobSorting,
} from "../../api/freelance/candidate/matchingjobSorting";
import { recprimaryList } from "../../api/recruiter/candidate/primaryList";
import { freeprimaryList } from "../../api/freelance/candidate/primaryList";
import { recsecondaryList } from "../../api/recruiter/candidate/secondaryList";
import { freesecondaryList } from "../../api/freelance/candidate/secondaryList";
import { recdomainList } from "../../api/recruiter/candidate/domainList";
import { freedomainList } from "../../api/freelance/candidate/domainList";
import { receducationList } from "../../api/recruiter/candidate/educationList";
import { freeeducationList } from "../../api/freelance/candidate/educationList";
import { reccertificateList } from "../../api/recruiter/candidate/certificateList";
import { freecertificateList } from "../../api/freelance/candidate/certificateList";
import { recshortlistJobs } from "../../api/recruiter/candidate/shortlistJobs";
import { freeshortlistJobs } from "../../api/freelance/candidate/shortlistJobs";
import { useMediaQuery } from 'react-responsive';


const MatchingJob = () => {
  // return matchingjob page
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let cardName = query.get("title");

  try {
    if (cardName === "interview") {
      document.getElementById("replacetitleimg").innerText =
        "Interview Schedule";
    } else if (cardName === "shortlisted") {
      document.getElementById("replacetitleimg").innerText = "Shortlisted Jobs";
    } else {
      document.getElementById("replacetitleimg").innerText = "Matching Jobs";
    }
  } catch { }

  const dispatch = useDispatch();
  dispatch({ type: "newuserfalse" });
  // for search
  const [information, setInformation] = useState("");
  const [record, setRecord] = useState();
  const [loading, setLoading] = useState();
  const history = useHistory();

  async function filterData() {
    try {
      if (cardName) {
        const response = isrecruiterCandidate
          ? await recSearchByCard(token, information, 1, cardName)
          : isFreelance
            ? await freeSearchByCard(token, information, 1, cardName)
            : await SearchByCard(token, information, 1, cardName);
        if (response.status === 200) {
          let payload = await response.json();
          payload = payload.payload;
          const totalRecord = payload[payload.length - 1].totalRecord;
          setRecord(totalRecord);
          setItem(payload.slice(0, -1));
          setTotalLength(totalRecord);
          setPageCount(Math.ceil(payload[6].totalRecord / 6));
        }
      } else {
        const response = isrecruiterCandidate
          ? await recSearch(token, information, 1)
          : isFreelance
            ? await freeSearch(token, information, 1)
            : await Search(token, information, 1);
        if (response.status === 200) {
          let payload = await response.json();
          payload = payload.payload;
          const totalRecord = payload[payload.length - 1].totalRecord;
          setRecord(totalRecord);
          setItem(payload.slice(0, -1));
          setTotalLength(totalRecord);
          setPageCount(Math.ceil(payload[6].totalRecord / 6));
        }
      }
    } catch { }
  }
  useEffect(() => {
    async function callUserDetails() {
      if (information.length === 0) {
        try {
          if (cardName) {
            const response = isrecruiterCandidate
              ? await recSearchByCard(token, information, 1, cardName)
              : isFreelance ? await freeSearchByCard(token, information, 1, cardName)
                : await SearchByCard(token, information, 1, cardName);
            if (response.status === 200) {
              let payload = await response.json();
              payload = payload.payload;
              const totalRecord = payload[payload.length - 1].totalRecord;
              setRecord(totalRecord);
              setItem(payload.slice(0, -1));
              setTotalLength(totalRecord);
              setPageCount(Math.ceil(payload[6].totalRecord / 6));
            }
          } else {
            const response = isrecruiterCandidate
              ? await recSearch(token, information, 1)
              : isFreelance
                ? await freeSearch(token, information, 1)
                : await Search(token, information, 1);
            if (response.status === 200) {
              let payload = await response.json();
              payload = payload.payload;
              const totalRecord = payload[payload.length - 1].totalRecord;
              setRecord(totalRecord);
              setItem(payload.slice(0, -1));
              setTotalLength(totalRecord);
              setPageCount(Math.ceil(payload[6].totalRecord / 6));
            }
          }
        } catch { }
      }
    }
    callUserDetails();
  }, [information]);

  const [item, setItem] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const [totalLength, setTotalLength] = useState();
  const [idOrder, setIdOrder] = useState(true);
  const [order, setOrder] = useState(true);
  const [matching, setMatching] = useState(true);
  const [statusValue, setStatusValue] = useState();

  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPage(data.selected);
    setLoading(true);
    let location = "location";
    let primarySkill = "primarySkill";
    let secondarySkill = "secondarySkill";
    let workAuth = "workAuthorization";
    let security = "securityClearanceCategory";
    let employment = "employementType";
    let domain = "domain";
    let certificate = "certificate";
    let education = "academicsQualification";
    let USADegree = "usaDegree";
    let rateType = "rateType";
    if (cardName) {
      const response = isrecruiterCandidate
        ? await recmatchingCardFilter(
          token,
          location,
          checkedItems,
          primarySkill,
          checkedPrimaryItems,
          secondarySkill,
          checkedSecondaryItems,
          workAuth,
          checkedAuthItems,
          security,
          checkedSecurityItems,
          employment,
          checkedEmployItems,
          domain,
          checkedDomainItems,
          certificate,
          checkedCertificateItems,
          education,
          checkedEducationItems,
          rateType,
          checkedRate,
          USADegree,
          checkedDegree,
          currentPage,
          cardName
        )
        : isFreelance
          ? await freematchingCardFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            currentPage,
            cardName
          )
          : await matchingCardFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            currentPage,
            cardName
          );
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        const totalRecord = payload[payload.length - 1].totalRecord;
        setRecord(totalRecord);
        setItem(payload.slice(0, -1));
        setLoading(false);
      }
    } else {
      const response = isrecruiterCandidate
        ? await recmatchingMultipleFilter(
          token,
          location,
          checkedItems,
          primarySkill,
          checkedPrimaryItems,
          secondarySkill,
          checkedSecondaryItems,
          workAuth,
          checkedAuthItems,
          security,
          checkedSecurityItems,
          employment,
          checkedEmployItems,
          domain,
          checkedDomainItems,
          certificate,
          checkedCertificateItems,
          education,
          checkedEducationItems,
          rateType,
          checkedRate,
          USADegree,
          checkedDegree,
          currentPage
        )
        : isFreelance
          ? await freematchingMultipleFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            currentPage
          )
          : await matchingMultipleFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            currentPage
          );

      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        const totalRecord = payload[payload.length - 1].totalRecord;
        setRecord(totalRecord);
        setItem(payload.slice(0, -1));
        setLoading(false);
      }
    }
  };
  const handleMatchingSorting = async () => {
    let rate = "percentage";
    let high = "high";
    let low = "low";
    setPage(0);
    setLoading(true);
    if (cardName) {
      if (matching) {
        const response = isrecruiterCandidate
          ? await recmatchingjobCardSorting(token, cardName, 1, rate, high)
          : isFreelance
            ? await freematchingjobCardSorting(token, cardName, 1, rate, high)
            : await matchingjobCardSorting(token, cardName, 1, rate, high);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      } else {
        const response = isrecruiterCandidate
          ? await recmatchingjobCardSorting(token, cardName, 1, rate, low)
          : isFreelance
            ? await freematchingjobCardSorting(token, cardName, 1, rate, low)
            : await matchingjobCardSorting(token, cardName, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      }
    } else {
      if (matching) {
        const response = isrecruiterCandidate
          ? await recmatchingjobSorting(token, 1, rate, high)
          : isFreelance
            ? await freematchingjobSorting(token, 1, rate, high)
            : await matchingjobSorting(token, 1, rate, high);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      } else {
        const response = isrecruiterCandidate
          ? await recmatchingjobSorting(token, 1, rate, low)
          : isFreelance
            ? await freematchingjobSorting(token, 1, rate, low)
            : await matchingjobSorting(token, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      }
    }
  };
  const handleRateSorting = async () => {
    let rate = "rate";
    let high = "high";
    let low = "low";
    setPage(0);
    setLoading(true);
    if (cardName) {
      if (order) {
        const response = isrecruiterCandidate
          ? await recmatchingjobCardSorting(token, cardName, 1, rate, high)
          : isFreelance
            ? await freematchingjobCardSorting(token, cardName, 1, rate, high)
            : await matchingjobCardSorting(token, cardName, 1, rate, high);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      } else {
        const response = isrecruiterCandidate
          ? await recmatchingjobCardSorting(token, cardName, 1, rate, low)
          : isFreelance
            ? await freematchingjobCardSorting(token, cardName, 1, rate, low)
            : await matchingjobCardSorting(token, cardName, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      }
    } else {
      if (order) {
        const response = isrecruiterCandidate
          ? await recmatchingjobSorting(token, 1, rate, high)
          : isFreelance
            ? await freematchingjobSorting(token, 1, rate, high)
            : await matchingjobSorting(token, 1, rate, high);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      } else {
        const response = isrecruiterCandidate
          ? await recmatchingjobSorting(token, 1, rate, low)
          : isFreelance
            ? await freematchingjobSorting(token, 1, rate, low)
            : await matchingjobSorting(token, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      }
    }
  };

  const handleIdSorting = async () => {
    let rate = "id";
    let high = "high";
    let low = "low";
    setPage(0);
    setLoading(true);
    if (cardName) {
      if (idOrder) {
        const response = isrecruiterCandidate
          ? await recmatchingjobCardSorting(token, cardName, 1, rate, high)
          : isFreelance
            ? await freematchingjobCardSorting(token, cardName, 1, rate, high)
            : await matchingjobCardSorting(token, cardName, 1, rate, high);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      } else {
        const response = isrecruiterCandidate
          ? await recmatchingjobCardSorting(token, cardName, 1, rate, low)
          : isFreelance
            ? await freematchingjobCardSorting(token, cardName, 1, rate, low)
            : await matchingjobCardSorting(token, cardName, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      }
    } else {
      if (idOrder) {
        const response = isrecruiterCandidate
          ? await recmatchingjobSorting(token, 1, rate, high)
          : isFreelance
            ? await freematchingjobSorting(token, 1, rate, high)
            : await matchingjobSorting(token, 1, rate, high);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      } else {
        const response = isrecruiterCandidate
          ? await recmatchingjobSorting(token, 1, rate, low)
          : isFreelance
            ? await freematchingjobSorting(token, 1, rate, low)
            : await matchingjobSorting(token, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
          setLoading(false);
        }
      }
    }
  };

  //location list
  const [location, setLocation] = useState([]);
  const [outsideLocation, setOutsideLocation] = useState([]);
  //primary skill list
  const [skill, setSkill] = useState([]);
  //seconday
  const [secondary, setSecondary] = useState([]);
  //domain
  const [domain, setDomain] = useState([]);
  const [domainLength, setDomainLength] = useState();
  //education
  const [education, setEducation] = useState([]);
  //certificate
  const [certificate, setCertificate] = useState([]);

  useEffect(() => {
    function callLocationDetails() {
      try {
        locationList(token)
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            const payload = data.payload;
            setOutsideLocation(payload);
            setLocation(payload);
          });
      } catch { }
      try {
        const primResponse = isrecruiterCandidate
          ? recprimaryList(token)
          : isFreelance
            ? freeprimaryList(token)
            : primaryList(token);
        primResponse
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            const payload = data.payload;
            setSkill(payload);
          });
      } catch { }
      try {
        const secondaryRes = isrecruiterCandidate
          ? recsecondaryList(token)
          : isFreelance
            ? freesecondaryList(token)
            : secondaryList(token);
        secondaryRes
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            const payload = data.payload;
            setSecondary(payload);
          });
      } catch { }
      try {
        const domainRes = isrecruiterCandidate
          ? recdomainList(token)
          : isFreelance
            ? freedomainList(token)
            : domainList(token);
        domainRes
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            const payload = data.payload;
            if (payload.length !== 0) {
              setDomain(payload);
              let domainCount = 0;
              payload.map((obj) => {
                domainCount += obj.Candidate.CandidateExperiences[0].domain;
              });
              setDomainLength(domainCount);
            }
          });
      } catch { }
      try {
        const response = isrecruiterCandidate
          ? receducationList(token)
          : isFreelance
            ? freeeducationList(token)
            : educationList(token);
        response
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            const payload = data.payload;
            if (payload.length !== 0) {
              setEducation(payload);
            }
          });
      } catch { }
      try {
        const certificateRes = isrecruiterCandidate
          ? reccertificateList(token)
          : isFreelance
            ? freecertificateList(token)
            : certificateList(token);
        certificateRes
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            const payload = data.payload;
            if (payload.length !== 0) {
              setCertificate(payload);
            }
          });
      } catch { }
    }
    callLocationDetails();
  }, []);
  //filter location
  const [checked, setChecked] = useState([]);

  const handleLocationFilter = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  const checkedItems = checked.length
    ? checked.reduce((total, item) => {
      return total + "," + item;
    })
    : "";

  //filter primary skill
  const [checkedPrimary, setCheckedPrimary] = useState([]);

  const handlePrimarySkillFilter = (event) => {
    var updatedList = [...checkedPrimary];
    if (event.target.checked) {
      updatedList = [...checkedPrimary, event.target.value];
    } else {
      updatedList.splice(checkedPrimary.indexOf(event.target.value), 1);
    }
    setCheckedPrimary(updatedList);
  };
  const checkedPrimaryItems = checkedPrimary.length
    ? checkedPrimary.reduce((total, item) => {
      return total + "," + item;
    })
    : "";

  //filter secondary skill
  const [checkedSecondary, setCheckedSecondary] = useState([]);

  const handleSecondarySkillFilter = (event) => {
    var updatedList = [...checkedSecondary];
    if (event.target.checked) {
      updatedList = [...checkedSecondary, event.target.value];
    } else {
      updatedList.splice(checkedSecondary.indexOf(event.target.value), 1);
    }
    setCheckedSecondary(updatedList);
  };
  const checkedSecondaryItems = checkedSecondary.length
    ? checkedSecondary.reduce((total, item) => {
      return total + "," + item;
    })
    : "";

  //filter workAuthorization
  const [checkedAuth, setCheckedAuth] = useState([]);

  const handleWorkAuthFilter = (event) => {
    var updatedList = [...checkedAuth];
    if (event.target.checked) {
      updatedList = [...checkedAuth, event.target.value];
    } else {
      updatedList.splice(checkedAuth.indexOf(event.target.value), 1);
    }
    setCheckedAuth(updatedList);
  };
  const checkedAuthItems = checkedAuth.length
    ? checkedAuth.reduce((total, item) => {
      return total + "," + item;
    })
    : "";

  //filter employmentType
  const [checkedEmploy, setCheckedEmploy] = useState([]);

  const handleEmploymentFilter = (event) => {
    var updatedList = [...checkedEmploy];
    if (event.target.checked) {
      updatedList = [...checkedEmploy, event.target.value];
    } else {
      updatedList.splice(checkedEmploy.indexOf(event.target.value), 1);
    }
    setCheckedEmploy(updatedList);
  };
  const checkedEmployItems = checkedEmploy.length
    ? checkedEmploy.reduce((total, item) => {
      return total + "," + item;
    })
    : "";

  //filter securityCategory
  const [checkedSecurity, setCheckedSecurity] = useState([]);

  const handleSecurityFilter = (event) => {
    var updatedList = [...checkedSecurity];
    if (event.target.checked) {
      updatedList = [...checkedSecurity, event.target.value];
    } else {
      updatedList.splice(checkedSecurity.indexOf(event.target.value), 1);
    }
    setCheckedSecurity(updatedList);
  };
  const checkedSecurityItems = checkedSecurity.length
    ? checkedSecurity.reduce((total, item) => {
      return total + "," + item;
    })
    : "";

  //filter USADegree
  const [checkedDegree, setCheckedDegree] = useState("");

  const handleUSDegreeFilter = async (event) => {
    setCheckedDegree(event.target.value);
  };
  //filterRatetype
  const [checkedRate, setCheckedRate] = useState("");

  const handleRateFilter = async (event) => {
    setCheckedRate(event.target.value);
  };

  //filter domain
  const [checkedDomain, setCheckedDomain] = useState([]);

  const handleDomainFilter = (event) => {
    var updatedList = [...checkedDomain];
    if (event.target.checked) {
      updatedList = [...checkedDomain, event.target.value];
    } else {
      updatedList.splice(checkedDomain.indexOf(event.target.value), 1);
    }
    setCheckedDomain(updatedList);
  };
  const checkedDomainItems = checkedDomain.length
    ? checkedDomain.reduce((total, item) => {
      return total + "," + item;
    })
    : "";

  //filter certificates
  const [checkedCertificate, setCheckedCertificate] = useState([]);

  const handleCertificateFilter = (event) => {
    var updatedList = [...checkedCertificate];
    if (event.target.checked) {
      updatedList = [...checkedCertificate, event.target.value];
    } else {
      updatedList.splice(checkedCertificate.indexOf(event.target.value), 1);
    }
    setCheckedCertificate(updatedList);
  };
  const checkedCertificateItems = checkedCertificate.length
    ? checkedCertificate.reduce((total, item) => {
      return total + "," + item;
    })
    : "";

  //filter education
  const [checkedEducation, setCheckedEducation] = useState([]);

  const handleEducationFilter = (event) => {
    var updatedList = [...checkedEducation];
    if (event.target.checked) {
      updatedList = [...checkedEducation, event.target.value];
    } else {
      updatedList.splice(checkedEducation.indexOf(event.target.value), 1);
    }
    setCheckedEducation(updatedList);
  };
  const checkedEducationItems = checkedEducation.length
    ? checkedEducation.reduce((total, item) => {
      return total + "," + item;
    })
    : "";

  useEffect(() => {
    async function callItSelf() {
      let location = "location";
      let primarySkill = "primarySkill";
      let secondarySkill = "secondarySkill";
      let workAuth = "workAuthorization";
      let security = "securityClearanceCategory";
      let employment = "employementType";
      let domain = "domain";
      let certificate = "certificate";
      let education = "academicsQualification";
      let USADegree = "usaDegree";
      let rateType = "rateType";
      setLoading(true);
      if (cardName) {
        setStatusValue(cardName)
        try {
          const response = isrecruiterCandidate
            ? await recmatchingCardFilter(
              token,
              location,
              checkedItems,
              primarySkill,
              checkedPrimaryItems,
              secondarySkill,
              checkedSecondaryItems,
              workAuth,
              checkedAuthItems,
              security,
              checkedSecurityItems,
              employment,
              checkedEmployItems,
              domain,
              checkedDomainItems,
              certificate,
              checkedCertificateItems,
              education,
              checkedEducationItems,
              rateType,
              checkedRate,
              USADegree,
              checkedDegree,
              1,
              cardName
            )
            : isFreelance
              ? await freematchingCardFilter(
                token,
                location,
                checkedItems,
                primarySkill,
                checkedPrimaryItems,
                secondarySkill,
                checkedSecondaryItems,
                workAuth,
                checkedAuthItems,
                security,
                checkedSecurityItems,
                employment,
                checkedEmployItems,
                domain,
                checkedDomainItems,
                certificate,
                checkedCertificateItems,
                education,
                checkedEducationItems,
                rateType,
                checkedRate,
                USADegree,
                checkedDegree,
                1,
                cardName
              )
              : await matchingCardFilter(
                token,
                location,
                checkedItems,
                primarySkill,
                checkedPrimaryItems,
                secondarySkill,
                checkedSecondaryItems,
                workAuth,
                checkedAuthItems,
                security,
                checkedSecurityItems,
                employment,
                checkedEmployItems,
                domain,
                checkedDomainItems,
                certificate,
                checkedCertificateItems,
                education,
                checkedEducationItems,
                rateType,
                checkedRate,
                USADegree,
                checkedDegree,
                1,
                cardName
              );
          if (response.status === 200) {
            const data = await response.json();
            const payload = data.payload;
            const totalRecord = payload[payload.length - 1].totalRecord;
            setRecord(totalRecord);
            setTotalLength(totalRecord);
            setItem(payload.slice(0, -1));
            setLoading(false);
            setPageCount(Math.ceil(payload[6].totalRecord / 6));
          }
        } catch { }
      } else {
        try {
          const response = isrecruiterCandidate
            ? await recmatchingMultipleFilter(
              token,
              location,
              checkedItems,
              primarySkill,
              checkedPrimaryItems,
              secondarySkill,
              checkedSecondaryItems,
              workAuth,
              checkedAuthItems,
              security,
              checkedSecurityItems,
              employment,
              checkedEmployItems,
              domain,
              checkedDomainItems,
              certificate,
              checkedCertificateItems,
              education,
              checkedEducationItems,
              rateType,
              checkedRate,
              USADegree,
              checkedDegree,
              1
            )
            : isFreelance
              ? await freematchingMultipleFilter(
                token,
                location,
                checkedItems,
                primarySkill,
                checkedPrimaryItems,
                secondarySkill,
                checkedSecondaryItems,
                workAuth,
                checkedAuthItems,
                security,
                checkedSecurityItems,
                employment,
                checkedEmployItems,
                domain,
                checkedDomainItems,
                certificate,
                checkedCertificateItems,
                education,
                checkedEducationItems,
                rateType,
                checkedRate,
                USADegree,
                checkedDegree,
                1
              )
              : await matchingMultipleFilter(
                token,
                location,
                checkedItems,
                primarySkill,
                checkedPrimaryItems,
                secondarySkill,
                checkedSecondaryItems,
                workAuth,
                checkedAuthItems,
                security,
                checkedSecurityItems,
                employment,
                checkedEmployItems,
                domain,
                checkedDomainItems,
                certificate,
                checkedCertificateItems,
                education,
                checkedEducationItems,
                rateType,
                checkedRate,
                USADegree,
                checkedDegree,
                1
              );

          if (response.status === 200) {
            const data = await response.json();
            const payload = data.payload;
            const totalRecord = payload[payload.length - 1].totalRecord;
            setRecord(totalRecord);
            setTotalLength(totalRecord);
            setItem(payload.slice(0, -1));
            setLoading(false);
            setPageCount(Math.ceil(payload[6].totalRecord / 6));
          }
        } catch { }
      }
    }
    callItSelf()
  }, [
    checked,
    checkedPrimary,
    checkedSecondary,
    checkedAuth,
    checkedSecurity,
    checkedEmploy,
    checkedDomain,
    checkedCertificate,
    checkedEducation,
    checkedRate,
    checkedDegree,
  ]);

  //shortlist integration
  const [listId, setListId] = useState([]);
  const [shortlistValue, setShortlistValue] = useState([]);
  const [matchingselcetallId, setMatchingSelectallId] = useState([]);
  const [allshortlistValues, setAllShortlistValue] = useState([]);
  var updated = [...matchingselcetallId];
  var updateAllShortlistData = [...allshortlistValues];

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      item.slice(0, 6).map((data) => updated.push(data.uuid));
      item
        .slice(0, 6)
        .map((data) => updateAllShortlistData.push(data.candidateShortlisted));
    } else {
      updated.splice(0, 6);
      updateAllShortlistData.splice(0, 6);
    }
    setMatchingSelectallId(updated);
    setAllShortlistValue(updateAllShortlistData);
  };

  function handleShortlist(event, shortlistData) {
    var updatedList = [...listId];
    var updateShortlistData = [...shortlistValue];
    if (event.target.checked) {
      updatedList = [...listId, event.target.value];
      updateShortlistData = [...shortlistValue, shortlistData];
    } else {
      updatedList.splice(listId.indexOf(event.target.value), 1);
      updateShortlistData.splice(shortlistValue.indexOf(shortlistData), 1);
      updated.splice(matchingselcetallId.indexOf(event.target.value), 1);
      updateAllShortlistData.splice(
        allshortlistValues.indexOf(shortlistData),
        1
      );
    }
    setListId(updatedList);
    setShortlistValue(updateShortlistData);
    setMatchingSelectallId(updated);
    setAllShortlistValue(updateAllShortlistData);
  }

  const commonForAllShortlist = async () => {
    let location = "location";
    let primarySkill = "primarySkill";
    let secondarySkill = "secondarySkill";
    let workAuth = "workAuthorization";
    let security = "securityClearanceCategory";
    let employment = "employementType";
    let domain = "domain";
    let certificate = "certificate";
    let education = "academicsQualification";
    let USADegree = "usaDegree";
    let rateType = "rateType";
    if (cardName) {
      const response = isrecruiterCandidate
        ? await recmatchingCardFilter(
          token,
          location,
          checkedItems,
          primarySkill,
          checkedPrimaryItems,
          secondarySkill,
          checkedSecondaryItems,
          workAuth,
          checkedAuthItems,
          security,
          checkedSecurityItems,
          employment,
          checkedEmployItems,
          domain,
          checkedDomainItems,
          certificate,
          checkedCertificateItems,
          education,
          checkedEducationItems,
          rateType,
          checkedRate,
          USADegree,
          checkedDegree,
          page + 1,
          cardName
        )
        : isFreelance
          ? await freematchingCardFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            page + 1,
            cardName
          )
          : await matchingCardFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            page + 1,
            cardName
          );
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        const totalRecord = payload[payload.length - 1].totalRecord;
        setRecord(totalRecord);
        setItem(payload.slice(0, -1));
        setLoading(false);
        setTotalLength(totalRecord);
      }
    } else {
      const response = isrecruiterCandidate
        ? await recmatchingMultipleFilter(
          token,
          location,
          checkedItems,
          primarySkill,
          checkedPrimaryItems,
          secondarySkill,
          checkedSecondaryItems,
          workAuth,
          checkedAuthItems,
          security,
          checkedSecurityItems,
          employment,
          checkedEmployItems,
          domain,
          checkedDomainItems,
          certificate,
          checkedCertificateItems,
          education,
          checkedEducationItems,
          rateType,
          checkedRate,
          USADegree,
          checkedDegree,
          page + 1
        )
        : isFreelance
          ? await freematchingMultipleFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            page + 1
          )
          : await matchingMultipleFilter(
            token,
            location,
            checkedItems,
            primarySkill,
            checkedPrimaryItems,
            secondarySkill,
            checkedSecondaryItems,
            workAuth,
            checkedAuthItems,
            security,
            checkedSecurityItems,
            employment,
            checkedEmployItems,
            domain,
            checkedDomainItems,
            certificate,
            checkedCertificateItems,
            education,
            checkedEducationItems,
            rateType,
            checkedRate,
            USADegree,
            checkedDegree,
            page + 1
          );
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        const totalRecord = payload[payload.length - 1].totalRecord;
        setRecord(totalRecord);
        setItem(payload.slice(0, -1));
        setLoading(false);
        setTotalLength(totalRecord);
      }
    }
  }

  const ShortlistJobs = async () => {
    setLoading(true);
    if (listId.length !== 0) {
      var data = {
        uuid: [listId],
      };
    } else if (matchingselcetallId.length !== 0) {
      var data = {
        uuid: [matchingselcetallId],
      };
    }
    let booleanValueForShortlist = "";
    if (shortlistValue.includes(false)) {
      booleanValueForShortlist = true;
    } else if (allshortlistValues.includes(false)) {
      booleanValueForShortlist = true;
    } else {
      booleanValueForShortlist = false;
    }
    const response = isrecruiterCandidate
      ? await recshortlistJobs(token, data, booleanValueForShortlist)
      : isFreelance
        ? await freeshortlistJobs(token, data, booleanValueForShortlist)
        : await shortlistJobs(token, data, booleanValueForShortlist);
    if (response.status === 200) {
      commonForAllShortlist();
      setListId([]);
      setShortlistValue([]);
      setAllShortlistValue([]);
      setMatchingSelectallId([]);
      setTimeout(() => {
        for (let obj of document.getElementsByClassName("hidebox")) {
          obj.style.cssText = "display:inline";
        }
        for (let obj of document.getElementsByClassName("showbox")) {
          obj.style.cssText = "display:none";
        }
        const chkbtnobj = document.getElementsByClassName("checkbx");
        for (let obj of chkbtnobj) {
          obj.checked = false;
        }
        for (let obj of document.getElementsByClassName("box")) {
          obj.style.cssText = "background-color:white";
        }
      }, 1500)

    }
  };

  async function handleaddSingleShortlist(id) {
    var data = {
      uuid: [id],
    };
    const response = isrecruiterCandidate
      ? await recshortlistJobs(token, data, true)
      : isFreelance
        ? await freeshortlistJobs(token, data, true)
        : await shortlistJobs(token, data, true);
    if (response.status === 200) {
      commonForAllShortlist();
    }
  }

  async function handleremoveSingleShortlist(id) {
    var data = {
      uuid: [id],
    };
    const response = isrecruiterCandidate
      ? await recshortlistJobs(token, data, false)
      : isFreelance
        ? await freeshortlistJobs(token, data, false)
        : await shortlistJobs(token, data, false);
    if (response.status === 200) {
      commonForAllShortlist();
    }
  }

  //location search
  const [informationData, setInformationData] = useState({ locationData: "" });

  function handleInformation(event, setInformationData) {
    let { name, value } = event.currentTarget;
    setInformationData((state) => {
      return { ...state, [name]: value };
    });
  }
  function callMethod(event) {
    handleInformation(event, setInformationData);
  }
  async function handleLocationSearch() {
    let data = informationData.locationData;
    const response = await locationListSearch(token, data);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setLocation(payload);
    }
  }

  useEffect(() => {
    handleLocationSearch();
  }, [informationData.locationData]);

  //status dropdown filter
  const handleStatusFilter = async (event) => {
    try {
      setLoading(true);
      let cardName = event.target.value;
      setStatusValue(cardName)
      const response = isrecruiterCandidate
        ? await recmatchingCardPagination(token, cardName, 1)
        : isFreelance
          ? await freematchingCardPagination(token, cardName, 1)
          : await matchingCardPagination(token, cardName, 1);
      if (response.status === 200) {
        history.push(`/candidate/matchjobs?title=${cardName}`);
        const data = await response.json();
        const payload = data.payload;
        const totalRecord = payload[payload.length - 1].totalRecord;
        setRecord(totalRecord);
        setTotalLength(totalRecord);
        setItem(payload.slice(0, -1));
        setLoading(false);
        setPageCount(Math.ceil(payload[6].totalRecord / 6));
      }
    } catch { }
  };

  const handleClearAll = () => {
    var aa = document.getElementsByTagName("input");
    for (var i = 0; i < aa.length; i++) {
      if (aa[i].type == "checkbox") {
        if (aa[i].checked) {
          setChecked([]);
          setCheckedPrimary([]);
          setCheckedSecondary([]);
          setCheckedAuth([]);
          setCheckedSecurity([]);
          setCheckedEmploy([]);
          setCheckedDomain([]);
          setCheckedCertificate([]);
          setCheckedEducation([]);
          aa[i].checked = false;
        }
      }
      if (aa[i].type == "radio") {
        if (aa[i].checked) {
          setCheckedRate("");
          setCheckedDegree("");
          aa[i].checked = false;
        }
      }
    }
  };

  // for modal
  const filterToggleHide = () => {
    document.getElementById("sidebaricon").style.pointerEvents = "none";
    // document.getElementsByClassName(
    //   "filtertogglebutton"
    // )[0].style.pointerEvents = "none";
    const sidebar = document.getElementsByClassName("sidebar")[0];
    sidebar.style.cssText = "min-width:280px  !important";
    const companylogo = document.getElementsByClassName("companylogo")[0];
    companylogo.style.cssText = "position:relative;";
    const container = document.getElementsByClassName("stick")[0];
    container.style.cssText =
      "margin-left:260px;border-radius:38px 0px 0px 0px";
    document.getElementsByClassName('humbergerIcon')[0].style.cssText = "transform:translateX(12%) scale(0.8);cursor:pointer ";
    document.getElementById('replacetitleimg').style.cssText = "margin-left:0rem"
  };
  //
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1151px)' });
  return (
    <div className={"col mx-lg-4 mx-1"}>
      {/*this is first box*/}
      {/* <div
        className={"row mt-3 align-items-center"}
        style={{
          borderRadius: "20px",
          boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
          backgroundColor: "white",
        }}
      >
        <div className={"col-8 py-3"}>
          <div className={"row align-items-center"}>
            <div className={"col ml-2"}>
              <input
                type={"search"}
                className={
                  "form-control p-4 placetext border-0 searchbxfocusbdr"
                }
                value={information}
                name={"searchData"}
                placeholder={"Search by Title, Company or any jobs keyword..."}
                onChange={(event) => setInformation(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className={"col"}>
          <button className={"findbtn float-right mr-3"} onClick={filterData}>
            FIND
          </button>
          <button
            className={"filterbtn mx-4 float-right filtertogglebutton"}
            onClick={filterToggleHide}
            data-toggle={"modal"}
            data-target={"#modalfilter"}
          >
            FILTER
          </button>
        </div>
      </div> */}
      <div
        className={"row mt-3 align-items-center"}
      // style={{
      //   borderRadius: "20px",
      //   boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
      //   backgroundColor: "white",
      // }}
      >
        <div className={"col-lg-11 col-9 py-3"}>
          <div className={"row align-items-center matching-job-margin-left-17rem"} >
            <div className={"col-lg-12 "}>
              <input
                type={"search"}
                className={
                  "form-control py-4 border-0 placetext searchbxfocusbdr border-radius-05rem"
                }
                placeholder={"Search jobs"}
                value={information}
                name={"searchData"}
                onChange={(event) => setInformation(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className={"col-lg-1 col-2"}>
          {/* <button
            className={"findbtn float-right mr-3"}
            onClick={handleSearchData}
          >
            FIND

          </button> */}
          <button
            className={" ml-lg-3 ml-0  background-colored-Lavender-mist border-0"}
            onClick={filterToggleHide}
            data-toggle={"modal"}
            data-target={"#modalfilter"}
          >
            <img src="/icon/candidate/applications/Frame53.png" alt="Filter " />
            {/* FILTER */}
          </button>
        </div>
      </div>
      <Filter
        location={location}
        outsideLocation={outsideLocation}
        checked={checked}
        primary={skill}
        secondary={secondary}
        domain={domain}
        domainLength={domainLength}
        certificate={certificate}
        education={education}
        informationData={informationData}
        handleInformation={callMethod}
        handleLocationFilter={handleLocationFilter}
        handlePrimarySkillFilter={handlePrimarySkillFilter}
        handleSecondarySkillFilter={handleSecondarySkillFilter}
        handleWorkAuthFilter={handleWorkAuthFilter}
        handleEmploymentFilter={handleEmploymentFilter}
        handleSecurityFilter={handleSecurityFilter}
        handleUSDegreeFilter={handleUSDegreeFilter}
        handleRateFilter={handleRateFilter}
        handleDomainFilter={handleDomainFilter}
        handleEducationFilter={handleEducationFilter}
        handleCertificateFilter={handleCertificateFilter}
        handleClearAll={handleClearAll}
      />
      {/*toggle box of filter button*/}
      <div
        className={"row mt-3 py-2 align-items-center collapse"}
        id={"filter"}
      >
        <div className={"col-9 filterfont pl-4 ml-2"}>
          Filter Applied
          <button
            className={"filterbttn ml-5 matching-job-filter-Applied"}
          >
            hourly
          </button>
          <button
            className={"filterbttn mx-2 matching-job-filter-Applied"}
          >
            California
          </button>
          <button
            className={"filterbttn mx-2 matching-job-filter-Applied"}
          >
            Telephonic
          </button>
          <button
            className={"filterbttn mx-2 matching-job-filter-Applied"}
          >
            java
          </button>
          <button
            className={"filterbttn mx-2 my-1 matching-job-filter-Applied"}
          >
            8 Year
          </button>
        </div>
        <div className={"col"}>
          <button
            className={
              "filterfontright float-right mr-4 border-0 bg-transparent"
            }
            onClick={clearFilter}
          >
            Clear filter
          </button>
        </div>
      </div>
      {/*this is second box of data heading-----changed*/}
      {isDesktopOrLaptop && <div
        className={"row mt-3 align-items-center matching-job-box-shadow"}

      >
        <div className={"col py-4"}>
          {/*heading box*/}
          <div className={"row pl-3"}>
            <div className={"col-1 pr-0"}>
              <input
                type={"checkbox"}
                className={"checkbx checkboxcustomcss  ml-3"}
                onClick={(e) => {
                  MatchingJobSelectAllData(e);
                  handleSelectAll(e);
                }}
              />
            </div>
            <div className={"col-1 hidebox"}>
              <font className={"headingfont pr-0"}>ID</font>
              <img
                src={`../../icon/candidate/matchingjob/${idOrder ? 'down-arrow.png' : 'down-arrow(2).png'}`}
                alt={"align"}
                className={"img-fluid mr-4 pl-1"}
                onClick={() => {
                  handleIdSorting();
                  setIdOrder(!idOrder);
                }}
                height={22}
                width={22}
              />
            </div>
            <div className={"col-1 hidebox"}>

              <font className={"headingfont ml-5"}>Position</font>
            </div>

            <div className={"col-2 hidebox"}>
              <div className={"pl-5"}>
                <font className={"headingfont ml-3 ml-lg-4"}>Matching</font>
                <img
                  src={`../../icon/candidate/matchingjob/${matching ? 'down-arrow.png' : 'down-arrow(2).png'}`}

                  alt={"align"}
                  className={"img-fluid pl-1"}
                  onClick={() => {
                    handleMatchingSorting();
                    setMatching(!matching);
                  }}
                  height={22}
                  width={22}
                />
              </div>
            </div>
            <div className={"col-1 pr-0 hidebox"}>
              <div className={"ml-4"}>
                <font className={"headingfont"}>SourcedBy</font>
              </div>
            </div>
            <div className={"col-1 ml-4 pr-0 hidebox"}>
              <font className={"headingfont ml-lg-3 ml-4"}>Rate</font>
              <img
                src={`../../icon/candidate/matchingjob/${order ? 'down-arrow.png' : 'down-arrow(2).png'}`}
                alt={"align"}
                className={"img-fluid pl-1"}
                onClick={() => {
                  handleRateSorting();
                  setOrder(!order)
                }}
                height={22}
                width={22}
              />
            </div>
            <div className={"col-2 pr-0 hidebox"}>
              <font className={"headingfont ml-2"}>Interview Type</font>
            </div>
            <div className={"col-1 pl-0 pr-0 hidebox"}>
              <font className={"headingfont"} style={{ marginLeft: '-1rem' }}>Location</font>
            </div>
            <div className={"col-1 pl-0 hidebox"}>
              <select
                className={"form-select statusdropdown px-4 pl-0 headingfont"}
                onChange={handleStatusFilter}
                value={statusValue}
              >
                <option value={""}>Status</option>
                <option value={"interview"}>Scheduled</option>
                <option value={"shortlisted"}>Shortlisted</option>
                <option value={"offer"}>Offered</option>
                <option value={"reject"}>Rejected</option>
              </select>
            </div>
            <div className={"col-11 showbox"} style={{ display: "none" }}>
              <div
                className={"pt-2 pb-1 mr-5 matching-job-status"}
              // style={{
              //   background: "#FBFBFB",
              //   boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              // }}
              >
                <div className={"row"}>
                  <div className={"col pl-5 ml-5 mt-2 font-weight-500 font-size-14px"}>
                    <label

                      className={"pr-5 matching-job-shortlisted"}
                      onClick={ShortlistJobs}
                    >
                      {shortlistValue.length !== 0 &&
                        shortlistValue.includes(false)
                        ? "Shortlist"
                        : allshortlistValues.length !== 0 &&
                          allshortlistValues.includes(false)
                          ? "Shortlist"
                          : "Remove from shortlist"}
                    </label>
                    {/* <span
                      className={"pt-2 pb-2 pl-3 pr-3"}
                      style={{
                        backgroundColor:`${shortlistValue.length!==0} &&
                        ${shortlistValue.includes(false)} ? "rgba(255, 138, 0, 0.2)" :" "`,
                        borderRadius: "8px",
                      }}
                    > */}
                    {shortlistValue.length !== 0 &&
                      shortlistValue.includes(false) ? (
                      <label className="matching-job-shortlisted-value font-size-18px">
                        &#9733;{" "}
                      </label>
                    ) : allshortlistValues.length !== 0 &&
                      allshortlistValues.includes(false) ? (
                      <label className="matching-job-shortlisted-value font-size-18px">
                        &#9733;{" "}
                      </label>
                    ) : (
                      <label className="matching-job-shortlisted-value-two font-size-18px">
                        &#9734;{" "}
                      </label>
                    )}
                    {/* </span> */}
                  </div>
                  <div className={"col mt-2 pr-5"}>
                    <span
                      className={"pt-2 pb-2 pl-5 pr-5 matching-job-reject-span"}

                    >
                      <label
                        className="colored-Bright-red font-size-14px font-weight-500"

                      >
                        Reject
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
      {/*this is second box of heading data*/}
      <Data
        item={item}
        record={record}
        handleShortlist={handleShortlist}
        handleaddSingleShortlist={handleaddSingleShortlist}
        handleremoveSingleShortlist={handleremoveSingleShortlist}
      />
      {/*last box of data of heading*/}
      {loading ? (
        ""
      ) : (
        <>
          {(isDesktopOrLaptop && record !== 0) && (
            <div
              className={"row align-items-center matching-job-box-shadow-with-margin"}
            >
              <div className={"col py-2"}></div>
            </div>
          )}
        </>
      )}
      <div className={"col mt-5 pb-5"}>
        <div className={"row align-items-center  pb-5"}>
          {/* <div className={"col-md-6 pl-0 pb-5"}> */}
          {/* Showing 6 from {data.length} data */}
          {/* </div> */}
          <div className={"col-md"}>
            {totalLength > 6 && (
              <ReactPaginate
                previousLabel={"<< "}
                nextLabel={" >>"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={page}
              />
            )}
          </div>
        </div>
      </div>
      <GoToTop />
    </div>
  );
};

export default React.memo(MatchingJob);
