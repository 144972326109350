import { GETRECRUITERUSERDETAILS } from "../ENVIRONMENT";

// api for submit recruiter register form
function registerForm(token, userdetails) {
  return fetch(GETRECRUITERUSERDETAILS, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(userdetails),
  });
}

function notificationRegisterForm(token, userdetails) {
  return fetch(GETRECRUITERUSERDETAILS + "?initialStep=true", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(userdetails),
  });
}

export { registerForm,notificationRegisterForm };
