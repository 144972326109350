import "../../../styles/app.scss";
import React, { useEffect, useState } from "react";
import GoToTop from "../../common/GoToTop";
import { useSelector } from "react-redux";
import { adminCandidateListPagination } from "../../../api/admin/candidatelist/candidateList";
import { Data } from "../../../function/admin/candidateList";
import ReactPaginate from "react-paginate";
import { candidateListSorting } from "../../../api/admin/candidatelist/candidateListSorting";
import { candidateListLocation } from "../../../api/admin/candidatelist/candidateListLocation";
import CandidateFilter from "./candidateFilter";
import DashboardCards from "../common/DashboardCards";

const AdminCandidate = () => {
  const token = useSelector((state) => state.token);
  const [item, setItem] = useState([]);
  const [record, setRecord] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [totalLength, setTotalLength] = useState();
  const [idOrder, setIdOrder] = useState(true);

  useEffect(() => {
    async function adminCandidateListDetails() {
      try {
        setLoading(true);
        const response = await adminCandidateListPagination(token, 1);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          const totalRecord = payload[payload.length - 1].totalRecord;
          setRecord(totalRecord);
          setItem(payload.slice(0, -1));
          setLoading(false);
          setPageCount(Math.ceil(payload[6].totalRecord / 6));
          setTotalLength(payload[6].totalRecord);
        }
      } catch { }
    }
    adminCandidateListDetails();
  }, []);

  // pagination
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPage(data.selected);
    setLoading(true);
    const response = await adminCandidateListPagination(token, currentPage);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      const totalRecord = payload[payload.length - 1].totalRecord;
      setRecord(totalRecord);
      setTotalLength(totalRecord);
      setItem(payload.slice(0, -1));
      setLoading(false);
    }
  };

  //id sorting
  const handleIdSorting = async () => {
    let rate = "id";
    let high = "high";
    let low = "low";
    setPage(0);
    if (idOrder) {
      const response = await candidateListSorting(token, 1, rate, high);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        setItem(payload.slice(0, -1));
      }
    } else {
      const response = await candidateListSorting(token, 1, rate, low);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        setItem(payload.slice(0, -1));
      }
    }
  };

  const [location, setLocation] = useState([]);

  useEffect(() => {
    async function callLocationDetails() {
      try {
        candidateListLocation(token)
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            const payload = data.payload;
            setLocation(payload);
          });
      } catch { }
    }
    callLocationDetails();
  }, []);

  // for modal
  const filterToggleHide = () => {
    document.getElementById("sidebaricon").style.pointerEvents = "none";
    document.getElementsByClassName(
      "filtertogglebutton"
    )[0].style.pointerEvents = "none";
    const sidebar = document.getElementsByClassName("sidebar")[0];
    sidebar.style.cssText = "min-width:280px";
    const companylogo = document.getElementsByClassName("companylogo")[0];
    companylogo.style.cssText = "position:relative;";
    const container = document.getElementsByClassName("stick")[0];
    container.style.cssText =
      "margin-left:260px;border-radius:38px 0px 0px 0px";
    document.getElementsByClassName('humbergerIcon')[0].style.cssText = "transform:translateX(12%) scale(0.8) ;cursor:pointer ";
  };

  const data = [
    {
      name: "Active Candidates",
      progressColor: "#2BC155",
      count: 100
    },
    {
      name: "Total Candidates",
      progressColor: "#3F9AE0",
      count: 1
    },
    {
      name: "Shortlisted Jobs",
      progressColor: "#FF9B52",
      count: 2
    }
  ]

  return (
    <div className={"col mx-lg-4 mx-2 pt-1"}>
      <div
        className={"row align-items-center mt-4 pr-0"}
        style={{
          borderRadius: "20px",
          boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
          backgroundColor: "white",
        }}
      >
        <div className={"col-6 py-2"}>
          <div className={"row align-items-center"}>
            <div className={"col ml-2"}>
              <input
                type={"search"}
                className={
                  "form-control p-4 placetext border-0 searchbxfocusbdr"
                }
                name={"searchData"}
                placeholder={"Search by Title, Company or any jobs keyword..."}
              />
            </div>
          </div>
        </div>
        <div className={"col pr-0 ml-5"}>
          <button className={"findbtn"}>FIND</button>
          <button
            className={"filterbtn mx-4 filtertogglebutton"}
            onClick={filterToggleHide}
            data-toggle={"modal"}
            data-target={"#modalfilter"}
          >
            FILTER
          </button>
          <select
            className={"form-select dashboardforminputfield inputgray px-5 "}
            style={{
              color: "#313131",
              boxShadow: "none",
              outline: "none",
            }}
          >
            <option value={""}>From the Begining</option>
            <option value={"interview"}>5 days ago</option>
            <option value={"shortlisted"}>10 days ago</option>
            <option value={"offer"}>15 days ago</option>
            <option value={"reject"}>30 days ago</option>
            <option value={"reject"}>45 days ago</option>
            <option value={"reject"}>60 days ago</option>
          </select>
        </div>
      </div>
      <CandidateFilter location={location} />
      <div className={"row"}>
        <DashboardCards data={data} col={"col-lg-4"} />
      </div>
      <div
        className={"row mt-3 align-items-center pl-3"}
        style={{
          borderRadius: "20px 20px 0 0",
          boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
          backgroundColor: "white",
        }}
      >
        <div className={"col py-4"}>
          {/*heading box*/}
          <div className={"row"}>
            <div className={"col-2 pr-0 pl-0"}>
              <font className={"headingfont pl-5 pr-1"}>ID</font>
              <img
                src="/icon/candidate/matchingjob/align.png"
                alt={"align"}
                className={"img-fluid"}
                onClick={() => {
                  {
                    handleIdSorting();
                    setIdOrder(!idOrder);
                  }
                }}
              />
            </div>
            <div className={"col-2  hidebox"} style={{ textAlign: "center" }}>
              <font className={"headingfont"}>Name</font>
            </div>
            <div className={"col-3  hidebox"} style={{ textAlign: "center" }}>
              <font className={"headingfont"}>Email</font>
            </div>
            <div className={"col-2 hidebox"}>
              <font className={"headingfont ml-4"}>Phone</font>
            </div>
            <div className={"col-1 hidebox pl-0"}>
              <font className={"headingfont"}>Rate</font>
            </div>
            <div className={"col-1 hidebox ml-5"}>
              <font className={"headingfont"}>Status</font>
            </div>
          </div>
        </div>
      </div>
      <Data item={item} record={record} Link={"/admin/candidateDetails"} />
      {loading ? (
        ""
      ) : (
        <>
          {record !== 0 && (
            <div
              className={"row align-items-center"}
              style={{
                borderRadius: "0 0 20px 20px",
                boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
                backgroundColor: "white",
                marginTop: "1px",
              }}
            >
              <div className={"col py-2"}></div>
            </div>
          )}
        </>
      )}
      <div className={"row align-items-center mt-5"}>
        <div className={"col-md-6 pl-0 pb-5"}></div>
        <div className={""}>
          {totalLength > 6 && (
            <ReactPaginate
              previousLabel={"<< Previous"}
              nextLabel={"Next >>"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={3}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              forcePage={page}
            />
          )}
        </div>
      </div>
      <GoToTop />
    </div>
  );
};
export default React.memo(AdminCandidate);
