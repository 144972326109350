import React, { useRef, useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { EditorState, ContentState } from "draft-js";
import GoToTop from "../../common/GoToTop";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { recgetRequirementInfo } from "../../../api/recruiter/client/BuildRequirement/getRequirementInfo";
import { recpostBuildRequirementDescription } from "../../../api/recruiter/client/BuildRequirement/buildRequirementDescription";
import {
  CreateSignedUrlAPI,
  uploadImage,
} from "../../../api/common/ImageUpload";
import { useSelector } from "react-redux";
import { recgetrequirementParsingData } from "../../../api/recruiter/client/requirementParsing";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const JobRequirment5 = (props) => {
  // return job requirement fifth page
  const [loader, setLoader] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [information, setInformation] = useState({ uploadskill: "" });
  const onEditorStateChange = (editorState) => {
    return setEditorState(editorState);
  };
  try {
    document.getElementById("replacetitleimg").innerText = "Job Posting";
    document.getElementById("postjobid").style.display = "none";
  } catch {}

  // job description validation
  const jobValidation = () => {
    var regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    var mailregex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(editorState.getCurrentContent().getPlainText())) {
      document.getElementById("jobdesc").innerText =
        "Phone number are not allowed";
    } else if (mailregex.test(editorState.getCurrentContent().getPlainText())) {
      document.getElementById("jobdesc").innerText = "Email is not allowed";
    } else {
      document.getElementById("jobdesc").innerText = "";
    }
  };

  const schema = yup.object().shape({
    picture: yup
      .mixed()
      .test("name", "Please select a file", (value) => {
        return value[0] && value[0].name !== "";
      })
      .test("fileSize", "File size < 5MB", (value, context) => {
        return value && value[0] && value[0].size <= 5000000;
      })
      .test("type", "Please upload a valid file", (value) => {
        return value && value[0] && (
            value[0].type === 'application/pdf' ||
            value[0].type === "application/msword" ||
            value[0].type === 'application/doc' ||
            value[0].type === 'application/ms-doc' ||
            value[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        );
      })
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });


  const handelDelete = () => {
    setInformation({
      uploadskill: ""
    })
    document.getElementById("skillSet").value = null
 }


  const param = useParams();
  const id = param.id;
  const refId = param.refId;
  let descRef = useRef();

  const token = useSelector((state) => state.token);
  function handleFile() {
    const file = document.getElementById("skillSet").files[0];
    const obj = {
      for: "RECRUITER",
      files: [
        {
          extension: file.name.split(".")[1],
          contentType: file.type,
          fileName: file.name,
        },
      ],
    };
    CreateSignedUrlAPI(token, obj)
      .then((result) => {
        const statusCode = result.status;
        const data = result.json();
        return Promise.all([statusCode, data]).then((res) => ({
          statusCode: res[0],
          data: res[1],
        }));
      })
      .then((res) => {
        if (res.statusCode === 200) {
          const promises = res.data?.payload?.signedUrls.map((url) => {
            uploadImage(url.signedUrl, file);
            return url;
          });
          Promise.all(promises).then((data) => {
            uploadImageListHandler(data);
          });
        }
      });
  }

  function uploadImageListHandler(items) {
    setTimeout(() => {
     sessionStorage.setItem("fileUrl", items[0].fileUrl);
    }, 1000);
  }
  const history = useHistory();

  function handleClick(event) {
    event.preventDefault();
    if (descRef.current) {
      descRef.current.setAttribute("disabled", "disabled");
    }
    setLoader(true);
    let registerData = {
      skillSetKey: information.uploadskill
        ? information.uploadskill
        : sessionStorage.getItem("fileUrl"),
    };
    if (editorState) {
      registerData = {
        description: editorState.getCurrentContent().getPlainText(),
        ...registerData,
      };
    }
    let filteredValue = {};
    Object.keys(registerData)
      .filter((key) => {
        const value = registerData[key];
        if (value || value === false) {
          return true;
        } else {
          return false;
        }
      })
      .map((key) => {
        filteredValue = { [key]: registerData[key], ...filteredValue };
      });
    recpostBuildRequirementDescription(token, filteredValue, id).then((res) => {
      if (res.status === 200) {
        history.push(`${props.link[5]}/${id}`);
      } else {
        descRef.current.removeAttribute("disabled");
        setLoader(false);
      }
    });
  }

  useEffect(() => {
    callItSelf();
  }, []);

  const callItSelf = async () => {
    try {
      if (refId) {
        const response = await recgetrequirementParsingData(token, refId);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload.parsedData;
          if (payload.description.length !== 0) {
            setEditorState(
              EditorState.createWithContent(
                ContentState.createFromText(payload.description)
              )
            );
          }
        }
      } else {
        const response = await recgetRequirementInfo(token, id);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload.requirement;
          setEditorState(
            EditorState.createWithContent(
              ContentState.createFromText(payload.description)
            )
          );
          const skillsetName = payload.skillSetKey.split("/");
          document.getElementById("filename").placeholder =
            skillsetName[skillsetName.length - 1];
        }
      }
    } catch {}
  };

  return (
    <div className={"col"}>
      <form className={"form-group"} onSubmit={handleClick}>
        <div className={"row pl-5"}>
          <div className={"container-fluid container1 pl-5"}>
            <ul className={"progressbar mt-5 pr-5"}>
              <li className={"active"}>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li className={"active"}>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li className={"active"}>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li className={"active"}>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
            </ul>
          </div>
        </div>
        <div className={"row mx-5 pl-5  resumealignment"}>
          <div className={"col-lg-2 pb-3 pl-4"}>
            <Link to={`${props.link[6]}/${id}`}>
              <div
                className={"pt-2 pl-2 pr-2"}
                style={{
                  width: "140px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#000000",
                  }}
                >
                  Requirement
                  <br />
                  info
                </label>
                <br />
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link to={`${props.link[1]}/${id}`}>
              <div
                className={"pl-3 pb-2 pr-3 pt-3"}
                style={{
                  width: "140px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#000000",
                  }}
                >
                  Skill
                </label>
                <br />
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link to={`${props.link[2]}/${id}`}>
              <div
                className={"pt-2 pl-2 pr-2 "}
                style={{
                  width: "140px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#000000",
                  }}
                >
                  Academics
                  <br />& Certificate
                </label>
                <br />
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link to={`${props.link[3]}/${id}`}>
              <div
                className={"pt-2 pl-2 pr-2"}
                style={{
                  width: "140px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#000000",
                  }}
                >
                  Security Clearance
                </label>
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <div
              className={"pt-1 pl-2 pr-2"}
              style={{
                width: "140px",
                borderRadius: "10px",
                background: "#FFFFFF",
                textAlign: "center",
              }}
            >
              <div className={"row"}>
                <div className={"col-lg pb-0"}>
                  <label
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#000000",
                      fontFamily: "poppins",
                    }}
                  >
                    Job <br />
                    Description
                  </label>
                </div>
              </div>
              <div className={"row pl-0"}>
                <div className={"col-lg"}>
                  <div
                    style={{
                      background: "#3F9AE0",
                      width: "100%",
                      height: "4px",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"pl-1 pr-5 mr-5 pb-3"}>
          <div className={"row px-5 pr-5 mx-3 pt-3"}>
            <div className={"w-100 pt-4 pl-5 contentholder"}>
              <div className={"row"}>
                <div className={"col-md"}>
                  <label
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#000000",
                    }}
                  >
                    JobDescription
                  </label>
                </div>
              </div>
              <div className={"row"}>
                <div
                  className={"col"}
                  style={{ height: "400px", overflowY: "auto" }}
                >
                  <Editor
                    editorState={editorState}
                    onChange={jobValidation}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    localization={{ locale: "en" }}
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "list",
                        "history",
                      ],
                      inline: { inDropdown: true },
                      list: { inDropdown: false },
                      link: { inDropdown: true },
                      history: { inDropdown: false },
                    }}
                    onEditorStateChange={(event) => onEditorStateChange(event)}
                  />
                </div>
              </div>
            </div>
            <label
              id={"jobdesc"}
              style={{ color: "red", fontSize: "12px", marginTop: "12px" }}
            ></label>
          </div>
        </div>
        <div className={"pl-1 pr-5 mr-5 pb-3"}>
          <div className={"row px-5 pr-5 mx-3 pt-3"}>
            <div className={"w-100 pt-4 pl-5 pb-4 contentholder"}>
              <div className={"row"}>
                <div className={"col-md"}>
                  <label
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#000000",
                    }}
                  >
                    Skill Matrix
                  </label>
                </div>
              </div>
              <div className={"row"}>
                <div className={"col-lg-4 pt-2"}>
                  <input
                    type={"text"}
                    className={
                      "form-control resumeuploadtextbox py-3 shadow-none"
                    }
                    placeholder={information.uploadskill
                      ? information.uploadskill.name
                      : "Upload skillset"}
                    id={"filename"}
                    disabled
                  />
                </div>
                <div className={"col-lg-2 pt-3 pr-0"}>
                  <label className={"browsebtn btn"}>
                    <input
                      type="file"
                      accept=".doc,.pdf,.docx"
                      style={{ position: "absolute", opacity: 0 }}
                      id={"skillSet"}
                      name={"picture"}
                      {...register("picture")}
                      onChange={(event) => {
                        setInformation({
                          ...information,
                          uploadskill: event.target.files[0]
                        })
                      }}
                    />
                    <span>Browse</span>
                  </label>
                </div>
                <div className={"col-lg-2 pt-3 pl-0"}>
                  <button
                    type={"button"}
                    className={"btn uploadbtn"}
                    onClick={handleSubmit(handleFile)}
                  >
                    Upload
                  </button>
                </div>
                {/* <div className={"col-lg-1 pr-5 pt-3"}>
                  <label
                    className={"cancelskill"}
                    onClick={handelDelete}
                  >
                    Cancel
                  </label>
                </div> */}
              </div>
              <div className={"row pt-5"}>
                <div className={"col-md-6 pl-5 pt-2"}>
                  <div className={"pl-5"}>
                    <label className={"dashboardforminputtext  pl-5"}>
                    </label>
                  </div>
                </div>

                <div className={"col-md"}>
                  {errors.picture && (
                    <p className="errormsg">{errors.picture.message}</p>
                  )}
                  <label
                    className={"fileerr errormsg"}
                    style={{ fontSize: "14px" }}
                  ></label>
                  {/* end */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"row pt-4"}>
          <div className={"col pr-3"}>
            <button
              type="submit"
              className={"btn offset-sm-9 save"}
              ref={descRef}
            >
              {loader ? (
                <i
                  className = "fa fa-refresh fa-spin"
                  style={{ padding: "3px 5px" }}
                ></i>
              ) : (
                ""
              )}
              Snapshot
            </button>
          </div>
        </div>
      </form>
      <GoToTop />
    </div>
  );
};

export default React.memo(JobRequirment5);
