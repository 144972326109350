import { FREELANCEBUILDFINISH } from "../../../ENVIRONMENT";

// api for submit freelance candidate snapshot
async function freesnapshot(token, id) {
  return await fetch(FREELANCEBUILDFINISH + `/${id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
}

export { freesnapshot };
