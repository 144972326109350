import { FREELANCECANDIDATEPRIMARY } from "../../ENVIRONMENT";

// api for freelance primary list
function freeprimaryList(token) {
  const response = fetch(FREELANCECANDIDATEPRIMARY, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { freeprimaryList };
