import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getMatchingJob } from "../../../api/candidate/matchingJobList";
import { recgetMatchingJob } from "../../../api/recruiter/candidate/matchingJobList";
import { freegetMatchingJob } from "../../../api/freelance/candidate/matchingJobList";
import { Puff } from 'react-loader-spinner';

const JobApplyContent = () => {
  // return jobapply content data

  const paramId = useParams();
  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);

  const [data, setData] = useState();

  useEffect(() => {
    async function callItSelf() {
      try {
        let payload;
        let response = isrecruiterCandidate
          ? await recgetMatchingJob(token, paramId.reqID)
          : isFreelance
            ? await freegetMatchingJob(token, paramId.reqID)
            : await getMatchingJob(token, paramId.reqID);
        if (response.status === 200) {
          payload = await response.json();
          payload = payload.payload;
          if (payload) {
            setData(payload.JobRequirement);
          }
        }
      } catch { }
    }
    callItSelf();
  }, []);
  // useEffect(() => {
  //   async function callItSelf() {
  //     try {
  //       console.log(paramId.reqID)
  //       const filteredData = jobData.filter(data => data.uuid == paramId.reqID)
  //       console.log(filteredData)
  //       setData(filteredData);
  //       // let payload;
  //       // let response = isrecruiterCandidate
  //       //   ? await recgetMatchingJob(token, paramId.reqID)
  //       //   : isFreelance
  //       //     ? await freegetMatchingJob(token, paramId.reqID)
  //       //     : await getMatchingJob(token, paramId.reqID);
  //       // if (response.status === 200) {
  //       //   payload = await response.json();
  //       //   payload = payload.payload;
  //       //   if (filteredData) {
  //       //     setData(filteredData)
  //       //   }
  //       // }
  //     } catch { }
  //   }
  //   callItSelf();
  // }, []);
  // useEffect(() => {
  //   async function callItSelf() {
  //     try {
  //       console.log(paramId.reqID);
  //       const filteredData = jobData.find((data) => data.uuid == paramId.reqID);
  //       console.log(filteredData);
  //       if (filteredData) {
  //         setData(filteredData);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching job data:", error);
  //     }
  //   }
  //   callItSelf();
  // }, [paramId.reqID]);
  useEffect(() => {
    async function callItSelf() {
      try {
        let payload;
        let response = isrecruiterCandidate
          ? await recgetMatchingJob(token, paramId.reqID)
          : isFreelance
            ? await freegetMatchingJob(token, paramId.reqID)
            : await getMatchingJob(token, paramId.reqID);
        if (response.status === 200) {
          payload = await response.json();
          payload = payload.payload;
          if (payload) {
            setData(payload.JobRequirement);
          }
        }
      } catch { }
    }
    callItSelf();
  }, []);
  return (
    <>
      {!data ? (
        // <div
        //   className="row h-100 background-colored-Pale-gray matchingJob-loader-container"
        // >
        //   <div className="col-sm-12 my-auto">
        //     <img
        //       src={"/icon/Gif/loader.gif"}
        //       className={"mx-auto d-block"}
        //       alt={"loader"}
        //     />
        //   </div>
        // </div>
        <div className="loader">
          <div className="svg-wrapper">    <Puff
            height="80"
            width="80"
            color="#3D007A"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          /></div>

        </div>
      ) : (
        <React.Fragment>
          <div className="ml-4">
            <div className={"row  pt-3 "}>
              <div className={"col-md mb-2"}>
                <div
                  className={" globalfontrule colored-Deep-violet font-size-1rem job-description-border-radius"}
                  style={{ fontWeight: 400, color: '#7E73B5', fontSize: '1.2rem' }}

                >
                  Job Description
                </div>
              </div>
            </div>

            <div className={"row pb-3"}>
              <div className={"col-md"}>
                <font className={"parafont"} style={{ fontWeight: "normal" }}>
                  {data ? <div dangerouslySetInnerHTML={{ __html: data.description }} /> : ""}

                </font>
              </div>
            </div>
            <div className={"row "}>
              <div className={"col-md"}>
                <img
                  src="/icon/candidate/applications/Group348.png"
                  alt={"correction icon"}
                  className={"img-fluid pr-3"}
                  height={30}
                  width={32}
                />
                <font className={"parafont font-size-08 font-weight-600 font-family-roboto"}>
                  Primary Skill
                </font>
              </div>
            </div>
            {data
              ? data.ClientPrimarySkills.map((obj, index) => {
                return (
                  <div className={"row ml-2"} key={index}>
                    <div className={"col-md parafont ml-3 "}>
                      <font className="color-dark-gray font-size-08 font-family-roboto font-weight-light">
                        {obj.ClientPrimarySkill}
                      </font>
                    </div>
                  </div>
                );
              })
              : ""}
            {data && data.ClientSecondarySkills ? (
              <div className={"row pt-2"}>
                <div className={"col-md"}>
                  <img
                    src="/icon/candidate/applications/Group348.png"
                    alt={"correction icon"}
                    className={"img-fluid pr-3"}
                    height={30}
                    width={32}
                  />
                  <font className={"parafont"} >Secondary Skill</font>
                </div>
              </div>
            ) : (
              ""
            )}
            {data && data.ClientSecondarySkills
              ? data.ClientSecondarySkills.map((obj, index) => {
                return (
                  <div className={"row ml-2"} key={index}>
                    <div className={"col-md parafont ml-3"}>
                      <font className="color-dark-gray font-size-08 font-family-roboto font-weight-light">
                        {obj.ClientSecondarySkill}
                      </font>
                    </div>
                  </div>
                );
              })
              : ""}
            <div className={"row pt-2"}>
              <div className={"col-md"}>
                <img
                  src="/icon/candidate/applications/Group348.png"
                  alt={"correction icon"}
                  className={"img-fluid pr-3"}
                  height={30}
                  width={32}
                />
                <font className={"parafont"}>Academics Qualification</font>
              </div>
            </div>
            {data && data.AcademicCertificates
              ? data.AcademicCertificates.map((obj, index) => {
                return (
                  <div className={"row pt-1 ml-2"} key={index}>
                    <div className={"col-md parafont ml-3"}>
                      <font className="color-dark-gray font-size-08 font-family-roboto font-weight-light">
                        {obj.academicsQualification} - {obj.specialization}
                      </font>
                    </div>
                  </div>
                );
              })
              : ""}
            {data && data.securityClearanceRequired === "YES" ? (
              <>
                <div className={"row pt-2"}>
                  <div className={"col-md parafont"}>
                    <img
                      src="/icon/candidate/applications/Group348.png"
                      alt={"correction icon"}
                      className={"img-fluid pr-3"}
                      height={30}
                      width={32}
                    />
                    <font className={"parafont"}>Security Clearance</font>
                  </div>
                </div>
                <div className={"row pt-2 ml-3"}>
                  <div className={"col-md pl-4"}>
                    <font className="color-dark-gray font-size-08 font-family-roboto font-weight-light parafont" >
                      {data ? data.securityClearanceClassification : ""}
                    </font>
                    <br />
                    <font className="color-dark-gray font-size-08 font-family-roboto font-weight-light parafont">
                      {data ? data.securityClearanceCategory : ""}
                    </font>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            <div className={"row pl-5 ml-5"}>
              <div
                className={"col-md"}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div>
                  {/* <Link to={"/"} className={"btn shortlistjob"}>
              Shortlist job
            </Link> */}
                </div>
              </div>
            </div>
            <div className={"row pt-2 pb-2"}>
              <div className={"col-xl"}>
                <label className="main pt-1">
                  Create alert for similar jobs
                  <input type="checkbox" className="modalcheckbox" />
                  <span className="geekmark" ></span>
                </label>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </>
  );
};

export default React.memo(JobApplyContent);
