import { FREELANCEJOBUPLOAD,FREELANCEJOBUPLOADEDDATA } from "../../ENVIRONMENT.js";

function freerequirementParsing(token, data) {
  // api for freelance requirement parsing
  const response = fetch(FREELANCEJOBUPLOAD, {
    method: "POST",
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
  return response;
}

async function freegetrequirementParsingData(token,id) {
  // api for get data from freelance requirement parsing
  const response = await fetch(FREELANCEJOBUPLOADEDDATA + `/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { freerequirementParsing,freegetrequirementParsingData };
