import React, { useState, useEffect } from "react";
import GoToTop from "../common/GoToTop";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  recCandidateDetails,
  recSpecificCandidateApplication,
  recSpecificCandidateMatches,
} from "../../api/recruiter/candidate/candidateDetails";
import {
  freeCandidateDetails,
  freeSpecificCandidateApplication,
  freeSpecificCandidateMatches,
} from "../../api/freelance/candidate/candidateDetails";
import ReactPaginate from "react-paginate";

const CandidateDetails = () => {
  // return candidate details
  const param = useParams();
  const id = param.cadidateID;
  const token = useSelector((state) => state.token);
  const isFreelance = useSelector((state) => state.isFreelance);
  const [match, setMatch] = useState();
  const [line, setLine] = useState(false);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState();
  const [pageCount,setPageCount] = useState();

  useEffect(() => {
    async function callUserDetails() {
      try {
        const response = isFreelance
          ? await freeCandidateDetails(token, id)
          : await recCandidateDetails(token, id);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setMatch(payload);
        }
      } catch {}
    }
    callUserDetails();
  }, []);

  useEffect(() => {
    async function callUserDetails() {
      try {
        setLoading(true);
        if (line) {
          let data = {
            applied: true,
          };
          const response = isFreelance
            ? await freeSpecificCandidateApplication(token, id, data,1)
            : await recSpecificCandidateApplication(token, id, data,1);
          if (response.status === 200) {
            const data = await response.json();
            const payload = data.payload;
            setRecord(payload.count);
            setList(payload.rows);
            setLoading(false);
            setPageCount(Math.ceil(payload.count/ 6));
          }
        } else {
          const response = isFreelance
            ? await freeSpecificCandidateMatches(token, id,1)
            : await recSpecificCandidateMatches(token, id,1);
          if (response.status === 200) {
            const data = await response.json();
            const payload = data.payload;
            setRecord(payload.count);
            setList(payload.rows);
            setLoading(false);
            setPageCount(Math.ceil(payload.count/ 6));
          }
        }
      } catch {}
    }
    callUserDetails();
  }, [line]);

  const handlePageClick = async(data) =>{
    let currentPage = data.selected + 1;
    setLoading(true)
    if (line) {
      let data = {
        applied: true,
      };
      const response = isFreelance
        ? await freeSpecificCandidateApplication(token, id, data,currentPage)
        : await recSpecificCandidateApplication(token, id, data,currentPage);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        setRecord(payload.count);
        setList(payload.rows);
        setLoading(false);
     }
    } else {
      const response = isFreelance
        ? await freeSpecificCandidateMatches(token, id,currentPage)
        : await recSpecificCandidateMatches(token, id,currentPage);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        setRecord(payload.count);
        setList(payload.rows);
        setLoading(false);
      }
    }
  }

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return (
    <div className={"col mx-5"}>
      <div className={"row pt-5 pb-5"}>
        <div className={"contentholder w-100 pl-4 pb-5"}>
          <div className={"row mt-4"}>
            <div className={"col-md-8"}>
              <label
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "#515151",
                }}
              >
                Candidate ID - {match ? match.candidateId : ""}
              </label>
            </div>
          </div>
          <div className={"row align-items-center pt-1 pl-4"}>
            <div className={"col-md-3"}>
              <font style={{ fontSize: "10px", color: "#3F9AE0" }}></font>
              <br />
              <font style={{ fontSize: "16px", color: "#313131" }}>
                {match ? match.firstName : ""}&nbsp;
                {match ? match.lastName : ""}
              </font>
              <br />
            </div>
            <div className={"col-md-2 pl-4"}>
              <font style={{ fontSize: "12px", color: "#313131" }}>
                ${match ? match.rate : ""}/{match ? match.rateType : ""}
              </font>
              <br />
            </div>
          </div>
          <div className={"row pl-4"}>
            <div className={"col"}>
              <font style={{ fontSize: "14px", color: "#313131" }}>
                {match ? match.resumeTitle : ""}
              </font>
            </div>
          </div>
          {/*second row*/}
          <div className={"row pl-4 pt-2"}>
            <div className={"col-md"}>
              <img
                src="/icon/candidate/applications/correction.png"
                alt={"correction icon"}
                className={"img-fluid pr-3"}
              />
              <font className={"parafont"} style={{ fontWeight: "bold" }}>
                Primary Address
              </font>
            </div>
          </div>
          <div className={"row"}>
            <div
              className={"col-md parafont ml-3"}
              style={{ fontWeight: "normal" }}
            >
              {match
                ? match.Addresses.map((obj, index) => {
                    return (
                      <div className={"row ml-2"} key={index}>
                        <div className={"col-md parafont ml-3"}>
                          <font
                            style={{ fontWeight: "normal" }}
                            className={"parafont"}
                          >
                            <font style={{ fontWeight: "bold" }}>
                              Zipcode :
                            </font>
                            {obj.zipCode}
                          </font>
                          <br />
                          <font
                            style={{ fontWeight: "normal" }}
                            className={"parafont"}
                          >
                            <font style={{ fontWeight: "bold" }}>City :</font>
                            {obj.city}
                          </font>
                          <br />
                          <font
                            style={{ fontWeight: "normal" }}
                            className={"parafont"}
                          >
                            <font style={{ fontWeight: "bold" }}>State :</font>
                            {obj.state}
                          </font>
                          <br />
                          <font
                            style={{ fontWeight: "normal" }}
                            className={"parafont"}
                          >
                            <font style={{ fontWeight: "bold" }}>
                              Country :
                            </font>
                            {obj.country}
                          </font>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      </div>
      {/*second box */}
      <div className={"row"}>
        <div className={"contentholder w-100  pb-2 pt-3"}>
          <div className={"row px-3"} style={{ textAlign: "center" }}>
            <div
              className={"col-md"}
              onClick={() => setLine(false)}
              style={{
                borderBottom: `${
                  line ? "2px solid #d2d1d0" : "2px solid #3F9AE0"
                }`,
                cursor: "pointer",
              }}
            >
              <label
                className="mb-3"
                onClick={() => setLine(false)}
                style={{ cursor: "pointer" }}
              >
                Matching Jobs
              </label>
            </div>
            <div
              className={"col-md"}
              onClick={() => setLine(true)}
              style={{
                borderBottom: `${
                  line ? "2px solid #3F9AE0" : "2px solid #d2d1d0"
                }`,
                cursor: "pointer",
              }}
            >
              <label
                className="mb-3"
                onClick={() => setLine(true)}
                style={{ cursor: "pointer" }}
              >
                Applications
              </label>
            </div>
          </div>
          <div className={"row pl-5 pt-4"}>
            {/* <div className={"col-1 pr-0"}>
              <input
                type={"checkbox"}
                className={"checkbx ml-3"}
                onClick={(e) => {
                  MatchingJobSelectAllData(e);
                  handleSelectAll(e);
                }}
              />
            </div> */}
            <div className={"col-2 hidebox"}>
              <font className={"headingfont pr-0"}>ID</font>
              {/* <img
                src="../../icon/candidate/matchingjob/align.png"
                alt={"align"}
                className={"img-fluid mr-4"}
                onClick={() => {
                    handleIdSorting();
                    setIdOrder(!idOrder);
                }}
              /> */}
            </div>
            <div className={"col-1"}>
              <font className={"headingfont pr-0"}>Position</font>
            </div>
            <div className={"col-3 hidebox"}>
              <div className={"pl-5"}>
                <font className={"headingfont ml-4"}>Matching</font>
                {/* <img
                  src="../../icon/candidate/matchingjob/align.png"
                  alt={"align"}
                  className={"img-fluid"}
                  onClick={() => {
                      handleMatchingSorting();
                      setMatching(!matching);
                  }}
                /> */}
              </div>
            </div>
            {/* <div className={"col-1 pr-0 hidebox"}>
              <div className={"ml-5"}>
                <font className={"headingfont"}>SourcedBy</font>
              </div>
            </div> */}
            <div className={"col-1 hidebox"}>
              <font className={"headingfont"}>Rate</font>
              {/* <img
                src="../../icon/candidate/matchingjob/align.png"
                alt={"align"}
                className={"img-fluid"}
                onClick={() => {
                    handleRateSorting();
                    setOrder(!order)
                }}
              /> */}
            </div>
            <div className={"col-2 pr-0 hidebox"}>
              <font className={"headingfont"}>Interview Type</font>
            </div>
            <div className={"col-1 pl-0 pr-0 hidebox"}>
              <font className={"headingfont"}>Location</font>
            </div>
            <div className={"col-1 pl-0 hidebox ml-2"}>
              <font className={"headingfont"}>Status</font>
            </div>
          </div>
          <hr />
          <>
            {loading ? (
              <div
                className="row h-100"
                style={{ paddingBottom: "11%", backgroundColor: "#F9F9F9" }}
              >
                <div className="col-sm-12 my-auto">
                  <img
                    src={"/icon/Gif/loader.gif"}
                    className={"mx-auto d-block"}
                    alt={"loader"}
                  />
                </div>
              </div>
            ) : (
              <>
                {record !== 0 ? (
                  <>
                    {list.map((data, index) => (
                      <div key={index} className={"row"}>
                        <div className={"col py-3 box"}>
                          {/*heading box data*/}
                          <div className={"row"}>
                            <div className={"col-2"}>
                              <label className={"matchingjobdata ml-4"}>
                                {data.jobRequirementId}
                                <br />
                                <font className={"matchingjobdata"}>
                                  {new Date(data.createdAt).toLocaleDateString(
                                    "en-US",
                                    options
                                  )}
                                </font>
                              </label>
                            </div>
                            <div className={"col-md-2 pl-0"} style={{textAlign:"center"}}>
                              <div
                                className=""
                                style={{
                                  wordWrap: "break-word",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                <label className={"matchingjobdata"}>
                                  {data.JobRequirement.jobTitle}
                                </label>
                              </div>
                            </div>
                            <div className="col-2">
                              <div className={"row"}>
                                <div className="col-5">
                                  <div
                                    className={"badge py-3"}
                                    style={{
                                      backgroundColor: "#D3D3D3",
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {data.matchPercentage} %
                                  </div>
                                </div>
                                <div className="col-7 pl-0">
                                  <div className={"row"}>
                                    <div
                                      className="col-md"
                                      style={{
                                        wordWrap: "break-word",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      <font
                                        style={{
                                          fontWeight: "600",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {data.JobRequirement.domain}
                                      </font>
                                    </div>
                                  </div>
                                  <div className={"row"}>
                                    <div className={"col-md"}>
                                      <font
                                        style={{
                                          fontWeight: "normal",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {data.JobRequirement.employementType}
                                      </font>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-1">
                        <font className={"matchingjobdata"}>{data.source}</font>
                      </div> */}
                            <div className={"col-1"}>
                              <font className={"matchingjobdata ml-4"}>
                                ${data.JobRequirement.rate}
                              </font>
                            </div>
                            <div className={"col-1"}>
                              <font className={"matchingjobdata ml-4"}>
                                {data.JobRequirement.interviewType}
                              </font>
                            </div>
                            <div className="col-2 pl-0">
                              <div
                                style={{
                                  wordWrap: "break-word",
                                  whiteSpace: "pre-wrap",
                                }}
                                className={"ml-5 pl-5"}
                              >
                                <font className={"matchingjobdata"}>
                                  {data.JobRequirement.location}
                                </font>
                              </div>
                            </div>
                            <div className={"col-1"}>
                              {data.status !== null &&
                              data.status !== "Apply" ? (
                                <label
                                  className={"btn bg-success"}
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    width: "100px",
                                    borderRadius: "10px",
                                    color: "white",
                                  }}
                                >
                                  {data.status}
                                </label>
                              ) : (
                                <label
                                  className={"btn bg-success"}
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    width: "100px",
                                    borderRadius: "10px",
                                    color: "white",
                                  }}
                                >
                                  Apply
                                </label>
                              )}
                            </div>
                          </div>
                          <hr />
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="d-flex justify-content-center pt-5 pb-5">
                    No Data Found
                  </div>
                )}
              </>
            )}
          </>
        </div>
      </div>
      <div className={"row mt-5 float-right"}>
      <div className={"col-md"}>
             {record > 4 && ( 
              <ReactPaginate
                previousLabel={"<< Previous"}
                nextLabel={"Next >>"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
               
              />
             )} 
        </div>
        </div>
      <GoToTop />
    </div>
  );
};

export default React.memo(CandidateDetails);
