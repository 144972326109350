import "../styles/app.scss";
import React from "react";
import { Link } from "react-router-dom";
import NavbarSidebarBtnForNewUser from "./NavbarSidebarBtnForNewUser";

const NavbarSidebarForNewUser = (props) => {
  // return navbarsidebar button for new user of client
  return (
    <div className={"col-md-1 sidebar"}>
      {/* <div className={"col-md-2"}> */}
      <Link to={props.homelocation}>
        <img
          src="/icon/Authentication/DirectHire_Dark_logo.png"
          alt={"companylogo"}
          className={"pb-5 p-2 companylogo"}
          width="150px"
        />
      </Link>
      {/* </div> */}
      <div className={"dashbtn"}>
        <NavbarSidebarBtnForNewUser />
      </div>
    </div>
  );
};

export default React.memo(NavbarSidebarForNewUser);
