import { AUTHENTICATION_URL } from "../ENVIRONMENT";

async function resetPassword(userdetails, id) {
  // api for reset password of user
  const response = await fetch(AUTHENTICATION_URL + `reset/${id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userdetails),
  });
  return response;
}
export { resetPassword };
