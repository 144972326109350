import React, { useState, useEffect, useRef } from "react";
import { clearFilter } from "../common/clearFilter";
import Popup from "reactjs-popup";
import { Link } from "react-router-dom";

const Data = (props) => {
  const [loading, setLoading] = useState();
  const [match, setMatch] = useState([]);
  const ref = useRef();

  useEffect(() => {
    try {
      setLoading(true);
      if (props.item.length !== 0) {
        setMatch(props.item);
        setLoading(false);
      }
      if (props.record === 0) {
        setLoading(false);
      }
    } catch {}
  }, [props.item]);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return (
    // return client list table data group which generated by data variable
    <>
      {loading ? (
        <div
          className="row h-100"
          style={{ paddingBottom: "11%", backgroundColor: "#F9F9F9" }}
        >
          <div className="col-sm-12 my-auto">
            <img
              src={"/icon/Gif/loader.gif"}
              alt={"loader"}
              className={"mx-auto d-block"}
            />
          </div>
        </div>
      ) : (
        <>
          {props.record !== 0 ? (
            <>
              {match.map((match, index) => (
                <div
                  className={"row pl-4"}
                  style={{
                    boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
                    backgroundColor: "white",
                    marginTop: "1px",
                  }}
                  key={index}
                >
                  {/* <input type={"checkbox"} className={"checkbx  mr-4 mt-4"}/> */}
                  <div className={"col py-3 box"}>
                    {/*heading box data*/}
                    <div className={"row  align-items-center pl-3"}>
                      <div className={"col-2 pl-4"}>
                        <Link 
                        to={"/admin/recruiterDashboard"}
                        className={"matchingjobdata text-primary"}>
                          {match.recruiterId && match.recruiterId}
                        </Link>
                        <br />
                        <font className="matchingjobdata">
                          {new Date(match.createdAt).toLocaleDateString(
                            "en-US",
                            options
                          )}
                        </font>
                        <br />
                      </div>
                      <div className="col-3">
                        <div
                          style={{
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                            textAlign: "center",
                          }}
                        >
                          <font className="matchingjobdata">
                            {match.firstName}&nbsp;&nbsp;{match.lastName}
                          </font>
                        </div>
                      </div>
                      <div className="col-3">
                        <div
                          style={{
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                            textAlign: "center",
                          }}
                        >
                          <font className={"matchingjobdata"}>
                            {match.corporateEmail}
                          </font>
                        </div>
                      </div>
                      <div className={"col-2 pl-0"}>
                        <div
                          style={{
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                            textAlign: "center",
                          }}
                        >
                          <font className={"matchingjobdata"}>{match.Addresses.length!==0 && match.Addresses[0].city}</font>
                        </div>
                      </div>
                      <div className={"col-1"}>
                        <font className={"matchingjobdata"}>{match.isRegistered ? "ACTIVE" : "INACTIVE"}</font>
                      </div>
                     <div className="col-1">
                      <Popup
                          ref={ref}
                          arrow={false}
                          trigger={
                            <i
                              className="fa fa-ellipsis-v"
                              aria-hidden="true"
                              style={{ cursor: "pointer" }}
                            />
                          }
                          position="bottom right"
                          contentStyle={{
                            width: "165px",
                            height: "100px",
                            background: "#F9F9F9",
                            borderRadius: "10px",
                          }}
                        >
                      <label className="popuptext">Archive</label>
                      <br />
                      <label className="popuptext">Delete </label>
                      <br />
                      <label className="popuptext">Block</label>
                    </Popup>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className={"d-flex justify-content-center pt-5 pb-5"}>
              No Data Found
            </div>
          )}
        </>
      )}
    </>
  );
};
export { Data, clearFilter };
