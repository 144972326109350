import { CLIENTDOMAIN } from "../ENVIRONMENT";

// api for client domain list
function domainList(token) {
  const response = fetch(CLIENTDOMAIN, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { domainList };
