import React, { useEffect, useState } from "react";

function MessageBody(props) {
  // return message right box
  const [match, setMatch] = useState([
    {
      sentBy: 'OTHER',
      type: 'TEMPLATE',
      message: 'Hello'
    },
    {
      sentBy: 'YOU',
      type: 'TEMPLATE',
      message: 'Hiiiiiiii'
    },
    {
      sentBy: 'OTHER',
      type: 'TEMPLATE',
      message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et'
    },
    {
      sentBy: 'YOU',
      type: 'TEMPLATE',
      message: 'Start editing to see some magic happen!'
    }
  ]);
  useEffect(() => {
    async function callUserDetails() {
      try {
        if (props.item.length !== 0) {
          setMatch(props.item);
        }
      } catch { }
    }
    callUserDetails();
  }, [props.item]);
  return (
    <>
      <div className="row">
        {/* <div className="col-10 mt-4 pb-4">
          <label className="message ml-5"></label>
        </div> */}
      </div>
      {match.map((data, index) => (
        <div key={index}>
          {/* <div className="col-1 mt-3">
          <i
            className="fa fa-window-minimize"
            aria-hidden="true"
            style={{ fontWeight: "500", color: "#92929D" }}
          ></i>
        </div>
        <div className="col-1 mt-2 mb-0">
          <label
            style={{ fontWeight: "500", color: "#92929D", fontSize: "30px" }}
          >
            &times;
          </label>
        </div> */}
          <div className="row mt-3 ml-1 mr-2">
            {data.sentBy === "OTHER" ? (
              <div className="col-6">
                <div
                  className="py-2 messagetextborder border-radius-20px message-body-show-message-list-css"
                  // style={{ backgroundColor: "rgba(177,175,255,0.49)", height: "auto" }}
                >
                  {data.type === "TEMPLATE" ? (
                    <label className="messagedata mb-0 mx-3">
                      {data.message.replace(/<[^>]+>/g, "")}
                    </label>
                  ) : (
                    <label className="messagedata mb-0 mx-3">{data.message}</label>
                  )}
                </div>
              </div>
            ) : (
              <div className="col-6 py-2"></div>
            )}
            {data.sentBy === "YOU" ? (
              <div className="col-6">
                <div
                  className="py-2 messagetextborder border-radius-20px float-right message-body-show-message-list-css"
                  // style={{ backgroundColor: "rgba(177,175,255,0.49)", height: "auto" }}
                >
                  {data.type === "TEMPLATE" ? (
                    <label className="messagedata mb-0 mx-3">
                      {data.message.replace(/<[^>]+>/g, "")}
                    </label>
                  ) : (
                    <label className="messagedata mb-0 mx-3">{data.message}</label>
                  )}
                </div>
              </div>
            ) : (
              <div className="col-6 py-2"></div>
            )}
          </div>
        </div>
      ))}
    </>
  );
}
export default React.memo(MessageBody);
