import {READNOTIFICATIONS} from "../ENVIRONMENT";

async function markasReadNotifications(token, id) {
  const response = await fetch(READNOTIFICATIONS, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

  export {markasReadNotifications}