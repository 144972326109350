import React, { useState, useEffect } from "react";
import GoToTop from "../common/GoToTop";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getRequirementInfo } from "../../api/client/getRequirementInfo";
import { freegetRequirementInfo } from "../../api/freelance/client/BuildRequirement/getRequirementInfo";
import { recgetRequirementInfo } from "../../api/recruiter/client/BuildRequirement/getRequirementInfo";
import { Link } from "react-router-dom";

import { Puff } from 'react-loader-spinner';

const JobPreview = () => {
  // return job apply main page
  const param = useParams();
  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);
  const [match, setMatch] = useState();
  try {
    document.getElementById("replacetitleimg").innerText =
      "Job Posting Preview";
    document.getElementById("postjobid").style.display = "none";
  } catch { }
  useEffect(() => {
    async function callUserDetails() {
      try {
        const response = isrecruiterCandidate
          ? await recgetRequirementInfo(token, param.reqID)
          : isFreelance
            ? await freegetRequirementInfo(token, param.reqID)
            : await getRequirementInfo(token, param.reqID);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload.requirement;
          if (payload) {
            setMatch(payload);
          }
        }
      } catch { }
    }
    callUserDetails();
  }, []);

  return (
    <>
      {!match ? (
        // <div
        //   className="row h-100 background-colored-Pale-gray matchingJob-loader-container"
        // >
        //   <div className="col-sm-12 my-auto">
        //     <img
        //       src={"/icon/Gif/loader.gif"}
        //       className={"mx-auto d-block"}
        //       alt={"loader"}
        //     />
        //   </div>
        // </div>
        <div className="loader">
          <div className="svg-wrapper">    <Puff
            height="80"
            width="80"
            color="#3D007A"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          /></div>

        </div>
      ) : (
        <div className={"col mx-lg-5 mx-2"}>
          <div className={"row pt-5 pl-3 pl-lg-0"}>
            <div
              className={"container pl-xl-5 pl-4 pb-4 border-radius-24px background-colored-primary-white"}
            >
              {/* <div className="pt-4 " style={{
            fontFamily: 'Roboto',
            fontSize: '1.4rem',
            fontWeight: '600',
            color: ' #7E73B5',

          }}>Job Posting Preview</div> */}
              <div className="row mt-4">
                <div className="col-md-11 col-9 colored-primary-black font-weight-600 colored-Lavender-slate font-size-15rem "
                // style={{
                //   fontSize: '1.5rem',
                //   color: 'black',
                //   fontWeight: 600
                // }}
                >{match ? match.jobTitle : ''}</div>
                <div className="col-md-1 col-1">

                  {/* <div className="ml-0  " */}
                  <Link to={`/client/postjobpreview/${match ? match.uuid : ''}`}>
                    {/* <label className="btn btn-warning" style={{ color: 'white' }}>Edit</label> */}

                    {/* </div> */}
                    <i className="fas fa-edit  editbtn float-center font-weight-500 cursor-pointer jobPreview-edit-icon-css colored-primary-black font-size-16px " ></i> </Link>
                </div>
              </div>
              <div className={"row mt-2"}>
                <div className={"col-md-10"}>
                  <label
                    className=" font-size-1rem font-weight-600 colored-neutral-gray"
                  // style={{ fontWeight: '600', color: '#989898' }}
                  >
                    Requirement ID - <span className=" font-size-1rem font-weight-600 colored-neutral-gray"
                    // style={{ fontWeight: '600', color: '#989898' }}
                    >{match ? match.ClientRequirementId : ""}</span>

                  </label>
                </div>
                <div className={"col-md-2 col-12 pl-4 pl-md-0"}>
                  {/* <font
                className={"globalfontrule font-size-14px colored-Deep-charcoal"}

              >
                ${match ? match.rate : ""}/{match ? match.rateType : ""}
              </font> */}
                  <br />
                </div>

              </div>
              <div className={"row align-items-center "}>
                <div className={"col-md-8"}>
                  <font
                    className={"globalfontrule font-size-14px  colored-primary-black font-weight-600"}
                  //style={{ fontWeight: '600', color: '#000000' }}

                  >
                    ${match ? match.rate : ""}/{match ? match.rateType : ""}
                  </font>

                  <br />
                  {/* <font
                className={"globalfontrule"}
                style={{ fontSize: "0.9rem", color: "#313131" }}
              >
                {match ? match.jobTitle : ""}
              </font> */}
                  {/* <br /> */}
                  <div className={"row"}>
                    <div className={"col"}>
                      {/* <font
                    className={"globalfontrule font-weight-normal font-size-14px colored-Pale-taupe jobPreview-match-location-padding-top"}

                  >
                    {match ? match.location : ""}
                  </font> */}
                      <font
                        className={"globalfontrule  colored-primary-black font-weight-600 font-size-07rem "}
                      // style={{ fontWeight: '600', color: '#000000' }}

                      >
                        {match ? match.employementType : ""}
                      </font>

                    </div>
                  </div>
                  <div className={"row"}>
                    <div className={"col"}>
                      <font
                        className={"globalfontrule font-weight-normal font-size-14px jobPreview-match-location-padding-top colored-neutral-gray"}
                      // style={{ color: '#989898' }}
                      >
                        {match ? match.location : ""}
                      </font>

                    </div>
                  </div>
                </div>
                {/* <div className={"col-md-4 pl-4"}>
              <font
                className={"globalfontrule"}
                style={{ fontSize: "14px", color: "#313131" }}
              >
                ${match ? match.rate : ""}/{match ? match.rateType : ""}
              </font>
              <br />
            </div> */}
                <div className={"col-md-0"}>
                  {/* <div
                className={"globalfontrule btn starbtn"}
                style={{ fontSize: "12px", color: "#FF8A00" }}
              >
                4.3
              </div> */}
                  {/* <div className="circular-progress">
                <div className="progress-value">4.3</div>
              </div>*/}
                </div>
                {/* <div className={"col-md"}>
                    <Link to={"/client/jobapply"} className={"btn sharejobbtn"}>Share job</Link>
                </div> */}
              </div>
              {/*second row*/}
              <div className={"row  pt-3"}>
                <div className={"col-md"}>
                  <div
                    className={" globalfontrule border-radius-6px font-size-1rem colored-Deep-violet font-size-13rem  font-weight-500 colored-Lavender-slate"}
                  // style={{ fontWeight: 600, color: '#7E73B5', fontSize: '1.3rem' }}
                  >
                    Job Description
                  </div>
                </div>
              </div>
              <div className={"row pt-2"}>
                <div className={"col-md"}>
                  <font className={"parafont font-weight-normal colored-primary-black"}
                  // style={{ fontWeight: "normal", color: '#000000' }}>
                  >
                    <div dangerouslySetInnerHTML={{ __html: match ? match.description : "" }} />
                    {/* {match ? match.description : ""} */}
                  </font>
                </div>
              </div>
              <div className={"row pt-1"}>
                <div className={"col-md"}>
                  <img
                    src="../../icon/candidate/applications/Group348.png"
                    alt={"correction icon"}
                    className={"img-fluid pr-3"}
                    height={30}
                    width={32}
                  />
                  <font className={"parafont font-size-08 font-weight-600"}
                  // style={{ fontWeight: 600 }}
                  >
                    Primary Skill
                  </font>
                </div>
              </div>
              {match
                ? match.ClientPrimarySkills.map((obj, index) => {
                  return (
                    <div className={"row ml-2"} key={index}>
                      <div className={"col-md parafont ml-3"}>
                        <font
                          className=" color-dark-gray font-size-08 font-weight-normal"
                        // style={{ fontWeight: '400' }}
                        >
                          {obj.ClientPrimarySkill}
                        </font>
                      </div>
                    </div>
                  );
                })
                : ""}
              {match && match.ClientSecondarySkills.length !== 0 ? (
                <div className={"row pt-2"}>
                  <div className={"col-md"}>
                    <img
                      src="../../icon/candidate/applications/Group348.png"
                      alt={"correction icon"}
                      className={"img-fluid pr-3"}
                      height={30}
                      width={32}
                    />
                    <font className={"parafont font-size-08 font-weight-600"}>Secondary Skill</font>
                  </div>
                </div>
              ) : (
                ""
              )}
              {match
                ? match.ClientSecondarySkills.map((obj, index) => {
                  return (
                    <div className={"row ml-2"} key={index}>
                      <div className={"col-md parafont ml-3"}>
                        <font
                          className="color-dark-gray font-size-08 font-weight-normal">
                          {obj.ClientSecondarySkill}
                        </font>
                      </div>
                    </div>
                  );
                })
                : ""}
              {match && match.AcademicCertificates.length !== 0 ?
                <div className={"row pt-2"}>
                  <div className={"col-md"}>
                    <img
                      src="../../icon/candidate/applications/Group348.png"
                      alt={"correction icon"}
                      className={"img-fluid pr-3"}
                      height={30}
                      width={32}
                    />
                    <font className={"parafont font-size-08 font-weight-600"} >Academics Qualification</font>
                  </div>
                </div>
                : ""
              }
              {match
                ? match.AcademicCertificates.map((obj, index) => {
                  return (
                    <div className={"row pt-1 ml-2"} key={index}>
                      <div className={"col-md parafont ml-3"}>
                        <font className="color-dark-gray font-size-08 font-weight-normal" >
                          {obj.academicsQualification} - {obj.specialization}
                        </font>
                      </div>
                    </div>
                  );
                })
                : ""}
              {match && match.securityClearanceRequired === "YES" ? (
                <>
                  <div className={"row pt-2"}>
                    <div className={"col-md parafont"}>
                      <img
                        src="../../icon/candidate/applications/Group348.png"
                        alt={"correction icon"}
                        className={"img-fluid pr-3"}
                        height={30}
                        width={32}
                      />
                      <font className={"parafont font-size-08 font-weight-600"} >Security Clearance</font>
                    </div>
                  </div>
                  <div className={"row pt-2 "}>
                    <div className={"col-md ml-3"} >
                      <font className={"parafont font-size-08 font-weight-light color-dark-gray pl-4"} style={{ fontWeight: '400' }} >
                        {match ? match.securityClearanceClassification : ""}
                      </font>
                      <br />
                      <font className={"parafont font-size-08 font-weight-light color-dark-gray pl-4"} style={{ fontWeight: '400' }}>
                        {match ? match.securityClearanceCategory : ""}
                      </font>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <GoToTop />
        </div>
      )}
    </>

  );
};

export default React.memo(JobPreview);
