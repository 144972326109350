import React, { useState, useEffect, useRef } from "react";
import GoToTop from "../../common/GoToTop";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { verifySecurityClearance } from "../../../function/candidate/checkRequired";
import { securityClearance } from "../../../function/candidate/validateField";
import {
  postParseData,
  getParseData,
} from "../../../function/candidate/parseSecurityData";
import { uploadDocument } from "../../../api/candidate/uploadDocument";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { freegetSecurity, freepostSecurity } from "../../../api/freelance/candidate/buildResume/buildResumeSecurity";
import YearOfValidity from "../../common/yearOfValidity";
import GetValidityMonth from "../../common/GetValidityMonth";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
  CreateSignedUrlAPI,
  uploadImage,
} from "../../../api/common/ImageUpload";

const BuildResume4 = (props) => {
  const [redirect, setRedirect] = useState(false);
  const [loader, setLoader] = useState(false);
  const [documentLoader, setDocumentLoader] = useState(false);
  const [workAuthLoader, setWorkAuthLoader] = useState(false);
  const [licenseLoader, setLicenseLoader] = useState(false);
  const [information, setInformation] = useState({
    classification: "",
    category: "",
    year: "",
    month: "",
    comment: "",
    lifeTimeValidity: false,
  });
  const [error, setError] = useState({
    classification: "",
    category: "",
    year: "",
    month: "",
  });

  const [visibility, setVisibility] = useState({
    zero: true,
    one: false,
    two: false,
    zeroin: true,
    onein: true,
    twoin: true,
  });

  const [securityDocument, setSecurityDocument] = useState({
    passport: "",
    workAuth: "",
    drivingLicense: "",
  });
  const [documentError, setDocumentError] = useState({
    passportError: "",
    workAuthError: "",
    drivingLicenseError: "",
  });

  const [socialNavigation, setSocialNavigation] = useState(false);

  const token = useSelector((state) => state.token);
  const securityRef = useRef();
  const param = useParams();
  const id = param.id;
  const resumeRef = useRef();
  const workAuthRef = useRef();
  const licenseRef = useRef();

  const [securityFlag, setSecurityFlag] = useState(false);
  useEffect(() => {
    async function callSelf() {
      try {
        const response = await freegetSecurity(token, id);
        if (response.status === 200) {
          let payload = await response.json();
          payload = payload.payload;
          if (payload.candidate.securityClearance !== null) {
            setSocialNavigation(true);
            document.getElementsByClassName("buildhide")[0].style.display =
              "none";
          }
          getParseData(
            payload,
            visibility,
            setVisibility,
            information,
            setInformation
          );
          setSecurityFlag(true);
        }
      } catch {}
    }
    callSelf();
  }, [securityFlag]);

  useEffect(() => {
    if(!visibility.zeroin){
      setInformation((state) => {
        return {
          ...state,
          classification: "",
          category: "",
          year: "",
          month: "",
          comment: "",
          lifeTimeValidity: false,
        };
      });
      setError((state) => {
        return {
          ...state,
          classification: "",
          category: "",
          year: "",
          month: "",
        };
      });
    }
  }, [visibility.zeroin]);

  useEffect(() => {
    setInformation((state) => {
        return {
          ...state,
          classification1: "",
          category1: "",
          year1: "",
          month1: "",
          comment1: "",
          lifeTimeValidity1: false,
        };
      });
      setError((state) => {
        return {
          ...state,
          classification1: "",
          category1: "",
          year1: "",
          month1: "",
        };
      });
  }, [visibility.one]);

  useEffect(() => {
    setInformation((state) => {
        return {
          ...state,
          classification2: "",
          category2: "",
          year2: "",
          month2: "",
          comment2: "",
          lifeTimeValidity2: false,
        };
      });
      setError((state) => {
        return {
          ...state,
          classification2: "",
          category2: "",
          year2: "",
          month2: "",
        };
      });
  }, [visibility.two]);

  async function handleClick(event) {
    event.preventDefault();
    if (verifySecurityClearance(error, setError, information, visibility)) {
      try {
        if (securityRef.current) {
          securityRef.current.setAttribute("disabled", "disabled");
        }
        setLoader(true);
        const parsedData = postParseData(information, visibility,securityDocument);
        const response = await freepostSecurity(token, parsedData, id);
        if (response.status === 200) {
          setRedirect(true);
        } else {
          securityRef.current.removeAttribute("disabled");
          setLoader(false);
        }
      } catch {}
    }
  }

  const Addsecurityquestion = () => {
    // return security duplicate div
    setVisibility((state) => {
      if (!state.one) return { ...state, one: true,onein: true  };
      else return { ...state, two: true,twoin: true };
    });
  };

  const schema = yup.object().shape({
    picture: yup
      .mixed()
      .test("name", "File is required", (value) => {
        return value[0] && value[0].name !== "";
      })
      .test("fileSize", "The file is too large", (value, context) => {
        return value && value[0] && value[0].size <= 5000000;
      })
      .test("type", "Upload only .doc , .docx. or pdf file", (value) => {
        return value && value[0] && (
            value[0].type === 'application/pdf' ||
            value[0].type === "application/msword" ||
            value[0].type === 'application/doc' ||
            value[0].type === 'application/ms-doc' ||
            value[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        );
      })
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

 //Integration of upload file
 function handlePassport() {
  submitEnabled()
 const file = document.getElementById("uploadDocument").files[0];
 if (file === undefined) {
   setDocumentError({
     ...documentError,
     passportError: "Please select a file",
   });
 } else if (file.size >= 5000000) {
   setDocumentError({
     ...documentError,
     passportError: "File size < 5MB",
   });
 } else if (
   !(
     file.type === "application/pdf" ||
     file.type === "image/jpg" ||
     file.type === "image/png" ||
     file.type === "image/jpeg"
   )
 ) {
   setDocumentError({
     ...documentError,
     passportError: "Please upload a valid file",
   });
 } else {
   setDocumentError({
     ...documentError,
     passportError: "",
   });
   if (resumeRef.current) {
   resumeRef.current.setAttribute("disabled", "disabled");
   }
   setDocumentLoader(true);
   const obj = {
     for: "FREELANCE_RECRUITER",
     files: [
       {
         extension: file.name.split(".")[1],
         contentType: file.type,
         fileName: file.name,
       },
     ],
   };
   try {
     CreateSignedUrlAPI(token, obj)
       .then((result) => {
         const statusCode = result.status;
         const data = result.json();
         return Promise.all([statusCode, data]).then((res) => ({
           statusCode: res[0],
           data: res[1],
         }));
       })
       .then((res) => {
         if (res.statusCode === 200) {
           const promises = res.data?.payload?.signedUrls.map((url) => {
             uploadImage(url.signedUrl, file);
             setSecurityDocument({
               ...securityDocument,
               passport: url.fileUrl
             })
             setDocumentLoader(false);
             resumeRef.current.removeAttribute("disabled");
             return url;
           });
         } else {
           setDocumentLoader(false);
           resumeRef.current.removeAttribute("disabled");
         }
       });
   } catch {}
 }
}

function handleWorkAuth() {
 submitEnabled();
 const file = document.getElementById("uploadWorkAuthDocument").files[0];
 if (file === undefined) {
   setDocumentError({
     ...documentError,
     workAuthError: "Please select a file",
   });
 } else if (file.size >= 5000000) {
   setDocumentError({
     ...documentError,
     workAuthError: "File size < 5MB",
   });
 } else if (
   !(
     file.type === "application/pdf" ||
     file.type === "image/jpg" ||
     file.type === "image/png" ||
     file.type === "image/jpeg"
   )
 ) {
   setDocumentError({
     ...documentError,
     workAuthError: "Please upload a valid file",
   });
 } 
 else {
   setDocumentError({
     ...documentError,
     workAuthError: ""
   })
   if (workAuthRef.current) {
     workAuthRef.current.setAttribute("disabled", "disabled");
   }
   setWorkAuthLoader(true);
   const obj = {
     for: "FREELANCE_RECRUITER",
     files: [
       {
         extension: file.name.split(".")[1],
         contentType: file.type,
         fileName: file.name,
       },
     ],
   };
   try {
     CreateSignedUrlAPI(token, obj)
       .then((result) => {
         const statusCode = result.status;
         const data = result.json();
         return Promise.all([statusCode, data]).then((res) => ({
           statusCode: res[0],
           data: res[1],
         }));
       })
       .then((res) => {
         if (res.statusCode === 200) {
           const promises = res.data?.payload?.signedUrls.map((url) => {
             uploadImage(url.signedUrl, file);
             setSecurityDocument({
               ...securityDocument,
               workAuth: url.fileUrl,
             });
             setWorkAuthLoader(false);
             workAuthRef.current.removeAttribute("disabled");
             return url;
           });
         } else {
           setWorkAuthLoader(false);
           workAuthRef.current.removeAttribute("disabled");
         }
       });
   } catch {}
 }
}

function handleDrivingLicense() {
 submitEnabled();
 const file = document.getElementById("uploadLicenseDocument").files[0];

 if (file === undefined) {
   setDocumentError({
     ...documentError,
     drivingLicenseError: "Please select a file",
   });
 } else if (file.size >= 5000000) {
   setDocumentError({
     ...documentError,
     drivingLicenseError: "File size < 5MB",
   });
 } else if (
   !(
     file.type === "application/pdf" ||
     file.type === "image/jpg" ||
     file.type === "image/png" ||
     file.type === "image/jpeg"
   )
 ) {
   setDocumentError({
     ...documentError,
     drivingLicenseError: "Please upload a valid file",
   });
 } 
 else {
   setDocumentError({
     ...documentError,
     drivingLicenseError: "",
   });
   if (licenseRef.current) {
     licenseRef.current.setAttribute("disabled", "disabled");
   }
   setLicenseLoader(true);
   const obj = {
     for: "FREELANCE_RECRUITER",
     files: [
       {
         extension: file.name.split(".")[1],
         contentType: file.type,
         fileName: file.name,
       },
     ],
   };
   try {
     CreateSignedUrlAPI(token, obj)
       .then((result) => {
         const statusCode = result.status;
         const data = result.json();
         return Promise.all([statusCode, data]).then((res) => ({
           statusCode: res[0],
           data: res[1],
         }));
       })
       .then((res) => {
         if (res.statusCode === 200) {
           const promises = res.data?.payload?.signedUrls.map((url) => {
             uploadImage(url.signedUrl, file);
             setSecurityDocument({
               ...securityDocument,
               drivingLicense: url.fileUrl,
             });
             setLicenseLoader(false);
             licenseRef.current.removeAttribute("disabled");
             return url;
           });
         } else {
           setLicenseLoader(false);
           licenseRef.current.removeAttribute("disabled");
         }
       });
   } catch {}
 }
}

  function submitEnabled() {
    try {
      document.getElementsByClassName("buildhide")[0].style.display = "block";
      setSocialNavigation(false);
    } catch {}
  }

  return (
    <div className={"col"} style={{ height: "100vh" }}>
      <form className={"form-group"} onSubmit={handleClick}>
        <div className={"row pl-5 ml-5"}>
          <div className={"container-fluid container1 pl-5"}>
            <ul className={"progressbar pt-5 mr-2"}>
              <li className={"active"}>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li className={"active"}>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li></li>
            </ul>
          </div>
        </div>
        <div className={"row mx-5 pl-5  resumealignment"}>
          <div className={"col-lg-1"}></div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link
              to={`/freelance/dashboard/buildresume/resumeexperience/${id}`}
            >
              <div
                className={"pl-3 pb-2 pr-3 pt-3"}
                style={{
                  width: "140px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  Experience
                </label>
                <br />
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link
              to={`/freelance/dashboard/buildresume/resumecertificate/${id}`}
            >
              <div
                className={"pt-2 pl-2 pr-2 "}
                style={{
                  width: "140px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#000000",
                  }}
                >
                  Academics
                  <br />& Certificate
                </label>
                <br />
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link to={`/freelance/dashboard/buildresume/resumesecurity/${id}`}>
              <div
                className={"pt-1 pl-2 pr-2"}
                style={{
                  width: "140px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                <div className={"row"}>
                  <div className={"col-lg pb-0"}>
                    <label
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#000000",
                        fontFamily: "poppins",
                      }}
                    >
                      Security Clearance
                    </label>
                  </div>
                </div>
                <div className={"row pl-0"}>
                  <div className={"col-lg"}>
                    <div
                      style={{
                        background: "#3F9AE0",
                        width: "100%",
                        height: "4px",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-3 pb-3"}>
            {socialNavigation ? (
              <Link to={`/freelance/dashboard/buildresume/resumesocial/${id}`}>
                <div
                  className={"pl-3 pb-2 pr-3 pt-3"}
                  style={{
                    width: "140px",
                    borderRadius: "10px",
                    background: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  <label
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#BFBFBF",
                    }}
                  >
                    Social
                  </label>
                  <br />
                </div>
              </Link>
            ) : (
              <div
                className={"pl-3 pb-2 pr-3 pt-3"}
                style={{
                  width: "140px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#BFBFBF",
                  }}
                >
                  Social
                </label>
                <br />
              </div>
            )}
          </div>
        </div>
        {/*second box*/}
        <div className={"pl-1 pr-5 mr-5"}>
          <div className={"row px-5 pr-5 mx-3 pt-3"}>
            <div className={"w-100 pb-4 pt-2 contentholder"}>
              <div className={"row pt-2 pl-4 pr-4"}>
                <div className={"col-md-4 form-inline pt-3"}>
                  <label className={"resumeformquerytext pr-2 pl-2"}>
                    Security Clearance<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio"
                    name="degree0"
                    value="yes"
                    checked={visibility.zeroin}
                    onChange={() => {
                      setVisibility((state) => {
                        return { ...state, zeroin: true };
                      });
                      submitEnabled();
                    }}
                  />
                  <label
                    className="dashboardforminputtext pl-2 pr-3"
                    htmlFor="customRadio"
                  >
                    Yes
                  </label>
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio0"
                    name="degree0"
                    value="no"
                    checked={!visibility.zeroin}
                    onChange={() => {
                      setVisibility((state) => {
                        return { ...state, 
                          zeroin: false,
                          one: false,
                          two: false };
                      });
                      submitEnabled();
                    }}
                  />
                  <label
                    className="dashboardforminputtext pl-2"
                    htmlFor="customRadio0"
                  >
                    No
                  </label>
                </div>
              </div>
              {visibility.zeroin && (
                <div id={"securityclearance"}>
                  <div className={"row mx-3 mt-2"}>
                    <div className={"col-md-4"}>
                      <label
                        htmlFor={"classification"}
                        className={"resumeforminputtext"}
                      >
                        Classification<span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className={
                          "form-control resumeforminputfield form-select inputgray shadow-none"
                        }
                        id={"classification"}
                        name={"classification"}
                        value={information.classification}
                        style={{
                          border: `${
                            error.classification ? "1px solid red" : ""
                          }`,
                        }}
                        onChange={(event) => {
                          securityClearance(
                            event,
                            error,
                            setError,
                            information,
                            setInformation
                          );
                          submitEnabled();
                        }}
                      >
                        <option value={""}>Select</option>
                        <option value={"TOP_SECRET"}>Top Secret</option>
                        <option value={"SECRET"}>Secret</option>
                        <option value={"CONFIDENTIAL"}>Confidential</option>
                      </select>
                      <font className={"dashboardforminputtext text-danger"}>
                        {error.classification}
                      </font>
                    </div>
                    <div className={"col-md-4"}>
                      <label
                        htmlFor={"category"}
                        className={"resumeforminputtext"}
                      >
                        Category<span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className={
                          "form-control resumeforminputfield form-select inputgray shadow-none"
                        }
                        id={"category"}
                        name={"category"}
                        value={information.category}
                        style={{
                          border: `${error.category ? "1px solid red" : ""}`,
                        }}
                        onChange={(event) => {
                          securityClearance(
                            event,
                            error,
                            setError,
                            information,
                            setInformation
                          );
                          submitEnabled();
                        }}
                      >
                        <option value={""}>Select</option>
                        <option value={"SCI"}>SCI</option>
                        <option value={"SAPS"}>SAPS</option>
                        <option value={"RD"}>RD</option>
                        <option value={"FRD"}>FRD</option>
                        <option value={"NSI"}>NSI</option>
                      </select>
                      <font className={"dashboardforminputtext text-danger"}>
                        {error.category}
                      </font>
                    </div>
                  </div>
                  <div className={"row pt-2 mx-3"}>
                  <div className={"col-md-4"}>
                      <label
                        htmlFor={"experience"}
                        className={"resumeforminputtext"}
                      >
                        Validity<span style={{ color: "red" }}>*</span>
                      </label>
                      <div
                        className={"dropdownforminputfield"}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          border: `${
                            error.year || error.month ? "1px solid red" : ""
                          }`,
                        }}
                      >
                        <select
                          className={
                            "form-control form-select dropgray1 shadow-none"
                          }
                          id={"pexp"}
                          name={"month"}
                          value={information.month}
                          onChange={(event) => {
                            securityClearance(
                              event,
                              error,
                              setError,
                              information,
                              setInformation
                            );
                            submitEnabled();
                          }}
                        >
                          <option value={""}>Month</option>
                          <GetValidityMonth />
                        </select>
                        <select
                          className={
                            "form-control form-select dropgray shadow-none ml-0"
                          }
                          id={"pexp"}
                          name={"year"}
                          value={information.year}
                          onChange={(event) => {
                            securityClearance(
                              event,
                              error,
                              setError,
                              information,
                              setInformation
                            );
                            submitEnabled();
                          }}
                        >
                          <option value={""}>Year</option>
                          <YearOfValidity />
                        </select>
                      </div>
                      <font className={"dashboardforminputtext text-danger"}>
                        {error.year}
                      </font>
                      <font className={"dashboardforminputtext text-danger"}>
                        {error.month}
                      </font>
                    </div>
                    <div className={"col-md-4 form-inline mt-4"}>
                      <input
                        type="checkbox"
                        style={{ width: "18px", height: "18px" }}
                        checked={information.lifeTimeValidity}
                        onChange={() => {
                          setInformation((state) => {
                            return {
                              ...state,
                              lifeTimeValidity: !information.lifeTimeValidity,
                            };
                          });
                        }}
                      />
                      <label className={"dashboardforminputtext pr-2 pl-3"}>
                        Lifetime Validity
                      </label>
                    </div>
                  </div>
                  <div className={"row pt-2 mx-3"}>
                    <div className={"col-md-8"}>
                      <label
                        htmlFor={"information"}
                        className={"resumeforminputtext"}
                      >
                        Add Comments If Any
                      </label>
                      <input
                        type={"text"}
                        placeholder={""}
                        id={"information"}
                        value={information.comment}
                        onChange={(event) => {
                          setInformation({
                            ...information,
                            comment: event.currentTarget.value,
                          });
                          submitEnabled();
                        }}
                        className={
                          "form-control resumeforminputfield shadow-none"
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/*new div flow here*/}
        {visibility.one && (
          <div className={"pl-1 pr-5 mr-5"}>
            <div className={"row px-5 pr-5 mx-3 pt-3"}>
              <div className={"w-100 pb-2 pt-2 contentholder"}>
                {visibility.onein && (
                  <div id={"securityclearance"}>
                    <div className={"row mx-3 mt-2"}>
                      <div className={"col-md-4"}>
                        <label
                          htmlFor={"classification"}
                          className={"resumeforminputtext"}
                        >
                          Classification<span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          className={
                            "form-control resumeforminputfield form-select inputgray shadow-none"
                          }
                          id={"classification"}
                          name={"classification1"}
                          value={information.classification1}
                          style={{
                            border: `${
                              error.classification1 ? "1px solid red" : ""
                            }`,
                          }}
                          onChange={(event) => {
                            securityClearance(
                              event,
                              error,
                              setError,
                              information,
                              setInformation
                            );
                            submitEnabled();
                          }}
                        >
                          <option value={""}>Select</option>
                          <option value={"TOP_SECRET"}>Top Secret</option>
                          <option value={"SECRET"}>Secret</option>
                          <option value={"CONFIDENTIAL"}>Confidential</option>
                        </select>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.classification1}
                        </font>
                      </div>
                      <div className={"col-md-4"}>
                        <label
                          htmlFor={"category"}
                          className={"resumeforminputtext"}
                        >
                          Category<span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          className={
                            "form-control resumeforminputfield form-select inputgray shadow-none"
                          }
                          id={"category"}
                          name={"category1"}
                          value={information.category1}
                          style={{
                            border: `${error.category1 ? "1px solid red" : ""}`,
                          }}
                          onChange={(event) => {
                            securityClearance(
                              event,
                              error,
                              setError,
                              information,
                              setInformation
                            );
                            submitEnabled();
                          }}
                        >
                          <option value={""}>Select</option>
                          <option value={"SCI"}>SCI</option>
                          <option value={"SAPS"}>SAPS</option>
                          <option value={"RD"}>RD</option>
                          <option value={"FRD"}>FRD</option>
                          <option value={"NSI"}>NSI</option>
                        </select>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.category1}
                        </font>
                      </div>
                    </div>
                    <div className={"row pt-2 mx-3"}>
                    <div className={"col-md-4"}>
                        <label
                          htmlFor={"experience"}
                          className={"resumeforminputtext"}
                        >
                          Validity<span style={{ color: "red" }}>*</span>
                        </label>
                        <div
                          className={"dropdownforminputfield"}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            border: `${
                              error.year1 || error.month1 ? "1px solid red" : ""
                            }`,
                          }}
                        >
                          <select
                            className={
                              "form-control form-select dropgray1 shadow-none"
                            }
                            id={"pexp"}
                            name={"month1"}
                            value={information.month1}
                            onChange={(event) => {
                              securityClearance(
                                event,
                                error,
                                setError,
                                information,
                                setInformation
                              );
                              submitEnabled();
                            }}
                          >
                            <option value={""}>Month</option>
                            <GetValidityMonth />
                          </select>
                          <select
                            className={
                              "form-control form-select dropgray shadow-none ml-0"
                            }
                            id={"pexp"}
                            name={"year1"}
                            value={information.year1}
                            onChange={(event) => {
                              securityClearance(
                                event,
                                error,
                                setError,
                                information,
                                setInformation
                              );
                              submitEnabled();
                            }}
                          >
                            <option value={""}>Year</option>
                            <YearOfValidity />
                          </select>
                        </div>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.year1}
                        </font>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.month1}
                        </font>
                      </div>
                      <div className={"col-md-4 form-inline mt-4"}>
                        <input
                          type="checkbox"
                          style={{ width: "18px", height: "18px" }}
                          checked={information.lifeTimeValidity1}
                          onChange={() => {
                            setInformation((state) => {
                              return {
                                ...state,
                                lifeTimeValidity1:
                                  !information.lifeTimeValidity1,
                              };
                            });
                          }}
                        />
                        <label className={"dashboardforminputtext pr-2 pl-3"}>
                          Lifetime Validity
                        </label>
                      </div>
                    </div>
                    <div className={"row pt-2 mx-3"}>
                      <div className={"col-md-8 "}>
                        <label
                          htmlFor={"information"}
                          className={"resumeforminputtext"}
                        >
                          Add Comments If Any
                        </label>
                        <input
                          type={"text"}
                          placeholder={"Java Backend"}
                          value={information.comment1}
                          onChange={(event) => {
                            setInformation({
                              ...information,
                              comment1: event.currentTarget.value,
                            });
                            submitEnabled();
                          }}
                          id={"information reqfalse"}
                          className={
                            "form-control resumeforminputfield shadow-none"
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className={"row"}>
                  <div className={"col-md"}>
                    <div
                      className={"btn offset-sm-10 pl-5 badge  cancelskill"}
                      onClick={() => {
                        setVisibility((state) => {
                          return { ...state, one: false };
                        });
                        submitEnabled();
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/*another duplicate*/}
        {visibility.two && (
          <div className={"pl-1 pr-5 mr-5"}>
            <div className={"row px-5 pr-5 mx-3 pt-3"}>
              <div className={"w-100 pb-2 pt-2 contentholder"}>
                {visibility.twoin && (
                  <div id={"securityclearance"}>
                    <div className={"row mx-3 mt-2"}>
                      <div className={"col-md-4"}>
                        <label
                          htmlFor={"classification"}
                          className={"resumeforminputtext"}
                        >
                          Classification<span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          className={
                            "form-control resumeforminputfield form-select inputgray shadow-none"
                          }
                          id={"classification"}
                          name={"classification2"}
                          value={information.classification2}
                          style={{
                            border: `${
                              error.classification2 ? "1px solid red" : ""
                            }`,
                          }}
                          onChange={(event) => {
                            securityClearance(
                              event,
                              error,
                              setError,
                              information,
                              setInformation
                            );
                            submitEnabled();
                          }}
                        >
                          <option value={""}>Select</option>
                          <option value={"TOP_SECRET"}>Top Secret</option>
                          <option value={"SECRET"}>Secret</option>
                          <option value={"CONFIDENTIAL"}>Confidential</option>
                        </select>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.classification2}
                        </font>
                      </div>
                      <div className={"col-md-4"}>
                        <label
                          htmlFor={"category"}
                          className={"resumeforminputtext"}
                        >
                          Category<span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          className={
                            "form-control resumeforminputfield form-select inputgray shadow-none"
                          }
                          id={"category"}
                          name={"category2"}
                          value={information.category2}
                          style={{
                            border: `${error.category2 ? "1px solid red" : ""}`,
                          }}
                          onChange={(event) => {
                            securityClearance(
                              event,
                              error,
                              setError,
                              information,
                              setInformation
                            );
                            submitEnabled();
                          }}
                        >
                          <option value={""}>Select</option>
                          <option value={"SCI"}>SCI</option>
                          <option value={"SAPS"}>SAPS</option>
                          <option value={"RD"}>RD</option>
                          <option value={"FRD"}>FRD</option>
                          <option value={"NSI"}>NSI</option>
                        </select>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.category2}
                        </font>
                      </div>
                    </div>
                    <div className={"row pt-2 mx-3"}>
                    <div className={"col-md-4"}>
                        <label
                          htmlFor={"experience"}
                          className={"resumeforminputtext"}
                        >
                          Validity<span style={{ color: "red" }}>*</span>
                        </label>
                        <div
                          className={"dropdownforminputfield"}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            border: `${
                              error.year2 || error.month2 ? "1px solid red" : ""
                            }`,
                          }}
                        >
                          <select
                            className={
                              "form-control form-select dropgray1 shadow-none"
                            }
                            id={"pexp"}
                            name={"month2"}
                            value={information.month2}
                            onChange={(event) => {
                              securityClearance(
                                event,
                                error,
                                setError,
                                information,
                                setInformation
                              );
                              submitEnabled();
                            }}
                          >
                            <option value={""}>Month</option>
                            <GetValidityMonth />
                          </select>   
                          <select
                            className={
                              "form-control form-select dropgray shadow-none ml-0"
                            }
                            id={"pexp"}
                            name={"year2"}
                            value={information.year2}
                            onChange={(event) => {
                              securityClearance(
                                event,
                                error,
                                setError,
                                information,
                                setInformation
                              );
                              submitEnabled();
                            }}
                          >
                            <option value={""}>Year</option>
                            <YearOfValidity />
                          </select>
                        </div>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.year2}
                        </font>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.month2}
                        </font>
                      </div>
                      <div className={"col-md-4 form-inline mt-4"}>
                        <input
                          type="checkbox"
                          style={{ width: "18px", height: "18px" }}
                          checked={information.lifeTimeValidity2}
                          onChange={() => {
                            setInformation((state) => {
                              return {
                                ...state,
                                lifeTimeValidity2:
                                  !information.lifeTimeValidity2,
                              };
                            });
                          }}
                        />
                        <label className={"dashboardforminputtext pr-2 pl-3"}>
                          Lifetime Validity
                        </label>
                      </div>
                    </div>
                    <div className={"row pt-2 mx-3"}>
                      <div className={"col-md-8 "}>
                        <label
                          htmlFor={"information"}
                          className={"resumeforminputtext"}
                        >
                          Add Comments If Any
                        </label>
                        <input
                          type={"text"}
                          placeholder={""}
                          id={"information reqfalse"}
                          value={information.comment2}
                          onChange={(event) => {
                            setInformation({
                              ...information,
                              comment2: event.currentTarget.value,
                            });
                            submitEnabled();
                          }}
                          className={
                            "form-control resumeforminputfield shadow-none"
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className={"row"}>
                  <div className={"col-md"}>
                    <div
                      className={"btn offset-sm-10 pl-5 badge  cancelskill"}
                      onClick={() => {
                        setVisibility((state) => {
                          return { ...state, two: false };
                        });
                        submitEnabled();
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/*end here*/}
        {visibility.zeroin && !(visibility.one && visibility.two) && (
          <div className={"row pt-3"}>
            <div className={"col-md"}>
              <div
                className={
                  "btn offset-sm-9 bg-transparent badge border-0  newskill hideaddmore "
                }
                onClick={Addsecurityquestion}
              >
                + Add More
              </div>
            </div>
          </div>
        )}
        {/* new div for upload document */}
        <div className={"pl-1 pr-5 mr-5"}>
          <div className={"row px-5 pr-5 mx-3 pt-3"}>
            <div className={"w-100 pb-2 pt-2 contentholder"}>
              <div className={"row mx-3 mt-2"}>
                <div className={"col-md-4"}>
                  <label className={"resumeforminputtext"}>
                    Upload Passport{" "}
                  </label>
                </div>
              </div>
              <div className={"row mx-3"}>
                <div className={"col-md-4 pb-2"}>
                  <input
                    type={"text"}
                    className={
                      "form-control resumeuploadtextbox py-3 shadow-none ellipsis"
                    }
                    placeholder={
                      information.passport
                        ? information.passport.name
                        : "Upload Resume"
                    }
                    disabled
                  />
                </div>
                <div className={"col-md-1  pl-3"}>
                  <label className={"browsebtn btn"}>
                    <input
                      type="file"
                      accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      style={{ opacity: 0, position: "absolute" }}
                      name="picture"
                      {...register("picture")}
                      id={"uploadDocument"}
                      onChange={(event) => {
                        setInformation({
                          ...information,
                          passport: event.target.files[0],
                        });
                        submitEnabled();
                      }}
                    />
                    <span>Browse</span>
                  </label>
                </div>
                <div className={"col-md-3 pr-0"}>
                  <button
                    type={"button"}
                    className={"btn uploadbtn"}
                    ref={resumeRef}
                    onClick={handlePassport}
                  >
                    {documentLoader ? (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ padding: "3px 10px" }}
                      ></i>
                    ) : (
                      ""
                    )}
                    Upload Document
                  </button>
                </div>
                {/* <div className={"col-md pl-0"}>
                  <button
                  type={"button"}
                    className={"btn bg-transparent cancelskill"}
                    onClick={() => {
                      handelDelete()
                      submitEnabled()
                    }}
                  >
                    Cancel
                  </button>
                </div> */}
              </div>
              <div className={"row mx-1"}>
                <div className={"row mx-3"}>
                  <div className={"col-md"}>
                    <label className={"resumeforminputtext"}>
                      Supporting file format * PDF * JPG * JPEG * PNG
                      <br />
                    </label>
                  </div>
                </div>
                <div className="col-md mx-5">
                  {documentError.passportError && (
                    <p className="errormsg">{documentError.passportError}</p>
                  )}
                  <label
                    className={"fileerr errormsg"}
                    style={{ fontSize: "14px" }}
                  ></label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"pl-1 pr-5 mr-5"}>
          <div className={"row px-5 pr-5 mx-3 pt-3"}>
            <div className={"w-100 pb-2 pt-2 contentholder"}>
              <div className={"row mx-3 mt-2"}>
                <div className={"col-md-4"}>
                  <label className={"resumeforminputtext"}>
                    Upload Work Auth{" "}
                  </label>
                </div>
              </div>
              <div className={"row mx-3"}>
                <div className={"col-md-4 pb-2"}>
                  <input
                    type={"text"}
                    className={
                      "form-control resumeuploadtextbox py-3 shadow-none ellipsis"
                    }
                    placeholder={
                      information.workAuth
                        ? information.workAuth.name
                        : "Upload Resume"
                    }
                    disabled
                  />
                </div>
                <div className={"col-md-1  pl-3"}>
                  <label className={"browsebtn btn"}>
                    <input
                      type="file"
                      accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      style={{ opacity: 0, position: "absolute" }}
                      name="workAuthPicture"
                      {...register("workAuthPicture")}
                      id={"uploadWorkAuthDocument"}
                      onChange={(event) => {
                        setInformation({
                          ...information,
                          workAuth: event.target.files[0],
                        });
                        submitEnabled();
                      }}
                    />
                    <span>Browse</span>
                  </label>
                </div>
                <div className={"col-md-3 pr-0"}>
                  <button
                    type={"button"}
                    className={"btn uploadbtn"}
                    ref={workAuthRef}
                    onClick={handleWorkAuth}
                  >
                    {workAuthLoader ? (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ padding: "3px 10px" }}
                      ></i>
                    ) : (
                      ""
                    )}
                    Upload Document
                  </button>
                </div>
                {/* <div className={"col-md pl-0"}>
                  <button
                  type={"button"}
                    className={"btn bg-transparent cancelskill"}
                    onClick={() => {
                      handelDelete()
                      submitEnabled()
                    }}
                  >
                    Cancel
                  </button>
                </div> */}
              </div>
              <div className={"row mx-1"}>
                <div className={"row mx-3"}>
                  <div className={"col-md"}>
                    <label className={"resumeforminputtext"}>
                      Supporting file format * PDF * JPG * JPEG * PNG
                    </label>
                  </div>
                </div>
                <div className="col-md mx-5">
                  {documentError.workAuthError && (
                    <p className="errormsg">{documentError.workAuthError}</p>
                  )}
                  <label
                    className={"fileerr errormsg"}
                    style={{ fontSize: "14px" }}
                  ></label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"pl-1 pr-5 mr-5"}>
          <div className={"row px-5 pr-5 mx-3 pt-3"}>
            <div className={"w-100 pb-2 pt-2 contentholder"}>
              <div className={"row mx-3 mt-2"}>
                <div className={"col-md-4"}>
                  <label className={"resumeforminputtext"}>
                    Upload Driving License{" "}
                  </label>
                </div>
              </div>
              <div className={"row mx-3"}>
                <div className={"col-md-4 pb-2"}>
                  <input
                    type={"text"}
                    className={
                      "form-control resumeuploadtextbox py-3 shadow-none ellipsis"
                    }
                    placeholder={
                      information.drivingLicense
                        ? information.drivingLicense.name
                        : "Upload Resume"
                    }
                    disabled
                  />
                </div>
                <div className={"col-md-1  pl-3"}>
                  <label className={"browsebtn btn"}>
                    <input
                      type="file"
                      accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      style={{ opacity: 0, position: "absolute" }}
                      name="licensePicture"
                      {...register("licensePicture")}
                      id={"uploadLicenseDocument"}
                      onChange={(event) => {
                        setInformation({
                          ...information,
                          drivingLicense: event.target.files[0],
                        });
                        submitEnabled();
                      }}
                    />
                    <span>Browse</span>
                  </label>
                </div>
                <div className={"col-md-3 pr-0"}>
                  <button
                    type={"button"}
                    className={"btn uploadbtn"}
                    ref={licenseRef}
                    onClick={handleDrivingLicense}
                  >
                    {licenseLoader ? (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ padding: "3px 10px" }}
                      ></i>
                    ) : (
                      ""
                    )}
                    Upload Document
                  </button>
                </div>
                {/* <div className={"col-md pl-0"}>
                  <button
                  type={"button"}
                    className={"btn bg-transparent cancelskill"}
                    onClick={() => {
                      handelDelete()
                      submitEnabled()
                    }}
                  >
                    Cancel
                  </button>
                </div> */}
              </div>
              <div className={"row mx-1"}>
                <div className={"row mx-3"}>
                  <div className={"col-md"}>
                    <label className={"resumeforminputtext"}>
                      Supporting file format * PDF * JPG * JPEG * PNG
                    </label>
                  </div>
                </div>
                <div className="col-md mx-5">
                  {documentError.drivingLicenseError && (
                    <p className="errormsg">{documentError.drivingLicenseError}</p>
                  )}
                  <label
                    className={"fileerr errormsg"}
                    style={{ fontSize: "14px" }}
                  ></label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end here */}
        <div className={"row hidebox"}>
          <div className={"col pt-2 pb-2 pr-5"}>
            {socialNavigation ? (
              <>
                {redirect ? (
                  <Redirect
                    to={`/freelance/dashboard/buildresume/resumesocial/${id}`}
                  />
                ) : (
                  <button
                    type="submit"
                    className={"btn save offset-sm-9 buildhide"}
                    ref={securityRef}
                  >
                    {loader ? (
                      <i
                        className = "fa fa-refresh fa-spin"
                        style={{ padding: "3px 5px" }}
                      ></i>
                    ) : (
                      ""
                    )}
                    Save & Continue
                  </button>
                )}
              </>
            ) : (
              <>
                {redirect ? (
                  <Redirect
                    to={`/freelance/dashboard/buildresume/resumesocial/${id}`}
                  />
                ) : (
                  <button
                    type="submit"
                    className={"btn save offset-sm-9"}
                    ref={securityRef}
                  >
                    {loader ? (
                      <i
                        className = "fa fa-refresh fa-spin"
                        style={{ padding: "3px 5px" }}
                      ></i>
                    ) : (
                      ""
                    )}
                    Save & Continue
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </form>
      <GoToTop />
    </div>
  );
};
export default React.memo(BuildResume4);
