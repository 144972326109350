import React from "react";
function YearOfPassing() {
  const option = [];
  const year = new Date().getFullYear();
  const expYear = year - 80;
  for (let i = year; expYear <= i; i--) {
    option.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }
  return option;
}

export default React.memo(YearOfPassing);
