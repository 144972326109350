import { CLIENTBUILDREQUIREMENT } from "../ENVIRONMENT";

// api for submit client snapshot
async function snapshot(token, id) {
  return await fetch(CLIENTBUILDREQUIREMENT + `/${id}/finish`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
}

export { snapshot };
