import { RECRUITERCLIENTREQUIREMENT } from "../../ENVIRONMENT";

// api for recruiter download requirement
async function recdownloadRequirement(token, id) {
  const response = await fetch(RECRUITERCLIENTREQUIREMENT + `/download/${id}`, {
    observe: "response",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
      "Content-Type": "text/csv",
    },
  });
  return response;
}

export { recdownloadRequirement };
