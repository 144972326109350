import "../../styles/app.scss";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Data } from "../../function/client/Matchingjob";
import GoToTop from "../common/GoToTop";
import { useSelector } from "react-redux";
import { useMediaQuery } from 'react-responsive';

import {
  ArchiveRequirements,
  DashboardActiveJobs,
  DashboardCardRequirment,
  DashboardRequirment,
} from "../../api/client/dashboardRequirement";
import {
  activeJobSorting,
  archiveJobSorting,
  requirementCardSorting,
  requirementSorting,
} from "../../api/client/requirementSorting";
import {
  deleteAssociatedRequirment,
  deleteRequirment,
} from "../../api/client/deleteRequirement";
import { snapshot } from "../../api/client/snapshot";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { duplicateRequirement } from "../../api/client/duplicateRequirement";
import { downloadRequirement } from "../../api/client/downloadRequirement";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  recArchiveRequirements,
  recDashboardActiveJobs,
  recDashboardCardRequirment,
  recDashboardRequirment,
} from "../../api/recruiter/client/dashboardRequirement";
import {
  freeArchiveRequirements,
  freeDashboardActiveJobs,
  freeDashboardCardRequirment,
  freeDashboardRequirment,
} from "../../api/freelance/client/dashboardRequirement";
import {
  recactiveJobSorting,
  recarchiveJobSorting,
  recrequirementCardSorting,
  recrequirementSorting,
} from "../../api/recruiter/client/requirementSorting";
import {
  freeactiveJobSorting,
  freearchiveJobSorting,
  freerequirementCardSorting,
  freerequirementSorting,
} from "../../api/freelance/client/requirementSorting";
import {
  recdeleteAssociatedRequirment,
  recdeleteRequirment,
} from "../../api/recruiter/client/deleteRequirement";
import {
  freedeleteAssociatedRequirment,
  freedeleteRequirment,
} from "../../api/freelance/client/deleteRequirement";
import { recduplicateRequirement } from "../../api/recruiter/client/duplicateRequirement";
import { freeduplicateRequirement } from "../../api/freelance/client/duplicateRequirement";
import { recdownloadRequirement } from "../../api/recruiter/client/downloadRequirement";
import { freedownloadRequirement } from "../../api/freelance/client/downloadRequirement";
import { FaThLarge, FaBars } from 'react-icons/fa'; // Import icons
import { FaGripHorizontal, FaListUl } from 'react-icons/fa';
import { useDispatch } from "react-redux";
import Switch from 'react-switch';
import TableChartIcon from '@mui/icons-material/TableChart';
import GridViewIcon from '@mui/icons-material/GridView';

const Requirement = () => {
  // return requirement page
  const dispatch = useDispatch();
  dispatch({ type: "newuserfalse" });
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let cardName = query.get("title");
  try {
    if (cardName === "archive") {
      document.getElementById("replacetitleimg").innerText = "Archive";
    } else {
      document.getElementById("replacetitleimg").innerText = "Requirements";
    }
  } catch { }
  const [loading, setLoading] = useState();
  const [item, setItem] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState();
  const [order, setOrder] = useState(true);
  const [idOrder, setIdOrder] = useState(true);
  const [totalLength, setTotalLength] = useState();
  const [record, setRecord] = useState();
  const [archiveModal, setArchiveModal] = useState(false);
  const [archiveErrmsg, setArchiveErrmsg] = useState();
  const [archiveId, setArchiveId] = useState();
  const [isGridView, setIsGridView] = useState(false);

  const history = useHistory();
  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);
  useEffect(() => {
    async function callUserDetails() {
      try {
        setLoading(true);
        if (cardName === "active") {
          const response = isrecruiterCandidate
            ? await recDashboardActiveJobs(token, 1)
            : isFreelance
              ? await freeDashboardActiveJobs(token, 1)
              : await DashboardActiveJobs(token, 1);
          if (response.status === 200) {
            const data = await response.json();
            const payload = data.payload;
            const totalRecord = payload[payload.length - 1].totalRecord;
            setRecord(totalRecord);
            setItem(payload.slice(0, -1));
            setLoading(false);
            setPageCount(Math.ceil(payload[6].totalRecord / 6));
            setTotalLength(payload[6].totalRecord);
          }
        } else if (cardName === "archive") {
          const response = isrecruiterCandidate
            ? await recArchiveRequirements(token, 1)
            : isFreelance
              ? await freeArchiveRequirements(token, 1)
              : await ArchiveRequirements(token, 1);
          if (response.status === 200) {
            const data = await response.json();
            const payload = data.payload;
            const totalRecord = payload[payload.length - 1].totalRecord;
            setRecord(totalRecord);
            setItem(payload.slice(0, -1));
            setLoading(false);
            setPageCount(Math.ceil(payload[6].totalRecord / 6));
            setTotalLength(payload[6].totalRecord);
          }
        } else if (cardName === "scheduled" || cardName === "shortlisted") {
          const response = isrecruiterCandidate
            ? await recDashboardCardRequirment(token, 1, cardName)
            : isFreelance
              ? await freeDashboardCardRequirment(token, 1, cardName)
              : await DashboardCardRequirment(token, 1, cardName);
          if (response.status === 200) {
            const data = await response.json();
            const payload = data.payload;
            const totalRecord = payload[payload.length - 1].totalRecord;
            setRecord(totalRecord);
            setItem(payload.slice(0, -1));
            setLoading(false);
            setPageCount(Math.ceil(payload[6].totalRecord / 6));
            setTotalLength(payload[6].totalRecord);
          }
        }
        else {
          const response = isrecruiterCandidate
            ? await recDashboardRequirment(token, 1)
            : isFreelance
              ? await freeDashboardRequirment(token, 1)
              : await DashboardRequirment(token, 1);
          if (response.status === 200) {
            const data = await response.json();
            const payload = data.payload;
            const totalRecord = payload[payload.length - 1].totalRecord;
            setRecord(totalRecord);
            setItem(payload.slice(0, -1));
            setLoading(false);
            setPageCount(Math.ceil(payload[6].totalRecord / 6));
            setTotalLength(payload[6].totalRecord);
          }
        }
      } catch { }
    }
    callUserDetails();
  }, []);

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPage(data.selected);
    if (cardName === "active") {
      const response = isrecruiterCandidate
        ? await recDashboardActiveJobs(token, currentPage)
        : isFreelance
          ? await freeDashboardActiveJobs(token, currentPage)
          : await DashboardActiveJobs(token, currentPage);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        setItem(payload.slice(0, -1));
      }
    } else if (cardName === "archive") {
      const response = isrecruiterCandidate
        ? await recArchiveRequirements(token, currentPage)
        : isFreelance
          ? await freeArchiveRequirements(token, currentPage)
          : await ArchiveRequirements(token, currentPage);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        setItem(payload.slice(0, -1));
      }
    } else if (cardName === "scheduled" || cardName === "shortlisted") {
      const response = isrecruiterCandidate
        ? await recDashboardCardRequirment(token, 1, cardName)
        : isFreelance
          ? await freeDashboardCardRequirment(token, 1, cardName)
          : await DashboardCardRequirment(token, 1, cardName);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        const totalRecord = payload[payload.length - 1].totalRecord;
        setRecord(totalRecord);
        setItem(payload.slice(0, -1));
        setLoading(false);
        setPageCount(Math.ceil(payload[6].totalRecord / 6));
        setTotalLength(payload[6].totalRecord);
      }
    } else {
      const response = isrecruiterCandidate
        ? await recDashboardRequirment(token, currentPage)
        : isFreelance
          ? await freeDashboardRequirment(token, currentPage)
          : await DashboardRequirment(token, currentPage);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        setItem(payload.slice(0, -1));
      }
    }
  };

  const handleRateSorting = async () => {
    let rate = "rate";
    let high = "high";
    let low = "low";
    setPage(0);
    if (cardName === "active") {
      if (order) {
        const response = isrecruiterCandidate
          ? await recactiveJobSorting(token, 1, rate, high)
          : isFreelance
            ? await freeactiveJobSorting(token, 1, rate, high)
            : await activeJobSorting(token, 1, rate, high);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
        }
      } else {
        const response = isrecruiterCandidate
          ? await recactiveJobSorting(token, 1, rate, low)
          : isFreelance
            ? await freeactiveJobSorting(token, 1, rate, low)
            : await activeJobSorting(token, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
        }
      }
    } else if (cardName === "archive") {
      if (order) {
        const response = isrecruiterCandidate
          ? await recarchiveJobSorting(token, 1, rate, high)
          : isFreelance
            ? await freearchiveJobSorting(token, 1, rate, high)
            : await archiveJobSorting(token, 1, rate, high);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
        }
      } else {
        const response = isrecruiterCandidate
          ? await recarchiveJobSorting(token, 1, rate, low)
          : isFreelance
            ? await freearchiveJobSorting(token, 1, rate, low)
            : await archiveJobSorting(token, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
        }
      }
    } else if (cardName === "scheduled" || cardName === "shortlisted") {
      if (order) {
        const response = isrecruiterCandidate
          ? await recrequirementCardSorting(token, 1, rate, high, cardName)
          : isFreelance
            ? await freerequirementCardSorting(token, 1, rate, high, cardName)
            : await requirementCardSorting(token, 1, rate, high, cardName);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
        }
      } else {
        const response = isrecruiterCandidate
          ? await recrequirementCardSorting(token, 1, rate, low, cardName)
          : isFreelance
            ? await freerequirementCardSorting(token, 1, rate, low, cardName)
            : await requirementCardSorting(token, 1, rate, low, cardName);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
        }
      }
    } else {
      if (order) {
        const response = isrecruiterCandidate
          ? await recrequirementSorting(token, 1, rate, high)
          : isFreelance
            ? await freerequirementSorting(token, 1, rate, high)
            : await requirementSorting(token, 1, rate, high);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
        }
      } else {
        const response = isrecruiterCandidate
          ? await recrequirementSorting(token, 1, rate, low)
          : isFreelance
            ? await freerequirementSorting(token, 1, rate, low)
            : await requirementSorting(token, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
        }
      }
    }
  };

  const handleIdSorting = async () => {
    let rate = "candidateId";
    let high = "high";
    let low = "low";
    setPage(0);
    if (cardName === "active") {
      if (idOrder) {
        const response = isrecruiterCandidate
          ? await recactiveJobSorting(token, 1, rate, high)
          : isFreelance
            ? await freeactiveJobSorting(token, 1, rate, high)
            : await activeJobSorting(token, 1, rate, high);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
        }
      } else {
        const response = isrecruiterCandidate
          ? await recactiveJobSorting(token, 1, rate, low)
          : isFreelance
            ? await freeactiveJobSorting(token, 1, rate, low)
            : await activeJobSorting(token, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
        }
      }
    } else if (cardName === "archive") {
      if (idOrder) {
        const response = isrecruiterCandidate
          ? await recarchiveJobSorting(token, 1, rate, high)
          : isFreelance
            ? await freearchiveJobSorting(token, 1, rate, high)
            : await archiveJobSorting(token, 1, rate, high);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
        }
      } else {
        const response = isrecruiterCandidate
          ? await recarchiveJobSorting(token, 1, rate, low)
          : isFreelance
            ? await freearchiveJobSorting(token, 1, rate, low)
            : await archiveJobSorting(token, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
        }
      }
    } else if (cardName === "scheduled" || cardName === "shortlisted") {
      if (idOrder) {
        const response = isrecruiterCandidate
          ? await recrequirementCardSorting(token, 1, rate, high, cardName)
          : isFreelance
            ? await freerequirementCardSorting(token, 1, rate, high, cardName)
            : await requirementCardSorting(token, 1, rate, high, cardName);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
        }
      } else {
        const response = isrecruiterCandidate
          ? await recrequirementCardSorting(token, 1, rate, low, cardName)
          : isFreelance
            ? await freerequirementCardSorting(token, 1, rate, low, cardName)
            : await requirementCardSorting(token, 1, rate, low, cardName);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
        }
      }
    } else {
      if (idOrder) {
        const response = isrecruiterCandidate
          ? await recrequirementSorting(token, 1, rate, high)
          : isFreelance
            ? await freerequirementSorting(token, 1, rate, high)
            : await requirementSorting(token, 1, rate, high);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
        }
      } else {
        const response = isrecruiterCandidate
          ? await recrequirementSorting(token, 1, rate, low)
          : isFreelance
            ? await freerequirementSorting(token, 1, rate, low)
            : await requirementSorting(token, 1, rate, low);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setItem(payload.slice(0, -1));
        }
      }
    }
  };
  //delete requirement
  async function handleDelete(id) {
    setArchiveId(id);
    try {
      const response = isrecruiterCandidate
        ? await recdeleteRequirment(token, id)
        : isFreelance
          ? await freedeleteRequirment(token, id)
          : await deleteRequirment(token, id);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.message;
        toast.success(payload);
        snapshot(token, id);
        if (cardName === "active") {
          const resRequirement = isrecruiterCandidate
            ? await recDashboardActiveJobs(token, 1)
            : isFreelance
              ? await freeDashboardActiveJobs(token, 1)
              : await DashboardActiveJobs(token, 1);
          if (resRequirement.status === 200) {
            const data = await resRequirement.json();
            const payload = data.payload;
            const totalRecord = payload[payload.length - 1].totalRecord;
            setRecord(totalRecord);
            setItem(payload.slice(0, -1));
            setLoading(false);
            setTotalLength(totalRecord);
            setPageCount(Math.ceil(payload[6].totalRecord / 6));
          }
        } else if (cardName === "scheduled" || cardName === "shortlisted") {
          const resRequirement = isrecruiterCandidate
            ? await recDashboardCardRequirment(token, 1, cardName)
            : isFreelance
              ? await freeDashboardCardRequirment(token, 1, cardName)
              : await DashboardCardRequirment(token, 1, cardName);
          if (resRequirement.status === 200) {
            const data = await resRequirement.json();
            const payload = data.payload;
            const totalRecord = payload[payload.length - 1].totalRecord;
            setRecord(totalRecord);
            setItem(payload.slice(0, -1));
            setLoading(false);
            setTotalLength(totalRecord);
            setPageCount(Math.ceil(payload[6].totalRecord / 6));
          }
        } else {
          const resRequirement = isrecruiterCandidate
            ? await recDashboardRequirment(token, 1)
            : isFreelance
              ? await freeDashboardRequirment(token, 1)
              : await DashboardRequirment(token, 1);
          if (resRequirement.status === 200) {
            const data = await resRequirement.json();
            const payload = data.payload;
            const totalRecord = payload[payload.length - 1].totalRecord;
            setRecord(totalRecord);
            setItem(payload.slice(0, -1));
            setLoading(false);
            setTotalLength(totalRecord);
            setPageCount(Math.ceil(payload[6].totalRecord / 6));
          }
        }
      }
      if (response.status === 404) {
        const data = await response.json();
        const payload = data.message;
        setArchiveErrmsg(payload);
        setArchiveModal(true);
      }
    } catch { }
  }

  async function handleAssociatedRequirement(id) {
    setArchiveModal(false);
    try {
      let requireData = {
        forced: true,
      };
      const response = isrecruiterCandidate
        ? await recdeleteAssociatedRequirment(token, id, requireData)
        : isFreelance
          ? await freedeleteAssociatedRequirment(token, id, requireData)
          : await deleteAssociatedRequirment(token, id, requireData);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.message;
        toast.success(payload);
        snapshot(token, id);
        if (cardName === "active") {
          const resRequirement = isrecruiterCandidate
            ? await recDashboardActiveJobs(token, 1)
            : isFreelance
              ? await freeDashboardActiveJobs(token, 1)
              : await DashboardActiveJobs(token, 1);
          if (resRequirement.status === 200) {
            const data = await resRequirement.json();
            const payload = data.payload;
            const totalRecord = payload[payload.length - 1].totalRecord;
            setRecord(totalRecord);
            setItem(payload.slice(0, -1));
            setLoading(false);
            setTotalLength(totalRecord);
            setPageCount(Math.ceil(payload[6].totalRecord / 6));
          }
        } else if (cardName === "scheduled" || cardName === "shortlisted") {
          const resRequirement = isrecruiterCandidate
            ? await recDashboardCardRequirment(token, 1, cardName)
            : isFreelance
              ? await freeDashboardCardRequirment(token, 1, cardName)
              : await DashboardCardRequirment(token, 1, cardName);
          if (resRequirement.status === 200) {
            const data = await resRequirement.json();
            const payload = data.payload;
            const totalRecord = payload[payload.length - 1].totalRecord;
            setRecord(totalRecord);
            setItem(payload.slice(0, -1));
            setLoading(false);
            setTotalLength(totalRecord);
            setPageCount(Math.ceil(payload[6].totalRecord / 6));
          }
        } else {
          const resRequirement = isrecruiterCandidate
            ? await recDashboardRequirment(token, 1)
            : isFreelance
              ? await freeDashboardRequirment(token, 1)
              : await DashboardRequirment(token, 1);
          if (resRequirement.status === 200) {
            const data = await resRequirement.json();
            const payload = data.payload;
            const totalRecord = payload[payload.length - 1].totalRecord;
            setRecord(totalRecord);
            setItem(payload.slice(0, -1));
            setLoading(false);
            setTotalLength(totalRecord);
            setPageCount(Math.ceil(payload[6].totalRecord / 6));
          }
        }
      }
    } catch { }
  }

  function hideModal() {
    setArchiveModal(false);
  }

  //duplicate requirement
  async function handleDuplicate(id) {
    try {
      const response = isrecruiterCandidate
        ? await recduplicateRequirement(token, id)
        : isFreelance
          ? await freeduplicateRequirement(token, id)
          : await duplicateRequirement(token, id);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload;
        history.push(`/client/postjobpreview/${payload.uuid}`);
      }
      if (response.status === 404) {
        const data = await response.json();
        const message = data.message;
        toast.error(message);
      }
    } catch { }
  }
  //download requirement responses
  async function handleDownload(id) {
    try {
      const response = isrecruiterCandidate
        ? await recdownloadRequirement(token, id)
        : isFreelance
          ? await freedownloadRequirement(token, id)
          : await downloadRequirement(token, id);
      let text = await response.text();
      const url = window.URL.createObjectURL(new Blob([text]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "requirements.csv");
      document.body.appendChild(link);
      link.click();
    } catch { }
  }
  const handleTableView = () => {
    setIsGridView(false);
    // onSwitch(checked); // Pass the switch state to the parent component
  };
  const handleGridView = () => {
    setIsGridView(true);
    // onSwitch(checked); // Pass the switch state to the parent component
  };
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' });
  // const handleImageClick = () => {
  //   setIsShifted(!isShifted);
  // }
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 901px)' });
  return (
    <div className={"col mx-lg-4 mx-0 pb-5"}>
      {!isTabletOrMobile && <div className="Requirement-container-switcher-css">
        <button className=" colored-Lavender-slate Table-button-css" onClick={handleTableView} disabled={!isGridView} style={{ cursor: `${!isGridView ? 'not-allowed' : 'pointer'}`, border: 'none', background: '#F6F4FF', color: `${isGridView ? '#3D007A' : 'rgba(116, 105, 182, 0.59)'}` }}><TableChartIcon
        //  style={{ color: '#7E73B5' }} 
        /></button>
        <button className=" colored-Lavender-slate Grid-button-css" style={{ cursor: `${isGridView ? 'not-allowed' : 'pointer'}`, border: 'none', background: '#F6F4FF', color: `${isGridView ? 'rgba(116, 105, 182, 0.59)' : '#3D007A'}` }} onClick={handleGridView} disabled={isGridView}> <GridViewIcon
        // style={{ color: '#7E73B5' }} 
        /></button>
        {/* <label className="mt-2 mr-2 colored-Lavender-slate"  > Tabular View </label> */}
        {/* <Switch
          onChange={handleViewSwitch}
          checked={isGridView}
          offColor="#7E73B5"
          onColor="#7E73B5"
          uncheckedIcon={false} // No icon for unchecked (row view)
          checkedIcon={false}   // No icon for checked (grid view)
          handleDiameter={24}   // Size of the switch handle
          height={28}           // Height of the switch
          width={48}
        />
        <label className="mt-2 ml-2 colored-Lavender-slate" >Card View</label> */}
      </div>
      }
      <ToastContainer position={"top-center"} />
      {!isGridView && isDesktopOrLaptop &&

        <div
          className={"row mt-4 align-items-center pl-2 Requirment-container-boxshadow"}

        >
          <div className={"col py-4"}>
            {/*heading box*/}
            <div className={"row"}>
              <div className={"col-2 pr-0"}>
                <font className={"headingfont pl-5"}>ID</font>
                <img
                  src={`../../icon/candidate/matchingjob/${idOrder ? 'down-arrow.png' : 'down-arrow(2).png'}`}

                  alt={"align"}
                  className={"img-fluid ml-1"}
                  onClick={() => {
                    {
                      handleIdSorting();
                      setIdOrder(!idOrder);
                    }
                  }}
                />
              </div>
              <div className={"col-1"}>
                <div className={"pl-0"}>
                  <font className={"headingfont ml-lg-3 ml-4"}>Job title</font>
                </div>
              </div>
              <div className={"col-1"}>
                <div className={"pl-0"}>
                  <font className={"headingfont ml-4"}>Positions(#)</font>
                </div>
              </div>
              <div className={"col-2 pl-5"}>
                <font className={"headingfont ml-1"}>Duration</font>
              </div>
              <div className={`col-${cardName !== "archive" ? 1 : 2} pl-0 matching-job-14rem ${cardName !== "archive" ? '' : 'text-center'}`} >
                <font className={"headingfont"}>
                  Rate
                  <img
                    src={`../../icon/candidate/matchingjob/${order ? 'down-arrow.png' : 'down-arrow(2).png'}`}

                    alt={"align"}
                    className={"img-fluid ml-1"}
                    onClick={() => {
                      {
                        handleRateSorting();
                        setOrder(!order);
                      }
                    }}
                  />
                </font>
              </div>
              {
                cardName !== "archive" ?
                  <div className={"col-1 pl-3"}>
                    <font className={"headingfont"}>Shortlist</font>
                  </div>
                  :
                  ''
              }
              <div className={"col-2 pl-3 pr-0"}>
                <font className={"headingfont pl-3"}>Work Type</font>
              </div>
              <div className={"col-1 pl-3"}>
                <font className={"headingfont"}>Offer</font>
              </div>
            </div>
          </div>
        </div>
      }
      {/*this is first box*/}
      {/*this is second box of data heading-----changed*/}
      {/* <div
        className={"row mt-4 align-items-center pl-2"}
        style={{
          borderRadius: "20px 20px 0 0",
          boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
          backgroundColor: "white",
        }}
      >
        <div className={"col py-4"}>
          <div className={"row"}>
            <div className={"col-2 pr-0"}>
              <font className={"headingfont pl-5"}>ID</font>
              <img
                src="../../icon/candidate/matchingjob/align.png"
                alt={"align"}
                className={"img-fluid ml-1"}
                onClick={() => {
                  {
                    handleIdSorting();
                    setIdOrder(!idOrder);
                  }
                }}
              />
            </div>
            <div className={"col-2"}>
              <div className={"pl-5"}>
                <font className={"headingfont ml-4"}>Job title</font>
              </div>
            </div>
            <div className={"col-2 pl-5"}>
              <font className={"headingfont ml-4"}>Duration</font>
            </div>
            <div className={"col-1 pl-4"}>
              <font className={"headingfont"}>
                Rate
                <img
                  src="../../icon/candidate/matchingjob/align.png"
                  alt={"align"}
                  className={"img-fluid ml-1"}
                  onClick={() => {
                    {
                      handleRateSorting();
                      setOrder(!order);
                    }
                  }}
                />
              </font>
            </div>
            {
              cardName!== "archive" ?
              <div className={"col-1 pl-0"}>
              <font className={"headingfont"}>Shortlist</font>
              </div>
            :
            <div className={"col-1 pl-0"}>
            
          </div>
            }
            <div className={"col-2 pl-0 pr-0"}>
              <font className={"headingfont pl-3"}>Work Type</font>
            </div>
            <div className={"col-1 pl-0"}>
              <font className={"headingfont"}>Offer</font>
            </div>
          </div>
        </div>
      </div> */}
      {/*this is second box of heading data*/}
      <Data
        item={item}
        record={record}
        handleDelete={handleDelete}
        archiveErrmsg={archiveErrmsg}
        archiveModal={archiveModal}
        archiveId={archiveId}
        handleAssociatedRequirement={handleAssociatedRequirement}
        hideModal={hideModal}
        handleDuplicate={handleDuplicate}
        handleDownload={handleDownload}
        isGridView={isGridView}
      />
      {/*last box of data of heading*/}
      {loading ? (
        ""
      ) : (
        <>
          {(!isGridView && isDesktopOrLaptop && record !== 0) && (
            <div
              className={"row align-items-center applictions-box-shadow-reverse"}
            // style={{
            //   borderRadius: "0 0 20px 20px",
            //   boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
            //   backgroundColor: "white",
            //   // marginTop: "1px",
            // }}
            >
              <div className={"col py-2"}></div>
            </div>
          )}
        </>
      )}
      <div className={"col mt-5 pb-5"}>
        <div className={"row align-items-center pb-5"}>
          {/* <div className={"col-md-6 pl-0 pb-5"}></div> */}
          <div className={"col-md"}>
            {totalLength > 6 && (
              <ReactPaginate
                previousLabel={"<<"}
                nextLabel={">>"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={page}
              />
            )}
          </div>
        </div>
      </div>
      <GoToTop />
    </div>
  );
};
export default React.memo(Requirement);
