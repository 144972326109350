import { SUPERADMINCLIENTLOCATIONLIST } from "../../ENVIRONMENT";
// api for location
function clientListLocation(token) {
  const response = fetch(SUPERADMINCLIENTLOCATIONLIST, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { clientListLocation };
