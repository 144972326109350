import { RECRUITERCANDIDATEAPPLICATION } from "../../ENVIRONMENT";

// api for recruiter candidate application search
async function recapplicationSearch(token, data,currentPage) {
  const response = await fetch(RECRUITERCANDIDATEAPPLICATION+`?q=${data}&limit=6&page=${currentPage}`, {
    
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    
  });
  return response;
}

export { recapplicationSearch }
