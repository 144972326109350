import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const CandidateMatchingData = (props) => {
  const [loading, setLoading] = useState();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let cardName = query.get("title");
  const [match, setMatch] = useState([
    {
      clientID: "DS21222K3351",
      reqID: "DS21222K3351",
      position: "java developer",
      matching: "50%",
      source: "client",
      rate: "455",
      interviewType: "Telephone",
      location: "Texas",
      status: "scheduled",
      createdAt: "2022-11-21T06:43:32.000Z",
    },
    {
      clientID: "DS21222K3351",
      reqID: "DS21222K3351",
      position: "java developer",
      matching: "50%",
      source: "client",
      rate: "455",
      interviewType: "Telephone",
      location: "Texas",
      status: "scheduled",
      createdAt: "2022-11-21T06:43:32.000Z",
    },
    {
      clientID: "DS21222K3351",
      reqID: "DS21222K3351",
      position: "java developer",
      matching: "50%",
      source: "client",
      rate: "455",
      interviewType: "Telephone",
      location: "Texas",
      status: "scheduled",
      createdAt: "2022-11-21T06:43:32.000Z",
    },
    {
      clientID: "DS21222K3351",
      reqID: "DS21222K3351",
      position: "java developer",
      matching: "50%",
      source: "client",
      rate: "455",
      interviewType: "Telephone",
      location: "Texas",
      status: "scheduled",
      createdAt: "2022-11-21T06:43:32.000Z",
    },
    {
      clientID: "DS21222K3351",
      reqID: "DS21222K3351",
      position: "java developer",
      matching: "50%",
      source: "client",
      rate: "455",
      interviewType: "Telephone",
      location: "Texas",
      status: "scheduled",
      createdAt: "2022-11-21T06:43:32.000Z",
    },
  ]);
  const ref = useRef();

  //   useEffect(() => {
  //     try {
  //       setLoading(true);
  //       if (props.item.length !== 0) {
  //         setMatch(props.item);
  //         setLoading(false);
  //       }
  //       if (props.record === 0) {
  //         setLoading(false);
  //       }
  //     } catch {}
  //   }, [props.item]);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const dispatch = useDispatch();

  return (
    <>
      {match.map((match) => (
        <div
          className={"row"}
          style={{
            boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
            backgroundColor: "white",
            marginTop: "1px",
          }}
          key={match.uuid && match.uuid.split("-").pop()}
        >
          {/* <input type={"checkbox"} className={"checkbx  mr-4 mt-4"}/> */}
          <div className={"col py-3 box"}>
            {/*heading box data*/}
            <div className={"row  align-items-center pl-3 pr-5"}>
              <div className={"col-2 pr-0"}>
                <label className={"matchingjobdatalin "}>
                  {match.clientID}
                </label>
                <br />
                <font className="matchingjobdata">
                  {new Date(match.createdAt).toLocaleDateString(
                    "en-US",
                    options
                  )}
                </font>
              </div>
              <div className={"col-1 pl-0"}>
                <label className={"matchingjobdatalink "}>
                  {match.reqID}
                </label>
              </div>
              <div className={"col-2"}>
                <div className="row">
                  <div
                    className="col-md"
                    style={{
                      wordWrap: "break-word",
                      whiteSpace: "pre-wrap",
                      textAlign: "center",
                    }}
                  >
                    <font className="matchingjobdata">{match.position}</font>
                  </div>
                </div>
              </div>
              <div className={"col-1"}>
                <label className={"matchingjobdata"}>{match.matching}</label>
              </div>
              <div className={"col-1"}>
                <label className={"matchingjobdata"}>{match.source}</label>
              </div>
              <div className={"col-1"}>
                <label className={"matchingjobdata"}>{match.rate}</label>
              </div>
              <div className={"col-2"}>
                <label className={"matchingjobdata"}>
                  {match.interviewType}
                </label>
              </div>
              <div className={"col-1 pl-0"}>
                <label className={"matchingjobdata"}>{match.location}</label>
              </div>
              <div className={"col-1"}>
                <label className={"matchingjobdata"}>{match.status}</label>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
export { CandidateMatchingData };
