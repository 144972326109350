import { RECRUITERBUILDSECURITY } from "../../../ENVIRONMENT";

// api for submit recruiter candidate buildresume security
async function postSecurity(token, data,id) {
  return await fetch(RECRUITERBUILDSECURITY + `/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}

// api for recruiter candidate buildresume security details
async function getSecurity(token,id) {
  return await fetch(RECRUITERBUILDSECURITY + `/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
}

export { postSecurity, getSecurity };
