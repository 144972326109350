function getSkillParseData(
  information,
  secondaryInfo,
  secondaryVisible,
  date,
  date0,
  date1,
  date2,
  secondaryDate,
  secondaryDate0,
  secondaryDate1,
  secondaryDate2
) {
  const primData = [];
  const secondaryData = [];

  primData.push({
    skill: information.primarySkill ? information.primarySkill : "",
    experienceInYear: information.year ? information.year : 0,
    experienceInMonth: information.month ? information.month : 0,
    lastUsed: date.toISOString().slice(0, 10),
  });

  if (information.primarySkill0) {
    primData.push({
      skill: information.primarySkill0 ? information.primarySkill0 : "",
      experienceInYear: information.year0 ? information.year0 : 0,
      experienceInMonth: information.month0 ? information.month0 : 0,
      lastUsed: date0.toISOString().slice(0, 10),
    });
  }
  if (information.primarySkill1) {
    primData.push({
      skill: information.primarySkill1 ? information.primarySkill1 : "",
      experienceInYear: information.year1 ? information.year1 : 0,
      experienceInMonth: information.month1 ? information.month1 : 0,
      lastUsed: date1.toISOString().slice(0, 10),
    });
  }
  if (information.primarySkill2) {
    primData.push({
      skill: information.primarySkill2 ? information.primarySkill2 : "",
      experienceInYear: information.year2 ? information.year2 : 0,
      experienceInMonth: information.month2 ? information.month2 : 0,
      lastUsed: date2.toISOString().slice(0, 10),
    });
  }

  if (secondaryInfo.secondarySkill.trim()) {
    secondaryData.push({
      skill: secondaryInfo.secondarySkill ? secondaryInfo.secondarySkill : "",
      experienceInYear: secondaryInfo.secondarySkillYear
        ? secondaryInfo.secondarySkillYear
        : 0,
      experienceInMonth: secondaryInfo.secondarySkillMonth
        ? secondaryInfo.secondarySkillMonth
        : 0,
      lastUsed: secondaryDate.toISOString().slice(0, 10),
    });
  }
  if (secondaryInfo.secondarySkill0.trim()) {
    secondaryData.push({
      skill: secondaryInfo.secondarySkill0 ? secondaryInfo.secondarySkill0 : "",
      experienceInYear: secondaryInfo.secondarySkillYear0
        ? secondaryInfo.secondarySkillYear0
        : 0,
      experienceInMonth: secondaryInfo.secondarySkillMonth0
        ? secondaryInfo.secondarySkillMonth0
        : 0,
      lastUsed: secondaryDate0.toISOString().slice(0, 10),
    });
  }
  if (secondaryInfo.secondarySkill1.trim()) {
    secondaryData.push({
      skill: secondaryInfo.secondarySkill1 ? secondaryInfo.secondarySkill1 : "",
      experienceInYear: secondaryInfo.secondarySkillYear1
        ? secondaryInfo.secondarySkillYear1
        : 0,
      experienceInMonth: secondaryInfo.secondarySkillMonth1
        ? secondaryInfo.secondarySkillMonth1
        : 0,
      lastUsed: secondaryDate1.toISOString().slice(0, 10),
    });
  }
  if (secondaryInfo.secondarySkill2.trim()) {
    secondaryData.push({
      skill: secondaryInfo.secondarySkill2 ? secondaryInfo.secondarySkill2 : "",
      experienceInYear: secondaryInfo.secondarySkillYear2
        ? secondaryInfo.secondarySkillYear2
        : 0,
      experienceInMonth: secondaryInfo.secondarySkillMonth2
        ? secondaryInfo.secondarySkillMonth2
        : 0,
      lastUsed: secondaryDate2.toISOString().slice(0, 10),
    });
  }
  // secondarySkill: secondaryData,
  let parseData = {
    primarySkill: primData,
  };
  if (secondaryData.length > 0) {
    parseData = {
      primarySkill: primData,
      secondarySkill: secondaryData,
    };
  }
  return parseData;
}

export { getSkillParseData };
