import { SUPERADMINRECRUITERLIST } from "../../ENVIRONMENT";

async function recruiterListSorting(token, currentPage, name, order) {
  // api for recruiter sorting
  const response = await fetch(
    SUPERADMINRECRUITERLIST + `?limit=6&page=${currentPage}&${name}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
export { recruiterListSorting };
