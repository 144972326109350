import '../../../styles/app.scss';
import React from 'react';
import { Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import RegistrationForm from './RegistrationForm';
import BuildResume2 from './BuildResume2';
import BuildResume3 from './BuildResume3';
import BuildResume4 from './BuildResume4';
import BuildResume5 from './BuildResume5';
import ResumeSnapshot from '../ResumeSnapshot';
import UploadProfile from '../UploadProfile';

const BuildResume = () => {
    // return buildresume router
    const dispatch = useDispatch();
    dispatch({ type: "buildresumefalse" });

    try {
        document.getElementById("dashboardfont").innerText = "Home";
        document.getElementById("dashboardbtn").style.display = "block"
        document.getElementById("replacetitleimg").innerText = "Build Resume";
    } catch { }

    return (
        <React.Fragment>
            <Route path={["/freelance/dashboard/buildresume/register/:id","/freelance/dashboard/buildresume/register"]}>
                <RegistrationForm link={"/freelance/dashboard/buildresume/uploadprofile"} />
            </Route>
            <Route path={"/freelance/dashboard/buildresume/uploadprofile/:id"}>
              <UploadProfile />
            </Route>
            <Route path={"/freelance/dashboard/buildresume/resumeexperience/:id"}>
                <BuildResume2 />
            </Route>
            <Route path={"/freelance/dashboard/buildresume/resumecertificate/:id"}>
                <BuildResume3 />
            </Route>
            <Route path={"/freelance/dashboard/buildresume/resumesecurity/:id"}>
                <BuildResume4 />
            </Route>
            <Route path={"/freelance/dashboard/buildresume/resumesocial/:id"}>
                <BuildResume5 />
            </Route>
            <Route path={"/freelance/dashboard/buildresume/resumesnapshot/:id"}>
                <ResumeSnapshot />
            </Route>
        </React.Fragment>
    )
}
export default React.memo(BuildResume)