import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import GoToTop from "../../common/GoToTop";
import { Link, useHistory } from "react-router-dom";
import {
  postBuildResumeSocial,
  getBuildResumeSocial,
} from "../../../api/candidate/buildResumeSocial";

const BuildResume5 = () => {
  // return buildresume fifth tab button data
  const history = useHistory();
  const socialRef = useRef();
  const [loader, setLoader] = useState(false);
  const [socialLink, setSocialLink] = useState({
    facebook: "",
    twitter: "",
    linkedin: "",
    pintrest: "",
    youtube: "",
    skype: "",
    website: "",
  });
  const [errList, setErrList] = useState({
    facebook: "",
    twitter: "",
    linkedin: "",
    pintrest: "",
    youtube: "",
    skype: "",
    website: "",
  });
  const token = useSelector((state) => state.token);
  async function submitData(event) {
    try {
      event.preventDefault();
      if (socialRef.current) {
        socialRef.current.setAttribute("disabled", "disabled");
      }
      setLoader(true);
      Object.keys(socialLink).map((key) => {
        if (!socialLink[key]) delete socialLink[key];
      });
      const response = await postBuildResumeSocial(token, socialLink);
      if (response.status === 200) {
        history.push("/candidate/dashboard/buildresume/resumesnapshot");
      } else if (response.status === 422) {
        socialRef.current.removeAttribute("disabled");
        setLoader(false);
        const data = await response.json();
        const payload = data.payload;
        const error = {
          facebook: "",
          twitter: "",
          linkedin: "",
          pintrest: "",
          youtube: "",
          skype: "",
          website: "",
        };

        if (payload.hasOwnProperty("twitter")) {
          error.twitter = payload.twitter.message;
        }
        if (payload.hasOwnProperty("facebook")) {
          error.facebook = payload.facebook.message;
        }
        if (payload.hasOwnProperty("linkedin")) {
          error.linkedin = payload.linkedin.message;
        }
        if (payload.hasOwnProperty("pintrest")) {
          error.pintrest = payload.pintrest.message;
        }
        if (payload.hasOwnProperty("skype")) {
          error.skype = payload.skype.message;
        }
        if (payload.hasOwnProperty("youtube")) {
          error.youtube = payload.youtube.message;
        }
        if (payload.hasOwnProperty("website")) {
          error.website = payload.website.message;
        }
        setErrList(error);
      } else {
        socialRef.current.removeAttribute("disabled");
        setLoader(false);
      }
    } catch { }
  }

  useEffect(() => {
    async function callItSelf() {
      try {
        const response = await getBuildResumeSocial(token);
        if (response.status === 200) {
          let payload = await response.json();
          payload = payload.payload;
          if (payload[0]) setSocialLink(payload[0]);
        }
      } catch { }
    }
    callItSelf();
  }, []);

  return (
    <div className={"col"}>
      <form onSubmit={submitData}>
        <div className={"row pl-5 ml-5"}>
          <div className={"container-fluid container1 pl-5"}>
            <ul className={"progressbar mt-5"}>
              <li className={"active"}>
                <img
                  src="../../../icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li className={"active"}>
                <img
                  src="../../../icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li className={"active"}>
                <img
                  src="../../../icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li className={"active"}>
                <img
                  src="../../../icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
            </ul>
          </div>
        </div>
        <div className={"row mx-5 pl-5  resumealignment"}>
          <div className={"col-lg-1"}></div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link to="/candidate/dashboard/buildresume/resumeexperience">
              <div
                className={"pl-3 pb-2 pr-3 pt-3 BuildResume1-box-container-width border-radius-10px background-colored-primary-white  text-center"}

              >
                <label
                  className="font-weight-500 font-size-14px  colored-primary-black"

                >
                  Experience
                </label>
                <br />
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link to="/candidate/dashboard/buildresume/resumecertificate">
              <div
                className={"pt-2 pl-2 pr-2 BuildResume1-box-container-width border-radius-10px background-colored-primary-white  text-center"}

              >
                <label
                  className="font-weight-500 font-size-14px  colored-primary-black"

                >
                  Academics
                  <br />& Certificates
                </label>
                <br />
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link to="/candidate/dashboard/buildresume/resumesecurity">
              <div
                className={"pt-2 pl-2 pr-2 BuildResume1-box-container-width border-radius-10px background-colored-primary-white  text-center"}

              >
                <label
                  className="font-weight-500 font-size-14px  colored-primary-black"

                >
                  Security Clearance
                </label>
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link to="/candidate/dashboard/buildresume/resumesocial">
              <div
                className={"pt-2 pb-0 pl-3 pr-3 BuildResume1-box-container-width border-radius-10px background-colored-primary-white  text-center"}

              >
                <div className={"row"}>
                  <div className={"col-lg pb-2"}>
                    <label
                      className="font-weight-500 font-size-14px  colored-primary-black"

                    >
                      Social
                    </label>
                  </div>
                </div>
                <div className={"row pl-0"}>
                  <div className={"col-lg pl-1"}>
                    <div
                      className="background-colored-Skyline-blue BuildResume1-box-container-widthAnd-height"
                    ></div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        {/*first box*/}
        <div className={"pl-1 pr-5 mr-5"}>
          <div className={"row px-5 pr-5 mx-3 pt-3"}>
            <div className={"w-100 pb-4 pt-4 contentholder"}>
              <div className={"row pl-4"}>
                <div className={"col-md-3"}>
                  <label
                    className="font-weight-500 font-size-14px  colored-primary-black"

                  >
                    Social Link
                  </label>
                </div>
              </div>
              {/*facebook*/}
              <div className={"row pl-4"}>
                <div className={"col-lg-5"}>
                  <div
                    className={"pt-1 background-colored-Soft-gray bordre-radius-10px"}

                  >
                    <div className={"row"}>
                      <div className="col-lg-2">
                        <span
                          className={"p-2 background-colored-Cerulean-blue border-radius-10px"}

                        >
                          <img
                            src="../../../icon/candidate/resume/facebook.png"
                            alt={"facebook"}
                            className={""}
                          />
                        </span>
                      </div>
                      <div className={"col-lg-10 portfoliocontent"}>
                        <input
                          type={"text"}
                          className={
                            "form-control socialinputfield shadow-none"
                          }
                          placeholder={"facebook.com/userid"}
                          value={socialLink.facebook}
                          style={{
                            border: `${errList.facebook ? "1px solid red" : ""}`,
                          }}
                          onChange={(event) =>
                            setSocialLink({
                              ...socialLink,
                              facebook: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <font
                    className={"facebookerr text-danger dashboardforminputtext"}
                  >
                    {errList.facebook}
                  </font>
                </div>
              </div>
              {/*ball */}
              <div className={"row pl-4 pt-3"}>
                <div className={"col-lg-5"}>
                  <div
                    className={"pt-1 background-colored-Soft-gray bordre-radius-10px"}

                  >
                    <div className={"row"}>
                      <div className="col-lg-2">
                        <span
                          className={"p-2 background-colored-Electric-blue border-radius-10px"}

                        >
                          <img
                            src="../../../icon/candidate/resume/ball.png"
                            alt={"ball"}
                            className={""}
                          />
                        </span>
                      </div>
                      <div className={"col-lg-10 portfoliocontent"}>
                        <input
                          type={"text"}
                          className={
                            "form-control socialinputfield shadow-none"
                          }
                          placeholder={"twitter.com/userid"}
                          value={socialLink.twitter}
                          style={{
                            border: `${errList.twitter ? "1px solid red" : ""}`,
                          }}
                          onChange={(event) =>
                            setSocialLink({
                              ...socialLink,
                              twitter: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <font
                    className={"twitter text-danger dashboardforminputtext"}
                  >
                    {errList.twitter}
                  </font>
                </div>
              </div>

              {/* linkedin*/}
              <div className={"row pl-4 pt-3"}>
                <div className={"col-lg-5"}>
                  <div
                    className={"pt-1 background-colored-Soft-gray bordre-radius-10px"}

                  >
                    <div className={"row"}>
                      <div className="col-lg-2">
                        <span
                          className={"p-2 background-colored-Ocean-blue border-radius-10px"}

                        >
                          <img
                            src="../../../icon/candidate/resume/linkedin.png"
                            alt={"linkedin"}
                            className={""}
                          />
                        </span>
                      </div>
                      <div className={"col-lg-10 portfoliocontent"}>
                        <input
                          type={"text"}
                          className={
                            "form-control socialinputfield shadow-none"
                          }
                          placeholder={"linkedln.com/userid"}
                          value={socialLink.linkedin}
                          style={{
                            border: `${errList.linkedin ? "1px solid red" : ""
                              }`,
                          }}
                          onChange={(event) =>
                            setSocialLink({
                              ...socialLink,
                              linkedin: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <font
                    className={"linkedin text-danger dashboardforminputtext"}
                  >
                    {errList.linkedin}
                  </font>
                </div>
              </div>
              {/*pinterest*/}
              <div className={"row pl-4 pt-3"}>
                <div className={"col-lg-5"}>
                  <div
                    className={"pt-1 background-colored-Soft-gray bordre-radius-10px"}

                  >
                    <div className={"row"}>
                      <div className="col-lg-2">
                        <span
                          className={"p-2"}
                          style={{
                            background: "#BFBFBF",
                            borderRadius: "10px",
                          }}
                        >
                          <img
                            src="../../../icon/candidate/resume/pin.png"
                            alt={"pinterest"}
                            className={""}
                          />
                        </span>
                      </div>
                      <div className={"col-lg-10 portfoliocontent"}>
                        <input
                          type={"text"}
                          className={
                            "form-control socialinputfield shadow-none"
                          }
                          placeholder={"pinterest.com/userid"}
                          value={socialLink.pintrest}
                          style={{
                            border: `${errList.pintrest ? "1px solid red" : ""
                              }`,
                          }}
                          onChange={(event) =>
                            setSocialLink({
                              ...socialLink,
                              pintrest: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <font
                    className={"pintrest text-danger dashboardforminputtext"}
                  >
                    {errList.pintrest}
                  </font>
                </div>
              </div>
              {/* youtube*/}
              <div className={"row pl-4 pt-3"}>
                <div className={"col-lg-5"}>
                  <div
                    className={"pt-1 background-colored-Soft-gray bordre-radius-10px"}

                  >
                    <div className={"row"}>
                      <div className="col-lg-2">
                        <span
                          className={"p-2"}
                          style={{
                            background: "#FF0000",
                            borderRadius: "10px",
                          }}
                        >
                          <img
                            src="../../../icon/candidate/resume/youtube.png"
                            alt={"youtube"}
                            className={""}
                          />
                        </span>
                      </div>
                      <div className={"col-lg-10 portfoliocontent"}>
                        <input
                          type={"text"}
                          className={
                            "form-control socialinputfield shadow-none"
                          }
                          placeholder={"youtube.com/userid"}
                          value={socialLink.youtube}
                          style={{
                            border: `${errList.youtube ? "1px solid red" : ""}`,
                          }}
                          onChange={(event) =>
                            setSocialLink({
                              ...socialLink,
                              youtube: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <font
                    className={"youtube text-danger dashboardforminputtext"}
                  >
                    {errList.youtube}
                  </font>
                </div>
              </div>
              {/*skype*/}
              <div className={"row pl-3 pt-3"}>
                <div className={"col-lg-5"}>
                  <div className={"row pt-0 pb-0"}>
                    <div className="col-lg-2 pt-0 pb-0">
                      <img
                        src="../../../icon/candidate/resume/skype.png"
                        alt={"skype"}
                        className="background-colored-Pale-gray BuildResume1-skype-image"

                      />
                    </div>
                    <div className={"col-lg-10 skypetext pt-2"}>
                      <input
                        type={"text"}
                        className={"form-control socialinputfield shadow-none BuildResume1-skype-height"}
                        placeholder={"skype/userid"}

                        value={socialLink.skype}
                        // style={{
                        //   border: `${errList.skype ? "1px solid red" : ""}`,
                        // }}
                        onChange={(event) =>
                          setSocialLink({
                            ...socialLink,
                            skype: event.currentTarget.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <font className={"skype text-danger dashboardforminputtext"}>
                    {errList.skype}
                  </font>
                </div>
              </div>
              {/*www*/}
              <div className={"row pl-4 pt-3"}>
                <div className={"col-lg-5"}>
                  <div
                    className={"pt-1 background-colored-Soft-gray bordre-radius-10px"}

                  >
                    <div className={"row"}>
                      <div className="col-lg-2">
                        <span
                          className={"p-2 border-radius-10px"}

                        >
                          <img
                            src="../../../icon/candidate/resume/www.png"
                            alt={"www"}
                            className={"BuildResume1-www-image"}

                          />
                        </span>
                      </div>
                      <div className={"col-lg-10 portfoliocontent"}>
                        <input
                          type={"text"}
                          className={
                            "form-control socialinputfield shadow-none"
                          }
                          placeholder={"www.domainname.com"}
                          value={socialLink.website}
                          style={{
                            border: `${errList.website ? "1px solid red" : ""}`,
                          }}
                          onChange={(event) =>
                            setSocialLink({
                              ...socialLink,
                              website: event.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <font
                        className={"website text-danger dashboardforminputtext"}
                      >
                        {errList.website}
                      </font>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"row"}>
          <div className={"col pt-3"}>
            <button
              type={"submit"}
              className={"btn offset-sm-9 save"}
              ref={socialRef}
            >
              {loader ? (
                <i
                  className="fa fa-refresh fa-spin BuildResume1-loader-padding"

                ></i>
              ) : (
                ""
              )}
              Snapshot
            </button>
          </div>
        </div>
      </form>
      <GoToTop />
    </div>
  );
};
export default React.memo(BuildResume5);
