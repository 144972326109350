import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  WorkAuthorization,
  rateCardValidate,
} from "../function/common/rateCard";
import { data } from "../function/candidate/UserRegistrationForm";
import { getRateCard } from "../api/candidate/ratecard";
import { getUserInfoForRegisterPage } from "../api/common/userInfo";
import { GetCandidateProfile } from "../api/recruiter/candidate/buildResume/candidateProfile";
import { FreeGetCandidateProfile } from "../api/freelance/candidate/buildResume/candidateProfile";

const RateCard = ({
  workAuthorization,
  preferredEmployment,
  rateType,
  rate,
}) => {
  // return rate card
  const rateVal = [
    "W2With",
    "W2Without",
    "C2H",
    "C2C",
    "W2HourlyWith",
    "W2HourlyWithout",
    "N1099With",
    "N1099Without",
  ];

  const token = useSelector((state) => state.token);
  const rateValError = [
    "W2Witherror",
    "W2Withouterror",
    "c2herror",
    "c2cerror",
    "W2Hourlyerror",
    "W2Hourlywithouterror",
    "1099error",
    "1099Withouterror",
  ];

  const isFreelance = useSelector((state) => state.isFreelance);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const param = useParams();
  const id = param.id;

  async function reloadRateValue(obj) {
    try {
      const form = document.forms[0];
      let objectData = obj || {
        workAuthorization: form.workauth.value || "",
        employmentType: form.taxterm.value || "",
        rateType: form.duration.value || "",
        rate: form.rate.value || 0,
      };
      document.getElementsByClassName("totalCheckError")[0].innerHTML = "";
      const rateCardResponse = await getRateCard(token, objectData);
      if (rateCardResponse.status === 200) {
        let ratePayload = await rateCardResponse.json();
        ratePayload = ratePayload.payload;
        if (ratePayload) {
          rateVal.map((key) => {
            if (ratePayload[key]) {
              try {
                document.getElementsByClassName(key)[0].style.display = "";
              } catch { }
              if (
                objectData.workAuthorization === "OPT" ||
                objectData.workAuthorization === "OPT_STEM" ||
                objectData.workAuthorization === "CPT"
              ) {
                document.getElementsByClassName("N1099With")[0].style.display =
                  "none";
                document.getElementsByClassName(
                  "N1099Without"
                )[0].style.display = "none";
              }
              form[key].value = ratePayload[key];
              form[key + "Check"].checked = true;
            } else {
              form[key].value = "";
              form[key + "Check"].checked = false;
              document.getElementsByClassName(key)[0].style.display = "none";
            }
            return null;
          });
        }
      }
      rateValError.map((key) => {
        document.getElementsByClassName(`${key}`)[0].innerHTML = "";
      });
    } catch { }
  }
  if (rate) {
    document.getElementById("rate").value = rate;
  }

  function EmployType() {
    try {
      if (workAuthorization) {
        const val = [];
        data.map((obj) => {
          if (Object.keys(obj)[0] === workAuthorization)
            val.push(obj[workAuthorization]);
          return null;
        });
        const result = [preferredEmployment];
        val[0].map((val) => {
          if (val !== preferredEmployment) result.push(val);
          return null;
        });
        const option = result.map((value, index) => {
          if (index === 0)
            return (
              <option key={index} value={value}>
                {value}
              </option>
            );
          return (
            <option key={index} value={value}>
              {value}
            </option>
          );
        });
        return option;
      } else
        return (
          <option value={"Select"} disabled>
            Select
          </option>
        );
    } catch { }
  }

  function Duration() {
    try {
      let option = ["Select", "Hourly", "Yearly"];
      if (rateType === "Hourly") option = ["Hourly", "Yearly"];
      else if (rateType === "Yearly") option = ["Yearly", "Hourly"];
      return option.map((val, index) => {
        return (
          <option key={index} value={val}>
            {val}
          </option>
        );
      });
    } catch { }
  }
  function callBoth(event) {
    rateCardValidate(event);
    reloadRateValue();
    document.getElementById("workautherror").innerHTML = "";
    document.getElementsByClassName("selectworkauth")[0].style.cssText = "";
    document.getElementById("employmenterror").innerHTML = "";
    document.getElementsByClassName("selectemploy")[0].style.cssText = "";
  }

  function callRateType(event) {
    rateCardValidate(event);
    reloadRateValue();
    document.getElementById("rateTypeerror").innerHTML = "";
    document.getElementsByClassName("selectrateType")[0].style.cssText = "";
    const rateValue = event.target.form.rate.value;

    if (rateValue && rateValue.substring(0, 1) === "0") {
      document.getElementById("rateerror").innerHTML = "cannot be 0";
      document.getElementsByClassName("ratefield")[0].style.cssText =
        "border: 1px solid red";
    } else if (rateValue !== "") {
      if (event.target.form.duration.value === "Hourly") {
        document.getElementsByClassName("ratefield")[0].maxLength = "3";
        if (rateValue > 500 || rateValue < 15) {
          document.getElementById("rateerror").innerHTML =
            "Must be 2-3 digits (between 15 to 500)";
          document.getElementsByClassName("ratefield")[0].style.cssText =
            "border: 1px solid red";
        } else {
          document.getElementById("rateerror").innerHTML = "";
          document.getElementsByClassName("ratefield")[0].style.cssText = "";
        }
      } else if (event.target.form.duration.value === "Yearly") {
        document.getElementsByClassName("ratefield")[0].maxLength = "6";
        if (rateValue > 500000 || rateValue < 30000) {
          document.getElementById("rateerror").innerHTML =
            "Must be 5-6 digits (between 30000 to 500000)";
          document.getElementsByClassName("ratefield")[0].style.cssText =
            "border: 1px solid red";
        } else {
          document.getElementById("rateerror").innerHTML = "";
          document.getElementsByClassName("ratefield")[0].style.cssText = "";
        }
      }
    }
  }

  function checkRateValue(e) {
    if (e.target.value !== "") {
      const ratefieldValue = e.target.form.rate.value;

      if (e.target.value.substring(0, 1) === "0") {
        document.getElementById("rateerror").innerHTML = "cannot be 0";
        document.getElementsByClassName("ratefield")[0].style.cssText =
          "border: 1px solid red";
      } else {
        if (e.target.form.duration.value === "Hourly") {
          if (ratefieldValue > 500 || ratefieldValue < 15) {
            document.getElementById("rateerror").innerHTML =
              "Must be 2-3 digits (between 15 to 500)";
            document.getElementsByClassName("ratefield")[0].style.cssText =
              "border: 1px solid red";
          } else {
            document.getElementById("rateerror").innerHTML = "";
            document.getElementsByClassName("ratefield")[0].style.cssText = "";
          }
        } else if (e.target.form.duration.value === "Yearly") {
          if (ratefieldValue > 500000 || ratefieldValue < 30000) {
            document.getElementById("rateerror").innerHTML =
              "Must be 5-6 digits (between 30000 to 500000)";
            document.getElementsByClassName("ratefield")[0].style.cssText =
              "border: 1px solid red";
          } else {
            document.getElementById("rateerror").innerHTML = "";
            document.getElementsByClassName("ratefield")[0].style.cssText = "";
          }
        }
      }
    } else {
      document.getElementById("rateerror").innerHTML = "";
      document.getElementsByClassName("ratefield")[0].style.cssText = "";
    }
  }
  useEffect(() => {
    async function callItSelf() {
      try {
        const response = isrecruiterCandidate
          ? await GetCandidateProfile(token, id)
          : isFreelance
            ? await FreeGetCandidateProfile(token, id)
            : await getUserInfoForRegisterPage(token);
        if (response.status === 200) {
          const form = document.forms[0];
          const data = await response.json();
          const payload = data.payload;
          if (payload.rate !== null) {
            if (payload.W2With) {
              form.W2With.value = payload.W2With;
              form.W2WithCheck.checked = true;
            } else {
              form.W2With.value = "";
              form.W2WithCheck.checked = false;
            }
            if (payload.W2Without) {
              form.W2Without.value = payload.W2Without;
              form.W2WithoutCheck.checked = true;
            } else {
              form.W2Without.value = "";
              form.W2WithoutCheck.checked = false;
            }
            if (payload.W2HourlyWith) {
              form.W2HourlyWith.value = payload.W2HourlyWith;
              form.W2HourlyWithCheck.checked = true;
            } else {
              form.W2HourlyWith.value = "";
              form.W2HourlyWithCheck.checked = false;
            }
            if (payload.W2HourlyWithout) {
              form.W2HourlyWithout.value = payload.W2HourlyWithout;
              form.W2HourlyWithoutCheck.checked = true;
            } else {
              form.W2HourlyWithout.value = "";
              form.W2HourlyWithoutCheck.checked = false;
            }
            if (payload.N1099With) {
              form.N1099With.value = payload.N1099With;
              form.N1099WithCheck.checked = true;
            } else {
              form.N1099With.value = "";
              form.N1099WithCheck.checked = false;
            }
            if (payload.N1099Without) {
              form.N1099Without.value = payload.N1099Without;
              form.N1099WithoutCheck.checked = true;
            } else {
              form.N1099Without.value = "";
              form.N1099WithoutCheck.checked = false;
            }
            if (payload.C2H) {
              form.C2H.value = payload.C2H;
              form.C2HCheck.checked = true;
            } else {
              form.C2H.value = "";
              form.C2HCheck.checked = false;
            }
            if (payload.C2C) {
              form.C2C.value = payload.C2C;
              form.C2CCheck.checked = true;
            } else {
              form.C2C.value = "";
              form.C2CCheck.checked = false;
            }
            if (
              payload.workAuthorization === "OPT" ||
              payload.workAuthorization === "OPT_STEM" ||
              payload.workAuthorization === "CPT"
            ) {
              document.getElementById("ratecardid").style.display = "";
              document.getElementsByClassName("N1099With")[0].style.display =
                "none";
              document.getElementsByClassName("N1099Without")[0].style.display =
                "none";
            } else if (
              payload.workAuthorization === "H1B" ||
              payload.workAuthorization === "APPROVED_I_140"
            ) {
              document.getElementById("ratecardid").style.display = "";
              document.getElementsByClassName("W2With")[0].style.display =
                "none";
              document.getElementsByClassName("W2Without")[0].style.display =
                "none";
              document.getElementsByClassName("W2HourlyWith")[0].style.display =
                "none";
              document.getElementsByClassName(
                "W2HourlyWithout"
              )[0].style.display = "none";
              document.getElementsByClassName("N1099With")[0].style.display =
                "none";
              document.getElementsByClassName("N1099Without")[0].style.display =
                "none";
            } else if (payload.workAuthorization === "E_3") {
              document.getElementById("ratecardid").style.display = "";
              document.getElementsByClassName("W2With")[0].style.display =
                "none";
              document.getElementsByClassName("W2Without")[0].style.display =
                "none";
              document.getElementsByClassName("C2H")[0].style.display = "none";
              document.getElementsByClassName("N1099With")[0].style.display =
                "none";
              document.getElementsByClassName("N1099Without")[0].style.display =
                "none";
            } else {
              document.getElementById("ratecardid").style.display = "";
            }
          }
          if (payload.rateType && payload.rateType === "Hourly") {
            document.getElementsByClassName("ratefield")[0].maxLength = "3";
          }
          if (payload.rateType && payload.rateType === "Yearly") {
            document.getElementsByClassName("ratefield")[0].maxLength = "6";
          }
        }
      } catch { }
    }
    callItSelf();
  }, []);

  function taxTermValidation(e, name) {
    let fieldValue = e.target.value;
    let fieldLength = e.target.value.length;
    if (fieldValue && fieldValue.substring(0, 1) === "0") {
      document.getElementsByClassName(`${name}`)[0].innerHTML = "cannot be 0";
    } else {
      if (fieldValue !== "") {
        if (fieldLength > 3 || fieldLength < 2) {
          document.getElementsByClassName(`${name}`)[0].innerHTML =
            "Must be 2-3 digits";
        } else {
          document.getElementsByClassName(`${name}`)[0].innerHTML = "";
        }
      } else {
        document.getElementsByClassName(`${name}`)[0].innerHTML = "";
      }
    }
  }

  return (
    <>
      <div className={"row pt-1 px-3 pl-4"}>
        <div className={"col-md pt-3"}>
          <label htmlFor={"relocate"} className={"dashboardforminputtext"}>
            Work Authorization
            <span className="colored-Bright-red ">*</span>
          </label>
          <select
            className={
              "form-control dashboardforminputfield form-select inputgray2 shadow-none selectworkauth"
            }
            id={"relocate"}
            name={"workauth"}
            onChange={callBoth}
          >
            <WorkAuthorization {...{ workAuthorization }} />
          </select>
          <label
            id={"workautherror"}
            className={"dashboardforminputtext text-danger text-align-center"}
          ></label>
        </div>
        <div className={"col-md pt-3"}>
          <label htmlFor={"taxterms"} className={"dashboardforminputtext"}>
            Employment Type
            <span className="colored-Bright-red ">*</span>
          </label>
          <select
            className={
              "form-control dashboardforminputfield form-select inputgray2 shadow-none selectemploy"
            }
            id={"taxtermsid"}
            name={"taxterm"}
            onChange={() => reloadRateValue()}
          >
            <EmployType />
          </select>
          <label
            id={"employmenterror"}
            className={"dashboardforminputtext text-danger text-align-center"}
          ></label>
        </div>
        <div className={"col-md-2 pt-5"}>
          <select
            className={
              "form-control dashboardforminputfield form-select inputgray2 shadow-none selectrateType"
            }
            name={"duration"}
            onChange={callRateType}
          >
            <Duration />
          </select>
          <label
            id={"rateTypeerror"}
            className={"dashboardforminputtext text-danger text-align-center"}
          ></label>
        </div>
        <div className={"col-md-2 pt-3"}>
          <label htmlFor={"rate"} className={"dashboardforminputtext pl-1"}>
            Rate
            <span className="colored-Bright-red ">*</span>
          </label>
          <input
            type={"text"}
            id={"rate"}
            className={
              "form-control dashboardforminputfield shadow-none ratefield"
            }
            name={"rate"}
            onKeyUp={rateCardValidate}
            onKeyDown={rateCardValidate}
            onKeyPress={rateCardValidate}
            onClick={rateCardValidate}
            maxLength={6}
            onChange={(e) => {
              reloadRateValue();
              checkRateValue(e);
            }}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
            }}
          />
          <label
            id={"rateerror"}
            className={"dashboardforminputtext text-danger text-align-center"}
          ></label>
        </div>
      </div>
      <div className={"row"} id={"ratecardid d-none"} >
        <div className={"pt-5 col-lg-9 table-responsive"}>
          <table className="table table-bordered">
            <thead>
              <tr className="text-center ">
                <th>Tax Term Calculation</th>
                <th>Benefits</th>
                <th>Rate</th>
                <th>Select</th>
                <th className="border-1px"></th>
              </tr>
            </thead>
            <tbody className="text-center ">
              <tr className={"W2With"}>
                <td>W2</td>
                <td>With</td>
                <td>
                  <div>
                    <input
                      type="text"
                      className={"generatewhitefield"}
                      name={"W2With"}
                      onChange={(e) => {
                        taxTermValidation(e, "W2Witherror");
                      }}
                      maxLength={3}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                    />
                  </div>
                </td>
                <td>
                  <input
                    type="checkbox"
                    className="cardcheck"
                    id="customCheck"
                    name="W2WithCheck"
                    onChange={() =>
                    (document.getElementsByClassName(
                      "totalCheckError"
                    )[0].innerHTML = "")
                    }
                  />
                </td>
                <td
                  className={"W2Witherror col-lg-3 border-1px colored-Bright-red"}

                ></td>
              </tr>

              <tr className={"W2Without"}>
                <td>W2</td>
                <td>Without</td>
                <td>
                  <div>
                    <input
                      type="text"
                      className={"generatewhitefield"}
                      name={"W2Without"}
                      onChange={(e) => {
                        taxTermValidation(e, "W2Withouterror");
                      }}
                      maxLength={3}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                    />
                  </div>
                </td>
                <td>
                  <input
                    type="checkbox"
                    className="cardcheck"
                    id="customCheck"
                    name="W2WithoutCheck"
                    onChange={() =>
                    (document.getElementsByClassName(
                      "totalCheckError"
                    )[0].innerHTML = "")
                    }
                  />
                </td>
                <td
                  className={"W2Withouterror col-lg-3  border-1px colored-Bright-red"}

                ></td>
              </tr>

              <tr className={"W2HourlyWith"}>
                <td>W2 Hourly</td>
                <td>With</td>
                <td>
                  <div>
                    <input
                      type="text"
                      className={"generatewhitefield"}
                      name={"W2HourlyWith"}
                      maxLength={3}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                      onChange={(e) => {
                        taxTermValidation(e, "W2Hourlyerror");
                      }}
                    />
                  </div>
                </td>
                <td>
                  <input
                    type="checkbox"
                    className="cardcheck"
                    id="customCheck"
                    name="W2HourlyWithCheck"
                    onChange={() =>
                    (document.getElementsByClassName(
                      "totalCheckError"
                    )[0].innerHTML = "")
                    }
                  />
                </td>
                <td
                  className={"W2Hourlyerror  border-1px colored-Bright-red"}

                ></td>
              </tr>
              <tr className={"W2HourlyWithout"}>
                <td>W2 Hourly</td>
                <td>Without</td>
                <td>
                  <div>
                    <input
                      type="text"
                      className={"generatewhitefield"}
                      name={"W2HourlyWithout"}
                      maxLength={3}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                      onChange={(e) => {
                        taxTermValidation(e, "W2Hourlywithouterror");
                      }}
                    />
                  </div>
                </td>
                <td>
                  <input
                    type="checkbox"
                    className="cardcheck"
                    id="customCheck"
                    name="W2HourlyWithoutCheck"
                    onChange={() =>
                    (document.getElementsByClassName(
                      "totalCheckError"
                    )[0].innerHTML = "")
                    }
                  />
                </td>
                <td
                  className={"W2Hourlywithouterror  border-1px colored-Bright-red"}

                ></td>
              </tr>

              <tr className={"N1099With"}>
                <td>1099</td>
                <td>With</td>
                <td>
                  <div>
                    <input
                      type="text"
                      className={"generatewhitefield"}
                      name={"N1099With"}
                      maxLength={3}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                      onChange={(e) => {
                        taxTermValidation(e, "1099error");
                      }}
                    />
                  </div>
                </td>
                <td>
                  <input
                    type="checkbox"
                    className="cardcheck"
                    id="customCheck"
                    name="N1099WithCheck"
                    onChange={() =>
                    (document.getElementsByClassName(
                      "totalCheckError"
                    )[0].innerHTML = "")
                    }
                  />
                </td>
                <td
                  className={"1099error  border-1px colored-Bright-red"}

                ></td>
              </tr>

              <tr className={"N1099Without"}>
                <td>1099</td>
                <td>Without</td>
                <td>
                  <div>
                    <input
                      type="text"
                      className={"generatewhitefield"}
                      name={"N1099Without"}
                      maxLength={3}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                      onChange={(e) => {
                        taxTermValidation(e, "1099Withouterror");
                      }}
                    />
                  </div>
                </td>
                <td>
                  <input
                    type="checkbox"
                    className="cardcheck"
                    id="customCheck"
                    name="N1099WithoutCheck"
                    onChange={() =>
                    (document.getElementsByClassName(
                      "totalCheckError"
                    )[0].innerHTML = "")
                    }
                  />
                </td>
                <td
                  className={"1099Withouterror  border-1px colored-Bright-red"}

                ></td>
              </tr>

              <tr className={"C2C"}>
                <td>C2C</td>
                <td></td>
                <td>
                  <div>
                    <input
                      type="text"
                      className={"generatewhitefield"}
                      name={"C2C"}
                      maxLength={3}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                      onChange={(e) => {
                        taxTermValidation(e, "c2cerror");
                      }}
                    />
                  </div>
                </td>
                <td>
                  <input
                    type="checkbox"
                    className="cardcheck"
                    id="customCheck"
                    name="C2CCheck"
                    onChange={() =>
                    (document.getElementsByClassName(
                      "totalCheckError"
                    )[0].innerHTML = "")
                    }
                  />
                </td>
                <td
                  className={"c2cerror  border-1px colored-Bright-red"}

                ></td>
              </tr>

              <tr className={"C2H"}>
                <td>C2H</td>
                <td></td>
                <td>
                  <div>
                    <input
                      type="text"
                      className={"generatewhitefield"}
                      name={"C2H"}
                      maxLength={3}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                      onChange={(e) => {
                        taxTermValidation(e, "c2herror");
                      }}
                    />
                  </div>
                </td>
                <td>
                  <input
                    type="checkbox"
                    className="cardcheck"
                    id="customCheck"
                    name="C2HCheck"
                    onChange={() =>
                    (document.getElementsByClassName(
                      "totalCheckError"
                    )[0].innerHTML = "")
                    }
                  />
                </td>
                <td
                  className={"c2herror  border-1px colored-Bright-red"}

                ></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3"></div>
        <div className={"col-md"}>
          <label
            className={"totalCheckError text-danger colored-Bright-red font-size-14px"}

          ></label>
        </div>
      </div>
    </>
  );
};
export default React.memo(RateCard);
