const requiredList = [
  "firstName",
  "lastName",
  "mobileNumber",
  "resumeTitle",
  "zipCode",
  "addressLine1",
  "city",
  "state",
  "country",
  "currentLocation",
  "preferredLocation",
];

const errorMsg = [
  "first name",
  "last name",
  "mobile number",
  "resume title",
  "zip code",
  "address",
  "city",
  "state",
  "country",
  "current location",
  "preferred location",
];

const empError = [
  "company name",
  "fein",
  "contact person",
  "email",
  "phone",
  "zip code",
  "address",
  "city",
  "state",
  "country",
]
function verifyValidate(
  event,
  information,
  setError,
  addmore,
  addMoreInformation,
  setAddMoreError,
  visibility
) {
  // check if required empty form will not submit
  let flag = true;
  const empField = [
    "companyName",
    "fein",
    "contactPerson",
    "email",
    "phone",
    "empzipCode",
    "empaddressLine1",
    "empcity",
    "empstate",
    "empcountry",
  ];
  const relocationValue = event.target.relocation.value
  requiredList.map((value, index) => {
    const val = information[value];
    if (value === "preferredLocation" && relocationValue === "Yes")
    {
      if(val.length === 0) {
        flag = false;
        setError((state) => {
          if (!visibility && empField.includes(value))
            return { ...state, [value]: "" };
          return { ...state, [value]: `Please enter the ${errorMsg[index]}` };
        });
      }
    }
    else {
      if (!val) {
      flag = false;
      setError((state) => {
        // if (!visibility && empField.includes(value))
        //   return { ...state, [value]: "" };
        return { ...state, [value]: `Please enter the ${errorMsg[index]}` };
      });
    }
    }
  });
  if (visibility) {
    empField.map((value, index) => {
      const val = information[value];
      if (!val) {
      flag = false;
      setError((state) => {
          return { ...state, [value]: `Please enter the ${empError[index]}` };
        });
      }
     });
  }
  // check addmore div required validation also
  if (addmore) {
    requiredList.map((value, index) => {
      const val = addMoreInformation[value];
      if (!val) {
       if(value === "zipCode" || value === "addressLine1" || value === "city" || value === "state") {
        flag = false;
        setAddMoreError((state) => {
          return { ...state, [value]: `Please enter the ${errorMsg[index]}` };
        });
      }
       }
    });
  }
  const flagTwo = requiredList
    .map((value) => {
      if (!visibility && empField.includes(value)) return true;
      return information[value] ? true : false;
    })
    .every((value) => {
      return value ? true : false;
    });
console.log(flag,flagTwo)
  return flag && flagTwo;
}

// check require for build resume 2

function verifyBuildResumeExperience(error, setError, information, visibility) {
  // check required
  const requiredzero = [
    "yearOfExp",
    "yearOfExpUS",
    "jobTitle",
    "client",
    "location",
  ];
  const requiredone = [
    "jobTitle1",
    "client1",
    "location1",
  ];
  const requiredtwo = [
    "jobTitle2",
    "client2",
    "location2",
  ];

  Object.keys(information).map((key) => {
    setError((state) => {
      if (
        !key.startsWith("domain") &&
        key !== "yearOfExp1" &&
        key !== "yearOfExp2" &&
        key !== "yearOfExpUS1" &&
        key !== "yearOfExpUS2"
      ) {
        if (!information[key] && information[key] === "") {
          if (key.startsWith("yearOf")) {
            state = { ...state, [key]: "Please enter the experience" };
          } else {
            state = {
              ...state,
              [key]: `Please enter the ${key.replace(/\d/, "")}`,
            };
          }
        }
      }
      return state;
    });
  });

  const flagone = !requiredzero
    .map((key) => {
      if (!information[key] && information[key] === "") return false;
      return null;
    })
    .concat(
      visibility.one
        ? requiredone.map((key) => {
            if (!information[key] && information[key] === "") return false;
            return null;
          })
        : [],
      visibility.two
        ? requiredtwo.map((key) => {
            if (!information[key] && information[key] === "") return false;
            return null;
          })
        : []
    )
    .includes(false);
  const flagtwo = !requiredzero
    .map((key) => {
      if (error[key]) return false;
      return null;
    })
    .concat(
      visibility.one
        ? requiredone.map((key) => {
            if (error[key]) return false;
            return null;
          })
        : [],
      visibility.two
        ? requiredtwo.map((key) => {
            if (error[key]) return false;
            return null;
          })
        : []
    )
    .includes(false);
  return flagone && flagtwo;
}

// for build resume 3=> academic and certification
function verifyBuildAcademic(
  error,
  setError,
  information,
  visibility,
  certificateVisibility,
  certificateError
) {
  setError((state) => {
    for (let i = 0; i < 3; i++) {
      if (i === 1 && !visibility.one) continue;
      else if (i === 2 && !visibility.two) continue;
      let suffix = "";
      if (i > 0) suffix = i;
      if (!information[`qualification${suffix}`]) {
        state = {
          ...state,
          [`qualification${suffix}`]: "Please enter the Qualification",
        };
      }
      // else {
      //   state = { ...state, [`qualification${suffix}`]: "" };
      // }

      if (!information[`specialization${suffix}`]) {
        state = {
          ...state,
          [`specialization${suffix}`]: "Please enter the Specialization",
        };
      }
      // else {
      //   state = { ...state, [`specialization${suffix}`]: "" };
      // }
    }
    return state;
  });

  const flagOne = !["qualification", "specialization"]
    .map((val) => {
      return information[val] ? null : false;
    })
    .concat(
      visibility.one
        ? ["qualification1", "specialization1"].map((val) => {
            return information[val] ? null : false;
          })
        : [],
      visibility.two
        ? ["qualification2", "specialization2"].map((val) => {
            return information[val] ? null : false;
          })
        : []
    )
    .includes(false);

  const flagTwo = !["qualification", "specialization"]
    .map((val) => {
      return error[val] ? false : null;
    })
    .concat(
      visibility.one
        ? ["qualification1", "specialization1"].map((val) => {
            return error[val] ? false : null;
          })
        : [],
      visibility.two
        ? ["qualification2", "specialization2"].map((val) => {
            return error[val] ? false : null;
          })
        : []
    )
    .includes(false);

  const flagThree = !["certificateName", "institute"]
    .map((key) => {
      return certificateError[key] ? false : null;
    })
    .concat(
      certificateVisibility.one
        ? ["certificateName0", "institute0"].map((key) => {
            return certificateError[key] ? false : null;
          })
        : [],
      certificateVisibility.two
        ? ["certificateName1", "institute1"].map((key) => {
            return certificateError[key] ? false : null;
          })
        : []
    )
    .includes(false);
  const flag = flagOne && flagTwo && flagThree;
  return flag;
}

function verifySecurityClearance(error, setError, information, visibility) {
  const securityZero = ["classification", "category", "year", "month"];
  const securityOne = ["classification1", "category1", "year1", "month1"];
  const securityTwo = ["classification2", "category2", "year2", "month2"];
  Object.keys(information).map((key) => {
    setError((state) => {
      if (!visibility.one && key.endsWith("1"))
        return { ...state, [key]: null };
      if (!visibility.two && key.endsWith("2"))
        return { ...state, [key]: null };
      if (!information[key] && visibility.zeroin)
        return {
          ...state,
          [key]: `Please enter the ${key.replace(/\d$/, " ")} `,
        };
      return state;
    });
  });

  // for value
  const flagOne = !securityZero
    .map((val) => {
      return visibility.zeroin ? (information[val] ? null : false) : null;
    })
    .concat(
      visibility.one
        ? securityOne.map((val) => {
            return visibility.onein ? (information[val] ? null : false) : null;
          })
        : [],
      visibility.two
        ? securityTwo.map((val) => {
            return visibility.twoin ? (information[val] ? null : false) : null;
          })
        : []
    )
    .includes(false);
  // for error
  const flagTwo = !securityZero
    .map((val) => {
      return visibility.zeroin ? (error[val] ? false : null) : null;
    })
    .concat(
      visibility.one
        ? securityOne.map((val) => {
            return visibility.onein ? (error[val] ? false : null) : null;
          })
        : [],
      visibility.two
        ? securityTwo.map((val) => {
            return visibility.twoin ? (error[val] ? false : null) : null;
          })
        : []
    )
    .includes(false);
  return flagOne && flagTwo;
}

export {
  verifyValidate,
  verifyBuildResumeExperience,
  verifyBuildAcademic,
  verifySecurityClearance,
};
