import { RECRUITERCLIENTMATCHINGJOBS } from "../../ENVIRONMENT";

// api for recruiter application filter
async function recfilterApplication(token, data, currentPage) {
  const response = await fetch(
    RECRUITERCLIENTMATCHINGJOBS + `?id=${data}&limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for recruiter filter schedule candidate
async function recfilterScheduledCandidates(
  token,
  data,
  cardName,
  currentPage
) {
  const response = await fetch(
    RECRUITERCLIENTMATCHINGJOBS +
      `?id=${data}&card=${cardName}&limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for recruiter filter status list
async function recfilterStatusDropList(token, cardName, currentPage) {
  const response = await fetch(
    RECRUITERCLIENTMATCHINGJOBS +
      `?card=${cardName}&limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export {
  recfilterApplication,
  recfilterScheduledCandidates,
  recfilterStatusDropList,
};
