import { RECRUITERCLIENTINTERVIEWSCHEDULE } from "../../ENVIRONMENT";

// api for recruiter interview schedule
async function recinterviewSchedule(token, data) {
  return await fetch(RECRUITERCLIENTINTERVIEWSCHEDULE, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}

export { recinterviewSchedule };
