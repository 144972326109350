//when we click it will display to shorlist /reject
const SelectSingle = (event) => {
  const chkbtnobj = document.getElementsByClassName("checkbx");
  const flagOne = [];
  for (let obj of chkbtnobj) flagOne.push(obj.checked);
  if (event.target.checked === true) {
    for (let obj of document.getElementsByClassName("hidebox")) {
      obj.style.cssText = "display:none";
    }
    for (let obj of document.getElementsByClassName("showbox")) {
      obj.style.cssText = "display:inline";
    }
    event.target.parentNode.lastChild.style.cssText =
      "background-color:rgba(196, 196, 196, 0.3)";
  } else {
    chkbtnobj[0].checked = false;
    let flag = false;
    for (let obj of document.getElementsByClassName("checkbx")) {
      if (obj.checked) {
        flag = true;
        break;
      }
      event.target.parentNode.lastChild.style.cssText =
        "background-color:white";
    }
    if (!flag) {
      for (let obj of document.getElementsByClassName("hidebox")) {
        obj.style.cssText = "display:inline";
      }
      document.getElementsByClassName("showbox")[0].style.cssText =
        "display:none";
      event.target.parentNode.lastChild.style.cssText =
        "background-color:white";
    }
  }
  const v = flagOne.slice(1).every((val) => (val ? true : false));
  if (v) {
    chkbtnobj[0].checked = true;
  }
};
const handleShortlistIcon = (event) => {
  event.target.style.display = "none";
  const parentNode = event.target.parentNode;
  parentNode.lastChild.style.display = "";
};

const handleRemoveShortlistIcon = (event) => {
  event.target.style.display = "none";
  const parentNode = event.target.parentNode;
  parentNode.firstChild.style.display = "";
};
export { SelectSingle, handleShortlistIcon, handleRemoveShortlistIcon };
