import React from "react";
import GoToTop from "../../../common/GoToTop";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import "../../../../styles/app.scss";
import { Link } from "react-router-dom";
import DashboardCards from "../../common/DashboardCards";
import RequirementList from "./Requirement/RequirementList";

const ClientDetails = () => {
  const token = useSelector((state) => state.token);

  const data = [
    {
      name: "Total Requirements",
      progressColor: "#2BC155",
      count: 100,
    },
    {
      name: "Interview Scheduled",
      progressColor: "#3F9AE0",
      count: 1,
    },
    {
      name: "Shortlisted Candidates",
      progressColor: "#FF9B52",
      count: 2,
    },
    {
      name: "Active Jobs",
      progressColor: "#8743DF",
      count: 1,
    },
  ];

  return (
    <div className={"col mx-5"}>
      <div className={"contentholder w-100 pl-4 pb-3 pt-4 pr-4 mt-5"}>
        <div className={"row"}>
          <div
            className={"col-md-2 pt-4"}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <figure className="story_shape">
              <img
                src="/icon/common/darkprofile.png"
                alt="person on a tour"
                className="story_img"
              />
              <figcaption className="story_caption">
                {/* {props.firstName.charAt(0)}
                  {props.lastName.charAt(0)} */}
                DS
              </figcaption>
            </figure>
          </div>
          <div className={"col-md-4"}>
            <div className={"row"}>
              <div className={"col"}>
                <label className="parafont">
                  Company Name :{" "}
                  <font style={{ fontWeight: "normal" }}>
                    Debapriya Subudhi
                  </font>
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col"}>
                <label className="parafont">
                  DS Client ID :{" "}
                  <font style={{ fontWeight: "normal" }}>DS1092020</font>
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col"}>
                <label className="parafont">
                  Contact Name :{" "}
                  <font style={{ fontWeight: "normal" }}>
                    Debapriya Subudhi
                  </font>
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col"}>
                <label className="parafont">
                  Business Email ID :{" "}
                  <font style={{ fontWeight: "normal" }}>
                    debapriyasubudhi2000@gmail.com
                  </font>
                </label>
              </div>
            </div>
          </div>
          <div className={"col-md-3"}>
            <div className={"row"}>
              <div className={"col"}>
                <label className="parafont">
                  Title : <font style={{ fontWeight: "normal" }}>HR</font>
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col"}>
                <label className="parafont">
                  Phone :{" "}
                  <font style={{ fontWeight: "normal" }}>(999) 898-7889</font>
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col"}>
                <label className="parafont">Address :</label>
                <font className="parafont" style={{ fontWeight: "normal" }}>
                  {" "}
                  1629,K St NW Suite 300 <br /> Washington,DC,20006,US
                </font>
              </div>
            </div>
          </div>
          <div className={"col-md-3"}>
          <div className={"row"}>
              <div className={"col"}>
                <label className="parafont">
                  Company FEIN : <font style={{ fontWeight: "normal" }}>88-8234567</font>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"row"}>
        <DashboardCards data={data} col={"col-lg-3"}/>
      </div>
      <RequirementList />
      <GoToTop />
    </div>
  );
};
export default React.memo(ClientDetails);
