import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import GoToTop from "../common/GoToTop";
import AddMoreAddress from "../../common-component/AddMoreAddress";
import { submitProfile } from "../../function/freelance/validateField";
import { validateRegistration } from "../../function/recruiter/validateField";
import { freegetDetails } from "../../api/freelance/getDetails";
import { useSelector } from "react-redux";
import { CreateSignedUrlAPI, uploadImage } from "../../api/common/ImageUpload";
import { zipCode, zipCodeDetails } from "../../api/common/zipCode";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";


const Profile = () => {
  // return profile page
  const [loader, setLoader] = useState(false);
  const [addmore, setAddMore] = useState(false);
  const [addMoreInformation, setAddMoreInformation] = useState({
    zipCode: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    county: "",
    state: "",
    country: "",
  });

  const [addMoreError, setAddMoreError] = useState({
    zipCode: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    county: "",
    state: "",
    country: "",
  });
  const [information, setInformation] = useState({
    companyName: "",
    fein: "",
    companyWebsite: "",
    workPhone: "",
    fax: "",

    zipCode: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    county: "",
    state: "",
    country: "",

    isPrimaryAddress: false,
    logo: "",
    profilePicture: "",

    firstName: "",
    middleName: "",
    lastName: "",
    mobileNumber: "",
    title: "",
    email: "",
  });

  const [error, setError] = useState({
    companyName: "",
    fein: "",
    companyWebsite: "",
    workPhone: "",
    fax: "",

    zipCode: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    county: "",
    state: "",
    country: "",

    firstName: "",
    middleName: "",
    lastName: "",
    mobileNumber: "",
    title: "",
    email: "",
  });
  const history = useHistory();
  let savebtnRef = useRef();
  const token = useSelector((state) => state.token);

 useEffect(() => {
    async function callUserDetails() {
      try {
        const response = await freegetDetails(token);
        let data = await response.json();
        const status = response.status;
        if (status === 200 && !information.fein) {
          const payload = data.payload;
          let primary = 0;
          let secondary = 1;
          if (payload.addresses.length > 1) {
            primary = payload.addresses[0].isPrimary ? 0 : 1;
            secondary = payload.addresses[1].isPrimary ? 0 : 1;
          }
          const primeAdd = payload.addresses[primary];
          if (!information.mobileNumber) {
            setInformation({
              firstName: payload.firstName,
              middleName: payload.middleName,
              lastName: payload.lastName,
              mobileNumber: payload.mobile || "",
              title: payload.requirementTitle,
              email: payload.email,
              companyName: payload.companyName,
              fein: payload.feinNumber,
              companyWebsite: payload.website,
              workPhone: payload.workPhone,
              fax: payload.fax || "",
              logo: payload.logo,
              profilePicture: payload.profilePicture,
              addressLine1: primeAdd.addressLine1,
              addressLine2: primeAdd.addressLine2,
              zipCode: primeAdd.zipCode,
              city: primeAdd.city,
              state: primeAdd.state,
              county: primeAdd.county,
              country: primeAdd.country,
              isPrimaryAddress: primeAdd.isPrimary,
            });
            setAddMoreInformation({
              country: "United States",
            });
            try {
              const addMoreAddr = payload.addresses[secondary];
              if (addMoreAddr.addressLine1) {
                setAddMore(true);
                setAddMoreInformation({
                  zipCode: addMoreAddr.zipCode,
                  addressLine1: addMoreAddr.addressLine1,
                  addressLine2: addMoreAddr.addressLine2,
                  city: addMoreAddr.city,
                  county: addMoreAddr.county,
                  state: addMoreAddr.state,
                  country: addMoreAddr.country,
                });
              }
            } catch {}
          }
        }
      } catch {}
    }
    callUserDetails();
  }, []);

  const schema = yup.object().shape({
    picture: yup
      .mixed()
      .test("name", "Please select a file", (value) => {
        return value[0] && value[0].name !== "";
      })
      .test("fileSize", "File size < 5MB", (value, context) => {
        return value && value[0] && value[0].size <= 5000000;
      })
      .test("type", "Please upload a valid file", (value) => {
        return value && value[0] && (
            value[0].type === "image/jpeg" ||
            value[0].type === 'image/png' ||
            value[0].type === 'image/jpg'
           );
      })
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  //Integration for upload logo
  function handleUploadLogo() {
    const file = document.getElementById("freelanceProfileLogo").files[0];
    const obj = {
      for: "FREELANCE_RECRUITER",
      files: [
        {
          extension: file.name.split(".")[1],
          contentType: file.type,
          fileName: file.name,
        },
      ],
    };
    CreateSignedUrlAPI(token, obj)
      .then((result) => {
        const statusCode = result.status;
        const data = result.json();
        return Promise.all([statusCode, data]).then((res) => ({
          statusCode: res[0],
          data: res[1],
        }));
      })
      .then((res) => {
        if (res.statusCode === 200) {
          const promises = res.data?.payload?.signedUrls.map((url) => {
            uploadImage(url.signedUrl, file);
            return url;
          });
          Promise.all(promises).then((data) => {
            uploadImageListHandler(data);
          });
        }
      });
  }
  function uploadImageListHandler(items) {
    setTimeout(() => {
      setInformation({
        ...information,
        logo: items[0].fileUrl,
      });
    }, 1000);
  }

  function handleProfilePicture() {
    const file = document.getElementById("freelanceProfilePicture").files[0];
    const obj = {
      for: "FREELANCE_RECRUITER",
      files: [
        {
          extension: file.name.split(".")[1],
          contentType: file.type,
          fileName: file.name,
        },
      ],
    };
    CreateSignedUrlAPI(token, obj)
      .then((result) => {
        const statusCode = result.status;
        const data = result.json();
        return Promise.all([statusCode, data]).then((res) => ({
          statusCode: res[0],
          data: res[1],
        }));
      })
      .then((res) => {
        if (res.statusCode === 200) {
          const promises = res.data?.payload?.signedUrls.map((url) => {
            uploadImage(url.signedUrl, file);
            return url;
          });
          Promise.all(promises).then((data) => {
            uploadProfileImageListHandler(data);
          });
        }
      });
  }
  function uploadProfileImageListHandler(items) {
    setTimeout(() => {
      setInformation({
        ...information,
        profilePicture: items[0].fileUrl,
      });
    }, 1000);
  }

  //zip code
  const [zipCodelist, setZipCodeList] = useState([]);
  const [show, setShow] = useState(false);

  async function handleCodeChange() {
    let data = information.zipCode;
    const response = await zipCode(token, data);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setZipCodeList(payload);
       if (payload.length === 0) {
         setShow(false);
         setError({
           ...error,
           zipCode: "please enter valid zipCode",
         });
       }
    }
  }

  useEffect(() => {
    handleCodeChange();
  }, [information.zipCode]);

  async function selectedPincode(zipCodeId, zipCode) {
    setShow(false);
    setInformation({
      ...information,
      zipCode: zipCode,
    });
    setError({
      ...error,
      zipCode:""
    })
    const response = await zipCodeDetails(token, zipCodeId);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setInformation({
        ...information,
        zipCode: payload.zip,
        city: payload.city,
        state: payload.stateName,
        county: payload.countyName,
      });
      setError({
        ...error,
        zipCode: "",
        city:"",
        state: ""
      })
    }
  }

  // zipcode dropdown
  const ref = React.useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (show && ref.current && !ref.current.contains(event.target)) {
        document.getElementById("zipCodeDiv").style.display = "none";
      }
      document.body.removeEventListener("click", handleClickOutside);
    };
    document.body.addEventListener("click", handleClickOutside);
  }, [show]);

  function callBoth(event) {
    validateRegistration(event, setInformation, setError);
    setShow(true);
  }
  //add mor zip code
  const [addzipCodelist, setAddZipCodeList] = useState([]);
  
  async function handleAddCodeChange() {
    let data = addMoreInformation.zipCode ? addMoreInformation.zipCode : "";
    const response = await zipCode(token, data);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setAddZipCodeList(payload);
    }
  }

  useEffect(() => {
    handleAddCodeChange();
  }, [addMoreInformation.zipCode]);

  function formatPhoneNumber(event, setInformation) {
    const value = event.target.value;
    const input = value.replace(/\D/g, "").substring(0, 10);
    const firstLetter = input.substring(0,1);
    const first = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);
    if(firstLetter === '0' || firstLetter === '1') {
      setError({
        ...error,
        mobileNumber: "Mobile number should not start with '0 or 1'"
      })
    }
    else {
      if(input.length !== 10 && input.length !== 0) {
        setError({
          ...error,
          mobileNumber: "Must be 10 digits"
        })
      }
      else {
        setError({
          ...error,
          mobileNumber: ""
        })
      }
    }
    if (input.length > 6) {
      setInformation({
        ...information,
        mobileNumber: `(${first}) ${middle}-${last}`,
      });
    } else if (input.length > 3) {
      setInformation({
        ...information,
        mobileNumber: `(${first}) ${middle}`,
      });
    } else if (input.length >= 0) {
      setInformation({
        ...information,
        mobileNumber: input,
      });
    }
  }

  function formatWorkPhoneNumber(event, setInformation) {
    const value = event.target.value;
    const input = value.replace(/\D/g, "").substring(0, 10);
    const firstLetter = input.substring(0,1);
    const first = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);
    if(firstLetter === '0' || firstLetter === '1') {
      setError({
        ...error,
        workPhone: "Workphone number should not start with '0 or 1'"
      })
    }
    else {
      if(input.length !== 10 && input.length !== 0) {
        setError({
          ...error,
          workPhone: "Must be 10 digits"
        })
      }
      else {
        setError({
          ...error,
          workPhone: ""
        })
      }
    }
    if (input.length > 6) {
      setInformation({
        ...information,
        workPhone: `(${first}) ${middle}-${last}`,
      });
    } else if (input.length > 3) {
      setInformation({
        ...information,
        workPhone: `(${first}) ${middle}`,
      });
    } else if (input.length >= 0) {
      setInformation({
        ...information,
        workPhone: input,
      });
    }
  }

  function formatFaxNumber(event, setInformation) {
    const value = event.target.value;
    const input = value.replace(/\D/g, "").substring(0, 10);
    const firstLetter = input.substring(0,1);
    const first = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);
    if(firstLetter === '0' || firstLetter === '1') {
      setError({
        ...error,
        fax: "Fax number should not start with '0 or 1'"
      })
    }
    else {
      if(input.length !== 10 && input.length !== 0) {
        setError({
          ...error,
          fax: "Must be 10 digits"
        })
      }
      else {
        setError({
          ...error,
          fax: ""
        })
      }
    }
    if (input.length > 6) {
      setInformation({
        ...information,
        fax: `(${first}) ${middle}-${last}`,
      });
    } else if (input.length > 3) {
      setInformation({
        ...information,
        fax: `(${first}) ${middle}`,
      });
    } else if (input.length >= 0) {
      setInformation({
        ...information,
        fax: input,
      });
    }
  }

  function formatFeinNumber(event, setInformation) {
    const value = event.target.value;
    const input = value.replace(/\D/g,"").substring(0, 9);
    const first = input.substring(0, 2);
    const last = input.substring(2, 9);
    const firstLetter = input.substring(0,1);

    if(firstLetter === '0') {
      setError({
        ...error,
        fein: "Fein number should not start with '0'"
      })
    }
    else {
      if(input.length !== 9 && input.length !== 0) {
        setError({
            ...error,
            fein: "Must be 9 digits"
          })
      }
      else {
        setError({
          ...error,
          fein: ""
        })
      }
    }
    
    if (input.length > 2) {
      setInformation({
        ...information,
        fein: `${first}-${last}`,
      });
    } else if (input.length >= 0) {
      setInformation({
        ...information,
        fein: input,
      });
    }
  }

  try {
    document.getElementById("replacetitleimg").innerText = "Profile Setting";
    document.getElementById("dashboardfont").innerText = "Profile";
  } catch {}

  return (
    <div className={"col mx-5"}>
      <form
        className={"form-group"}
        noValidate
        onSubmit={(event) =>
          submitProfile(
            event,
            history,
            setLoader,
            savebtnRef,
            error,
            information,
            setError,
            addmore,
            addMoreInformation,
            setAddMoreError,
            token
          )
        }
      >
        <ToastContainer position={"top-center"} />
        <div className={"row pt-2"}>
          <div className={"col"}>
            <div className={"pb-0"}>
              <div className={"row mt-2"}>
                <div className={"col-lg-1 pt-1"}>
                  <i
                    className="fas fa-arrow-left backicon"
                    onClick={() => history.goBack()}
                  ></i>
                </div>
                <div className={"col-lg-5"}>
                  <label
                    style={{
                      fontWeight: "600",
                      fontSize: "14px",
                      color: "#000000",
                    }}
                  >
                    Edit Profile
                  </label>
                </div>
              </div>
            </div>
            {/*first box*/}
            <div className={"row px-5 pr-5 mx-3"}>
              <div className={"w-100 pt-4 pb-4 px-5 contentholder"}>
                <div className={"row"}>
                  <div className={"col-md"}>
                    <label
                      htmlFor={"cname"}
                      className={"dashboardforminputtext"}
                    >
                      Company Name
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"cname"}
                      name={"companyName"}
                      maxLength={50}
                      value={information.companyName}
                      style={{
                        border: `${error.companyName ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.companyName}
                    </font>
                  </div>
                </div>
                <div className={"row pt-2"}>
                  <div className={"col-md-5"}>
                    <label
                      htmlFor={"ffin"}
                      className={"dashboardforminputtext"}
                    >
                      Company FEIN<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      placeholder={"xx-xxxxxxx"}
                      id={"ffin"}
                      name={"fein"}
                      maxLength={10}
                      value={information.fein}
                      style={{
                        border: `${error.fein ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        formatFeinNumber(event,setInformation)
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.fein}
                    </font>
                  </div>
                  <div className={"col-md-7"}>
                    <label
                      htmlFor={"cwebsite"}
                      className={"dashboardforminputtext"}
                    >
                      Company Website
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"cwebsite"}
                      name={"companyWebsite"}
                      value={information.companyWebsite}
                      style={{
                        border: `${
                          error.companyWebsite ? "1px solid red" : ""
                        }`,
                      }}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.companyWebsite}
                    </font>
                  </div>
                </div>
                <div className={"row pt-2"}>
                  <div className={"col-md-10"}>
                    <div className={"row"}>
                      <div className={"col-md-6"}>
                        <label
                          htmlFor={"workph"}
                          className={"dashboardforminputtext"}
                        >
                          Work Phone<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type={"text"}
                          name={"workPhone"}
                          id={"workph"}
                          maxLength={14}
                          placeholder={" (XXX) XXX-XXXX"}
                          value={information.workPhone}
                          style={{
                            border: `${error.workPhone ? "1px solid red" : ""}`,
                          }}
                          className={
                            "form-control dashboardforminputfield shadow-none"
                          }
                          onChange={(event) =>
                            formatWorkPhoneNumber(event, setInformation)
                          }
                        />
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.workPhone}
                        </font>
                      </div>
                      <div className={"col-md"}>
                        <label
                          htmlFor={"mobile"}
                          className={"dashboardforminputtext"}
                        >
                          Fax{" "}
                        </label>
                        <input
                          type={"text"}
                          placeholder={" (XXX) XXX-XXXX"}
                          id={"mobile"}
                          name={"fax"}
                          maxLength={14}
                          value={information.fax}
                          style={{
                            border: `${error.fax ? "1px solid red" : ""}`,
                          }}
                          className={
                            "form-control dashboardforminputfield form-select shadow-none"
                          }
                          onChange={(event) =>
                            formatFaxNumber(event, setInformation)
                          }
                        />
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.fax}
                        </font>
                      </div>
                    </div>
                    <div className={"row "}>
                      <div className={"col-md-6 pt-3"}>
                        <label className={"dashboardforminputtext"}>
                          Upload Company Logo
                        </label>
                        <input
                          type={"text"}
                          placeholder={information.resume
                            ? information.resume.name
                            : "logo.jpg"}
                          id={"filename"}
                          className={
                            "form-control dashboardforminputfield form-select shadow-none"
                          }
                          disabled
                        />
                      </div>
                      <div className={"col-md-2"}>
                        <div className={"pt-5 pl-4"}>
                          <label className={"btn browsebtn"}>
                            <input
                              type="file"
                              accept=".jpg,.pdf,.jpge"
                              style={{ opacity: 0, position: "absolute" }}
                              id={"freelanceProfileLogo"}
                              name={"picture"}
                              {...register("picture")}
                              onChange={(event) => {
                                setInformation({
                                  ...information,
                                  resume: event.target.files[0],
                                })
                              }}
                            />
                            <span>Browse</span>
                          </label>
                        </div>
                      </div>
                      <div className={"col-md-3"}>
                        <div className={"pt-5 pl-5"}>
                          <button
                            type={"button"}
                            className={"btn uploadbtn"}
                            onClick={handleSubmit(handleUploadLogo)}
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"col-md pl-0 pr-0"}>
                    <img
                      src={
                        information.logo
                          ? information.logo
                          : "../../icon/candidate/dashboard/profilePicture.png"
                      }
                      alt="logo"
                      className={"w-100 h-100"}
                      style={{ transform: "scale(0.8)" }}
                    />
                  </div>
                </div>
                <div className={"col-md"}>
                  {errors.picture && (
                    <p className="errormsg">{errors.picture.message}</p>
                  )}
                  <label
                    className={"fileerr errormsg"}
                    style={{ fontSize: "14px" }}
                  ></label>
                </div>
              </div>
            </div>
            {/*second box*/}

            <div className={"row  px-5 pr-5 mx-3 pt-4"}>
              <div
                className={"w-100 pt-4 pb-0 px-5 contentholder"}
                id={"addmoreaddress"}
              >
                <div className={"row"}>
                  <div className={"col-md-3"}>
                    <label
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#5C5C5C",
                      }}
                    >
                      Primary Address
                    </label>
                  </div>
                 </div>
                <div className={"row pt-2"}>
                  <div className={"col-md-4 input-placeholder"} ref={ref}>
                    <input
                      type={"text"}
                      name={"zipCode"}
                      value={information.zipCode}
                      style={{
                        border: `${error.zipCode ? "1px solid red" : ""}`,
                      }}
                      required
                      className={
                        "form-control dashboardforminputfield ziplocation shadow-none"
                      }
                      onChange={callBoth}
                      maxLength={"5"}
                      autoComplete="off"
                      onClick={() => {
                        setShow(!show);
                      }}
                    />
                    <div className={"placeholder"}>
                      Enter Zip Code<span>*</span>
                    </div>
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.zipCode}
                    </font>
                    <div
                      id={"zipCodeDiv"}
                      style={{
                        display: `${
                          show && information.zipCode > 0 ? "block" : "none"
                        }`,
                      }}
                    >
                      {zipCodelist &&
                        zipCodelist.length > 0 &&
                        zipCodelist.map((employee) => (
                          <div
                            key={employee.id}
                            style={{ cursor: "pointer" }}
                            value={employee.zip}
                            onClick={() =>
                              selectedPincode(employee.id, employee.zip)
                            }
                          >
                            {employee.zip} - {employee.city}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className={"col-md input-placeholder"}>
                    <input
                      type={"text"}
                      name={"addressLine1"}
                      value={information.addressLine1}
                      style={{
                        border: `${error.addressLine1 ? "1px solid red" : ""}`,
                      }}
                      required
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <div className={"placeholder"}>
                      Address Line 1<span>*</span>
                    </div>
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.addressLine1}
                    </font>
                  </div>
                </div>
                <div className={"row pt-4"}>
                  <div className={"col-md"}>
                    <input
                      type={"text"}
                      placeholder={"Address Line 2"}
                      name={"addressLine2"}
                      value={information.addressLine2}
                      style={{
                        border: `${error.addressLine2 ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control dashboardforminputfield address shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.addressLine2}
                    </font>
                  </div>
                  <div className={"col-md-4 input-placeholder"}>
                    <input
                      type={"text"}
                      name={"city"}
                      value={information.city}
                      style={{
                        border: `${error.city ? "1px solid red" : ""}`,
                      }}
                      required
                      disabled
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    {
                      information.city === "" &&
                      <div className={"placeholder"}>
                      City<span>*</span>
                    </div>
                    }
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.city}
                    </font>
                  </div>
                </div>
                <div className={"row pt-4"}>
                  <div className={"col-md-4"}>
                    <input
                      type={"text"}
                      name={"county"}
                      value={information.county}
                      style={{
                        border: `${error.county ? "1px solid red" : ""}`,
                      }}
                      placeholder="County"
                      disabled
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.county}
                    </font>
                  </div>
                  <div className={"col-md-4 input-placeholder"}>
                    <input
                      type={"text"}
                      name={"state"}
                      value={information.state}
                      style={{
                        border: `${error.state ? "1px solid red" : ""}`,
                      }}
                      required
                      disabled
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    {
                      information.state === "" &&
                      <div className={"placeholder"}>
                      State<span>*</span>
                    </div>
                    }
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.state}
                    </font>
                  </div>
                  <div className={"col-md-4 input-placeholder"}>
                    <input
                      type={"text"}
                      name={"country"}
                      value={information.country}
                      style={{
                        border: `${error.country ? "1px solid red" : ""}`,
                      }}
                      required
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <div className={"placeholder"}>
                      Country<span>*</span>
                    </div>
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.country}
                    </font>
                  </div>
                </div>

                {addmore ? (
                  <div className="row pt-2 pb-3"></div>
                ) : (
                  <div className={"row pr-5"}>
                    <div className={"col-md"}>
                      <div
                        className={
                          "btn float-right badge border-0 pt-3 newskill"
                        }
                        style={{ backgroundColor: "white" }}
                        onClick={() => setAddMore(true)}
                      >
                        + Add Mailing Address
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {addmore && (
              <AddMoreAddress
                information={addMoreInformation}
                setInformation={setAddMoreInformation}
                error={addMoreError}
                setError={setAddMoreError}
                addmore={addmore}
                setAddMore={setAddMore}
                zipCodeList={addzipCodelist}
              />
            )}
            {/*<AddMoreAddress />*/}
            {/*third box*/}
            <div className={"row px-5 pr-5 mx-3 pt-4"}>
              <div className={"w-100 pt-3 pb-4 px-5 contentholder"}>
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "15px",
                    color: "#212529",
                  }}
                >
                  Personal Info
                </label>
                <div className={"row"}>
                  <div className={"col-md"}>
                    <label
                      htmlFor={"fname"}
                      className={"dashboardforminputtext"}
                    >
                      First Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"fname"}
                      name={"firstName"}
                      maxLength={20}
                      value={information.firstName}
                      style={{
                        border: `${error.firstName ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control dashboardforminputfield names shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.firstName}
                    </font>
                  </div>
                  <div className={"col-md"}>
                    <label
                      htmlFor={"mname"}
                      className={"dashboardforminputtext"}
                    >
                      Middle Name
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"mname"}
                      name={"middleName"}
                      maxLength={20}
                      value={information.middleName}
                      style={{
                        border: `${error.middleName ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.middleName}
                    </font>
                  </div>
                  <div className={"col-md"}>
                    <label
                      htmlFor={"lname"}
                      className={"dashboardforminputtext"}
                    >
                      Last Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"lname"}
                      name={"lastName"}
                      maxLength={20}
                      value={information.lastName}
                      style={{
                        border: `${error.lastName ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control dashboardforminputfield names shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.lastName}
                    </font>
                  </div>
                </div>
                <div className={"row pt-2"}>
                  <div className={"col-md"}>
                    <label htmlFor={"mno"} className={"dashboardforminputtext"}>
                      Mobile Number
                    </label>
                    <input
                      type={"text"}
                      placeholder={" (xxx) xxx-xxxx"}
                      id={"mno"}
                      name={"mobileNumber"}
                      maxLength={14}
                      value={information.mobileNumber}
                      style={{
                        border: `${error.mobileNumber ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control dashboardforminputfield mobile shadow-none"
                      }
                      onChange={(event) => {
                        formatPhoneNumber(event, setInformation);
                      }}
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.mobileNumber}
                    </font>
                  </div>
                  <div className={"col-md"}>
                    <label
                      htmlFor={"rtitle"}
                      className={"dashboardforminputtext"}
                    >
                      Title<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"rtitle"}
                      name={"title"}
                      value={information.title}
                      style={{
                        border: `${error.title ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control dashboardforminputfield shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.title}
                    </font>
                  </div>
                  <div className={"col-md"}>
                    <label htmlFor={"ssn"} className={"dashboardforminputtext"}>
                      Corporate Email ID<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      id={"ssn"}
                      placeholder={""}
                      name={"email"}
                      value={information.email}
                      style={{
                        border: `${error.email ? "1px solid red" : ""}`,
                      }}
                      className={
                        "form-control dashboardforminputfield mobile shadow-none"
                      }
                      onChange={(event) =>
                        validateRegistration(event, setInformation, setError)
                      }
                      disabled
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.email}
                    </font>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={"row px-5 mx-3 pt-4"}>
              <div className={"w-100 px-5 pb-2 contentholder"}>
                <div className={"row"}>
                  <div className={"col-lg-4 pt-5"}>
                    <input
                      type={"text"}
                      className={
                        "form-control resumeuploadtextbox py-3 shadow-none"
                      }
                      placeholder={"Upload profile"}
                      id={"profilePictureName"}
                      disabled
                    />
                  </div>
                  <div className={"col-lg-2 pt-5"}>
                    <label className={"browsebtn btn"}>
                      <input
                        type="file"
                        accept=".jpg,.pdf,.jpeg"
                        style={{ display: "None" }}
                        id={"freelanceProfilePicture"}
                        onChange={(event) => {
                          document.getElementById(
                            "profilePictureName"
                          ).placeholder = event.target.files[0].name;
                        }}
                      />
                      <span>Browse</span>
                    </label>
                  </div>
                  <div className={"col-lg-3 pt-5"}>
                    <button
                      type={"button"}
                      className={"btn settinguploadbtn"}
                      onClick={handleProfilePicture}
                    >
                      Upload Profile Picture
                    </button>
                  </div>
                  <div className={"col-lg pl-0 pr-0"}>
                    <img
                      src={
                        information.profilePicture
                          ? information.profilePicture
                          : "../../icon/candidate/dashboard/profilePicture.png"
                      }
                      alt="logo"
                      className={"w-100 h-100"}
                      style={{ transform: "scale(0.6)" }}
                    />
                  </div>
                </div>
              </div>
            </div> */}
            {/*two button area of page last*/}
            <div className={"row mx-3 my-4"}>
              <div className={"col"}>
                <div className={"row float-right"}>
                  <button
                    ref={savebtnRef}
                    type={"submit"}
                    formNoValidate
                    className={"btn mr-5 my-2 uploadbtn"}
                  >
                    {loader ? (
                      <i
                        className = "fa fa-refresh fa-spin"
                        style={{ padding: "3px 5px" }}
                      ></i>
                    ) : (
                      ""
                    )}
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <GoToTop />
    </div>
  );
};
export default React.memo(Profile);
