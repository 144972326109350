import { CANDIDATESECURITY } from "../ENVIRONMENT";

async function postSecurity(token, data) {
  // api for submit candidate buildresume security
  return await fetch(CANDIDATESECURITY, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}

async function getSecurity(token) {
  // api for candidate buildresume security details
  return await fetch(CANDIDATESECURITY, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
}
export { postSecurity, getSecurity };
