import { GETCLIENTUSERDETAILS } from "../ENVIRONMENT";

// api for client user details
async function getDetails(token) {
  const response = await fetch(GETCLIENTUSERDETAILS, {
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { getDetails };
