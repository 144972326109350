import { RECRUITERCLIENTPERMANENTDATA } from "../../ENVIRONMENT";

// api for recruiter client permanent data
async function recgetPermanentDataClient(token) {
  return await fetch(RECRUITERCLIENTPERMANENTDATA, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
}

export { recgetPermanentDataClient };
