import {FREELANCESPECIFICCANDIDATEDETAILS} from "../../ENVIRONMENT"

// api for freelance specific candidate details
async function freeCandidateDetails(token, id) {
    const response = await fetch(FREELANCESPECIFICCANDIDATEDETAILS + `/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    });
    return response;
  }

   // api for freelance specific candidate matches
async function freeSpecificCandidateMatches(token, id,currentPage) {
  const response = await fetch(FREELANCESPECIFICCANDIDATEDETAILS + `/${id}/matchingjobs?limit=4&page=${currentPage}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

 // api for freelance specific candidate applications
 async function freeSpecificCandidateApplication(token,id,data,currentPage) {
  const response = await fetch(FREELANCESPECIFICCANDIDATEDETAILS + `/${id}/matchingjobs?limit=4&page=${currentPage}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body:JSON.stringify(data)
  });
  return response;
}

export {freeCandidateDetails,freeSpecificCandidateMatches,freeSpecificCandidateApplication};