function clientArchiveRedirect(dispatch) {
  // if click on viewmore button of dashboardview it will land to matchingjob or application page
  const parent = document.getElementById("applicationbtn");
  const dom = parent.firstChild;
  const reset_prev = parent.nextSibling.firstChild;
  // matching job tab change appearance as selected
  dom.style.cssText =
    "background:linear-gradient(90deg,#564BB6,#8C79C2) ;border-radius:10px 0px 0px 10px";
  dom.firstChild.firstChild.style.cssText =
    "box-shadow: 0px 0px 31px rgba(145,96,209,0.28);border-radius:18px";
  dom.lastChild.firstChild.style.cssText = "color:#292929;font-weight:600";
  // reset previous tab button appearance as unselected
  reset_prev.style.cssText = "";
  reset_prev.firstChild.firstChild.style.cssText = "";
  reset_prev.lastChild.firstChild.style.cssText = "";
  dispatch({
    type: "btnstate",
    value: {
      btn: dom.firstChild.firstChild,
      txt: dom.lastChild.firstChild,
      bg: dom,
    },
  });
}

export { clientArchiveRedirect };
