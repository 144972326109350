import { FREELANCECLIENTMATCHINGJOBS } from "../../ENVIRONMENT";

// api for freelance application filter
async function freefilterApplication(token, data, currentPage) {
  const response = await fetch(
    FREELANCECLIENTMATCHINGJOBS + `?id=${data}&limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for freelance candidate schedule filter
async function freefilterScheduledCandidates(
  token,
  data,
  cardName,
  currentPage
) {
  const response = await fetch(
    FREELANCECLIENTMATCHINGJOBS +
      `?id=${data}&card=${cardName}&limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

async function freefilterStatusDropList(token, cardName, currentPage) {
  // api for getClient details
  const response = await fetch(
    FREELANCECLIENTMATCHINGJOBS +
      `?card=${cardName}&limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export {
  freefilterApplication,
  freefilterScheduledCandidates,
  freefilterStatusDropList,
};
