import { CANDIDATECERTIFICATE } from "../ENVIRONMENT";

function certificateList(token) {
  // api for candidate certificate list
  const response = fetch(CANDIDATECERTIFICATE, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}
export { certificateList };
