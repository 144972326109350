import { FREELANCEBUILDSOCIAL } from "../../../ENVIRONMENT";

// api for freelance candidate social
async function freepostBuildResumeSocial(token, data,id) {
  return await fetch(FREELANCEBUILDSOCIAL + `/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}
// api for freelance candidate social details
async function freegetBuildResumeSocial(token,id) {
  return await fetch(FREELANCEBUILDSOCIAL + `/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
}

export { freepostBuildResumeSocial, freegetBuildResumeSocial };
