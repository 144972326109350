import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { forgotPassword } from "../../api/authentication/forgot";

const ForgotPassword = () => {
  // return forgotpassword page
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  let btnRef = useRef();
  const validateForgotPassword = async (form) => {
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
    }
    const userdetails = { email: form.email };
    const response = await forgotPassword(userdetails);
    if (response.status === 200) {
      const msg = await response.json();
      document.getElementById("msgstatus").innerText = msg.message;
      document.getElementById("msgstatus").style.cssText = "color:green";
      btnRef.current.removeAttribute("disabled");
    }
    if (response.status === 404) {
      const msg = await response.json();
      document.getElementById("msgstatus").innerText = msg.message;
      document.getElementById("msgstatus").style.cssText = "color:red";
      btnRef.current.removeAttribute("disabled");
    }
  };
  return (
    <div className="height-100vh">
      <div className={"col-md-12 h-100"}>
        <div className="row h-100">
          <div
            className="col-lg-6 background-colored-primary-white"

          >
            <form onSubmit={handleSubmit(validateForgotPassword)}>
              <div className="row">
                <div className="col-md-3  pt-4 pl-5">
                  <Link to="/">
                    {" "}
                    <img
                      src="/icon/Authentication/back.png"
                      alt="back"
                      className="img-fluid  my-2"
                    />
                  </Link>
                </div>
                <div className="col-md-5 mt-3">
                  <img
                    src="/icon/Authentication/DirectHire_Dark_logo.png"
                    className="img-fluid mx-auto d-block pt-2"
                    alt="dollar"
                    width='100%'
                    height="100%"
                  />
                </div>
              </div>
              <div className="row pt-5 pl-5">
                <div className="col-md">
                  <label
                    className="font-weight-bold colored-Midnight-blue font-size-31px "

                  >
                    Forgot password
                  </label>
                </div>
              </div>
              <div className="row pt-2 pl-5 pr-5">
                <div className="col-md">
                  <label
                    className="colored-Misty-gray font-weight-normal font-size-16px"

                  >
                    Enter the email address associated with your Dollar Staffing
                    account ,we will email you a link to reset your password.
                  </label>
                </div>
              </div>
              <div className="row pl-5 pr-5 pt-3">
                <div className="col-md-11">
                  <label
                    className="colored-Misty-gray font-size-16px font-weight-normal"

                  >
                    For security reasons, we do NOT store your password. So rest
                    assured that we will never send your password via email.
                  </label>
                </div>
              </div>
              <div className="row pl-5 pt-5">
                <div className="col-md-9">
                  <input
                    type={"email"}
                    placeholder={"Registered Email ID"}
                    id={"email"}
                    className={"form-control forminputfield shadow-none"}
                    {...register("email", {
                      required: {
                        value: true,
                        message: "Please enter valid email ID",
                      },
                      pattern: {
                        value:
                          /^([a-zA-Z0-9+_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                        message: "Please enter valid email ID",
                      },
                    })}
                  />
                  {errors.email && (
                    <font className={"dashboardforminputtext text-danger font-weight-600"}
                    >
                      {errors.email.message}
                    </font>
                  )}
                </div>
              </div>
              <div className="row pt-3 pl-5">
                <div className="col-md">
                  <button
                    type={"submit"}
                    ref={btnRef}
                    className="save btn px-4 py-2"
                    onClick={handleSubmit(validateForgotPassword)}
                  >
                    Send Link
                  </button>
                </div>
              </div>
              <div className="row pt-3 pl-5">
                <div className="col-md">
                  <div id={"msgstatus"} className={"successmsg"}></div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-6 colored-Soft-lavender " >
            <img
              src="../icon/Authentication/password.jpg"
              alt="Forgot password"
              className="img-fluid mx-auto d-block w-75 rounded-circle my-5 pt-5"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(ForgotPassword);
