import React from "react";
import GoToTop from "../../common/GoToTop";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import "../../../styles/app.scss";
import { validateRegistration } from "../../../function/admin/ValidateField";

const CreateAdmin = () => {
  const token = useSelector((state) => state.token);
  const [loader, setLoader] = useState(false)
  const [information, setInformation] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    department: "",
    title: "",
    emailId: "",
    role: "",
    mobileNumber: ""
  });

  const [error, setError] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    department: "",
    title: "",
    emailId: "",
    role: "",
    mobileNumber: ""
  })

  function formatPhoneNumber(event, setInformation) {
    const value = event.target.value;
    const input = value.replace(/\D/g, "").substring(0, 10);
    const firstLetter = input.substring(0, 1);
    const first = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);
    if (firstLetter === '0' || firstLetter === '1') {
      setError({
        ...error,
        mobileNumber: "Mobile number should not start with '0 or 1'"
      })
    }
    else {
      if (input.length !== 10 && input.length !== 0) {
        setError({
          ...error,
          mobileNumber: "Must be 10 digits"
        })
      }
      else {
        setError({
          ...error,
          mobileNumber: ""
        })
      }
    }
    if (input.length > 6) {
      setInformation({
        ...information,
        mobileNumber: `(${first}) ${middle}-${last}`,
      });
    } else if (input.length > 3) {
      setInformation({
        ...information,
        mobileNumber: `(${first}) ${middle}`,
      });
    } else if (input.length >= 0) {
      setInformation({
        ...information,
        mobileNumber: input,
      });
    }
  }

  return (
    <div className={"col mx-4"}>
      <div className={"row pt-4"}>
        <div className={"col"}>
          <div className={"row px-5 pr-5 mx-3"}>
            <div className={"w-100 pt-4 pb-2 px-5 contentholder"}>
              <div className={"row"}>
                <div className={"col-md"}>
                  <label htmlFor={"fname"} className={"dashboardforminputtext"}>
                    First Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Type Here"}
                    id={"fname"}
                    name={"firstName"}
                    value={information.firstName}
                    maxLength={20}
                    style={{
                      border: `${error.firstName ? "1px solid red" : ""}`,
                    }}
                    onChange={(event) =>
                      validateRegistration(event, setInformation, setError)
                    }
                    className={
                      "form-control dashboardforminputfield names shadow-none"
                    }
                  />
                  <font
                    className={
                      "dashboardforminputtext text-danger text-align-center"
                    }
                  >
                    {error.firstName}
                  </font>
                </div>
                <div className={"col-md"}>
                  <label htmlFor={"mname"} className={"dashboardforminputtext"}>
                    Middle Name
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Type Here"}
                    id={"mname"}
                    name={"middleName"}
                    maxLength={20}
                    value={information.middleName}
                    style={{
                      border: `${error.middleName ? "1px solid red" : ""}`,
                    }}
                    className={
                      "form-control dashboardforminputfield shadow-none"
                    }
                    onChange={(event) =>
                      validateRegistration(event, setInformation, setError)
                    }
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {error.middleName}
                  </font>
                </div>
                <div className={"col-md"}>
                  <label htmlFor={"lname"} className={"dashboardforminputtext"}>
                    Last Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Type Here"}
                    id={"lname"}
                    name={"lastName"}
                    value={information.lastName}
                    maxLength={20}
                    style={{
                      border: `${error.lastName ? "1px solid red" : ""}`,
                    }}
                    className={
                      "form-control dashboardforminputfield names shadow-none"
                    }
                    onChange={(event) =>
                      validateRegistration(event, setInformation, setError)
                    }
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {error.lastName}
                  </font>
                </div>
              </div>
              <div className={"row pt-2 pb-2"}>
                <div className={"col-md"}>
                  <label htmlFor={"mno"} className={"dashboardforminputtext"}>
                    Mobile Number<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type={"text"}
                    placeholder={" (XXX) XXX-XXXX"}
                    id={"mno"}
                    name={"mobileNumber"}
                    maxLength={"14"}
                    value={information.mobileNumber}
                    style={{
                      border: `${error.mobileNumber ? "1px solid red" : ""}`,
                    }}
                    className={
                      "form-control dashboardforminputfield shadow-none"
                    }
                    onChange={(event) => {
                      //validateRegistration(event, setInformation, setError);
                      formatPhoneNumber(event, setInformation);
                    }}
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {error.mobileNumber}
                  </font>
                </div>
                <div className={"col-md"}>
                  <label
                    htmlFor={"rtitle"}
                    className={"dashboardforminputtext"}
                  >
                    Title<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Type Here"}
                    id={"rtitle"}
                    name={"resumeTitle"}
                    value={information.title}
                    style={{
                      border: `${error.title ? "1px solid red" : ""}`,
                    }}
                    className={
                      "form-control dashboardforminputfield shadow-none"
                    }
                    onChange={(event) =>
                      validateRegistration(event, setInformation, setError)
                    }
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {error.resumeTitle}
                  </font>
                </div>
                <div className={"col-md"}>
                  <label
                    htmlFor={"department"}
                    className={"dashboardforminputtext"}
                  >
                    Department<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Type Here"}
                    id={"department"}
                    name={"department"}
                    value={information.department}
                    style={{
                      border: `${error.resumeTitle ? "1px solid red" : ""}`,
                    }}
                    className={
                      "form-control dashboardforminputfield shadow-none"
                    }
                    onChange={(event) =>
                      validateRegistration(event, setInformation, setError)
                    }
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {error.resumeTitle}
                  </font>
                </div>
              </div>
              <div className={"row pt-2 pb-2"}>
                <div className={"col-md"}>
                  <label htmlFor={"mno"} className={"dashboardforminputtext"}>
                    Email Id<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Type Here"}
                    id={"mno"}
                    name={"emailId"}
                    value={information.emailId}
                    style={{
                      border: `${error.emailId ? "1px solid red" : ""}`,
                    }}
                    className={
                      "form-control dashboardforminputfield shadow-none"
                    }
                    onChange={(event) => {
                      validateRegistration(event, setInformation, setError);
                    }}
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {error.emailId}
                  </font>
                </div>
                <div className={"col-md"}>
                  <label htmlFor={"rrole"} className={"dashboardforminputtext"}>
                    User Role<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Type Here"}
                    id={"rrole"}
                    name={"role"}
                    value={information.role}
                    style={{
                      border: `${error.resumeTitle ? "1px solid red" : ""}`,
                    }}
                    className={
                      "form-control dashboardforminputfield shadow-none"
                    }
                    onChange={(event) =>
                      validateRegistration(event, setInformation, setError)
                    }
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {error.resumeTitle}
                  </font>
                </div>
              </div>
            </div>
          </div>
          <div className={"row hidebox"}>
            <div className={"col pt-3 pb-2"}>
              <button
                type="submit"
                className={"btn offset-sm-10 save buildhide"}
              // ref={expRef}
              >
                {loader ? (
                  <i
                    className="fa fa-refresh fa-spin"
                    style={{ padding: "3px 10px" }}
                  ></i>
                ) : (
                  ""
                )}
                Create Admin
              </button>
            </div>
          </div>
        </div>
      </div>
      <GoToTop />
    </div>
  );
};
export default React.memo(CreateAdmin);
