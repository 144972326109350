import { GETUSERINFO, REGISTERCANDIDATE } from "../../api/ENVIRONMENT.js";
// api for navbar user information
async function getUserInfo(token) {
  const stoken = sessionStorage.getItem("token");
  const response = await fetch(GETUSERINFO, {
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${stoken ? stoken : token}`,
    },
  });
  return response;
}
// api for register user information
async function getUserInfoForRegisterPage(token) {
  const stoken = sessionStorage.getItem("token");
  const response = await fetch(REGISTERCANDIDATE, {
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${stoken ? stoken : token}`,
    },
  });
  return response;
}

export { getUserInfo, getUserInfoForRegisterPage };
