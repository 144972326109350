import { FREELANCEBUILDSKILL } from "../../../ENVIRONMENT";

// api for freelance candidate skills
async function freepostBuildResumeSkill(token,data,id) {
  return await fetch( FREELANCEBUILDSKILL + `/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}
// api for freelance candidate skills details
async function freegetBuildResumeSkill(token,id) {
  return await fetch( FREELANCEBUILDSKILL + `/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
}

export { freepostBuildResumeSkill, freegetBuildResumeSkill };
