import { CLIENTBUILDREQUIREMENT } from "../ENVIRONMENT";

async function postBuildRequirementDescription(token, data,id) {
  // api for submit client buildrequirement description
  return await fetch(CLIENTBUILDREQUIREMENT+`/${id}/jobdescription`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
       "Authorization": `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}

export { postBuildRequirementDescription };
