import { APPLICATIONDETAILS } from "../ENVIRONMENT";

async function applicationSearch(token, data, currentPage) {
  // api for candidate application search
  const response = await fetch(
    APPLICATIONDETAILS + `?q=${data}&limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
export { applicationSearch };
