import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import GoToTop from "../../../common/GoToTop";
import { Link } from "react-router-dom";

const data = [
  {
    count: "109",
    countColor: "#FF9B52",
    progressColor: "#FF9B52",
    icon: "../../icon/common/person.png",
    iconColor: "#FFF0EB",
    message: "Candidate",
    Link: "/admin/freelance/candidate",
  },
  {
    count: "40",
    countColor: "#2BC155",
    progressColor: "#2BC155",
    icon: "../../icon/common/suitcase.png",
    iconColor: "#E9FFEF",
    message: "Requirement",
    Link: "/admin/freelance/requirement",
  },
];

const FreelanceDashboard = () => {
  const token = useSelector((state) => state.token);
  return (
    <div className={"col mx-3"}>
      <div
        className={"row"}
        style={{ display: "flex", justifyContent: "center", marginTop: "20%" }}
      >
        {data.map((data, index) => (
          <div key={index} className={"col-lg-3 pt-4"}>
            <Link to={data.Link}>
              <div
                className={"pt-3 pb-0 viewcontent"}
                style={{
                  background: "#FFFFFF",
                  boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)",
                  borderRadius: "20px",
                  fontFamily: "poppins",
                }}
              >
                <div className={"row mx-3"}>
                  <div className={"col-lg"}>
                    <label
                      style={{
                        fontWeight: "600",
                        fontSize: "50px",
                        color: data.countColor,
                      }}
                    >
                      {data.count}
                    </label>
                    <br />
                  </div>
                  <div className={"col-lg my-4"}>
                    <div className={"pl-5"}>
                      <span
                        className={"pt-3 pb-3 pl-2 pr-2"}
                        style={{
                          background: data.iconColor,
                          borderRadius: "15px",
                        }}
                      >
                        <img
                          src={data.icon}
                          alt={"case"}
                          className={"img-fluid"}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className={"row mx-3"}>
                  <div className={"col-lg pb-1"}>
                    <label
                      style={{
                        fontWeight: "normal",
                        fontSize: "16px",
                        color: "rgba(15, 15, 15, 0.7)",
                      }}
                    >
                      {data.message}
                    </label>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-lg"}>
                    <div
                      style={{
                        background: data.progressColor,
                        borderRadius: "20px",
                        width: "90%",
                        height: "4px",
                        marginLeft: "5%",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <GoToTop />
    </div>
  );
};

export default React.memo(FreelanceDashboard);
