import { FREELANCEBUILDREQUIREMENT } from "../../../ENVIRONMENT";

// api for freelance client build requirement academic
async function freepostBuildRequirementAcademic(token, data, id) {
  return await fetch(FREELANCEBUILDREQUIREMENT + `/${id}/academic`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}

export { freepostBuildRequirementAcademic };
