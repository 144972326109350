import { FREELANCECANDIDATESHORTLIST } from "../../ENVIRONMENT";

// api for freelance shortlist job
async function freeshortlistJobs(token, data, status) {
  return await fetch(FREELANCECANDIDATESHORTLIST + `?shortlist=${status}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}

export { freeshortlistJobs };
