import { SUPERADMINFREELANCELIST } from "../../ENVIRONMENT";

async function freelanceListData(token, currentPage) {
  // api for freelance list
  const response = await fetch(
    SUPERADMINFREELANCELIST + `?limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
export { freelanceListData };
