import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GoToTop from "../common/GoToTop";
import { postJob, uploadProfile } from "../../function/recruiter/recruiter";
import { CreateSignedUrlAPI, uploadImage } from "../../api/common/ImageUpload";
import { freelanceHomePage } from "../../function/common/freelanceHomepage";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { freerequirementParsing } from "../../api/freelance/client/requirementParsing";

const UploadResume = () => {
  // return upload resume page
  const [uploadbtnflag, setUploadBtnFlag] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const resumeRef = useRef();
  const [loader,setLoader] = useState(false);
  dispatch({ type: "buildresumefalse" });
  try {
    document.getElementById("dashboardbtn").style.display = "none";
    document.getElementById("dashboardfont").innerText = "Job Posting";
  } catch {}

  const token = useSelector((state) => state.token);
  const schema = yup.object().shape({
    picture: yup
      .mixed()
      .test("name", "Please select a file", (value) => {
        return value[0] && value[0].name !== "";
      })
      .test("fileSize", "File size < 5MB", (value, context) => {
        return value && value[0] && value[0].size <= 5000000;
      })
      .test("type", "Please upload a valid file", (value) => {
        return value && value[0] && (
            value[0].type === 'application/pdf' ||
            value[0].type === "application/msword" ||
            value[0].type === 'application/doc' ||
            value[0].type === 'application/ms-doc' ||
            value[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        );
      })
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [information, setInformation] = useState({
    resume: ""
  });

  const handelDelete = () => {
    setInformation({
      resume: ""
    })
    document.getElementById("recuploadReq").value = null

  }

  //integration of upload requirement
  function handleUploadRequirement() {
    if (resumeRef.current) {
      resumeRef.current.setAttribute("disabled", "disabled");
    }
    setLoader(true)
    const file = document.getElementById("recuploadReq").files[0]
    const obj = {
      for: "FREELANCE_RECRUITER",
      files: [
        {
          extension: file.name.split(".")[1],
          contentType: file.type,
          fileName: file.name,
        },
      ],
    };
    CreateSignedUrlAPI(token, obj)
      .then((result) => {
        const statusCode = result.status;
        const data = result.json();
        return Promise.all([statusCode, data]).then((res) => ({
          statusCode: res[0],
          data: res[1],
        }));
      })
      .then((res) => {
        if (res.statusCode === 200) {
          const promises = res.data?.payload?.signedUrls.map((url) => {
            uploadImage(url.signedUrl, file);
            return url;
          });
          Promise.all(promises).then((data) => {
            let requirementData = {
              path: data[0].fileUrl.split("/").slice(3, 6).join("/"),
            };
            setTimeout(()=>{
              const parseResponse = freerequirementParsing(token, requirementData);
              parseResponse
              .then((res) => {
                if (res.status === 200) {
                  return res.json();
                }
                throw res;
              })
              .then((data) => {
                history.push(
                  `/freelance/dashboard/postjob?refId=${data.payload.reference}`
                );
              });
            },2000)
          });
       } else if (res.statusCode === 422) {
            resumeRef.current.removeAttribute("disabled");
            setLoader(false);
            const promises = res.data.payload.files.children.map((msg) => {
            const errmsg = document.getElementsByClassName("fileerr")[0];
            errmsg.innerText = msg.extension.message;
            errmsg.style.cssText = "display:inline";
            return msg;
          });
        }
      });
  }
  //integration of upload profile
  function handleUploadProfile() {
    const file = document.getElementById("recuploadResume").files[0];
    const obj = {
      for: "FREELANCE_RECRUITER",
      files: [
        {
          extension: file.name.split(".")[1],
          contentType: file.type,
          fileName: file.name,
        },
      ],
    };
    CreateSignedUrlAPI(token, obj)
      .then((result) => {
        const statusCode = result.status;
        const data = result.json();
        return Promise.all([statusCode, data]).then((res) => ({
          statusCode: res[0],
          data: res[1],
        }));
      })
      .then((res) => {
        if (res.statusCode === 200) {
          history.push("/freelance/dashboard/buildresume/register");
          const promises = res.data?.payload?.signedUrls.map((url) => {
            uploadImage(url.signedUrl, file);
            return url;
          });
          //   Promise.all(promises).then((data)=>{
          //       uploadProfileImageListHandler(data);
          //   })
        }else if (res.statusCode === 422) {
            const promises = res.data.payload.files.children.map((msg) => {
              const errmsg = document.getElementsByClassName("fileerr")[0];
              errmsg.innerText = msg.extension.message;
              errmsg.style.cssText = "display:inline";
              return msg;
            });
          }
      });
  }

  //     function  uploadProfileImageListHandler(items) {
  //        setTimeout(() => {
  //             setInformation({
  //                ...information, profilePicture: items[0].fileUrl
  //             });
  //         }, 1000);
  //     }

  return (
    <div className={"col-md mx-5 justify-content-center"}>
      <div className={"row align-content-center mr-5 mt-5 pt-5"}>
        <div className={"container-fluid mx-5 pb-5"}>
          <div className={"col-md pb-4"}>
            <div className={"row"}>
              <div className={"col-2 offset-sm-1"}>
                <button
                  className={"btn  browsebtn"}
                  onClick={(event) => postJob(event, setUploadBtnFlag)}
                >
                  Post Job
                </button>
              </div>
              <div className={"col-3"}>
                <Link to={"/freelance/dashboard/buildresume/register"}
                  className={"btn uploadbtn"}
                  style={{ backgroundColor: "#BEBEBE" }}
                  >
                  Upload Profile
                </Link>
              </div>
            </div>
            <div className={"row px-5 pr-5 mx-3 pt-4 uploadbox"}>
              <div className={"w-100  pb-2 px-5 contentholder"}>
                <div className={"col-md-1"}></div>
                <div className={"col-md pt-5  pl-3"}>
                  {uploadbtnflag ? (
                    <>
                      <form
                        className={
                          "row form-group align-items-center ml-5 mb-0"
                        }
                      >
                        <div className={"col-md pt-5"}>
                          <div className={"row"}>
                            <div className={"col"}>
                              <label
                                htmlFor={"filefize"}
                                className={"dashboardforminputtext"}
                              >
                                Maximum file size 5
                                <span style={{ color: "red" }}>*</span> Mb
                              </label>
                            </div>
                          </div>
                          <div className={"row align-items-center"}>
                            <div className={"col-5"}>
                              <input
                                type={"text"}
                                className={
                                  "form-control resumeuploadtextbox  shadow-none"
                                }
                                placeholder={"David resume.pdf"}
                                id={"filename"}
                                disabled
                              />
                            </div>
                            <div className={"col-2 mt-2"}>
                              <label className={"browsebtn btn"}>
                                <input
                                  type="file"
                                  name="picture"
                                  {...register("picture")}
                                  style={{ display: "None" }}
                                  id={"recuploadResume"}
                                />
                                <span>Browse</span>
                              </label>
                            </div>
                            <div className={"col pl-4"}>
                              <button
                                type={"button"}
                                className={"btn uploadbtn"}
                                ref={resumeRef}
                                onClick={handleSubmit(handleUploadProfile)}
                              >
                                Upload Resume
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className={"row"}>
                        <div className={"col-5 pl-4 pr-0"}>
                          <label
                            htmlFor={"filesize"}
                            className={"ml-5 mr-0 dashboardforminputtext"}
                          >
                            Upload only .doc or .docx. or pdf file
                            <span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                        <div className={"col pl-5 ml-2"}>
                          <Link
                            to={"/freelance/dashboard/uploadresume"}
                            className={"cancelskill"}
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                      <div className={"row pl-5 pt-5"}>
                        <div className={"col-md pl-5 pt-2"}>
                          <div className={"pl-5"}>
                            <label className={"dashboardforminputtext"}>
                              OR
                            </label>
                          </div>
                        </div>
                        <div className="col-md">
                          {errors.picture && (
                            <p className="errormsg">{errors.picture.message}</p>
                          )}
                          <label
                            className={"fileerr errormsg"}
                            style={{ fontSize: "14px" }}
                          ></label>
                        </div>
                      </div>
                      <div className={"row pl-5"}>
                        <div className={"col-10 pl-5"}>
                          <div className={"pt-3 pb-3"}>
                            <Link
                              to="/freelance/dashboard/buildresume/register"
                              className={"btn mr-5 build"}
                            >
                              Build Resume
                            </Link>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={"col-md pt-5"}>
                          <div className={"row"}>
                            <div className={"col"}>
                              <label
                                htmlFor={"filefize"}
                                className={"dashboardforminputtext"}
                              >
                                Maximum file size 5
                                <span style={{ color: "red" }}>*</span> Mb
                              </label>
                            </div>
                          </div>
                          <div className={"row align-items-center"}>
                            <div className={"col-5"}>
                              <input
                                type={"text"}
                                className={
                                  "form-control resumeuploadtextbox  shadow-none"
                                }
                                placeholder={information.resume
                                  ? information.resume.name
                                  : "David resume.pdf"}
                                id={"filename"}
                                disabled
                              />
                            </div>
                            <div className={"col-2 mt-2"}>
                              <label className={"browsebtn btn"}>
                                <input
                                  type="file"
                                  id={"recuploadReq"}
                                  name="picture"
                                  {...register("picture")}
                                  style={{ position: "absolute", opacity: 0 }}
                                  onChange={(event) => {
                                  setInformation({
                                  ...information,
                                  resume: event.target.files[0],
                                  })
                              }}
                                />
                                <span>Browse</span>
                              </label>
                            </div>
                            <div className={"col pl-4"}>
                             <button type={"button"} onClick={handleSubmit(handleUploadRequirement)} className={"btn uploadbtn"} ref={resumeRef}>
                             {loader ? (
                                <i
                                  className = "fa fa-refresh fa-spin"
                                  style={{ padding: "3px 10px" }}
                                ></i>
                              ) : (
                                ""
                              )}
                              Upload Requirement
                              </button>
                            </div>
                          </div>
                        </div>
                      
                      <div className={"row"}>
                        <div className={"col-5 pl-4 pr-0"}>
                          <label
                            htmlFor={"filesize"}
                            className={"ml-5 mr-0 dashboardforminputtext"}
                          >
                            Upload only .doc or .docx. or pdf file
                            <span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                        <div className={"col pl-5 ml-2"}>
                        <label
                        className={"cancelskill btn"}
                        onClick={handelDelete}
                      >
                        Cancel
                      </label>
                        </div>
                      </div>
                      <div className={"row pl-5 pt-5"}>
                        <div className={"col-md pl-5 pt-2"}>
                          <div className={"pl-5"}>
                            <label className={"dashboardforminputtext"}>
                              OR
                            </label>
                          </div>
                        </div>
                        <div className={"col-md"}>
                                    {errors.picture && (
                                        <p className="errormsg">{errors.picture.message}</p>
                                    )}
                                    <label className={"fileerr errormsg"} style={{fontSize:"14px"}}></label>
                                    </div>
                      </div>
                      <div className={"row pl-5"}>
                        <div className={"col-10 pl-5"}>
                          <div className={"pt-3 pb-3"}>
                            <Link
                              to="/freelance/dashboard/postjob"
                              className={"btn mr-5 build"}
                            >
                              Build Job Requirement
                            </Link>
                          </div>
                        </div>
                        <div className={"col align-self-end"}>
                          <Link
                            to="/client/dashboardview"
                            className={"cancelbtn"}
                            onClick={() => freelanceHomePage(dispatch)}
                            id={"skipid"}
                          >
                            SKIP
                          </Link>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GoToTop />
    </div>
  );
};
export default React.memo(UploadResume);
