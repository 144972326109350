import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { forwardRef } from "react";
import "react-datepicker/dist/react-datepicker.css";

const SelectCalendar = ({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  setAcademicNavigation,
  setSecurityNavigation,
  setSocialNavigation,
}) => {
  // customize calendar functionality
  const [checkInDate, setCheckInDate] = useState(new Date());
  const [checkOutDate, setCheckOutDate] = useState(new Date());
  let flag = false;
  if (fromDate) flag = true;

  const FromCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      type="button"
      className="resumeformcalendarfield w-100"
      onClick={onClick}
      ref={ref}
    >
      <label style={{ marginTop: "10px" }}>{value}</label>
      <i
        className="fas fa-calendar-alt"
        style={{ fontSize: "20px", marginTop: "10px" }}
      ></i>
    </button>
  ));
  const ToCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      type="button"
      className="resumeformcalendarfield w-100"
      onClick={onClick}
      ref={ref}
    >
      <label style={{ marginTop: "10px" }}>{value}</label>
      <i
        className="fas fa-calendar-alt"
        style={{ fontSize: "20px", marginTop: "10px" }}
      ></i>
    </button>
  ));

  const handleCheckInDate = (date) => {
    if (flag) {
      setFromDate(date);
      return;
    }
    setCheckInDate(date);
  };

  const handleCheckOutDate = (date) => {
    if (flag) {
      setToDate(date);
      return;
    }
    setCheckOutDate(date);
  };

  function submitEnabled() {
    try {
      document.getElementsByClassName("buildhide")[0].style.display = "block";
      setAcademicNavigation(false);
      setSecurityNavigation(false);
      setSocialNavigation(false);
    } catch {}
  }

  return (
    <div className="row">
      <div className={"col-md"}>
        <label className="resumeforminputtext">From</label>
        <DatePicker
          selected={flag ? fromDate : checkInDate}
          maxDate={new Date()}
          showMonthYearPicker
          onChange={(date) => {
            handleCheckInDate(date);
            submitEnabled();
          }}
          dateFormat="MM/yyyy"
          customInput={<FromCustomInput />}
        />
      </div>
      <div className="col-md">
        <label className="resumeforminputtext">to</label>
        <DatePicker
          selected={flag ? toDate : checkOutDate}
          minDate={flag ? fromDate : checkInDate}
          maxDate={new Date()}
          showMonthYearPicker
          onChange={(date) => {
            handleCheckOutDate(date);
            submitEnabled();
          }}
          dateFormat="MM/yyyy"
          customInput={<ToCustomInput />}
        />
      </div>
    </div>
  );
};
export default React.memo(SelectCalendar);
