import {NOTIFICATIONS} from "../ENVIRONMENT";

// api for notification
async function notification(token, currentPage) {
  const response = await fetch(NOTIFICATIONS +`?limit=6&page=${currentPage}`,{
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}
  export {notification}