import React, { useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { resetPassword } from "../../api/authentication/reset";


const ResetPassword = () => {
  
  const [passwordShown, setPasswordShown] = useState(false);
  const [resetPasswordShown, setresetPasswordShown] = useState(false);
  const [password,setPassword] = useState()
  const [confirmPassword,setConfirmPassword] = useState("");
  const [passwordError,setPasswordError] = useState("");
  const [confirmPasswordError,setConfirmPasswordError] = useState("");

  const token = useParams().token;
  const history = useHistory();

  
  const togglePassword = (e) => {
    setPasswordShown(!passwordShown);
    e.preventDefault();
  };

  const resetTogglePassword = (e) => {
    setresetPasswordShown(!resetPasswordShown);
    e.preventDefault();
  };
 
  function validatePasswordField(e,name){
    let value = e.target.value
    if (name === "password") {
      if(value.length !== 0) {
        if(!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+])[A-Za-z\d><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w+]{8,}$/.test(value))){
          setPasswordError("Password must be min. 8 characters with atleast one  from (A-Z), (a-z), (0-9), & special characters")
        }
        else {
          setPasswordError("")
        }
      }
      else {
        setPasswordError("")
      }
      if(confirmPassword !== "" ) {
        if(confirmPassword !== value) {
          setConfirmPasswordError("Passwords do not match")
        }
        else {
          setConfirmPasswordError("")
        }
      }
    }
    else if (name === "confirm") {
        if(value.length !== 0) {
          if(password !== value) {
            setConfirmPasswordError("Passwords do not match")
          }
          else {
            setConfirmPasswordError("")
          }
        }
        else {
           setConfirmPasswordError("")
        }
    }
  }

  let btnRef = useRef();

  const validateResetPassword = async (event) => {
    event.preventDefault();
    
    const userdetails = { password: password };
    if (token) {
      if (passwordError === "" && confirmPasswordError === "") {
       if (btnRef.current) {
          btnRef.current.setAttribute("disabled", "disabled");
        }
        const response = await resetPassword(userdetails, token);
        const status = response.status;
        if (status === 200) {
          history.push("/user/login")
        }
        if (status === 404) {
          btnRef.current.removeAttribute("disabled");
          const msg = await response.json();
          document.getElementById("charactererr").innerText = msg.message;
          document.getElementById("charactererr").style.cssText = "color:red";
        }
        if (status === 422) {
          btnRef.current.removeAttribute("disabled");
          const msg = await response.json();
          document.getElementById("charactererr").innerText =
            msg.payload.password.message;
          document.getElementById("charactererr").style.cssText = "color:red";
        }
      }
    }
  };

  
return (
    // return resetpassword page
    <div style={{ height: "100vh" }}>
      <div className={"col-md-12 h-100"}>
        <div className="row h-100">
          <div
            className="col-lg-6"
            style={{ backgroundColor: "#F7F7Fc", fontFamily: "Mulish" }}
          >
            <form
              className="form-group"
              onSubmit={validateResetPassword}
            >
              <div className="row">
                <div className="col-md-3  pt-4 pl-5">
                  {/* <Link to="/user/login">
                    {" "}
                    <img
                      src="/icon/Authentication/back.png"
                      alt="back"
                      className="img-fluid  my-2"
                    />
                  </Link> */}
                </div>
                <div className="col-md-5">
                  <img
                    src="/icon/common/mainlogo.png"
                    className="img-fluid mx-auto d-block pt-2"
                    alt="dollar"
                  />
                </div>
              </div>
              <div className="row pt-5 pl-5">
                <div className="col-md">
                  <label
                    style={{
                      fontWeight: "bold",
                      fontSize: "31px",
                      color: "#11142D",
                    }}
                  >
                    Reset password
                  </label>
                </div>
              </div>
              <div className="row pl-5 pt-5">
                <div className="col-md-9">
                  <i
                    className={passwordShown ? "fas fa-eye-slash" : "fa fa-eye"}
                    onClick={togglePassword}
                    style={{
                      position: "absolute",
                      right: "30px",
                      top: "20px",
                      color: "#DADADA",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                  />
                  <input
                    type={passwordShown ? "text" : "password"}
                    placeholder={"Password"}
                    id={"rpassword"}
                    value={password}
                    onChange={(e)=>{setPassword(e.target.value);validatePasswordField(e,"password")}}
                    className={"form-control forminputfield shadow-none"}
                  />
                  {passwordError && (
                    <font
                      className={"dashboardforminputtext text-danger"}
                      style={{ fontWeight: "600" }}
                    >
                      {passwordError}
                    </font>
                  )}
                </div>
              </div>
              <div className="row pl-5 pt-3">
                <div className="col-md-9">
                  <i
                    className={
                      resetPasswordShown ? "fas fa-eye-slash" : "fa fa-eye"
                    }
                    onClick={resetTogglePassword}
                    style={{
                      position: "absolute",
                      right: "30px",
                      top: "20px",
                      color: "#DADADA",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                  />
                  <input
                    type={resetPasswordShown ? "text" : "password"}
                    placeholder={"Re-type Password"}
                    id={"rpassword"}
                    className={"form-control forminputfield shadow-none"}
                    value={confirmPassword}
                    onChange={(e)=>{setConfirmPassword(e.target.value);validatePasswordField(e,"confirm")}}
                  />
                  {confirmPasswordError && (
                    <font
                      className={"dashboardforminputtext text-danger"}
                      style={{ fontWeight: "600" }}
                    >
                      {confirmPasswordError}
                    </font>
                  )}
                </div>
              </div>
              <div className={"row pl-3 pt-3"}>
                <div className={"col-md pl-5"}>
                  <div className={"resetpassworderrmsg errormsg"}></div>
                </div>
              </div>
              <div className="row pt-4 pl-5">
                <div className="col-md">
                  <button type={"submit"} 
                  ref={btnRef} 
                  className="btn  save px-4 py-2">
                    Reset Password
                  </button>
                </div>
              </div>
              <div className={"row pt-3 pl-5"}>
                <div className={"col-md pl-3"}>
                  <div id="charactererr" className={""}></div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-6" style={{ backgroundColor: "#E2E2EA" }}>
            <img
              src="/icon/Authentication/password.jpg"
              alt="Forgot password"
              className="img-fluid mx-auto d-block w-75 rounded-circle my-5 pt-5"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(ResetPassword);
