import { SUPERADMINCANDIDATELOCATIONLIST } from "../../ENVIRONMENT";
// api for location
function candidateListLocation(token) {
  const response = fetch(SUPERADMINCANDIDATELOCATIONLIST, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { candidateListLocation };
