import { SUPERADMINFREELANCELIST } from "../../ENVIRONMENT";

async function freelanceListSorting(token, currentPage, name, order) {
  // api for freelance sorting
  const response = await fetch(
    SUPERADMINFREELANCELIST + `?limit=6&page=${currentPage}&${name}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
export { freelanceListSorting };
