import React from "react";
const GetPrimaryYear = () => {
  // return a group of options from 0 to 50
  const child = [];
  for (var i = 0; i < 51; i++)
    child.push(
      <option key={i} value={`${i}`}>
        {i} year
      </option>
    );
  return child;
};
export default React.memo(GetPrimaryYear);