import { FREELANCECANDIDATEPROFILE } from "../../../ENVIRONMENT";

// api for submit freelance candidate profile
async function freecandidateProfile(token, registerData) {
  const response = await fetch(FREELANCECANDIDATEPROFILE, {
    method: "POST",
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(registerData),
  });
  return response;
}
// api for freelance candidate profile
async function freeupdateCandidateProfile(token,id, registerData) {
    const response = await fetch(FREELANCECANDIDATEPROFILE+`/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
      body: JSON.stringify(registerData),
    });
    return response;
  }

async function FreeGetCandidateProfile(token,id){
 const response = await fetch( FREELANCECANDIDATEPROFILE +`/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            token ? token : sessionStorage.getItem("token")
          }`,
        },
      });
      return response;  
}

export { freecandidateProfile,freeupdateCandidateProfile,FreeGetCandidateProfile };
