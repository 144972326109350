import { FREELANCECLIENTREQUIREMENT } from "../../ENVIRONMENT";

// api for submit freelance duplicate requirement
async function freeduplicateRequirement(token, id) {
  const response = await fetch(
    FREELANCECLIENTREQUIREMENT + `/duplicate/${id}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export { freeduplicateRequirement };
