import "../styles/app.scss";
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import PermanentData from "../components/candidate/PermanentData";
import NavbarSidebarBtnForNewUser from "./NavbarSidebarBtnForNewUser";

const NavbarSidebarForNewUserCandidate = (props) => {
  // return navbarsidebar button for new user of candidate
  const isbuildresume = useSelector((state) => state.isbuildresume);

  return (
    <div className={"col-md-1 sidebar pl-0"}>
      <div className={"col-md-2 pl-0"}>
        <Link to={props.homelocation || ""}>
          <img
            src="/icon/common/mainlogo.png"
            alt={"companylogo"}
            className={"pb-3 companylogo"}
          />
        </Link>
      </div>
      <div className={"dashbtn pl-5"}>
        <NavbarSidebarBtnForNewUser />
        <div id={"permanentdataid"}>
          {isbuildresume ? <PermanentData /> : null}
        </div>
      </div>
    </div>
  );
};

export default React.memo(NavbarSidebarForNewUserCandidate);
