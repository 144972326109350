import { RECRUITERBUILDFINISH } from "../../../ENVIRONMENT";

// api for recruiter candidate snapshot
async function snapshot(token, id) {
  return await fetch(RECRUITERBUILDFINISH + `/${id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
}

export { snapshot };
