import { FREELANCEBUILDACADEMIC } from "../../../ENVIRONMENT";
// api for freelance candidate academic
async function freepostAcademic(token, data, id) {
  const response = await fetch(FREELANCEBUILDACADEMIC + `/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
  return response;
}
// api for freelance candidate academic details
async function freegetAcademic(token, id) {
  const response = await fetch(FREELANCEBUILDACADEMIC + `/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { freepostAcademic, freegetAcademic };
