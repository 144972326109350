import { CANDIDATERESUMEUPLOAD } from "../ENVIRONMENT.js";

function resumeParsing(token, data) {
  // api for candidate resume parsing
  const response = fetch(CANDIDATERESUMEUPLOAD, {
    method: "POST",
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
  return response;
}

export { resumeParsing };
