import { RECRUITERCLIENTMATCHINGJOBS } from "../../ENVIRONMENT";

// api for recruiter academic list
async function recapplicationSorting(token, currentPage, name, order) {
  const response = await fetch(
    RECRUITERCLIENTMATCHINGJOBS +
      `?limit=6&page=${currentPage}&${name}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for recruiter application filter sorting
async function recfilterApplicationSorting(
  token,
  data,
  currentPage,
  name,
  order
) {
  const response = await fetch(
    RECRUITERCLIENTMATCHINGJOBS +
      `?id=${data}&limit=6&page=${currentPage}&${name}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for submit recruiter application sorting of other
async function recotherApplicationSorting(
  token,
  data,
  card,
  currentPage,
  name,
  order
) {
  const response = await fetch(
    RECRUITERCLIENTMATCHINGJOBS +
      `?id=${data}&card=${card}&limit=6&page=${currentPage}&${name}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}
// api for submit recruiter filter status sorting
async function recfilterStatusSorting(token, card, currentPage, name, order) {
  const response = await fetch(
    RECRUITERCLIENTMATCHINGJOBS +
      `?card=${card}&limit=6&page=${currentPage}&${name}=${order}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export {
  recapplicationSorting,
  recfilterApplicationSorting,
  recotherApplicationSorting,
  recfilterStatusSorting,
};
