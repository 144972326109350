import { useEffect, useState } from "react";

const DashboardCards = (props) => {
  // return dashboard card group which generated by data variable
  

 return props.data.map((data, index) => (
    <div key={index} className={props.col}>
        <div className={"contentholder w-100 mt-3 pt-3"} style={{textAlign: "center",borderRadius: 8}}>
            <label>{data.name} : </label>
            <label className="ml-2">{data.count}</label>
            <div className={"row"}>
              <div className={"col-lg"}>
                <div
                  style={{
                    background: data.progressColor,
                    borderRadius: "20px",
                    height: "4px",
                  }}
                ></div>
              </div>
            </div>
        </div>
    </div>
  ));
};
export default DashboardCards;
