import React from "react";
import GoToTop from "../../../../common/GoToTop";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import "../../../../../styles/app.scss";
import { Link } from "react-router-dom";
import ApplicationList from "./application/ApplicationList";

const RequirementDetails = () => {
  const token = useSelector((state) => state.token);

  return (
    <div className={"col mx-5"}>
      <div className={"contentholder w-100 pl-4 pb-3 pt-4 pr-4 mt-5"}>
        <div className={"row ml-3"}>
          <div className={"col-md-3"}>
            <div className={"row"}>
              <div className={"col"}>
                <label className="parafont">
                  Job Title :{" "}
                  <font style={{ fontWeight: "normal" }}>Java Dveloper</font>
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col"}>
                <label className="parafont">
                  Requirement ID :{" "}
                  <font style={{ fontWeight: "normal" }}>DS1092020</font>
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col"}>
                <label className="parafont">
                  Work Type :{" "}
                  <font style={{ fontWeight: "normal" }}>Work Remotely</font>
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col"}>
                <label className="parafont">
                  Hiring Type :{" "}
                  <font style={{ fontWeight: "normal" }}>Internal</font>
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col"}>
                <label className="parafont">
                  Location : <font style={{ fontWeight: "normal" }}>Texas</font>
                </label>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col"}>
                <label className="parafont">Domain :</label>
                <font className="parafont" style={{ fontWeight: "normal" }}>
                  {" "}
                  Java
                </font>
              </div>
            </div>
          </div>
          <div className={"col-md-3"}>
            <div className={"row pl-4 pt-2"}>
              <div className={"col-md"}>
                <img
                  src="../../icon/candidate/applications/correction.png"
                  alt={"correction icon"}
                  className={"img-fluid pr-3"}
                />
                <font className={"parafont"} style={{ fontWeight: "bold" }}>
                  WorkAuth Details
                </font>
              </div>
            </div>
            <div className={"row ml-4"}>
              <div
                className={"col-md parafont ml-3"}
                style={{ fontWeight: "normal" }}
              >
                <font>W2_Hourly</font>
                <br />
                <font>$45/Hourly</font>
                <br />
              </div>
            </div>
            <div className={"row pl-4 pt-2"}>
              <div className={"col-md"}>
                <img
                  src="../../icon/candidate/applications/correction.png"
                  alt={"correction icon"}
                  className={"img-fluid pr-3"}
                />
                <font className={"parafont"} style={{ fontWeight: "bold" }}>
                  Primary Skill
                </font>
              </div>
            </div>
            <div className={"row ml-4"}>
              <div
                className={"col-md parafont ml-3"}
                style={{ fontWeight: "normal" }}
              >
                <font>Java</font>
                <br />
              </div>
            </div>
          </div>
          <div className={"col-md-3"}>
            <div className={"row pl-4 pt-2"}>
              <div className={"col-md"}>
                <img
                  src="../../icon/candidate/applications/correction.png"
                  alt={"correction icon"}
                  className={"img-fluid pr-3"}
                />
                <font className={"parafont"} style={{ fontWeight: "bold" }}>
                  Academics Qualification
                </font>
              </div>
            </div>
            <div className={"row ml-4"}>
              <div
                className={"col-md parafont ml-3"}
                style={{ fontWeight: "normal" }}
              >
                <font>Master</font>
                <br />
                <font>Doctorate</font>
                <br />
              </div>
            </div>
            <div className={"row pl-4 pt-2"}>
              <div className={"col-md"}>
                <img
                  src="../../icon/candidate/applications/correction.png"
                  alt={"correction icon"}
                  className={"img-fluid pr-3"}
                />
                <font className={"parafont"} style={{ fontWeight: "bold" }}>
                  Security Clearance
                </font>
              </div>
            </div>
            <div className={"row ml-4"}>
              <div
                className={"col-md parafont ml-3"}
                style={{ fontWeight: "normal" }}
              >
                <font>SCI</font>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ApplicationList />
      <GoToTop />
    </div>
  );
};
export default React.memo(RequirementDetails);
