import {   CLIENTJOBUPLOAD,CLIENTJOBUPLOADEDDATA } from "../ENVIRONMENT.js";

function requirementParsing(token, data) {
  // api for candidate resume parsing
  const response = fetch(CLIENTJOBUPLOAD, {
    method: "POST",
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
  return response;
}

async function getrequirementParsingData(token,id) {
  // api for get data from client requirement parsing
  const response = await fetch(CLIENTJOBUPLOADEDDATA + `/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { requirementParsing,getrequirementParsingData };
