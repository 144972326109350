import { RECRUITERCANDIDATEDETAILS } from "../../ENVIRONMENT";

// api for recruiter candidate list data details
async function candidateListData(token, currentPage) {
  const response = await fetch(
    RECRUITERCANDIDATEDETAILS + `?limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export { candidateListData };
