import { CANDIDATESHORTLIST } from "../ENVIRONMENT";

async function shortlistJobs(token,data,status) {
  // api for candidate job sortlist
  return await fetch(CANDIDATESHORTLIST + `?shortlist=${status}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
       "Authorization": `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}

export { shortlistJobs };
