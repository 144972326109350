import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import GoToTop from "../../common/GoToTop";
import { Redirect } from "react-router";
import { useSelector } from "react-redux";
import { verifySecurityClearance } from "../../../function/candidate/checkRequired.js";
import { securityClearance } from "../../../function/candidate/validateField.js";
import {
  postParseData,
  getParseData,
} from "../../../function/candidate/parseSecurityData";
import {
  postSecurity,
  getSecurity,
} from "../../../api/candidate/buildResumeSecurity";
import YearOfValidity from "../../common/yearOfValidity";
import GetValidityMonth from "../../common/GetValidityMonth";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
  CreateSignedUrlAPI,
  uploadImage,
} from "../../../api/common/ImageUpload";

const BuildResume4 = () => {
  // return buildresume forth tab button data
  const [loader, setLoader] = useState(false);
  const [documentLoader, setDocumentLoader] = useState(false);
  const [workAuthLoader, setWorkAuthLoader] = useState(false);
  const [licenseLoader, setLicenseLoader] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const resumeRef = useRef();
  const workAuthRef = useRef();
  const licenseRef = useRef();

  const [information, setInformation] = useState({
    classification: "",
    category: "",
    year: "",
    month: "",
    comment: "",
    lifeTimeValidity: false,
  });
  const [error, setError] = useState({
    classification: "",
    category: "",
    year: "",
    month: "",
  });
  const [visibility, setVisibility] = useState({
    zero: true,
    one: false,
    two: false,
    zeroin: true,
    onein: true,
    twoin: true,
  });
  const [securityDocument, setSecurityDocument] = useState({
    passport: "",
    workAuth: "",
    drivingLicense: "",
  });
  const [documentError, setDocumentError] = useState({
    passportError: "",
    workAuthError: "",
    drivingLicenseError: "",
  });
  const [socialNavigation, setSocialNavigation] = useState(false);
  const token = useSelector((state) => state.token);
  const securityRef = useRef();

  const [securityFlag, setSecurityFlag] = useState(false);
  useEffect(() => {
    async function callSelf() {
      try {
        const response = await getSecurity(token);
        if (response.status === 200) {
          let payload = await response.json();
          payload = payload.payload;
          if (payload.candidate.securityClearance !== null) {
            setSocialNavigation(true);
            document.getElementsByClassName("buildhide")[0].style.display =
              "none";
          }
          getParseData(
            payload,
            visibility,
            setVisibility,
            information,
            setInformation
          );
          setSecurityFlag(true);
        }
      } catch { }
    }
    callSelf();
  }, [securityFlag]);

  useEffect(() => {
    if (!visibility.zeroin) {
      setInformation((state) => {
        return {
          ...state,
          classification: "",
          category: "",
          year: "",
          month: "",
          comment: "",
          lifeTimeValidity: false,
        };
      });
      setError((state) => {
        return {
          ...state,
          classification: "",
          category: "",
          year: "",
          month: "",
        };
      });
    }
  }, [visibility.zeroin]);


  useEffect(() => {
    setInformation((state) => {
      return {
        ...state,
        classification1: "",
        category1: "",
        year1: "",
        month1: "",
        comment1: "",
        //docUrl1: "",
        lifeTimeValidity1: false,
      };
    });
    setError((state) => {
      return {
        ...state,
        classification1: "",
        category1: "",
        year1: "",
        month1: "",
      };
    });
  }, [visibility.one]);

  useEffect(() => {
    setInformation((state) => {
      return {
        ...state,
        classification2: "",
        category2: "",
        year2: "",
        month2: "",
        comment2: "",
        //docUrl2: "",
        lifeTimeValidity2: false,
      };
    });
    setError((state) => {
      return {
        ...state,
        classification2: "",
        category2: "",
        year2: "",
        month2: "",
      };
    });
  }, [visibility.two]);

  //   useEffect(() => {
  //     //if (!visibility.onein || !visibility.one) {
  //       setInformation((state) => {
  //         return {
  //           ...state,
  //           classification1: "",
  //           category1: "",
  //           year1: "",
  //           month1: "",
  //           comment1: "",
  //         };
  //       });
  //       setError((state) => {
  //         return {
  //           ...state,
  //           classification1: "",
  //           category1: "",
  //           year1: "",
  //           month1: "",
  //         };
  //       });
  //     //}
  //   // }, [visibility.onein, visibility.one]);
  // }, [visibility.one]);

  //   useEffect(() => {
  //     //if (!visibility.twoin || !visibility.two) {
  //       setInformation((state) => {
  //         return {
  //           ...state,
  //           classification2: "",
  //           category2: "",
  //           year2: "",
  //           month2: "",
  //           comment2: "",
  //         };
  //       });
  //       setError((state) => {
  //         return {
  //           ...state,
  //           classification2: "",
  //           category2: "",
  //           year2: "",
  //           month2: "",
  //         };
  //       });
  //    // }
  //   // }, [visibility.twoin, visibility.two]);
  // }, [visibility.two]);

  // useEffect(() => {
  //   let a = false;
  //   let flag = false;
  //   if (visibility.zero && !visibility.zeroin) {
  //     flag = true;
  //     a = false;
  //   } else if (visibility.one && !visibility.onein) {
  //     flag = true;
  //     a = false;
  //   } else if (visibility.two && !visibility.twoin) {
  //     flag = true;
  //     a = false;
  //   } else {
  //     a = true;
  //   }
  //   if (visibility.zero && visibility.one && visibility.two) {
  //     a = false;
  //   }
  //   setAddVisible(a);
  // }, [visibility]);

  async function handleClick(event) {
    event.preventDefault();
    if (verifySecurityClearance(error, setError, information, visibility)) {
      //try {
      if (securityRef.current) {
        securityRef.current.setAttribute("disabled", "disabled");
      }
      setLoader(true);
      const parsedData = postParseData(
        information,
        visibility,
        securityDocument
      );
      const response = await postSecurity(token, parsedData);
      if (response.status === 200) {
        setRedirect(true);
      } else {
        securityRef.current.removeAttribute("disabled");
        setLoader(false);
      }
      // } catch {}
    }
  }

  const Addsecurityquestion = () => {
    // return security duplicate div
    setVisibility((state) => {
      if (!state.one) return { ...state, one: true, onein: true };
      else return { ...state, two: true, twoin: true };
    });
  };

  function submitEnabled() {
    try {
      document.getElementsByClassName("buildhide")[0].style.display = "block";
      setSocialNavigation(false);
    } catch { }
  }

  const schema = yup.object().shape({
    picture: yup
      .mixed()
      .test("name", "Please select a file", (value) => {
        return value[0] && value[0].name !== "";
      })
      .test("fileSize", "File size < 5MB", (value, context) => {
        return value && value[0] && value[0].size <= 5000000;
      })
      .test("type", "Please upload a valid file", (value) => {
        return (
          value &&
          value[0] &&
          (value[0].type === "image/jpeg" ||
            value[0].type === "application/pdf" ||
            value[0].type === "image/jpg" ||
            value[0].type === "image/png")
        );
      }),
    workAuthPicture: yup
      .mixed()
      .test("name", "Please select a file", (value) => {
        return value[0] && value[0].name !== "";
      })
      .test("fileSize", "File size < 5MB", (value, context) => {
        return value && value[0] && value[0].size <= 5000000;
      })
      .test("type", "Please upload a valid file", (value) => {
        return (
          value &&
          value[0] &&
          (value[0].type === "image/jpeg" ||
            value[0].type === "application/pdf" ||
            value[0].type === "image/jpg" ||
            value[0].type === "image/png")
        );
      }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  //Integration of upload file
  function handlePassport() {
    submitEnabled()
    const file = document.getElementById("uploadDocument").files[0];
    if (file === undefined) {
      setDocumentError({
        ...documentError,
        passportError: "Please select a file",
      });
    } else if (file.size >= 5000000) {
      setDocumentError({
        ...documentError,
        passportError: "File size < 5MB",
      });
    } else if (
      !(
        file.type === "application/pdf" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/jpeg"
      )
    ) {
      setDocumentError({
        ...documentError,
        passportError: "Please upload a valid file",
      });
    } else {
      setDocumentError({
        ...documentError,
        passportError: "",
      });
      if (resumeRef.current) {
        resumeRef.current.setAttribute("disabled", "disabled");
      }
      setDocumentLoader(true);
      const obj = {
        for: "CANDIDATE",
        files: [
          {
            extension: file.name.split(".")[1],
            contentType: file.type,
            fileName: file.name,
          },
        ],
      };
      try {
        CreateSignedUrlAPI(token, obj)
          .then((result) => {
            const statusCode = result.status;
            const data = result.json();
            return Promise.all([statusCode, data]).then((res) => ({
              statusCode: res[0],
              data: res[1],
            }));
          })
          .then((res) => {
            if (res.statusCode === 200) {
              const promises = res.data?.payload?.signedUrls.map((url) => {
                uploadImage(url.signedUrl, file);
                setSecurityDocument({
                  ...securityDocument,
                  passport: url.fileUrl
                })
                setDocumentLoader(false);
                resumeRef.current.removeAttribute("disabled");
                return url;
              });
            } else {
              setDocumentLoader(false);
              resumeRef.current.removeAttribute("disabled");
            }
          });
      } catch { }
    }
  }

  function handleWorkAuth() {
    submitEnabled();
    const file = document.getElementById("uploadWorkAuthDocument").files[0];
    if (file === undefined) {
      setDocumentError({
        ...documentError,
        workAuthError: "Please select a file",
      });
    } else if (file.size >= 5000000) {
      setDocumentError({
        ...documentError,
        workAuthError: "File size < 5MB",
      });
    } else if (
      !(
        file.type === "application/pdf" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/jpeg"
      )
    ) {
      setDocumentError({
        ...documentError,
        workAuthError: "Please upload a valid file",
      });
    }
    else {
      setDocumentError({
        ...documentError,
        workAuthError: ""
      })
      if (workAuthRef.current) {
        workAuthRef.current.setAttribute("disabled", "disabled");
      }
      setWorkAuthLoader(true);
      const obj = {
        for: "CANDIDATE",
        files: [
          {
            extension: file.name.split(".")[1],
            contentType: file.type,
            fileName: file.name,
          },
        ],
      };
      try {
        CreateSignedUrlAPI(token, obj)
          .then((result) => {
            const statusCode = result.status;
            const data = result.json();
            return Promise.all([statusCode, data]).then((res) => ({
              statusCode: res[0],
              data: res[1],
            }));
          })
          .then((res) => {
            if (res.statusCode === 200) {
              const promises = res.data?.payload?.signedUrls.map((url) => {
                uploadImage(url.signedUrl, file);
                setSecurityDocument({
                  ...securityDocument,
                  workAuth: url.fileUrl,
                });
                setWorkAuthLoader(false);
                workAuthRef.current.removeAttribute("disabled");
                return url;
              });
            } else {
              setWorkAuthLoader(false);
              workAuthRef.current.removeAttribute("disabled");
            }
          });
      } catch { }
    }
  }

  function handleDrivingLicense() {
    submitEnabled();
    const file = document.getElementById("uploadLicenseDocument").files[0];

    if (file === undefined) {
      setDocumentError({
        ...documentError,
        drivingLicenseError: "Please select a file",
      });
    } else if (file.size >= 5000000) {
      setDocumentError({
        ...documentError,
        drivingLicenseError: "File size < 5MB",
      });
    } else if (
      !(
        file.type === "application/pdf" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/jpeg"
      )
    ) {
      setDocumentError({
        ...documentError,
        drivingLicenseError: "Please upload a valid file",
      });
    }
    else {
      setDocumentError({
        ...documentError,
        drivingLicenseError: "",
      });
      if (licenseRef.current) {
        licenseRef.current.setAttribute("disabled", "disabled");
      }
      setLicenseLoader(true);
      const obj = {
        for: "CANDIDATE",
        files: [
          {
            extension: file.name.split(".")[1],
            contentType: file.type,
            fileName: file.name,
          },
        ],
      };
      try {
        CreateSignedUrlAPI(token, obj)
          .then((result) => {
            const statusCode = result.status;
            const data = result.json();
            return Promise.all([statusCode, data]).then((res) => ({
              statusCode: res[0],
              data: res[1],
            }));
          })
          .then((res) => {
            if (res.statusCode === 200) {
              const promises = res.data?.payload?.signedUrls.map((url) => {
                uploadImage(url.signedUrl, file);
                setSecurityDocument({
                  ...securityDocument,
                  drivingLicense: url.fileUrl,
                });
                setLicenseLoader(false);
                licenseRef.current.removeAttribute("disabled");
                return url;
              });
            } else {
              setLicenseLoader(false);
              licenseRef.current.removeAttribute("disabled");
            }
          });
      } catch { }
    }
  }

  const onSubmit = () => { };

  // const handelDelete = () => {
  //   setInformation({
  //     docUrl: "",
  //   });
  //   document.getElementById("uploadDocument").value = null;
  // };
  //end
  return (
    <div className={"col"}>
      <form className={"form-group"} onSubmit={handleSubmit(onSubmit)}>
        <div className={"row pl-5 ml-5"}>
          <div className={"container-fluid container1 pl-5"}>
            <ul className={"progressbar pt-5 mr-2"}>
              <li className={"active"}>
                <img
                  src="../../../icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li className={"active"}>
                <img
                  src="../../../icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li>
                <img
                  src="../../../icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li></li>
            </ul>
          </div>
        </div>
        <div className={"row mx-5 pl-5  resumealignment"}>
          <div className={"col-lg-1"}></div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link to="/candidate/dashboard/buildresume/resumeexperience">
              <div
                className={"pl-3 pb-2 pr-3 pt-3  BuildResume1-box-container-width border-radius-10px background-colored-primary-white  text-center"}

              >
                <label
                  className="font-weight-500 font-size-14px  colored-primary-black"

                >
                  Experience
                </label>
                <br />
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link to="/candidate/dashboard/buildresume/resumecertificate">
              <div
                className={"pt-2 pl-2 pr-2  BuildResume1-box-container-width border-radius-10px background-colored-primary-white  text-center"}

              >
                <label
                  className="font-weight-500 font-size-14px  colored-primary-black"

                >
                  Academics
                  <br />& Certificates
                </label>
                <br />
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link to="/candidate/dashboard/buildresume/resumesecurity">
              <div
                className={"pt-1 pl-2 pr-2  BuildResume1-box-container-width border-radius-10px background-colored-primary-white  text-center"}

              >
                <div className={"row"}>
                  <div className={"col-lg pb-0"}>
                    <label
                      className="font-weight-500 font-size-14px  colored-primary-black"

                    >
                      Security Clearance
                    </label>
                  </div>
                </div>
                <div className={"row pl-0"}>
                  <div className={"col-lg"}>
                    <div
                      className="background-colored-Skyline-blue BuildResume1-box-container-widthAnd-height"

                    ></div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-3 pb-3"}>
            {socialNavigation ? (
              <Link to={"/candidate/dashboard/buildresume/resumesocial"}>
                <div
                  className={"pl-3 pb-2 pr-3 pt-3  BuildResume1-box-container-width border-radius-10px background-colored-primary-white  text-center"}

                >
                  <label
                    className="font-weight-500 font-size-14px  colored-Pale-steel"

                  >
                    Social
                  </label>
                  <br />
                </div>
              </Link>
            ) : (
              <div
                className={"pl-3 pb-2 pr-3 pt-3  BuildResume1-box-container-width border-radius-10px background-colored-primary-white  text-center"}

              >
                <label
                  className="font-weight-500 font-size-14px  colored-Pale-steel"

                >
                  Social
                </label>
                <br />
              </div>
            )}
          </div>
        </div>
        {/*second box*/}
        <div className={"pl-1 pr-5 mr-5"}>
          <div className={"row px-5 pr-5 mx-3 pt-3"}>
            <div className={"w-100 pb-4 pt-2 contentholder"}>
              <div className={"row pt-2 pl-4 pr-4"}>
                <div className={"col-md-4 form-inline pt-3"}>
                  <label className={"resumeformquerytext pr-2 pl-2"}>
                    Security Clearance<span className="colored-Bright-red">*</span>
                  </label>
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio"
                    name="degree0"
                    value="yes"
                    checked={visibility.zeroin}
                    onChange={() => {
                      setVisibility((state) => {
                        return { ...state, zeroin: true };
                      });
                      submitEnabled();
                    }}
                  />
                  <label
                    className="dashboardforminputtext pl-2 pr-3"
                    htmlFor="customRadio"
                  >
                    Yes
                  </label>
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio0"
                    name="degree0"
                    value="no"
                    checked={!visibility.zeroin}
                    onChange={() => {
                      setVisibility((state) => {
                        return {
                          ...state,
                          zeroin: false,
                          one: false,
                          two: false,
                        };
                      });
                      submitEnabled();
                    }}
                  />
                  <label
                    className="dashboardforminputtext pl-2"
                    htmlFor="customRadio0"
                  >
                    No
                  </label>
                </div>
              </div>
              {visibility.zeroin && (
                <div id={"securityclearance"}>
                  <div className={"row mx-3 mt-2"}>
                    <div className={"col-md-4"}>
                      <label
                        htmlFor={"classification"}
                        className={"resumeforminputtext"}
                      >
                        Classification<span className="colored-Bright-red">*</span>
                      </label>
                      <select
                        className={
                          "form-control resumeforminputfield form-select inputgray shadow-none"
                        }
                        id={"classification"}
                        name={"classification"}
                        value={information.classification}
                        style={{
                          border: `${error.classification ? "1px solid red" : ""
                            }`,
                        }}
                        onChange={(event) => {
                          securityClearance(
                            event,
                            error,
                            setError,
                            information,
                            setInformation
                          );
                          submitEnabled();
                        }}
                      >
                        <option value={""}>Select</option>
                        <option value={"TOP_SECRET"}>Top Secret</option>
                        <option value={"SECRET"}>Secret</option>
                        <option value={"CONFIDENTIAL"}>Confidential</option>
                      </select>
                      <font className={"dashboardforminputtext text-danger"}>
                        {error.classification}
                      </font>
                    </div>
                    <div className={"col-md-4"}>
                      <label
                        htmlFor={"category"}
                        className={"resumeforminputtext"}
                      >
                        Category<span className="colored-Bright-red">*</span>
                      </label>
                      <select
                        className={
                          "form-control resumeforminputfield form-select inputgray shadow-none"
                        }
                        id={"category"}
                        name={"category"}
                        value={information.category}
                        style={{
                          border: `${error.category ? "1px solid red" : ""}`,
                        }}
                        onChange={(event) => {
                          securityClearance(
                            event,
                            error,
                            setError,
                            information,
                            setInformation
                          );
                          submitEnabled();
                        }}
                      >
                        <option value={""}>Select</option>
                        <option value={"SCI"}>SCI</option>
                        <option value={"SAPS"}>SAPS</option>
                        <option value={"RD"}>RD</option>
                        <option value={"FRD"}>FRD</option>
                        <option value={"NSI"}>NSI</option>
                      </select>
                      <font className={"dashboardforminputtext text-danger"}>
                        {error.category}
                      </font>
                    </div>
                  </div>
                  <div className={"row pt-2 mx-3"}>
                    <div className={"col-md-4"}>
                      <label
                        htmlFor={"experience"}
                        className={"resumeforminputtext"}
                      >
                        Validity<span className="colored-Bright-red">*</span>
                      </label>
                      <div
                        className={"dropdownforminputfield"}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          border: `${error.year || error.month ? "1px solid red" : ""
                            }`,
                        }}
                      >
                        <select
                          className={
                            "form-control form-select dropgray1 shadow-none"
                          }
                          id={"pexp"}
                          name={"month"}
                          value={information.month}
                          onChange={(event) => {
                            securityClearance(
                              event,
                              error,
                              setError,
                              information,
                              setInformation
                            );
                            submitEnabled();
                          }}
                        >
                          <option value={""}>Month</option>
                          <GetValidityMonth />
                        </select>
                        <select
                          className={
                            "form-control form-select dropgray shadow-none ml-0"
                          }
                          id={"pexp"}
                          name={"year"}
                          value={information.year}
                          onChange={(event) => {
                            securityClearance(
                              event,
                              error,
                              setError,
                              information,
                              setInformation
                            );
                            submitEnabled();
                          }}
                        >
                          <option value={""}>Year</option>
                          <YearOfValidity />
                        </select>
                      </div>
                      <font className={"dashboardforminputtext text-danger"}>
                        {error.month}
                      </font>
                      <font className={"dashboardforminputtext text-danger"}>
                        {error.year}
                      </font>
                    </div>
                    <div className={"col-md-4 form-inline mt-4"}>
                      <input
                        type="checkbox"
                        className="BuildResume1-checkbox-height-width"
                        checked={information.lifeTimeValidity}
                        onChange={() => {
                          setInformation((state) => {
                            return {
                              ...state,
                              lifeTimeValidity: !information.lifeTimeValidity,
                            };
                          });
                        }}
                      />
                      <label className={"dashboardforminputtext pr-2 pl-3"}>
                        Lifetime Validity
                      </label>
                    </div>
                  </div>
                  <div className={"row pt-2 mx-3"}>
                    <div className={"col-md-8"}>
                      <label
                        htmlFor={"information"}
                        className={"resumeforminputtext"}
                      >
                        Add Comments If Any
                      </label>
                      <input
                        type={"text"}
                        placeholder={"Type Here"}
                        id={"information"}
                        value={information.comment}
                        onChange={(event) => {
                          setInformation({
                            ...information,
                            comment: event.currentTarget.value,
                          });
                          submitEnabled();
                        }}
                        className={
                          "form-control resumeforminputfield shadow-none"
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/*new div flow here*/}
        {visibility.one && (
          <div className={"pl-1 pr-5 mr-5"}>
            <div className={"row px-5 pr-5 mx-3 pt-3"}>
              <div className={"w-100 pb-2 pt-2 contentholder"}>
                {visibility.onein && (
                  <div id={"securityclearance"}>
                    <div className={"row mx-3 mt-2"}>
                      <div className={"col-md-4"}>
                        <label
                          htmlFor={"classification"}
                          className={"resumeforminputtext"}
                        >
                          Classification<span className="colored-Bright-red">*</span>
                        </label>
                        <select
                          className={
                            "form-control resumeforminputfield form-select inputgray shadow-none"
                          }
                          id={"classification"}
                          name={"classification1"}
                          value={information.classification1}
                          style={{
                            border: `${error.classification1 ? "1px solid red" : ""
                              }`,
                          }}
                          onChange={(event) => {
                            securityClearance(
                              event,
                              error,
                              setError,
                              information,
                              setInformation
                            );
                            submitEnabled();
                          }}
                        >
                          <option value={""}>Select</option>
                          <option value={"TOP_SECRET"}>Top Secret</option>
                          <option value={"SECRET"}>Secret</option>
                          <option value={"CONFIDENTIAL"}>Confidential</option>
                        </select>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.classification1}
                        </font>
                      </div>
                      <div className={"col-md-4"}>
                        <label
                          htmlFor={"category"}
                          className={"resumeforminputtext"}
                        >
                          Category<span className="colored-Bright-red">*</span>
                        </label>
                        <select
                          className={
                            "form-control resumeforminputfield form-select inputgray shadow-none"
                          }
                          id={"category"}
                          name={"category1"}
                          value={information.category1}
                          style={{
                            border: `${error.category1 ? "1px solid red" : ""}`,
                          }}
                          onChange={(event) => {
                            securityClearance(
                              event,
                              error,
                              setError,
                              information,
                              setInformation
                            );
                            submitEnabled();
                          }}
                        >
                          <option value={""}>Select</option>
                          <option value={"SCI"}>SCI</option>
                          <option value={"SAPS"}>SAPS</option>
                          <option value={"RD"}>RD</option>
                          <option value={"FRD"}>FRD</option>
                          <option value={"NSI"}>NSI</option>
                        </select>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.category1}
                        </font>
                      </div>
                    </div>
                    <div className="row pt-2 mx-3">
                      <div className={"col-md-4"}>
                        <label
                          htmlFor={"experience"}
                          className={"resumeforminputtext"}
                        >
                          Validity<span className="colored-Bright-red">*</span>
                        </label>
                        <div
                          className={"dropdownforminputfield"}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            border: `${error.year1 || error.month1 ? "1px solid red" : ""
                              }`,
                          }}
                        >
                          <select
                            className={
                              "form-control form-select dropgray1 shadow-none"
                            }
                            id={"pexp"}
                            name={"month1"}
                            value={information.month1}
                            onChange={(event) => {
                              securityClearance(
                                event,
                                error,
                                setError,
                                information,
                                setInformation
                              );
                              submitEnabled();
                            }}
                          >
                            <option value={""}>Month</option>
                            <GetValidityMonth />
                          </select>
                          <select
                            className={
                              "form-control form-select dropgray shadow-none ml-0"
                            }
                            id={"pexp"}
                            name={"year1"}
                            value={information.year1}
                            onChange={(event) => {
                              securityClearance(
                                event,
                                error,
                                setError,
                                information,
                                setInformation
                              );
                              submitEnabled();
                            }}
                          >
                            <option value={""}>Year</option>
                            <YearOfValidity />
                          </select>
                        </div>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.month1}
                        </font>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.year1}
                        </font>
                      </div>
                      <div className={"col-md-4 form-inline mt-4"}>
                        <input
                          type="checkbox"
                          className="BuildResume1-checkbox-height-width"
                          checked={information.lifeTimeValidity1}
                          onChange={() => {
                            setInformation((state) => {
                              return {
                                ...state,
                                lifeTimeValidity1:
                                  !information.lifeTimeValidity1,
                              };
                            });
                          }}
                        />
                        <label className={"dashboardforminputtext pr-2 pl-3"}>
                          Lifetime Validity
                        </label>
                      </div>
                    </div>
                    <div className={"row pt-2 mx-3"}>
                      <div className={"col-md-8 "}>
                        <label
                          htmlFor={"information"}
                          className={"resumeforminputtext"}
                        >
                          Add Comments If Any
                        </label>
                        <input
                          type={"text"}
                          placeholder={"Type Here"}
                          value={information.comment1}
                          onChange={(event) => {
                            setInformation({
                              ...information,
                              comment1: event.currentTarget.value,
                            });
                            submitEnabled();
                          }}
                          id={"information reqfalse"}
                          className={
                            "form-control resumeforminputfield shadow-none"
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className={"row"}>
                  <div className={"col-md"}>
                    <div
                      className={"btn offset-sm-10 pl-5 badge  cancelskill"}
                      onClick={() => {
                        setVisibility((state) => {
                          return { ...state, one: false };
                        });
                        submitEnabled();
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {visibility.two && (
          <div className={"pl-1 pr-5 mr-5"}>
            <div className={"row px-5 pr-5 mx-3 pt-3"}>
              <div className={"w-100 pb-2 pt-2 contentholder"}>
                {visibility.twoin && (
                  <div id={"securityclearance"}>
                    <div className={"row mx-3 mt-2"}>
                      <div className={"col-md-4"}>
                        <label
                          htmlFor={"classification"}
                          className={"resumeforminputtext"}
                        >
                          Classification<span className="colored-Bright-red">*</span>
                        </label>
                        <select
                          className={
                            "form-control resumeforminputfield form-select inputgray shadow-none"
                          }
                          id={"classification"}
                          name={"classification2"}
                          value={information.classification2}
                          style={{
                            border: `${error.classification2 ? "1px solid red" : ""
                              }`,
                          }}
                          onChange={(event) => {
                            securityClearance(
                              event,
                              error,
                              setError,
                              information,
                              setInformation
                            );
                            submitEnabled();
                          }}
                        >
                          <option value={""}>Select</option>
                          <option value={"TOP_SECRET"}>Top Secret</option>
                          <option value={"SECRET"}>Secret</option>
                          <option value={"CONFIDENTIAL"}>Confidential</option>
                        </select>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.classification2}
                        </font>
                      </div>
                      <div className={"col-md-4"}>
                        <label
                          htmlFor={"category"}
                          className={"resumeforminputtext"}
                        >
                          Category<span className="colored-Bright-red">*</span>
                        </label>
                        <select
                          className={
                            "form-control resumeforminputfield form-select inputgray shadow-none"
                          }
                          id={"category"}
                          name={"category2"}
                          value={information.category2}
                          style={{
                            border: `${error.category2 ? "1px solid red" : ""}`,
                          }}
                          onChange={(event) => {
                            securityClearance(
                              event,
                              error,
                              setError,
                              information,
                              setInformation
                            );
                            submitEnabled();
                          }}
                        >
                          <option value={""}>Select</option>
                          <option value={"SCI"}>SCI</option>
                          <option value={"SAPS"}>SAPS</option>
                          <option value={"RD"}>RD</option>
                          <option value={"FRD"}>FRD</option>
                          <option value={"NSI"}>NSI</option>
                        </select>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.category2}
                        </font>
                      </div>
                    </div>
                    <div className={"row mx-3 mt-2"}>
                      <div className={"col-md-4"}>
                        <label
                          htmlFor={"experience"}
                          className={"resumeforminputtext"}
                        >
                          Validity<span className="colored-Bright-red">*</span>
                        </label>
                        <div
                          className={"dropdownforminputfield"}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            border: `${error.year2 || error.month2 ? "1px solid red" : ""
                              }`,
                          }}
                        >
                          <select
                            className={
                              "form-control form-select dropgray1 shadow-none"
                            }
                            id={"pexp"}
                            name={"month2"}
                            value={information.month2}
                            onChange={(event) => {
                              securityClearance(
                                event,
                                error,
                                setError,
                                information,
                                setInformation
                              );
                              submitEnabled();
                            }}
                          >
                            <option value={""}>Month</option>
                            <GetValidityMonth />
                          </select>
                          <select
                            className={
                              "form-control form-select dropgray shadow-none ml-0"
                            }
                            id={"pexp"}
                            name={"year2"}
                            value={information.year2}
                            onChange={(event) => {
                              securityClearance(
                                event,
                                error,
                                setError,
                                information,
                                setInformation
                              );
                              submitEnabled();
                            }}
                          >
                            <option value={""}>Year</option>
                            <YearOfValidity />
                          </select>
                        </div>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.month2}
                        </font>
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.year2}
                        </font>
                      </div>
                      <div className={"col-md-4 form-inline mt-4"}>
                        <input
                          type="checkbox"
                          className="BuildResume1-checkbox-height-width"
                          checked={information.lifeTimeValidity2}
                          onChange={() => {
                            setInformation((state) => {
                              return {
                                ...state,
                                lifeTimeValidity2:
                                  !information.lifeTimeValidity2,
                              };
                            });
                          }}
                        />
                        <label className={"dashboardforminputtext pr-2 pl-3"}>
                          Lifetime Validity
                        </label>
                      </div>
                    </div>
                    <div className={"row pt-2 mx-3"}>
                      <div className={"col-md-8 "}>
                        <label
                          htmlFor={"information"}
                          className={"resumeforminputtext"}
                        >
                          Add Comments If Any
                        </label>
                        <input
                          type={"text"}
                          placeholder={"Type Here"}
                          id={"information reqfalse"}
                          value={information.comment2}
                          onChange={(event) => {
                            setInformation({
                              ...information,
                              comment2: event.currentTarget.value,
                            });
                            submitEnabled();
                          }}
                          className={
                            "form-control resumeforminputfield shadow-none"
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className={"row"}>
                  <div className={"col-md"}>
                    <div
                      className={"btn offset-sm-10 pl-5 badge  cancelskill"}
                      onClick={() => {
                        setVisibility((state) => {
                          return { ...state, two: false };
                        });
                        submitEnabled();
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/*end here*/}
        {/* add more */}
        {visibility.zeroin && !(visibility.one && visibility.two) && (
          <div className={"row pt-3"}>
            <div className={"col-md"}>
              <div
                className={
                  "btn offset-sm-9 bg-transparent badge border-0  newskill hideaddmore "
                }
                onClick={Addsecurityquestion}
              >
                + Add More
              </div>
            </div>
          </div>
        )}
        {/* new div for upload document */}
        <div className={"pl-1 pr-5 mr-5"}>
          <div className={"row px-5 pr-5 mx-3 pt-3"}>
            <div className={"w-100 pb-2 pt-2 contentholder"}>
              <div className={"row mx-3 mt-2"}>
                <div className={"col-md-4"}>
                  <label className={"resumeforminputtext"}>
                    Upload Passport{" "}
                  </label>
                </div>
              </div>
              <div className={"row mx-3"}>
                <div className={"col-md-4 pb-2"}>
                  <input
                    type={"text"}
                    className={
                      "form-control resumeuploadtextbox py-3 shadow-none ellipsis"
                    }
                    placeholder={
                      information.passport
                        ? information.passport.name
                        : "Upload Resume"
                    }
                    disabled
                  />
                </div>
                <div className={"col-md-1  pl-3"}>
                  <label className={"browsebtn btn"}>
                    <input
                      type="file"
                      accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      className="BuildResume1-fileupload-css"
                      name="picture"
                      {...register("picture")}
                      id={"uploadDocument"}
                      onChange={(event) => {
                        setInformation({
                          ...information,
                          passport: event.target.files[0],
                        });
                        submitEnabled();
                      }}
                    />
                    <span>Browse</span>
                  </label>
                </div>
                <div className={"col-md-3 pr-0"}>
                  <button
                    type={"button"}
                    className={"btn uploadbtn"}
                    ref={resumeRef}
                    onClick={handlePassport}
                  >
                    {documentLoader ? (
                      <i
                        className="fa fa-refresh fa-spin BuildResume1-loader-padding"

                      ></i>
                    ) : (
                      ""
                    )}
                    Upload Document
                  </button>
                </div>
                {/* <div className={"col-md pl-0"}>
                  <button
                  type={"button"}
                    className={"btn bg-transparent cancelskill"}
                    onClick={() => {
                      handelDelete()
                      submitEnabled()
                    }}
                  >
                    Cancel
                  </button>
                </div> */}
              </div>
              <div className={"row mx-1"}>
                <div className={"row mx-3"}>
                  <div className={"col-md"}>
                    <label className={"resumeforminputtext"}>
                      Supporting file format * PDF * JPG * JPEG * PNG
                      <br />
                    </label>
                  </div>
                </div>
                <div className="col-md mx-5">
                  {documentError.passportError && (
                    <p className="errormsg">{documentError.passportError}</p>
                  )}
                  <label
                    className={"fileerr errormsg font-size-14px "}

                  ></label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"pl-1 pr-5 mr-5"}>
          <div className={"row px-5 pr-5 mx-3 pt-3"}>
            <div className={"w-100 pb-2 pt-2 contentholder"}>
              <div className={"row mx-3 mt-2"}>
                <div className={"col-md-4"}>
                  <label className={"resumeforminputtext"}>
                    Upload Work Auth{" "}
                  </label>
                </div>
              </div>
              <div className={"row mx-3"}>
                <div className={"col-md-4 pb-2"}>
                  <input
                    type={"text"}
                    className={
                      "form-control resumeuploadtextbox py-3 shadow-none ellipsis"
                    }
                    placeholder={
                      information.workAuth
                        ? information.workAuth.name
                        : "Upload Resume"
                    }
                    disabled
                  />
                </div>
                <div className={"col-md-1  pl-3"}>
                  <label className={"browsebtn btn"}>
                    <input
                      type="file"
                      accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      className="BuildResume1-fileupload-css"
                      name="workAuthPicture"
                      {...register("workAuthPicture")}
                      id={"uploadWorkAuthDocument"}
                      onChange={(event) => {
                        setInformation({
                          ...information,
                          workAuth: event.target.files[0],
                        });
                        submitEnabled();
                      }}
                    />
                    <span>Browse</span>
                  </label>
                </div>
                <div className={"col-md-3 pr-0"}>
                  <button
                    type={"button"}
                    className={"btn uploadbtn"}
                    ref={workAuthRef}
                    onClick={handleWorkAuth}
                  >
                    {workAuthLoader ? (
                      <i
                        className="fa fa-refresh fa-spin BuildResume1-loader-padding"

                      ></i>
                    ) : (
                      ""
                    )}
                    Upload Document
                  </button>
                </div>
                {/* <div className={"col-md pl-0"}>
                  <button
                  type={"button"}
                    className={"btn bg-transparent cancelskill"}
                    onClick={() => {
                      handelDelete()
                      submitEnabled()
                    }}
                  >
                    Cancel
                  </button>
                </div> */}
              </div>
              <div className={"row mx-1"}>
                <div className={"row mx-3"}>
                  <div className={"col-md"}>
                    <label className={"resumeforminputtext"}>
                      Supporting file format * PDF * JPG * JPEG * PNG
                    </label>
                  </div>
                </div>
                <div className="col-md mx-5">
                  {documentError.workAuthError && (
                    <p className="errormsg">{documentError.workAuthError}</p>
                  )}
                  <label
                    className={"fileerr errormsg font-size-14px"}

                  ></label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"pl-1 pr-5 mr-5"}>
          <div className={"row px-5 pr-5 mx-3 pt-3"}>
            <div className={"w-100 pb-2 pt-2 contentholder"}>
              <div className={"row mx-3 mt-2"}>
                <div className={"col-md-4"}>
                  <label className={"resumeforminputtext"}>
                    Upload Driving License{" "}
                  </label>
                </div>
              </div>
              <div className={"row mx-3"}>
                <div className={"col-md-4 pb-2"}>
                  <input
                    type={"text"}
                    className={
                      "form-control resumeuploadtextbox py-3 shadow-none ellipsis"
                    }
                    placeholder={
                      information.drivingLicense
                        ? information.drivingLicense.name
                        : "Upload Resume"
                    }
                    disabled
                  />
                </div>
                <div className={"col-md-1  pl-3"}>
                  <label className={"browsebtn btn"}>
                    <input
                      type="file"
                      accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      className="BuildResume1-fileupload-css"
                      name="licensePicture"
                      {...register("licensePicture")}
                      id={"uploadLicenseDocument"}
                      onChange={(event) => {
                        setInformation({
                          ...information,
                          drivingLicense: event.target.files[0],
                        });
                        submitEnabled();
                      }}
                    />
                    <span>Browse</span>
                  </label>
                </div>
                <div className={"col-md-3 pr-0"}>
                  <button
                    type={"button"}
                    className={"btn uploadbtn"}
                    ref={licenseRef}
                    onClick={handleDrivingLicense}
                  >
                    {licenseLoader ? (
                      <i
                        className="fa fa-refresh fa-spin BuildResume1-loader-padding"

                      ></i>
                    ) : (
                      ""
                    )}
                    Upload Document
                  </button>
                </div>
                {/* <div className={"col-md pl-0"}>
                  <button
                  type={"button"}
                    className={"btn bg-transparent cancelskill"}
                    onClick={() => {
                      handelDelete()
                      submitEnabled()
                    }}
                  >
                    Cancel
                  </button>
                </div> */}
              </div>
              <div className={"row mx-1"}>
                <div className={"row mx-3"}>
                  <div className={"col-md"}>
                    <label className={"resumeforminputtext"}>
                      Supporting file format * PDF * JPG * JPEG * PNG
                    </label>
                  </div>
                </div>
                <div className="col-md mx-5">
                  {documentError.drivingLicenseError && (
                    <p className="errormsg">{documentError.drivingLicenseError}</p>
                  )}
                  <label
                    className={"fileerr errormsg font-size-14px"}

                  ></label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end here */}
        <div className={"hidebox"}>
          <div className={"col-12 pt-2 pb-2 pr-5 mr-5"}>
            {socialNavigation ? (
              <>
                {redirect ? (
                  <Redirect to="/candidate/dashboard/buildresume/resumesocial" />
                ) : (
                  <button
                    type="submit"
                    className={"btn offset-sm-9 save buildhide"}
                    ref={securityRef}
                    onClick={(e) => handleClick(e)}
                  >
                    {loader ? (
                      <i
                        className="fa fa-refresh fa-spin BuildResume1-loader-padding"

                      ></i>
                    ) : (
                      ""
                    )}
                    Save & Continue
                  </button>
                )}
              </>
            ) : (
              <>
                {redirect ? (
                  <Redirect to="/candidate/dashboard/buildresume/resumesocial" />
                ) : (
                  <button
                    type="submit"
                    className={"btn offset-sm-9 save"}
                    ref={securityRef}
                    onClick={(e) => handleClick(e)}
                  >
                    {loader ? (
                      <i
                        className="fa fa-refresh fa-spin BuildResume1-loader-padding"

                      ></i>
                    ) : (
                      ""
                    )}
                    Save & Continue
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </form>
      <GoToTop />
    </div>
  );
};
export default React.memo(BuildResume4);
