import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Redirect } from "react-router";
import GoToTop from "../../common/GoToTop";
import { verifyJobPostingSecurity } from "../../../function/client/checkRequired";
import { jobRequirementSecurity } from "../../../function/client/validateField";
import { useSelector } from "react-redux";
import { postBuildRequirementSecurity } from "../../../api/client/buildRequirementSecurityClearance";
import Multiselect from "multiselect-react-dropdown";
import { createRef } from "react";
import { getRequirementInfo } from "../../../api/client/getRequirementInfo";
import { recpostBuildRequirementSecurity } from "../../../api/recruiter/client/BuildRequirement/buildRequirementSecurityClearance";
import { freepostBuildRequirementSecurity } from "../../../api/freelance/client/BuildRequirement/buildRequirementSecurityClearance";
import { recgetRequirementInfo } from "../../../api/recruiter/client/BuildRequirement/getRequirementInfo";
import { freegetRequirementInfo } from "../../../api/freelance/client/BuildRequirement/getRequirementInfo";
import { Backdrop } from "@material-ui/core";
import { useHistory } from 'react-router-dom';
// import { useStepContext } from "../../../store-Jobrequirement/step-context";



const JobRequirment4 = (props) => {
  // const { currentStep, visitedSteps, updateStep } = useStepContext();


  // return job requirement fourth page
  const [loader, setLoader] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [securityVisibility, setSecurityVisibility] = useState(true);
  const [information, setInformation] = useState({
    classification: "",
    category: "",
  });
  const [error, setError] = useState({
    classification: "",
    category: "",
  });
  const [descriptionNavigation, setDescriptionNavigation] = useState(false);
  try {
    document.getElementById("replacetitleimg").innerText = "Job Posting";
    document.getElementById("postjobid").style.display = "none";
  } catch { }

  const multidata = [
    { document: "Passport", id: 1 },
    { document: "Workauth Document", id: 2 },
    { document: "Driving License", id: 3 },
  ];
  const [options] = useState(multidata);
  const [multiselectData, setMultiSelectData] = useState([
    { document: "", id: 1 },
    { document: "", id: 2 },
    { document: "", id: 3 },
  ]);

  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);
  const param = useParams();
  const id = param.id;
  const refId = param.refId;
  let securityRef = useRef();
  const multiselectRef = createRef();

  // const steps = [
  //   { id: 1, name: 'Step 1', color: '#3D007A' },
  //   { id: 2, name: 'Step 2', color: '#4B0082' },
  //   { id: 3, name: 'Step 3', color: '#7D26CD' },
  //   { id: 4, name: 'Step 4', color: '#9932CC' },
  //   { id: 5, name: 'Step 5', color: 'transparent' }
  // ];
  const steps = [
    { id: 1, name: 'Step 1', color: '#3D007A', linkIndex: 6 },
    { id: 2, name: 'Step 2', color: '#4B0082', linkIndex: 1 },
    { id: 3, name: 'Step 3', color: '#7D26CD', linkIndex: 2 },
    { id: 4, name: 'Step 4', color: '#9932CC', linkIndex: null },
    { id: 5, name: 'Step 5', color: 'transparent', linkIndex: descriptionNavigation ? 4 : null }
  ];
  function handleClick(e) {
    e.preventDefault();
    const target = e.target;
    const multidocument = multiselectRef.current.getSelectedItems();
    let registerData = {
      securityClearanceRequired: securityVisibility ? "YES" : "NO",
    };
    if (securityVisibility) {
      const classification = target.classification.value;
      const category = target.category.value;
      registerData = {
        securityClearanceClassification: classification,
        securityClearanceCategory: category,
        ...registerData,
      };
    }
    if (multidocument[0]) {
      registerData = {
        mandatoryDocument: [multidocument[0].document],
        ...registerData,
      };
    }
    if (multidocument[1]) {
      const pushData = registerData.mandatoryDocument.push(
        multidocument[1].document
      );
      registerData = {
        mandatoryDocument: pushData,
        ...registerData,
      };
    }
    if (multidocument[2]) {
      const pushData = registerData.mandatoryDocument.push(
        multidocument[2].document
      );
      registerData = {
        mandatoryDocument: pushData,
        ...registerData,
      };
    }
    if (
      verifyJobPostingSecurity(error, setError, information, securityVisibility)
    ) {
      if (securityRef.current) {
        securityRef.current.setAttribute("disabled", "disabled");
      }
      setLoader(true);
      const response = isrecruiterCandidate
        ? recpostBuildRequirementSecurity(token, registerData, id)
        : isFreelance
          ? freepostBuildRequirementSecurity(token, registerData, id)
          : postBuildRequirementSecurity(token, registerData, id);
      response.then((res) => {
        if (res.status === 200) {
          setRedirect(true);
        } else {
          securityRef.current.removeAttribute("disabled");
          setLoader(false);
        }
      });
    }
  }

  useEffect(() => {
    // updateStep(4);
    async function callItSelf() {
      try {
        const response = isrecruiterCandidate
          ? await recgetRequirementInfo(token, id)
          : isFreelance
            ? await freegetRequirementInfo(token, id)
            : await getRequirementInfo(token, id);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload.requirement;
          if (payload.securityClearanceRequired.length !== 0) {
            setDescriptionNavigation(true);
            document.getElementsByClassName("hidebtn")[0].style.display =
              "none";
          }
          if (payload.securityClearanceRequired === "NO") {
            setSecurityVisibility(false);
          } else {
            setSecurityVisibility(true);
          }
          if (payload.securityClearanceRequired === "YES") {
            setInformation({
              classification: payload.securityClearanceClassification,
              category: payload.securityClearanceCategory,
            });
            if (payload.mandatoryDocument.document[0]) {
              setMultiSelectData([
                {
                  document: payload.mandatoryDocument.document[0],
                },
              ]);
            }
            if (payload.mandatoryDocument.document[1]) {
              setMultiSelectData([
                { document: payload.mandatoryDocument.document[0] },
                { document: payload.mandatoryDocument.document[1] },
              ]);
            }
            if (payload.mandatoryDocument.document[2]) {
              setMultiSelectData([
                { document: payload.mandatoryDocument.document[0] },
                { document: payload.mandatoryDocument.document[1] },
                { document: payload.mandatoryDocument.document[2] },
              ]);
            }
          } else if (payload.securityClearanceRequired === "NO") {
            if (payload.mandatoryDocument.document[0]) {
              setMultiSelectData([
                {
                  document: payload.mandatoryDocument.document[0],
                },
              ]);
            }
            if (payload.mandatoryDocument.document[1]) {
              setMultiSelectData([
                { document: payload.mandatoryDocument.document[0] },
                { document: payload.mandatoryDocument.document[1] },
              ]);
            }
            if (payload.mandatoryDocument.document[2]) {
              setMultiSelectData([
                { document: payload.mandatoryDocument.document[0] },
                { document: payload.mandatoryDocument.document[1] },
                { document: payload.mandatoryDocument.document[2] },
              ]);
            }
          }
        }
      } catch { }
    }
    callItSelf();
  }, []);

  function submitEnabled() {
    try {
      document.getElementsByClassName("hidebtn")[0].style.display = "block";
      setDescriptionNavigation(false);
    } catch { }
  }

  const history = useHistory();

  // const navigateToStep = (stepNumber) => {
  //   updateStep(stepNumber);
  //   switch (stepNumber) {
  //     case 1:
  //       history.push(`${props.link[6]}/${id}`);
  //       break;
  //     case 2:
  //       history.push(`${props.link[1]}/${id}`);
  //       break;
  //     case 3:
  //       history.push(`${props.link[2]}/${id}`);
  //       break;
  //     case 4:
  //       history.push(`${props.link[3]}/${id}`);
  //       break;
  //     case 5:
  //       history.push(`${props.link[4]}/${id}`);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const checkNextStep = (index) => {
    const step = steps.find((s) => s.id === index + 1);

    if (step && step.linkIndex !== null) {
      const link = props.link[step.linkIndex];
      if (link) {
        history.push(`${link}/${id}`);
      }
    }
  };
  return (
    <div className={"col jobRequirment4-container-padding-bottom"} >
      <form className={"form-group"} onSubmit={handleClick}>
        {/* <div className={"row pl-5"}>
          <div className={"container-fluid container1 pl-5"}>
            <ul className={"progressbar mt-5 pr-5"}>
              <li className={"active"}>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li className={"active"}>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li className={"active"}>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li></li>
            </ul>
          </div>
        </div> */}

        <div className={"row mx-lg-5 mx-1 pl-lg-5 mt-lg-5 resumealignment pl-3 pl-lg-0"}>
          <div className="step-container pl-lg-4">
            {steps.map((step, index) => (
              <div key={step.id}
                className={`step-arrow bordered-step-arrow ${index < 4 ? 'colored-content' : ''}`}
                style={{ backgroundColor: step.color }} onClick={() => {
                  if (step.linkIndex !== null) {
                    checkNextStep(index);
                  }
                }}>
                {step.name}
              </div>
            ))}
          </div>
        </div>

        {/* <div className={"row mx-lg-5 pl-lg-5  resumealignment"}>
          <div className={"col-lg-2 pb-3 pl-4"}>
            <Link to={`${props.link[6]}/${id}`}>
              <div
                className={"pt-2 pl-2 pr-2 jobRequirment4-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

              >
                <label
                  className="font-weight-500 font-size-14px colored-primary-black"

                >
                  Requirement
                  <br />
                  info
                </label>
                <br />
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link to={`${props.link[1]}/${id}`}>
              <div
                className={"pl-3 pb-2 pr-3 pt-3 jobRequirment4-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

              >
                <label
                  className="font-weight-500 font-size-14px colored-primary-black"
                >
                  Skill
                </label>
                <br />
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link to={`${props.link[2]}/${id}`}>
              <div
                className={"pt-2 pl-2 pr-2 jobRequirment4-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

              >
                <label
                  className="font-weight-500 font-size-14px colored-primary-black"
                >
                  Academics
                  <br />& Certificate
                </label>
                <br />
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <div
              className={"pt-1 pl-2 pr-2 jobRequirment4-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

            >
              <div className={"row"}>
                <div className={"col-lg pb-0"}>
                  <label
                    className="font-weight-500 font-size-14px colored-primary-black"
                  >
                    Security Clearance
                  </label>
                </div>
              </div>
              <div className={"row pl-0"}>
                <div className={"col-lg"}>
                  <div
                    className="jobRequirment4-Requirement-info-border background-colored-sky-blue "

                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className={"col-lg-2 pl-3 pb-3"}>
            {descriptionNavigation ? (
              <Link to={`${props.link[4]}/${id}`}>
                <div
                  className={"pt-2 pl-2 pr-2  jobRequirment4-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

                >
                  <label
                    className="font-weight-500 font-size-14px colored-Silver-gray"

                  >
                    Job
                    <br />
                    Description
                  </label>
                </div>
              </Link>
            ) : (
              <div
                className={"pt-2 pl-2 pr-2 jobRequirment4-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

              >
                <label
                  className="font-weight-500 font-size-14px colored-Silver-gray"
                >
                  Job
                  <br />
                  Description
                </label>
              </div>
            )}
          </div>
        </div> */}
        <div className={"pl-1 pr-lg-5 mr-lg-5 pb-5 "}>
          <div className={"row px-lg-5 pr-lg-5 mx-lg-3 pt-3 pl-3 pl-lg-0"}>
            <div className={"w-100 pt-3 pb-3 pl-lg-5  pl-3 pr-3 contentholder py-3 py-lg-5"}>
              <div className="mb-4 d-inline-block font-weight-semibold font-size-26px" >
                Security Clearance
              </div>
              <div className={"row"}>
                <div className={"col-md form-inline"}>
                  <label className={"resumeformquerytext pr-3"}>
                    Security Clearance required
                  </label>
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio"
                    name="degree"
                    value="yes"
                    checked={securityVisibility}
                    onChange={() => {
                      submitEnabled();
                    }}
                    onClick={() => setSecurityVisibility(true)}
                  />
                  <label
                    className="dashboardforminputtext pl-2 pr-3"
                    htmlFor="customRadio"
                  >
                    Yes
                  </label>
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio0"
                    name="degree"
                    value="no"
                    checked={!securityVisibility}
                    onChange={() => {
                      submitEnabled();
                    }}
                    onClick={() => setSecurityVisibility(false)}
                  />
                  <label
                    className="dashboardforminputtext pl-2"
                    htmlFor="customRadio0"
                  >
                    No
                  </label>
                </div>
              </div>
              {securityVisibility && (
                <div id={"clearance"}>
                  <div className={"row pt-2"}>
                    <div className={"col-lg-4"}>
                      <label
                        htmlFor={"classification"}
                        className={"resumeforminputtext"}
                      >
                        Security Clearance Classification
                        <span className="colored-Bright-red">*</span>
                      </label>
                      <select
                        className={
                          "form-control resumeforminputfield form-select inputgray shadow-none"
                        }
                        value={information.classification}
                        id={"classification"}
                        name={"classification"}
                        style={{
                          border: `${error.classification ? "1px solid red" : ""
                            }`,
                        }}
                        onChange={(event) => {
                          jobRequirementSecurity(
                            event,
                            error,
                            setError,
                            information,
                            setInformation
                          );
                          submitEnabled();
                        }}
                      >
                        <option value={""}>Select</option>
                        <option value={"TOP_SECRET"}>Top Secret</option>
                        <option value={"SECRET"}>Secret</option>
                        <option value={"CONFIDENTIAL"}>Confidential</option>
                      </select>
                      <font className={"dashboardforminputtext text-danger"}>
                        {error.classification}
                      </font>
                    </div>
                    <div className={"col-lg-4"}>
                      <label
                        htmlFor={"category"}
                        className={"resumeforminputtext"}
                      >
                        Security Clearance category
                        <span className="colored-Bright-red">*</span>
                      </label>
                      <select
                        className={
                          "form-control resumeforminputfield form-select inputgray shadow-none"
                        }
                        value={information.category}
                        id={"category"}
                        name={"category"}
                        style={{
                          border: `${error.category ? "1px solid red" : ""
                            }`,
                        }}
                        onChange={(event) => {
                          jobRequirementSecurity(
                            event,
                            error,
                            setError,
                            information,
                            setInformation
                          );
                          submitEnabled();
                        }}
                      >
                        <option value={""}>Select</option>
                        <option value={"SCI"}>SCI</option>
                        <option value={"SAPS"}>SAPS</option>
                        <option value={"RD"}>RD</option>
                        <option value={"FRD"}>FRD</option>
                        <option value={"NSI"}>NSI</option>
                      </select>
                      <font className={"dashboardforminputtext text-danger"}>
                        {error.category}
                      </font>
                    </div>
                  </div>
                </div>
              )}

              <div className={"row pt-0 pt-2"}>
                <div className={"col-lg-4"}>
                  <label htmlFor={"document"} className={"resumeforminputtext"}>
                    Mandatory Documents required for submission
                  </label>
                  <Multiselect
                    options={options}
                    displayValue="document"
                    name={"multiselectbox"}
                    ref={multiselectRef}
                    selectedValues={
                      multiselectData[0].document ? multiselectData : ""
                    }
                    onSelect={() => submitEnabled()}
                    onRemove={() => submitEnabled()}
                    style={{
                      searchBox: {
                        backgroundColor: "#E8EAEF",
                        height: "auto",
                        border: 'none',
                        borderRadius: "8px",
                        fontSize: "1rem",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        color: '#A9A9A9',
                        ':focus': {
                          border: '2px solid #5B4FB7',
                          boxShadow: '0 0 5px rgba(91, 79, 183, 0.5)',
                          backgroundColor: '#D6D8DC',
                          color: '#333333',
                        },
                      },
                      option: {
                        fontSize: "12px",
                        color: "#000000",
                        fontWeight: "500",
                        backgroundColor: '#E8EAEF',
                        // color: '#A9A9A9'

                      },
                      chips: {
                        fontWeight: "500",
                        backgroundColor: "rgb(94, 82, 184)",
                        color: "white"
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"row hidebox pl-3 pl-lg-0"}>
          <div className={"col pr-5 pt-2 pb-2"}>
            {descriptionNavigation ? (
              <>
                {redirect ? (
                  <Redirect to={`${props.link[4]}/${id}`} />
                ) : (
                  <button
                    type="submit"
                    className={"btn offset-sm-9 offset-4 save hidebtn"}
                    ref={securityRef}
                  >
                    {loader ? (
                      <i
                        className="fa fa-refresh fa-spin jobRequirment4-loader-padding"

                      ></i>
                    ) : (
                      ""
                    )}
                    Save & Continue
                  </button>
                )}
              </>
            ) : (
              <>
                {redirect ? (
                  refId ? (
                    <Redirect to={`${props.link[4]}/${id}/${refId}`} />
                  ) : (
                    <Redirect to={`${props.link[4]}/${id}`} />
                  )
                ) : (
                  <button
                    type="submit"
                    className={"btn offset-sm-9 offset-4 save"}
                    ref={securityRef}
                  >
                    {loader ? (
                      <i
                        className="fa fa-refresh fa-spin jobRequirment4-loader-padding"

                      ></i>
                    ) : (
                      ""
                    )}
                    Save & Continue
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </form>
      <GoToTop />
    </div>
  );
};

export default React.memo(JobRequirment4);
