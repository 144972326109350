import { RECRUITERCANDIDATEAPPLYSTATUS } from "../../ENVIRONMENT";

// api for recruiter applyjob
async function recapplyJob(token,id,data) {
  const response = await fetch(RECRUITERCANDIDATEAPPLYSTATUS+`/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body:JSON.stringify(data),
});
  return response;
}

export {recapplyJob}