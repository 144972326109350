import { RECRUITERCANDIDATEPROFILE } from "../../../ENVIRONMENT";

// api for submit recruiter candidate profile
async function candidateProfile(token, registerData) {
  const response = await fetch(RECRUITERCANDIDATEPROFILE, {
    method: "POST",
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(registerData),
  });
  return response;
}

// api for submit recruiter candidate profile
async function updateCandidateProfile(token, id, registerData) {
  const response = await fetch(RECRUITERCANDIDATEPROFILE + `/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(registerData),
  });
  return response;
}

async function GetCandidateProfile(token, id) {
  const response = await fetch(RECRUITERCANDIDATEPROFILE + `/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { candidateProfile, updateCandidateProfile, GetCandidateProfile };
