import React from "react";
import GoToTop from "../../common/GoToTop";
import "../../../styles/app.scss";
import { Link } from "react-router-dom";

const UserList = () => {
  
return (
    <div className={"col mx-4 pt-1"}>
      <div className={"row mt-3 mb-3"} style={{ textAlign: "right" }}>
        <div className={"col"}>
          <Link to={"/admin/createAdmin"} className={"btn mr-5 addbtn"}>
            ADD
          </Link>
        </div>
      </div>
      <div
        className={"row mt-3 align-items-center pl-3"}
        style={{
          borderRadius: "20px 20px 0 0",
          boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
          backgroundColor: "white",
        }}
      >
        <div className={"col py-4"}>
          {/*heading box*/}
          <div className={"row"}>
            <div className={"col-2  hidebox"} style={{ textAlign: "center" }}>
              <font className={"headingfont"}>Name</font>
            </div>
            <div className={"col-3  hidebox"} style={{ textAlign: "center" }}>
              <font className={"headingfont"}>Email</font>
            </div>
            <div className={"col-2 hidebox"}>
              <font className={"headingfont ml-4"}>Phone</font>
            </div>
            <div className={"col-1 hidebox pl-0"}>
              <font className={"headingfont"}>Department</font>
            </div>
            <div className={"col-1 hidebox ml-5"}>
              <font className={"headingfont"}>Title</font>
            </div>
            <div className={"col-1 hidebox ml-5"}>
              <font className={"headingfont"}>Role</font>
            </div>
          </div>
        </div>
      </div>
      <div
        className={"row align-items-center"}
        style={{
          borderRadius: "0 0 20px 20px",
          boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
          backgroundColor: "white",
          marginTop: "1px",
        }}
      >
        <div className={"col py-2"}></div>
      </div>
      <GoToTop />
    </div>
  );
};
export default React.memo(UserList);
