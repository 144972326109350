import React from "react";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

const NavbarSidebarBtnForNewUser = () => (
  // return button for new user
  <div
    className={"pl-4 ml-3 sidebarBtnForNewUser background-colored-Pale-gray border-radius-16px"}

  >
    <div
      className={"row align-items-center py-1 background-colored-Pale-gray navbarsidebarBtnForNewUser11"}

    >
      <div
        className={"col-4 py-1 border-0 dashboardbtnpadding"}
        id={"dashboardbtn"}
      >
        <img
          src="/icon/common/Vector.png"
          alt={"dashboard"}
          className={"dashboardbtnimgpadding NavbarSidebarBtnForNewUser-box-shadow"}
          id="dashboardbtnimage"

        />
        <AppRegistrationIcon sx={{ color: 'white', fontSize: 30 }} style={{}} id="regisetericon" />

      </div>
      <div className={"col py-3 dashboardbtnpadding"}>
        <font className={"sidebarfont pl-1"} id={"dashboardfont"}>
          Home
        </font>
      </div>
    </div>
  </div>
);

export default React.memo(NavbarSidebarBtnForNewUser);
