import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import "../../styles/app.scss";
import { Route, Link, useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Signup from "./Signup";
import ForgotPassword from "./ForgotPassword";
import Register from "./Register";
import ResetPassword from "./ResetPassword";
import { loginUser } from "../../api/authentication/login";
import { verifyEmail } from "../../api/authentication/verify";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { getUserInfoForRegisterPage } from "../../api/common/userInfo";
import { getDetails } from "../../api/client/getDetails";
import { freegetDetails } from "../../api/freelance/getDetails";
import { recgetDetails } from "../../api/recruiter/getDetails";
import decode from "jwt-decode";
import io from "socket.io-client";
import { SOCKET_URL } from "../../api/ENVIRONMENT";
import { linkedinToken } from "../../api/authentication/linkedinToken";

const Login = () => {
  // return login page
  const dispatch = useDispatch();
  const history = useHistory();
  try {
    dispatch({ type: "newusertrue" });
    dispatch({ type: "buildresumefalse" });
    dispatch({ type: "recruiterfalse" });
    dispatch({ type: "recruiterCandidatefalse" });
    dispatch({ type: "freelancefalse" });
  } catch { }
  var count = 0;

  const { linkedInLogin } = useLinkedIn({
    clientId: "77bx0x727z9w6f",
    redirectUri: "https://app.dollarstaffing.com/linkedin",
    //redirectUri: "http://dollar-staffing.s3-website.ap-south-1.amazonaws.com/linkedin",
    scope: "r_liteprofile,r_emailaddress",
    onSuccess: async (code) => {
      let data = {
        authCode: code,
      };
      const response = await linkedinToken(data);
      if (response.status === 200) {
        let data = await response.json();
        data = data.payload;
        const token = data.token;
        const socket = io.connect(SOCKET_URL, {
          auth: {
            userId: decode(token).uuid,
          },
        });
        sessionStorage.setItem("token", token);
        dispatch({ type: "settoken", value: token });
        dispatch({ type: "setsocket", value: socket });
        try {
          const response = await getUserInfoForRegisterPage(token);
          if (response.status === 200) {
            const data = await response.json(token);
            if (data.payload.isRegistered) {
              dispatch({ type: "newuserfalse" });
              history.push("/candidate/dashboardview");
            } else {
              history.push("/candidate/dashboard/register");
            }
          }
        } catch { }
      }
    },
    onError: (error) => { },
  });

  const LoginHome = () => {
    const [passwordShown, setPasswordShown] = useState(false);
    // const [loginrole, setLoginRole] = useState();
    const [loginRole, setLoginRole] = useState("candidate");
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
    const param = useParams();
    const formRef = useRef();
    let btnRef = useRef();

    const togglePassword = (e) => {
      // toggle the password view to hide or show
      e.preventDefault();
      setPasswordShown(!passwordShown);
    };

    // const candidateChecked = document.getElementById("customtadio");
    // const clientChecked = document.getElementById("customtadio0");
    // const recChecked = document.getElementById("customtadio1");
    // const freeChecked = document.getElementById("customtadio2");
    const handleRoleClick = (role) => {
      setLoginRole(role);
      if (role !== "candidate") {
        // document.getElementById("linkedln").style.cssText = "display:none";
        // document.getElementById("btwlabel1").style.cssText = "display:none";
        // document.getElementById("google").style.cssText = "display:none"
        // document.getElementById("apple").style.cssText = "display:none"
        // document.getElementById("linkedin").style.cssText = "display:none"
        // document.getElementById("facebook").style.cssText = "display:none"

      } else {
        // document.getElementById("linkedln").style.cssText = "display:block";
        // document.getElementById("btwlabel1").style.cssText = "display:block ";
        // document.getElementById("btwlabel1").style.cssText = "font-family: 'Roboto'";
        // document.getElementById("btwlabel1").style.cssText = "font-size:20px";
        // document.getElementById("btwlabel1").style.cssText = "font-weight: '800'";
        // document.getElementById("btwlabel1").style.cssText = "color: ' #1E1E1E'";
        // document.getElementById("google").style.cssText = "display:block "
        // document.getElementById("google").style.cssText = "font-size:1rem"

        // document.getElementById("apple").style.cssText = "display:block "
        // document.getElementById("apple").style.cssText = " font-size:1rem"

        // document.getElementById("linkedin").style.cssText = "display:block "
        // document.getElementById("linkedin").style.cssText = " font-size:1rem"

        // document.getElementById("facebook").style.cssText = "display:block "

        // document.getElementById("facebook").style.cssText = " font-size:1rem"
      };
    }
    // const changebgtxt = (event) => {
    //   // this function is used for change bg and txt of role type of login page
    //   const defaultrole = document.getElementsByClassName("defaultrole")[0];
    //   //defaultrole.checked = false;
    //   defaultrole.nextSibling.style.cssText = "";
    //   defaultrole.parentNode.style.cssText = "";

    //   count += 1;
    //   if (count === 1) {
    //     const defaultrole = document.getElementsByClassName("defaultrole")[0];
    //     defaultrole.nextSibling.style.cssText = "";
    //     defaultrole.parentNode.style.cssText = "";
    //   }
    //   if (event.target.nodeName === "INPUT") {
    //     if (event.target.nextSibling.innerHTML !== "Candidate") {
    //       document.getElementById("linkedln").style.cssText = "display:none";
    //       document.getElementById("btwlabel1").style.cssText = "display:none";
    //     } else {
    //       document.getElementById("linkedln").style.cssText = "display:block";
    //       document.getElementById("btwlabel1").style.cssText = "display:block";
    //     }
    //     let background;
    //     if (event.target.value === "client")
    //       background = event.target.parentNode;
    //     else background = event.currentTarget.children[0];
    //     background.style.cssText = "background-color:#3F9AE0";
    //     event.target.nextSibling.style.cssText = "color:#FFFFFF";
    //     setLoginRole({ bg: background, txt: event.target.nextSibling });
    //   } else if (event.target.nodeName === "SPAN") {
    //     event.target.children[0].checked = true;
    //     if (event.target.innerText !== "Candidate") {
    //       document.getElementById("linkedln").style.cssText = "display:none";
    //       document.getElementById("btwlabel1").style.cssText = "display:none";
    //     } else {
    //       document.getElementById("linkedln").style.cssText = "display:block";
    //       document.getElementById("btwlabel1").style.cssText = "display:block";
    //     }

    //     event.target.style.cssText = "background-color:#3F9AE0";
    //     event.target.children[1].style.cssText = "color:#FFFFFF";
    //     setLoginRole({ bg: event.target, txt: event.target.children[1] });
    //   } else if (event.target.nodeName === "LABEL") {
    //     event.target.previousSibling.checked = true;
    //     if (event.target.textContent !== "Candidate") {
    //       document.getElementById("linkedln").style.cssText = "display:none";
    //       document.getElementById("btwlabel1").style.cssText = "display:none";
    //     } else {
    //       document.getElementById("linkedln").style.cssText = "display:block";
    //       document.getElementById("btwlabel1").style.cssText = "display:block";
    //     }

    //     event.target.parentNode.style.cssText = "background-color:#3F9AE0";
    //     event.target.style.cssText = "color:#FFFFFF";
    //     setLoginRole({ bg: event.target.parentNode, txt: event.target });
    //   }
    //   if (loginRole) {
    //     loginRole.bg.style.cssText = "";
    //     loginRole.txt.style.cssText = "";
    //   }
    // };
    useEffect(() => {
      // Retrieve email from storage and set it in the input field
      const storedEmail = localStorage.getItem("email");
      const userRole = localStorage.getItem('user-role')
      if (storedEmail && userRole) {
        formRef.current.email.value = storedEmail.toString();
        setLoginRole(userRole)
      }
    }, []);

    const selectdefaultrole = async (event) => {
      // const defaultrole = document.getElementsByClassName("defaultrole")[0];
      // defaultrole.checked = true;
      // defaultrole.nextSibling.style.cssText = "color:#FFFFFF";
      // defaultrole.parentNode.style.cssText = "background-color:#3F9AE0";

      // verifyemail start
      const errmsg = document.getElementsByClassName("errormsg")[0];
      const id = param.id;
      if (id) {
        const response = await verifyEmail(id);
        const status = response.status;
        if (status === 200) {
          const msg = await response.json();
          errmsg.innerText = msg.message;
          errmsg.style.cssText = "display:inline;color:green";
        }
      }
      // verifyemail ends
    };

    async function submitUser(userData) {
      // submit form for user creation if all validation meet requirement
      const rememberMe = document.getElementById("flexRadioDefault1").checked;
      if (btnRef.current) {
        btnRef.current.setAttribute("disabled", "disabled");
      }

      const form = formRef.current;
      // let opt = form.opt.value;
      let role =
        loginRole.toUpperCase() === "FREELANCE"
          ? "FREELANCE_RECRUITER"
          : loginRole.toUpperCase();
      console.log(role);
      try {
        document.getElementById("loader").style.display = "";
        const response = await loginUser({
          role: role,
          email: form.email.value,
          password: form.password.value,
        });

        const status = response.status;

        try {
          // sessionStorage.setItem("token", "");
          sessionStorage.setItem("clientnewuser", "");
          sessionStorage.removeItem("token");
          // sessionStorage.removeItem("email");
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          dispatch({ type: "settoken", value: "" });

          if (status === 200) {
            let data = await response.json();
            data = data.payload;
            const token = data.token;
            const socket = io.connect(SOCKET_URL, {
              auth: {
                userId: decode(token).uuid,
              },
            });
            // sessionStorage.setItem("token", token);
            // if (rememberMe) {
            //   localStorage.setItem("token", token); // Use localStorage for persistent storage
            // } else {
            //   sessionStorage.setItem("token", token); // Use sessionStorage for session-based storage
            // }
            if (rememberMe) {
              localStorage.setItem("email", form.email.value);
              localStorage.setItem("token", token);
              localStorage.setItem('user-role', loginRole)
            } else {
              // sessionStorage.setItem("email", form.email.value); 
              sessionStorage.setItem("token", token);
            }
            dispatch({ type: "settoken", value: token });
            dispatch({ type: "setsocket", value: socket });

            try {
              const response = await getUserInfoForRegisterPage(token);
              if (response.status === 200) {
                const data = await response.json(token);
                if (data.payload.isRegistered) {
                  dispatch({ type: "newuserfalse" });
                  history.push("/candidate/dashboardview");
                } else {
                  history.push("/candidate/dashboard/register");
                }
              }
            } catch { }
            try {
              const response = await getDetails(token);
              const status = response.status;
              let data = await response.json();
              data = data.payload;
              if (status === 200) {
                if (data.isRegistered) {
                  dispatch({ type: "newuserfalse" });
                  history.push("/client/dashboardview");
                } else {
                  history.push("/client/dashboard/register");
                }
              }
            } catch { }
            try {
              const response = await recgetDetails(token);
              const status = response.status;
              let data = await response.json();
              data = data.payload;
              if (status === 200) {
                if (data.isRegistered) {
                  dispatch({ type: "newuserfalse" });
                  dispatch({ type: "recruiterCandidatetrue" });
                  dispatch({ type: "recruitertrue" });
                  history.push("/candidate/dashboardview");
                } else {
                  history.push("/recruiter/dashboard/register");
                }
              }
            } catch { }
            try {
              const response = await freegetDetails(token);
              const status = response.status;
              let data = await response.json();
              data = data.payload;
              if (status === 200) {
                if (data.isRegistered) {
                  dispatch({ type: "newuserfalse" });
                  dispatch({ type: "freelancetrue" });
                  dispatch({ type: "recruitertrue" });
                  history.push("/candidate/dashboardview");
                } else {
                  history.push("/freelance/dashboard/register");
                }
              }
            } catch { }
          }
        } catch { }
        document.getElementById("loader").style.display = "none";
        btnRef.current.removeAttribute("disabled");
        const data = await response.json();
        const errmsg = document.getElementsByClassName("errormsg")[0];
        errmsg.innerText = data.message;
        errmsg.style.cssText = "display:inline;font-weight: 500";
        document.getElementsByClassName('btnsign')[0].classList.add("mt-2")
        // errmsg.classList.add('pb-2')
      } catch { }
    }

    return (
      <div
        className="main-container"
        // style={{ height: "100vh" }}
        onLoad={selectdefaultrole}
      >
        <div className="col-lg-12 ">
          <div className="row ">
            <div className="col-lg-6   image-style logInImgLeft" >
              <img
                src="/icon/Authentication/LoginAndSignup.png"
                className="full-cover-img  rounded-5"
                alt="dollar"

              />
              <img
                src="/icon/Authentication/DirectHire_Dark_logo.png"
                alt="Logo"
                height="110%"
                width="170%"
                className="top-left-logo-login"
              />
              {/* <div className="overlay-text"> */}
              {/* Welcome to Sulopa Tech */}
              {/* <span><span className="font-size-2rem" >DirectHire</span><br />
                  <span className="font-size-14 float-right" >by Sulopa</span>
                </span> */}

              {/* </div> */}
              {/* <img
                src="/icon/Authentication/DirectHire_logo.png" // replace with the path to your logo
                alt="Logo"
                className="top-left-logo-login"
              /> */}
            </div>

            <div className="col-lg-6   pl-lg-5 pr-lg-5  image-style formContainerMarginTop" >
              <div className="pl-lg-3 pr-lg-5">
                <div className=""> <form
                  className="form-group pl-lg-5 pr-lg-5 pt-5 "
                  onSubmit={handleSubmit(submitUser)}
                  ref={formRef}
                  method={"POST"}

                >
                  <div>
                    <h1 className="signup-text">Login</h1>
                    <div className="my-2 login-description  welcomeBackTextColor" >Welcome back! Please login
                      to your account </div>
                  </div>
                  <div className="mt-2">
                    <label className="d-block whoami-text">
                      Who am I
                    </label>
                    <div className="row mb-2 ml-1">
                      <button
                        type="button"
                        onClick={() => handleRoleClick("candidate")}
                        className={`btn typeofClient ${loginRole === "candidate" ? "active" : ""}`}
                        style={{

                          color: loginRole === "candidate" ? "#FFFFFF" : "#989898",
                          background: loginRole === "candidate" ? " #5E53BA" : "transparent"
                        }}>
                        Candidate
                      </button>

                      <button
                        type="button"
                        onClick={() => handleRoleClick("client")}
                        className={`btn typeofClient ${loginRole === "client" ? "active" : ""}`}
                        style={{

                          color: loginRole === "client" ? "#FFFFFF" : "#989898",
                          background: loginRole === "client" ? "#5E53BA" : "transparent"
                        }}>
                        Company
                      </button>

                      {/* <button
                        type="button"
                        onClick={() => handleRoleClick("recruiter")}
                        className={`btn typeofClient ${loginRole === "recruiter" ? "active" : ""}`}
                        style={{

                          color: loginRole === "recruiter" ? "#FFFFFF" : "#989898",
                          background: loginRole === "recruiter" ? " #5E53BA " : "transparent"
                        }}>
                        Recruiter
                      </button> */}

                      <button
                        type="button"
                        onClick={() => handleRoleClick("freelance")}
                        className={`btn typeofClient ${loginRole === "freelance" ? "active" : ""}`}
                        style={{

                          color: loginRole === "freelance" ? "#FFFFFF" : "#989898",
                          background: loginRole === "freelance" ? "#5E53BA " : "transparent"
                        }}>
                        {/* Freelance Recruiter */}
                        Recruiter
                      </button>
                    </div>
                  </div>

                  {/* <div className="">
                  <div
                    className=""
                    onClick={changebgtxt}
                    style={{
                      pointerEvents: `${recChecked !== null && recChecked.checked
                        ? "none"
                        : "auto"
                        }`,
                    }}
                  > */}

                  {/* </div> */}
                  {/* <div
                    className=""
                    onClick={changebgtxt}
                    style={{
                      pointerEvents: `${freeChecked !== null && freeChecked.checked
                        ? "none"
                        : "auto"
                        }`,
                    }}
                  > */}

                  {/* </div>
                </div> */}
                  <div className="row ">
                    <div className="col-lg">
                      {/* <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "28px",
                        color: "#11142D",
                        marginTop: "60px",
                        fontFamily: "Mulish",
                      }}
                    >
                      Log in
                    </label> */}

                      <div className="my-1 heading-label" >Email ID</div>
                    </div>
                  </div>
                  <div className="row  ">
                    <div className="col-lg-12 mb-2">
                      <input
                        type={"email"}
                        id={"email"}
                        {...register("email", {
                          required: {
                            value: true,
                            message: "Please enter a valid email ID",
                          },
                          pattern: {
                            value:
                              /^([a-zA-Z0-9+_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                            message: "Please enter a valid email ID",
                          },
                          onChange: () => {
                            document.getElementsByClassName(
                              "errormsg"
                            )[0].style.cssText = "display:none";
                          },
                        })}
                        className={
                          "form-control signupforminputfield shadow-none "
                        }
                        name={"email"}
                      />
                      {errors.email && (
                        <font
                          className={"dashboardforminputtext text-danger font-weight-normal "}
                        >
                          {errors.email.message}
                        </font>
                      )}
                    </div>
                  </div>
                  <div className="row ">
                    {/* <div className="col-xl-10"> */}
                    <div className="col-md">
                      {/* <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "28px",
                        color: "#11142D",
                        marginTop: "60px",
                        fontFamily: "Mulish",
                      }}
                    >
                      Log in
                    </label> */}
                      <div className="my-1 heading-label" >Password</div>
                    </div>
                  </div>

                  <div>
                    <div className="row">
                      <div className="col-lg-12">

                        <i
                          className={
                            passwordShown ? "fas fa-eye-slash eyeButton-login" : "fa fa-eye eyeButton-login"
                          }
                          onClick={togglePassword}

                        />
                        <input
                          type={passwordShown ? "text" : "password"}
                          name={"password"}
                          {...register("password", {
                            required: {
                              value: true,
                              message: "Please enter the password",
                            },
                            // minLength: {
                            //   value: 8,
                            //   message: "Password Must Have At Least 8 Character",
                            // },
                            // pattern: {
                            //   value:
                            //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/,
                            //   message: "Password Is Invalid",
                            // },
                            onChange: () => {
                              document.getElementsByClassName(
                                "errormsg"
                              )[0].style.cssText = "display:none";
                            },
                          })}
                          className={
                            "form-control forminputfield shadow-none pr-5"
                          }
                        />
                      </div>
                    </div>
                    {errors.password && (
                      <font
                        className={"dashboardforminputtext text-danger font-weight-normal"}
                      >
                        {errors.password.message}
                      </font>
                    )}
                  </div>
                  <div className="row  my-0">
                    <div className="col-lg-12 d-flex justify-content-between align-items-center ">
                      {/* <div className="form-check">
                        <input className="form-check-input rememberme-redio" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                        <label className="form-check-label rememberMe" htmlFor="flexRadioDefault1" >
                          Remember me
                        </label>
                      </div> */}
                      <div className="d-flex align-items-center">
                        <input
                          className=" rememberme-checkbox checkbx remembermecheckbox "
                          type="checkbox"
                          name="rememberMe"
                          id="flexRadioDefault1"
                        />
                        <label
                          className=" rememberMe mx-2  mt-2"
                          htmlFor="rememberMeCheckbox "
                        >
                          Remember me
                        </label>
                      </div>

                      <button className="btn p-0 forgot-password font-weight-500 font-size-08 font-family-roboto text-decoration-none colored-muted-purple">
                        <Link
                          to="/user/forgot"
                          className="forgot-pwd-margin-right colored-muted-purple"
                        >
                          Forgot Password?
                        </Link>
                      </button>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-lg-12  my-1">
                      <div className={"errormsg pr-2 font-weight-500"} ></div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-lg-7 col-7">
                      <button
                        type={"submit"}
                        ref={btnRef}
                        className={"btnsign loginnbtcss"}
                        id={"divbtndiv"}
                        onClick={handleSubmit(submitUser)}
                      >
                        {<i
                          className="fa fa-refresh fa-spin login-loader-css"
                          aria-hidden="true"
                          id={"loader"}

                        ></i>}
                        LOGIN
                      </button>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-lg-12 text-center my-2">
                      <label className={" text-center orcss"} id={"btwlabel1"} style={{ color: 'black', fontWeight: '600' }}>
                        OR
                      </label>
                    </div>
                  </div> */}
                  {/* <div className="row  mx-4">
                  <div className="col-xl-10">
                    <button
                      type={"button"}
                      className="btn btnlinkedln"
                      id={"linkedln"}
                      onClick={linkedInLogin}
                    >
                      Log in with Linkedln
                    </button>
                  </div>
                </div> */}
                  {/* <div className="col-lg-12 mb-1">
                    <div className="row justify-content-center">

                      <div className="col-auto ">
                        <button className="btn btn-icon socialMediaicons" onClick={() => { }} id="google"> */}
                  {/* <i className="fab fa-google "></i> */}
                  {/* <img src="/icon/Authentication/google.png" height={17} width={17} alt="google" />
                        </button>
                      </div>
                      <div className="col-auto">
                        <button className="btn btn-icon socialMediaicons" onClick={() => { }} id="apple"> */}
                  {/* <i className="fab fa-apple"></i> */}
                  {/* <img src="/icon/Authentication/apple.png" height={17} width={17} alt="apple" />
                        </button>
                      </div>
                      <div className="col-auto">
                        <button className="btn btn-icon socialMediaicons" onClick={() => { }} id="facebook"> */}
                  {/* <i className="fab fa-facebook-f"></i> */}
                  {/* <img src="/icon/Authentication/facebook.png" height={17} width={17} alt="facebook" />
                        </button>
                      </div>

                      <div className="col-auto">
                        <button className="btn btn-icon socialMediaicons" onClick={() => { }} id="linkedin"> */}
                  {/* <i className="fab fa-linkedin-in"></i> */}
                  {/* <img src="/icon/Authentication/linkedin.png" height={17} width={17} alt="linkedin" />
                        </button>
                      </div>
                    </div> */}
                  {/* </div> */}
                  <div className="row mt-2 pb-0 pt-1 d-flex justify-content-center align-items-center">
                    <div className=" pr-0 ">
                      <div
                        className={" pr-0 haveAnAccount"}

                      >
                        Don't have an account?
                      </div>
                    </div>
                    <div className=" pl-0">
                      <div className={"pl-2 loginnow text-decoration-none "}>
                        <Link
                          to="/user/register"
                          className="color-dark-gray"
                        >
                          Sign up
                        </Link>
                      </div>
                    </div>
                  </div>

                </form></div>
              </div>


            </div>
          </div>
        </div >
      </div >
    );
  };

  return (
    <React.Fragment>
      <Route path={["/user/login/:id", "/user/login"]}>
        <LoginHome />
      </Route>
      <Route path={"/user/forgot"}>
        <ForgotPassword />
      </Route>
      <Route path={"/user/reset/:token"}>
        <ResetPassword />
      </Route>
      <Route path={"/user/register"}>
        <Signup />
      </Route>
      <Route path={"/user/success"}>
        <Register />
      </Route>
    </React.Fragment>
  );
};

export default React.memo(Login);
