import "../../../styles/app.scss";
import React from "react";
import { Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import BuildResume2 from "./BuildResume2";
import BuildResume3 from "./BuildResume3";
import BuildResume4 from "./BuildResume4";
import BuildResume5 from "./BuildResume5";
import ResumeSnapshot from "../ResumeSnapshot";

const BuildResume = () => {
  // return buildresume page router
  try {
    const dispatch = useDispatch();
    dispatch({ type: "buildresumetrue" });
  } catch {}

  try {
    document.getElementById("dashboardfont").innerText = "Home";
    document.getElementById("dashboardbtn").style.display = "block";
    document.getElementById("replacetitleimg").innerText = "Build Resume";
  } catch {}
  return (
    <React.Fragment>
      <Route path={["/candidate/dashboard/buildresume/resumeexperience/:data","/candidate/dashboard/buildresume/resumeexperience"]}>
        <BuildResume2 />
      </Route>
      <Route path={"/candidate/dashboard/buildresume/resumecertificate"}>
        <BuildResume3 />
      </Route>
      <Route path={"/candidate/dashboard/buildresume/resumesecurity"}>
        <BuildResume4 />
      </Route>
      <Route path={"/candidate/dashboard/buildresume/resumesocial"}>
        <BuildResume5 />
      </Route>
      <Route path={"/candidate/dashboard/buildresume/resumesnapshot"}>
        <ResumeSnapshot />
      </Route>
    </React.Fragment>
  );
};
export default React.memo(BuildResume);
