import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MatchingPagination } from "../api/candidate/matchingPagination";
import { freematchingPagination } from "../api/freelance/candidate/matchingPagination";
import { recmatchingPagination } from "../api/recruiter/candidate/matchingPagination";
import { viewMore } from "../function/common/viewmore";

const MatchingjobCard = () => {
  // return group of matching job table data which generated by data variable
  const [match, setMatch] = useState([
    // {
    //   "uuid": "c147e8dc-841e-4394-8927-e51c3bd1668d",
    //   "ClientRequirementId": "DS05U24HA110",
    //   "requirementUuid": null,
    //   "hiringType": "INTERNAL_HIRING",
    //   "workType": "WORK_REMOTELY",

    //   'JobRequirement': {
    //     "jobTitle": "job ABC",
    //   },
    //   "clientName": "Sulopa Technology pvt ltd",
    //   "numberOfPosition": 3,
    //   "location": "Hatillo",
    //   "domain": "software",
    //   "startDate": "2024-08-07T00:00:00.000Z",
    //   "minDuration": 3,
    //   "maxDuration": 6,
    //   "interviewType": "TELEPHONE-IN_PERSON",
    //   "travelRequirement": "50%",
    //   "employementType": "W2Without",
    //   "rateType": "Yearly",
    //   "rate": 33000,
    //   "W2With": 14,
    //   "W2Without": 16,
    //   "C2H": 20,
    //   "C2C": 20,
    //   "W2HourlyWith": 17,
    //   "W2HourlyWithout": 18,
    //   "N1099With": 19,
    //   "N1099Without": 20,
    //   "updatedBy": null,
    //   "securityClearanceRequired": "YES",
    //   "securityClearanceClassification": "CONFIDENTIAL",
    //   "securityClearanceCategory": "SAPS",
    //   "mandatoryDocument": {
    //     "document": [
    //       "Passport",
    //       "Workauth Document"
    //     ]
    //   },
    //   "description": "React Native leverages common JavaScript skills while allowing a developer to simultaneously target both iOS and Android. Great React Native developers can build apps that feel truly native while using web technologies that are familiar to a wide range of developers. They usually focus on the front end of the product, but they are also adept at understanding the API services and infrastructure with which the app needs to integrate.",
    //   "skillSetKey": null,
    //   "ClientUuid": "5ec6deda-ee92-4c6a-b109-f10d02529e0d",
    //   "RecruiterUuid": null,
    //   "FreelanceRecruiterUuid": null,
    //   "archived": false,
    //   "createdAt": "2024-08-05T10:53:18.000Z",
    //   "updatedAt": "2024-08-05T10:58:28.000Z",
    //   "deletedAt": null,
    //   "shortlistedCount": 0,
    //   "scheduledCount": 0,
    //   "offerCount": 0,
    //   "AcademicCertificates": [
    //     {
    //       "uuid": "5cd37557-4908-4e6d-8dc0-e57b379490cd",
    //       "clearanceRequired": "YES",
    //       "academicsQualification": "BACHELORS",
    //       "specialization": "CSE",
    //       "JobRequirementUuid": "c147e8dc-841e-4394-8927-e51c3bd1668d",
    //       "createdAt": "2024-08-05T10:58:28.000Z",
    //       "updatedAt": "2024-08-05T10:58:28.000Z",
    //       "deletedAt": null
    //     }
    //   ],
    //   "ClientPrimarySkills": [
    //     {
    //       "uuid": "3c830636-a280-483f-bd0e-de98749797ff",
    //       "ClientPrimarySkill": "React native",
    //       "year": 2,
    //       "month": 0,
    //       "JobRequirementUuid": "c147e8dc-841e-4394-8927-e51c3bd1668d",
    //       "createdAt": "2024-08-05T10:58:28.000Z",
    //       "updatedAt": "2024-08-05T10:58:28.000Z",
    //       "deletedAt": null
    //     }
    //   ],
    //   "ClientSecondarySkills": [
    //     {
    //       "uuid": "7d370ac3-2d8a-4c4d-a722-a628bcb916c7",
    //       "ClientSecondarySkill": "Java",
    //       "year": 0,
    //       "month": 0,
    //       "type": "GOOD_TO_HAVE",
    //       "JobRequirementUuid": "c147e8dc-841e-4394-8927-e51c3bd1668d",
    //       "createdAt": "2024-08-05T10:58:28.000Z",
    //       "updatedAt": "2024-08-05T10:58:28.000Z",
    //       "deletedAt": null
    //     }
    //   ]
    // },
    // {
    //   "uuid": "47ca17a3-e240-4fdd-9a1f-dd935a197845",
    //   "ClientRequirementId": "DS02U24HA108",
    //   "requirementUuid": null,
    //   "hiringType": "INTERNAL_HIRING",
    //   "workType": "WORK_REMOTELY",
    //   "jobTitle": "py",
    //   'JobRequirement': {
    //     "jobTitle": "py",
    //   },
    //   "clientName": "Sulopa Technology pvt ltd",
    //   "numberOfPosition": 1,
    //   "location": "Las Marias",
    //   "domain": "py",
    //   "startDate": "2024-08-02T00:00:00.000Z",
    //   "minDuration": 3,
    //   "maxDuration": 6,
    //   "interviewType": "VIDEO",
    //   "travelRequirement": "0%",
    //   "employementType": "W2HourlyWithout",
    //   "rateType": "Hourly",
    //   "rate": 23,
    //   "W2With": 18,
    //   "W2Without": 20,
    //   "C2H": 26,
    //   "C2C": 26,
    //   "W2HourlyWith": 21,
    //   "W2HourlyWithout": 23,
    //   "N1099With": 24,
    //   "N1099Without": 26,
    //   "updatedBy": null,
    //   "securityClearanceRequired": null,
    //   "securityClearanceClassification": null,
    //   "securityClearanceCategory": null,
    //   "mandatoryDocument": null,
    //   "description": null,
    //   "skillSetKey": null,
    //   "ClientUuid": "5ec6deda-ee92-4c6a-b109-f10d02529e0d",
    //   "RecruiterUuid": null,
    //   "FreelanceRecruiterUuid": null,
    //   "archived": false,
    //   "createdAt": "2024-08-02T06:06:41.000Z",
    //   "updatedAt": "2024-08-02T06:06:41.000Z",
    //   "deletedAt": null,
    //   "shortlistedCount": 0,
    //   "scheduledCount": 0,
    //   "offerCount": 0,
    //   "AcademicCertificates": [],
    //   "ClientPrimarySkills": [
    //     {
    //       "uuid": "5af91f71-8e92-4c21-a513-ec65da718ee7",
    //       "ClientPrimarySkill": "fg",
    //       "year": 1,
    //       "month": 0,
    //       "JobRequirementUuid": "47ca17a3-e240-4fdd-9a1f-dd935a197845",
    //       "createdAt": "2024-08-02T06:07:38.000Z",
    //       "updatedAt": "2024-08-02T06:07:38.000Z",
    //       "deletedAt": null
    //     }
    //   ],
    //   "ClientSecondarySkills": []
    // }
  ]);
  const [record, setRecord] = useState(0);
  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);
  useEffect(() => {
    async function callUserDetails() {
      try {
        let response = isrecruiterCandidate
          ? await recmatchingPagination(token, 1)
          : isFreelance
            ? await freematchingPagination(token, 1)
            : await MatchingPagination(token, 1);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload.slice(0, 3);
          const totalRecord = payload[payload.length - 1].totalRecord;
          if (totalRecord !== 0) {
            setMatch(payload);
          }
          setRecord(totalRecord);
        }
      } catch { }
    }
    callUserDetails();
  }, []);

  const dispatch = useDispatch();
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  // console.log(match)
  return (
    <>
      {record !== 0 ? (
        <div className={"row col-md-12"}>
          <div className={"col-md-12 pt-2 pl-3 colored-lavender-gray font-size-30px font-weight-500"}>
            <label
              className="colored-lavender-gray font-size-30px font-weight-500"
            >
              Active Jobs
            </label>
          </div>
        </div>
      ) : (
        <div className={"row offset-sm-5 pt-5"}>
          <div className={"col-md-12 pt-2 pl-3"}>
            <label
              className="colored-lavender-gray font-size-30px font-weight-500"
            >
              No Active Jobs
            </label>
          </div>
        </div>
      )}
      {match.map((match, index) => (
        <div className="col-lg-4 pt-2" key={index}>
          {match.uuid ? (
            <Link
              to={match.actionTaken ? `/candidate/jobapply/${match.uuid}/Applied` : `/candidate/jobapply/${match.uuid}`}
              className={"matchingjobdata font-weight-600 font-size-20 "}
            >
              <div
                className={"p-3 lastrowcontent background-colored-primary-white client-mathingjobcard-card-shadow"}>
                <div className={"row"}>
                  <div className={"col-md-12"}>
                    <Link
                      to={match.actionTaken ? `/candidate/jobapply/${match.uuid}/Applied` : `/candidate/jobapply/${match.uuid}`}
                      className={"matchingjobdata font-weight-600 font-size-20 "}
                      style={{ fontSize: '1.2rem', color: 'black' }}

                    >
                      {/* {match.jobTitle && match.jobTitle.toUpperCase()} */}
                      {match.JobRequirement.jobTitle &&
                        match.JobRequirement.jobTitle.toUpperCase()}
                    </Link>
                    <br />
                    <label className="mb-3  colored-Granite-gray font-weight-500 font-size-16px" >
                      {match.JobRequirement.clientName}
                    </label>
                    <br />

                  </div>
                </div>
                <div className={"col-md-12 px-0 mb-4 font-size-14px colored-lavender-gray client-matchingjob-description-css"} style={{ color: 'black' }}>

                  {/* <div dangerouslySetInnerHTML={{ __html: match.JobRequirement.description }} /> */}
                  {/* {match.description} */}
                  Number of openings available     {match.JobRequirement.numberOfPosition}
                </div>
                <hr className="mt-0" />

                <div className={"row"}>
                  <div className="col-md-6">
                    <label className={"mr-4 font-weight-500 font-size-14px colored-Graphite-gray"}>
                      ${match.JobRequirement.rate}
                    </label>
                  </div>
                  <div className="col-md-6">
                    <label className="float-right colored-Silver-mist font-size-12px font-weight-500" >
                      {new Date(match.JobRequirement.createdAt).toLocaleDateString(
                        "en-US",
                        options
                      )}
                    </label>
                  </div>
                </div>
              </div>
            </Link>
          ) : (
            ""
          )}
        </div>
      ))}
      {record !== 0 ? (
        <div className={"row pt-4 pb-0 col-md-12"}>
          <div className={"col-md"}>
            <Link
              to={"/candidate/matchjobs"}
              className={"btn float-right dashboardviewmorelink"}
              onClick={() => viewMore(dispatch)}
            >
              View more
            </Link>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default MatchingjobCard;


// <div
//   className={"pb-1 lastrowcontent background-colored-primary-white candidate-matching-job-content-box-shadow font-family-roboto"}
//   style={{

//   }}
// >
//   <div className="col-md-12">
//     <div className={"row pt-2 background-colored-Deep-violet candidate-matching-job-border-radius-card"} >
//       <div className={"col-md-9 mx-2"}>
//         {match.actionTaken ? (
//           <Link
//             to={`/candidate/jobapply/${match.uuid}/Applied`}
//             className={"matchingjobdata font-weight-600 font-size-20px colored-primary-white"}

//           >
//             {match.JobRequirement.jobTitle &&
//               match.JobRequirement.jobTitle.toUpperCase()}
//           </Link>
//         ) : (
//           <Link
//             to={`/candidate/jobapply/${match.uuid}`}
//             className={"matchingjobdata font-weight-600 font-size-20px colored-primary-white"}
//           >
//             {match.JobRequirement.jobTitle &&
//               match.JobRequirement.jobTitle.toUpperCase()}
//           </Link>
//         )}
//         <br />
//         <label className="font-weight-500 font-size-16px colored-primary-white" >
//           {new Date(match.createdAt).toLocaleDateString(
//             "en-US",
//             options
//           )}
//         </label>
//       </div>
//     </div>
//   </div>
//   <hr />
//   <div className={"row mx-3 my-2"}>
//     <div className={"col-md-2"}>
//       <img
//         src={"/icon/common/money 1.svg"}
//         alt={"currency"}
//         className={"img-fluid"}
//       />
//     </div>
//     <div className={"col-md my-1"}>
//       <label
//         className={"mr-4 font-weight-600 font-size-18px colored-Graphite-gray"}
//       >
//         ${match.JobRequirement.rate}
//       </label>
//       <label
//       className="font-size-18px font-weight-normal colored-neutral-gray ml-5"
//       >
//         /
//         {match.JobRequirement.rateType &&
//           match.JobRequirement.rateType.toLowerCase()}
//       </label>
//     </div>
//   </div>
//   <div className={"row mx-3 my-2"}>
//     <div className={"col-md-2"}>
//       <img
//         src={"/icon/common/map_2.svg"}
//         alt={"map"}
//         className={"img-fluid"}
//       />
//     </div>
//     <div className={"col-md my-1"}>
//       <label
//         className={"mr-5 font-weight-600 font-size-18px colored-Graphite-gray"}
//       >
//         {match.JobRequirement.location &&
//           match.JobRequirement.location.charAt(0).toUpperCase() +
//           match.JobRequirement.location.slice(1).toLowerCase()}
//       </label>
//     </div>
//   </div>
//   <div className={"row mx-3 my-2"}>
//     <div className={"col-md-2"}>
//       <img
//         src={"/icon/common/jobSuitcase.svg"}
//         alt={"Time"}
//         className={"img-fluid"}
//       />
//     </div>
//     <div className={"col-md my-1"}>
//       <label
//         className={"mr-5 font-weight-600 font-size-18px colored-Graphite-gray"}
//       >
//         {match.JobRequirement.employementType}
//       </label>
//     </div>
//   </div>
// </div>