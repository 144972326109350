import { RECRUITERBUILDSOCIAL } from "../../../ENVIRONMENT";

// api for submit recruiter candidate buildresume social
async function postBuildResumeSocial(token, data,id) {
  return await fetch(RECRUITERBUILDSOCIAL + `/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
}

// api for recruiter candidate buildresume social
async function getBuildResumeSocial(token,id) {
  return await fetch(RECRUITERBUILDSOCIAL + `/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
}

export { postBuildResumeSocial, getBuildResumeSocial };
