import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  handleRemoveShortlistIcon,
  handleShortlistIcon,
  SelectSingle,
} from "../common/SingleSelectMatchingJob";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import { useMediaQuery } from 'react-responsive';
// import { helix } from 'ldrs'
import { Audio } from 'react-loader-spinner'
import { Rings } from 'react-loader-spinner'
import { Puff } from 'react-loader-spinner'




// helix.register()
const Data = (props) => {
  // const dummyData = [
  //   {
  //     uuid: "1",
  //     jobRequirementId: "101",
  //     Candidate: {
  //       candidateId: "C001",
  //       preferredLocation: "New York",
  //       Addresses: [{ city: "New York" }],
  //       resumeTitle: "Software Engineer",
  //       preferredEmployment: "Full-time",
  //       CandidateExperiences: [{ totalYearOfExperience: "5" }],
  //       rate: "50/hr",
  //       userId: "U001",
  //     },
  //     clientShortlisted: true,
  //     matchPercentage: 85,
  //     JobRequirement: { domain: "IT", archived: false },
  //     initiatedBy: "CANDIDATE",
  //     status: null,
  //     rejected: false,
  //   },
  //   {
  //     uuid: "2",
  //     jobRequirementId: "102",
  //     Candidate: {
  //       candidateId: "C002",
  //       preferredLocation: null,
  //       Addresses: [{ city: "Los Angeles" }],
  //       resumeTitle: "Data Scientist",
  //       preferredEmployment: "Contract",
  //       CandidateExperiences: [{ totalYearOfExperience: "3" }],
  //       rate: "70/hr",
  //       userId: "U002",
  //     },
  //     clientShortlisted: false,
  //     matchPercentage: 90,
  //     JobRequirement: { domain: "Data Science", archived: true },
  //     initiatedBy: "CLIENT",
  //     status: "Screening",
  //     rejected: false,
  //   },
  //   {
  //     uuid: "3",
  //     jobRequirementId: "103",
  //     Candidate: {
  //       candidateId: "C003",
  //       preferredLocation: "San Francisco",
  //       Addresses: [{ city: "San Francisco" }],
  //       resumeTitle: "DevOps Engineer",
  //       preferredEmployment: "Remote",
  //       CandidateExperiences: [{ totalYearOfExperience: "7" }],
  //       rate: "80/hr",
  //       userId: "U003",
  //     },
  //     clientShortlisted: true,
  //     matchPercentage: 75,
  //     JobRequirement: { domain: "IT", archived: false },
  //     initiatedBy: "CANDIDATE",
  //     status: "Interview",
  //     rejected: true,
  //   },
  //   {
  //     uuid: "4",
  //     jobRequirementId: "104",
  //     Candidate: {
  //       candidateId: "C004",
  //       preferredLocation: "Chicago",
  //       Addresses: [{ city: "Chicago" }],
  //       resumeTitle: "Product Manager",
  //       preferredEmployment: "Part-time",
  //       CandidateExperiences: [{ totalYearOfExperience: "10" }],
  //       rate: "60/hr",
  //       userId: "U004",
  //     },
  //     clientShortlisted: false,
  //     matchPercentage: 95,
  //     JobRequirement: { domain: "Management", archived: false },
  //     initiatedBy: "CLIENT",
  //     status: null,
  //     rejected: false,
  //   },
  // ];

  const [match, setMatch] = useState([]);
  const [loading, setLoading] = useState();
  useEffect(() => {
    try {
      setLoading(true);
      if (props.item.length !== 0) {
        setMatch(props.item);
        setLoading(false);
      }
      if (props.record === 0) {
        setLoading(false);
      }
    } catch { }
  }, [props.item]);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1150px)' });
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1151px)' });
  return (
    // return application table data in a group which generate by data variable
    <>
      {loading ? (
        // <div className="loader">
        //   <div className="svg-wrapper">   <Audio
        //     height="80"
        //     width="80"
        //     radius="9"
        //     color="rgb(246, 244, 255)"
        //     ariaLabel="loading"
        //     wrapperStyle
        //     wrapperClass
        //   /></div>

        // </div>
        //----------------------
        // <div className="loader">
        //   <div className="svg-wrapper">    <Rings
        //     height="80"
        //     width="80"
        //     color="#3D007A"
        //     ariaLabel="rings-loading"
        //     wrapperStyle={{}}
        //     wrapperClass=""
        //     visible={true}
        //   /></div>

        // </div>
        //-----------------------
        <div className="loader">
          <div className="svg-wrapper">    <Puff
            height="80"
            width="80"
            color="#3D007A"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          /></div>

        </div>
        //-----------------------------
        // <div className="loader">
        //   <div className="svg-wrapper">
        //     {/* copy svg image and past it here */}
        //     <svg
        //       height="80"
        //       viewBox="0 0 196 313"
        //       fill=""
        //       xmlns="http://www.w3.org/2000/svg"
        //     >
        //       <title>Loader</title>
        //       <defs>
        //         <linearGradient id="ttb" y2="1">

        //           {/* if you are thinking how it is animating 
        //               we have added animation to svg here 
        //           */}

        //           <stop offset="100%" stopOpacity="1" stopColor="#3D007A">
        //             <animate
        //               attributeName="offset"
        //               values="0;1;1;0"
        //               repeatCount="indefinite"
        //               repeatDur="05:00"
        //               dur="4s"
        //               begin="0s"
        //             />
        //           </stop>
        //           <stop offset="100%" stopOpacity="1" stopColor="#E0E0E0">
        //             <animate
        //               attributeName="offset"
        //               values="0;1;1;0"
        //               repeatCount="indefinite"
        //               repeatDur="05:00"
        //               dur="4s"
        //               begin="0s"
        //             />
        //           </stop>

        //           {/* end */}

        //         </linearGradient>
        //       </defs>
        //       <path
        //         fill="url(#ttb)"
        //         d="M188.971 175.237L196 145.949V102.018L169.446 56.6225L167.493 36.3165L161.831 41.9788L139.573 9.86013L137.425 20.7941L110.383 4.00262L108.528 14.9366L87.7339 0L82.9502 18.6464L49.953 14.9366L54.1509 23.0395L39.8 24.6015V35.8284L30.5256 41.8811L35.6997 46.7624L23.3014 51.1555L28.2803 56.7201L0.75 102.116V148.292L8.36475 173.87L12.465 165.474V141.361L22.2275 127.986V94.7939L40.6786 70.29H98.8631L156.852 69.8019L175.499 94.9891V127.694L185.261 142.825V165.084L188.971 175.237Z M175.01 147.999L171.203 141.166L159.293 134.43L125.124 137.651L120.243 146.828L128.639 147.999L165.638 143.704L175.01 147.999Z M22.8134 147.999L26.6208 141.166L38.531 134.43L72.6022 137.651L77.4834 146.828L69.0877 147.999L32.1854 143.704L22.8134 147.999Z M140.256 245.039L111.262 235.179L102.28 240.646V221.609L114.776 224.44L146.895 232.445L153.631 250.603L140.256 245.039ZM57.5679 245.039L86.4649 235.179L95.4464 240.646V221.609L82.9504 224.44L50.8317 232.445L44.0956 250.603L57.5679 245.039Z M161.831 229.614L149.921 259.975L132.739 277.255H64.9873L48.7816 260.952L35.8951 229.614L10.7078 202.572L12.1722 229.614L23.3991 265.735L53.4676 296.097V296.389L69.5757 312.009L99.5466 312.4L129.42 312.009L174.23 265.735L185.554 229.614L187.116 202.572L161.831 229.614Z"
        //       />
        //     </svg>
        //     <div className="loading-text">Hiring...</div>
        //   </div>
        // </div>
        //-------------------------
        // <div className="loader">
        //   <div className="svg-wrapper">
        //     {/* <svg
        //       width="100px"
        //       height="100px"
        //       viewBox="0 0 100 100"
        //       xmlns="http://www.w3.org/2000/svg"
        //       fill="none"
        //       stroke-width="4"
        //     >
        //       <title>Hiring Loader</title>
        //       <defs>
        //         <linearGradient id="mirrorGradient" x1="0%" y1="0%" x2="100%" y2="100%">
        //           <stop offset="0%" stop-color="#FFFFFF" />
        //           <stop offset="100%" stop-color="#000000" />
        //         </linearGradient>
        //         <filter id="mirrorEffect" x="0" y="0" width="100%" height="100%">
        //           <feGaussianBlur in="SourceGraphic" stdDeviation="4" result="blur" />
        //           <feOffset in="blur" dx="0" dy="4" result="offsetBlur" />
        //           <feMerge>
        //             <feMergeNode in="offsetBlur" />
        //             <feMergeNode in="SourceGraphic" />
        //           </feMerge>
        //         </filter>
        //       </defs>

        //       <circle
        //         cx="50"
        //         cy="50"
        //         r="30"
        //         stroke="url(#mirrorGradient)"
        //         stroke-width="4"
        //         stroke-linecap="round"
        //         stroke-linejoin="round"
        //         fill="none"
        //       />
        //       <line
        //         x1="70"
        //         y1="70"
        //         x2="90"
        //         y2="90"
        //         stroke="url(#mirrorGradient)"
        //         stroke-width="4"
        //         stroke-linecap="round"
        //       />

        //       <circle
        //         cx="50"
        //         cy="50"
        //         r="5"
        //         fill="url(#mirrorGradient)"
        //         stroke="none"
        //       >
        //         <animate
        //           attributeName="r"
        //           values="5;15;5"
        //           dur="2s"
        //           repeatCount="indefinite"
        //         />
        //       </circle>

        //       <circle
        //         cx="50"
        //         cy="50"
        //         r="30"
        //         stroke="url(#mirrorGradient)"
        //         stroke-width="4"
        //         fill="none"
        //         filter="url(#mirrorEffect)"
        //       >
        //         <animate
        //           attributeName="stroke-dashoffset"
        //           from="0"
        //           to="100"
        //           dur="2s"
        //           repeatCount="indefinite"
        //         />
        //       </circle>
        //     </svg> */}





        //   </div>
        // </div>
        //------------------------------


        // Default values shown
        // <l-helix
        //   size="45"
        //   speed="2.5"
        //   color="black"
        // ></l-helix>
        // <div
        //   className="row h-100 background-colored-Pale-gray applicationData-conatiner-padding-bottom"
        //  style={{ paddingBottom: "11%", backgroundColor: "#F9F9F9" }}
        // >
        //   <div className="col-sm-12 my-auto">
        //     <img
        //       src={"/icon/Gif/loader.gif"}
        //       className={"mx-auto d-block"}
        //       alt={"loader"}
        //     />
        //   </div>
        // </div>
      ) : (
        <>
          {props.record !== 0 ? (
            <>
              {isTabletOrMobile && <div className="row pl-3 pl-lg-0">
                {match.map((match) => (
                  <div className="col-md-6" key={match.uuid && match.uuid.split("-").pop()}>
                    <div className="matchingJobCard p-3 mt-3">
                      <div className="mb-0 d-flex flex-row justify-content-between">
                        <div className="inline-block font-weight-medium ">
                          {/* {cardName === "archive" ? ( */}
                          <Link
                            to={`/client/filteredapplications?id=${match.uuid}`}
                            className={"matchingjobdatalink font-size-20px"}

                          // onClick={() => clientArchiveRedirect(dispatch)}
                          >
                            {match.Candidate && match.Candidate.resumeTitle}

                          </Link>
                          {/* ) : (
                            <Link
                              to={`/client/filteredapplications?id=${match.uuid}`}
                              className={"matchingjobdatalink font-size-20px"}
                              // onClick={() => clientRedirect(dispatch)}
                            >
                              {match.jobTitle}
                            </Link> */}
                          {/* )} */}
                        </div>
                        <div className="d-flex">
                          <div className="font-weight-medium colored-Steel-gray matchingjob-cretaedAt-width font-size-18px" >
                            {new Date(match.createdAt).toLocaleDateString(
                              "en-US",
                              options
                            )}
                          </div>





                          {/* <i class="fa fa-ellipsis-h pl-3" aria-hidden="true" aria-describedby="popup-8"
                          style={{ fontSize: '16px', color: '#F24E1E', cursor: 'pointer' }} ></i> */}
                        </div>
                        {/* <div
                          className={`modal fade ${props.archiveModal ? "show" : ""
                            }`}
                          id="deactivateprofile"
                          data-backdrop={"static"}
                          style={{
                            display: `${props.archiveModal ? "block" : "none"}`,
                          }}>
                          <div>
                            <div
                              className="modal-dialog matchingjob-modal-border"

                            >
                              <div className="modal-content">
                                <div className="modal-title pr-3 pt-3">
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    onClick={props.hideModal}
                                  >
                                    &times;
                                  </button>
                                </div>
                                <div className="modal-body pl-5 ml-3 matchingjobdata">
                                  {props.archiveErrmsg} <br />
                                  you want to archive the requirement
                                </div>
                                <div className="row pt-3 pb-4 pl-5 ml-5">
                                  <div className="col-md-5">
                                    <button
                                      type="button"
                                      className="btn btn-success py-1"
                                      onClick={() =>
                                        props.handleAssociatedRequirement(
                                          props.archiveId
                                        )
                                      }
                                      data-dismiss="modal"
                                    >
                                      Yes
                                    </button>
                                  </div>
                                  <div className="col-md-3">
                                    <button
                                      type="button"
                                      className="btn btn-danger py-1"
                                      data-dismiss="modal"
                                      onClick={props.hideModal}
                                    >
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className="mt-0 d-inline-block font-weight-medium font-size-18px color-dark-gray" >
                        <Link
                          to={`/client/jobapply/${match.uuid}`}
                          className={"matchingjobdatalink font-size-18px "}

                        >
                          {match.Candidate.candidateId &&
                            match.Candidate.candidateId}
                          {/* {match.Candidate.candidateId} */}
                        </Link>
                      </div>
                      <br />
                      {/* <div className="mt-2 d-inline-block font-weight-medium font-size-18px color-dark-gray " >
                        SourcedBy : {match.Candidate.preferredLocation === null
                          ? match.Candidate.Addresses[0]?.city
                          : match.Candidate.preferredLocation}
                      </div> */}
                      <div className="mt-2 d-flex d-flex-row justify-content-between">
                        <div className="d-inline-block font-weight-medium font-size-18px color-dark-gray" >
                          Location : {match.Candidate.preferredLocation === null
                            ? match.Candidate.Addresses[0]?.city
                            : match.Candidate.preferredLocation}
                        </div>
                        {/* <div className="d-flex p-1 matchingJob-working-type-cssText"
                        >
                          <span className="font-weight-medium colored-Forest-green font-size-16px" >
                             {match.workType &&
                              toTitleCase(match.workType.replace(/_/g, " "))} 
                          </span>
                        </div> */}
                      </div>
                      <div className="mb-2 d-inline-block font-weight-medium font-size-18px color-dark-gray" >
                        Experience : {match.Candidate.CandidateExperiences.length !== 0 ?
                          match.Candidate.CandidateExperiences[0]
                            .totalYearOfExperience
                          : "0"} Year
                      </div>

                      <div className="row">
                        <div className="mb-2 ml-3 d-inline-block font-weight-medium font-size-18px color-dark-gray" >
                          Rate : ${match.Candidate.rate}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-7  col-lg-5">
                          <div className=" d-flex d-flex-row">
                            <div className="d-flex align-items-center justify-content-center font-weight-medium color-dark-gray font-size-18px ">
                              Matching
                            </div>
                            <div className="flex p-2">
                              <div className="matchingJob-progressbar-width" style={{ width: '03rem' }}>
                                <CircularProgressbar
                                  value={match.matchPercentage}
                                  text={`${match.matchPercentage}%`}
                                  strokeWidth={11}
                                  // text={`100$`}
                                  styles={buildStyles({
                                    strokeWidth: 15,

                                    textColor: "#000",
                                    pathColor: "#5B4FB7",
                                    trailColor: "#f9f9f9",

                                  })}
                                  className="thick-circle"
                                />

                              </div>
                            </div>

                          </div>

                        </div>
                        <div className="p-2 py-3 col-5 col-lg-7">
                          {/* <div className="matchingJob-progressbar-width" style={{ width: '03rem' }}> */}

                          {match.JobRequirement.domain}
                          {/* </div> */}
                        </div>
                        {/* <div className="col-md-5">
                          {/* {cardName !== "archive" && ( 

                          <div className="d-flex d-flex-row">
                            <div className="d-flex justify-content-center align-items-center font-weight-medium color-dark-gray font-size-20px" >
                              Shortlisted
                            </div>
                            <div className="flex p-2">
                              <div className="matchingJob-progressbar-width-two">
                                <CircularProgressbar
                                  value={match.shortlistedCount ?? 0}
                                  text={`${match.shortlistedCount ?? 0}`}
                                  strokeWidth={11}
                                  // text={`100$`}
                                  styles={buildStyles({
                                    strokeWidth: '15',

                                    textColor: "#000",
                                    pathColor: "#5B4FB7",
                                    trailColor: "#f9f9f9",
                                  })}
                                  className="thick-circle"
                                />
                              </div>
                            </div>

                          </div>
                        </div> */}


                        {/* <div className="col-md-4">
                          <div className="d-flex d-flex-row">
                            <div className="d-flex align-items-center justify-content-center font-weight-medium color-dark-gray font-size-20px"
                            >
                              offered
                            </div>
                            <div className="flex p-2">
                              <div className="matchingJob-progressbar-width-Three">
                                <CircularProgressbar
                                  value={0}
                                  strokeWidth={11}
                                  // text={`${match.matchPercentage}%`}
                                  text={`0`}
                                  styles={buildStyles({
                                    strokeWidth: 15,

                                    textColor: "#000",
                                    pathColor: "#5B4FB7",
                                    trailColor: "#f9f9f9",
                                  })}
                                  className="thick-circle"
                                />
                              </div>
                            </div>
                          </div>
                        </div> */}

                      </div>
                    </div>
                  </div>
                ))}
              </div>
              }
              {isDesktopOrLaptop && match.map((match, index) => (
                <div
                  className={"row pl-3 background-colored-primary-white d-flex justify-content-center align-items-center"}
                  style={{
                    // boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
                    // backgroundColor: "white",
                    marginTop: `${index === 0 ? '1px' : ''}`,
                  }}
                  key={match.jobRequirementId}
                >

                  <div>
                    {match.clientShortlisted ? (
                      <>
                        <label
                          className="font-size-22px colored-Bright-orange mt-1"

                          onClick={(e) => {
                            handleShortlistIcon(e);
                            props.handleremoveSingleShortlist(match.uuid);
                          }}
                        >
                          &#9733;
                        </label>
                        <label
                          className="colored-primary-black font-size-24px font-weight-500 d-none mt-1"

                          onClick={(e) => {
                            handleRemoveShortlistIcon(e);
                            props.handleaddSingleShortlist(match.uuid);
                          }}
                        >
                          &#9734;
                        </label>
                      </>
                    ) : (
                      <>
                        <label

                          className={"mt-1 d-none font-size-22px colored-Bright-orange"}
                          onClick={(e) => {
                            handleShortlistIcon(e)
                            props.handleremoveSingleShortlist(match.uuid);
                          }}
                        >
                          &#9733;
                        </label>
                        <label
                          className={"mt-1 font-size-24px font-weight-500 colored-primary-black"}
                          onClick={(e) => {
                            handleRemoveShortlistIcon(e);
                            props.handleaddSingleShortlist(match.uuid);
                          }}
                        >
                          &#9734;
                        </label>
                      </>
                    )}
                  </div>
                  <input
                    type={"checkbox"}
                    className={"checkbx modalcheckbox mr-4 mt-0 ml-lg-2 ml-3"}
                    onClick={(e) => {
                      {
                        SelectSingle(e);
                        props.handleShortlist(e, match.clientShortlisted);
                      }
                    }}
                    value={match.uuid}
                  />
                  <div className={"col py-3  box"}>
                    {/*heading box data*/}
                    <div className={"row align-items-center"}>
                      <div className={"col-1 pl-0"}>
                        <div className={"row"}>
                          <div className={"col-md"}>
                            <Link
                              to={`/client/candidatedetails/${match.uuid}`}
                              className={"matchingjobdatalink "}
                            >
                              {match.Candidate.candidateId}
                            </Link>
                          </div>
                        </div>
                        <div className={"row"}>
                          <div
                            className={"col-md applicationData-wordWrapand-whitespace"}

                          >
                            <font className="font-weight-normal fonr-size-08 colored-Lavender-slate" >
                              {match.Candidate.preferredLocation === null
                                ? match.Candidate.Addresses[0]?.city
                                : match.Candidate.preferredLocation}
                            </font>
                          </div>
                        </div>
                      </div>
                      <div className={"col-2"}>
                        <div
                          className="ml-5 pl-lg-2 pl-0 applicationData-wordWrapand-whitespace"

                        >
                          <Link
                            to={`/client/candidatedetails/${match.uuid}`}
                            className={"matchingjobdatalink "}
                          >
                            {match.Candidate && match.Candidate.resumeTitle}
                          </Link>
                        </div>
                      </div>
                      <div className={"col-1"}>
                        <font
                          className="font-weight-normal font-size-08 colored-Lavender-slate"

                        >
                          Client
                        </font>
                      </div>
                      <div className="col-3">
                        <div className={"row ml-2 pr-0"}>
                          <div className="col-4 pr-0 d-flex align-items-center justify-content-center">
                            {/* <div
                              className={"badge py-3"}
                              style={{
                                backgroundColor: "#D3D3D3",
                                fontWeight: "500",
                                fontSize: "14px",
                              }}
                            > */}
                            <div className="pr-3">
                              <CircularProgressbar
                                value={match ? match.matchPercentage : ''}
                                text={`${match ? match.matchPercentage : ''}%`}
                                styles={buildStyles({
                                  textColor: "#7E73B5",
                                  pathColor: "#5B4FB7",
                                  trailColor: "#f9f9f9",
                                })}
                              />
                            </div>
                            {/* {match.matchPercentage} % */}
                            {/* </div> */}
                          </div>
                          <div className="col-8 pl-0 pr-5">
                            <div className={"row"}>
                              <div
                                className="col-md applicationData-wordWrapand-whitespace"

                              >
                                <font
                                  className="colored-Lavender-slate font-weight-600 font-size-1rem"
                                // style={{
                                //   fontWeight: 600,
                                //   fontsize: '1rem',
                                //   color: '#7E73B5',
                                // }}
                                >
                                  {match.JobRequirement.domain}
                                </font>
                              </div>
                            </div>
                            <div className={"row"}>
                              <div className={"col-md"}>
                                <font
                                  className="colored-Lavender-slate font-size-08 font-weight-normal"

                                >
                                  {match.Candidate.preferredEmployment}
                                </font>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={"col-1 pl-0 margin-left--2"}>
                        <font
                          className="font-weight-normal font-size-08 colored-Lavender-slate "
                        >
                          {
                            match.Candidate.CandidateExperiences.length !== 0 ?
                              match.Candidate.CandidateExperiences[0]
                                .totalYearOfExperience
                              : "0"
                          }{" "}
                          year
                        </font>
                      </div>
                      <div className={"col-1 ml-3"}>
                        <div
                          className={"ml-3 applicationData-wordWrapand-whitespace "}

                        >
                          <font
                            className="font-weight-normal font-size-08 colored-Lavender-slate"

                          >
                            ${match.Candidate.rate}
                          </font>
                        </div>
                      </div>
                      <div className="col-1 pr-0 pl-0">
                        {match.initiatedBy === "CANDIDATE" && (
                          <font
                            className="font-weight-normal font-size-08 colored-Lavender-slate"
                          >
                            Applied
                          </font>
                        )}
                        {match.initiatedBy === "CLIENT" && (
                          <font
                            className="font-weight-normal font-size-08 colored-Lavender-slate"
                          >
                            Request
                          </font>
                        )}
                      </div>

                      <div className={`col-1`} >
                        {match.JobRequirement.archived ? (
                          <label
                            // style={{ marginLeft: '-2rem' }}
                            className={"btn background-colored-Lavender-slate font-size-09rem border-radius-05rem font-weight-normal colored-primary-white applicationData-btn-css client-applicationData-margin-left-2rem margin-left-74 "}


                          // width: 81px;
                          // height: 31px;

                          >
                            {match.status === null ? "Interview" : match.status}
                          </label>
                        ) : (
                          <>
                            {match.status === null ? (
                              <Link
                                // style={{ marginLeft: '-2rem' }}
                                to={`/client/interview/${match.Candidate.userId}/${match.uuid}`}
                                className={"btn background-colored-Lavender-slate font-size-09rem border-radius-05rem font-weight-normal colored-primary-white applicationData-btn-css client-applicationData-margin-left-2rem margin-left-74"}

                              >
                                Interview
                              </Link>
                            ) : (
                              <label
                                // style={{ marginLeft: '-2rem' }}
                                className={"btn background-colored-Lavender-slate font-size-09rem border-radius-05rem font-weight-normal colored-primary-white applicationData-btn-css client-applicationData-margin-left-2rem margin-left-74"}

                              >
                                {match.status}
                              </label>
                            )}
                          </>
                        )}
                      </div>
                      <div className={"col-1 pr-0"}>
                        {
                          match.rejected ?
                            "" :
                            <img
                              src="/icon/candidate/applications/Union.png"
                              aria-hidden="true"
                              style={{
                                cursor: "pointer",
                                marginLeft: '30%'
                              }}
                              onClick={() => {
                                props.handleSingleReject(match.uuid);
                              }}
                            />
                        }

                      </div>
                    </div>
                  </div>
                </div>
              ))}{" "}
            </>
          ) : (
            <div className="d-flex justify-content-center pt-5 pb-5">
              No Data Found
            </div>
          )}
        </>
      )
      }
    </>
  );
};

export { Data };
