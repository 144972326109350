import React, { useEffect, useRef, useState } from "react";
import GoToTop from "../../common/GoToTop";
import { Link, useParams } from "react-router-dom";
import { Redirect } from "react-router";
import { jobRequirementSkill } from "../../../function/client/validateField";
import { secondarySkill } from "../../../function/candidate/validateField";
import { useSelector } from "react-redux";
import { postBuildRequirementSkill } from "../../../api/client/buildRequirementSkill";
import { setParsedSkillData, setSkillData } from "../../../function/client/setSkillData";
import { verifyBuildRequirementSkill } from "../../../function/client/checkRequired";
import { getRequirementInfo } from "../../../api/client/getRequirementInfo";
import { recpostBuildRequirementSkill } from "../../../api/recruiter/client/BuildRequirement/buildRequirementSkill";
import { freepostBuildRequirementSkill } from "../../../api/freelance/client/BuildRequirement/buildRequirementSkill";
import { recgetRequirementInfo } from "../../../api/recruiter/client/BuildRequirement/getRequirementInfo";
import { freegetRequirementInfo } from "../../../api/freelance/client/BuildRequirement/getRequirementInfo";
import { getrequirementParsingData } from "../../../api/client/requirementParsing";
import { recgetrequirementParsingData } from "../../../api/recruiter/client/requirementParsing";
import { freegetrequirementParsingData } from "../../../api/freelance/client/requirementParsing";
import GetPrimaryYear from "../../../common-component/GetPrimaryYear";
import GetPrimaryMonth from "../../../common-component/GetPrimaryMonth";
import { useHistory } from 'react-router-dom';
// import { useStepContext } from "../../../store-Jobrequirement/step-context";



const JobRequirment2 = (props) => {
  // const { currentStep, visitedSteps, updateStep } = useStepContext();

  // return job requirement second page
  const [redirect, setRedirect] = useState(false);
  const [loader, setLoader] = useState(false);
  const [information, setInformation] = useState({
    primarySkill: "",
    primarySkill0: "",
    primarySkill1: "",
    primarySkill2: "",
    primarySkill3: "",
  });
  const [error, setError] = useState({
    primarySkill: "",
    primarySkill0: "",
    primarySkill1: "",
    primarySkill2: "",
    primarySkill3: "",
  });
  const [visible, setVisible] = useState({
    one: false,
    two: false,
    three: false,
    four: false,
  });

  const [experience, setExperience] = useState({
    secondaryMonth: "",
    secondaryYear: "",
    secondaryMonth0: "",
    secondaryYear0: "",
    secondaryMonth1: "",
    secondaryYear1: "",
  });

  const [primeExperience, setPrimeExperience] = useState({
    month: "",
    year: "",
    month0: "",
    year0: "",
    month1: "",
    year1: "",
    month2: "",
    year2: "",
    month3: "",
    year3: "",
  });

  const [secondaryInfo, setSecondaryInfo] = useState({
    secondarySkill: "",
    secondarySkill0: "",
    secondarySkill1: "",
    secondarySkill2: "",
  });

  const [secondaryError, setSecondaryError] = useState({
    secondarySkill: "",
    secondarySkill0: "",
    secondarySkill1: "",
    secondarySkill2: "",
  });

  const [secondaryVisible, setSecondaryVisible] = useState({
    one: false,
    two: false,
    three: false,
  });

  const [navigation, setNavigation] = useState(false);
  const [securityNavigation, setSecurityNavigation] = useState(false);
  const [descriptionNavigation, setDescriptionNavigation] = useState(false);

  const param = useParams();
  const id = param.id;
  const refId = param.refId;
  let skillRef = useRef();

  // const steps = [
  //   { id: 1, name: 'Step 1', color: '#3D007A' },
  //   { id: 2, name: 'Step 2', color: '#4B0082' },
  //   { id: 3, name: 'Step 3', color: 'transparent' },
  //   { id: 4, name: 'Step 4', color: 'transparent' },
  //   { id: 5, name: 'Step 5', color: 'transparent' }
  // ];
  const steps = [
    { id: 1, name: 'Step 1', color: '#3D007A', linkIndex: 6 },
    { id: 2, name: 'Step 2', color: '#4B0082', linkIndex: null },
    { id: 3, name: 'Step 3', color: 'transparent', linkIndex: navigation ? 2 : null },
    { id: 4, name: 'Step 4', color: 'transparent', linkIndex: securityNavigation ? 3 : null },
    { id: 5, name: 'Step 5', color: 'transparent', linkIndex: descriptionNavigation ? 4 : null }
  ];
  useEffect(() => {
    setInformation((state) => {
      return {
        ...state,
        primarySkill0: "",
      };
    });
    setError((state) => {
      return {
        ...state,
        primarySkill0: "",
      };
    });
  }, [visible.one]);

  useEffect(() => {
    setInformation((state) => {
      return {
        ...state,
        primarySkill1: "",
      };
    });
    setError((state) => {
      return {
        ...state,
        primarySkill1: "",
      };
    });
  }, [visible.two]);

  useEffect(() => {
    setInformation((state) => {
      return {
        ...state,
        primarySkill2: "",
      };
    });
    setError((state) => {
      return {
        ...state,
        primarySkill2: "",
      };
    });
  }, [visible.three]);

  useEffect(() => {
    setInformation((state) => {
      return {
        ...state,
        primarySkill3: "",
      };
    });
    setError((state) => {
      return {
        ...state,
        primarySkill3: "",
      };
    });
  }, [visible.four]);

  const [mandatoryType, setMandatoryType] = useState(false);
  const [mandatoryType0, setMandatoryType0] = useState(false);
  const [mandatoryType1, setMandatoryType1] = useState(false);

  try {
    document.getElementById("replacetitleimg").innerText = "Job Posting";
    document.getElementById("postjobid").style.display = "none";
  } catch { }

  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);

  //Integration for submit data
  function handleClick(event) {
    event.preventDefault();
    setRedirect(false);
    setSecondaryError((state) => {
      if (!secondaryVisible.one) state = { ...state, secondarySkill0: "" };
      if (!secondaryVisible.two) state = { ...state, secondarySkill1: "" };
      if (!secondaryVisible.three) state = { ...state, secondarySkill1: "" };
      return state;
    });

    const result = Object.keys(secondaryError)
      .map((key) => {
        return secondaryError[key] ? true : false;
      })
      .includes(true);

    let registerData = {
      primarySkill: [
        {
          ClientPrimarySkill: information.primarySkill,
          year: primeExperience.year ? primeExperience.year : 0,
          month: primeExperience.month ? primeExperience.month : 0,
        },
      ],
    };
    if (information.primarySkill0) {
      const pushData = registerData.primarySkill.push({
        ClientPrimarySkill: information.primarySkill0,
        year: primeExperience.year0 ? primeExperience.year0 : 0,
        month: primeExperience.month0 ? primeExperience.month0 : 0,
      });
      registerData = {
        primarySkill: pushData,
        ...registerData,
      };
    }
    if (information.primarySkill1) {
      const pushData = registerData.primarySkill.push({
        ClientPrimarySkill: information.primarySkill1,
        year: primeExperience.year1 ? primeExperience.year1 : 0,
        month: primeExperience.month1 ? primeExperience.month1 : 0,
      });
      registerData = {
        primarySkill: pushData,
        ...registerData,
      };
    }
    if (information.primarySkill2) {
      const pushData = registerData.primarySkill.push({
        ClientPrimarySkill: information.primarySkill2,
        year: primeExperience.year2 ? primeExperience.year2 : 0,
        month: primeExperience.month2 ? primeExperience.month2 : 0,
      });
      registerData = {
        primarySkill: pushData,
        ...registerData,
      };
    }
    if (information.primarySkill3) {
      const pushData = registerData.primarySkill.push({
        ClientPrimarySkill: information.primarySkill3,
        year: primeExperience.year3 ? primeExperience.year3 : 0,
        month: primeExperience.month3 ? primeExperience.month3 : 0,
      });
      registerData = {
        primarySkill: pushData,
        ...registerData,
      };
    }
    if (secondaryInfo.secondarySkill) {
      registerData = {
        secondarySkill: [
          {
            ClientSecondarySkill: secondaryInfo.secondarySkill,
            year: experience.secondaryYear ? experience.secondaryYear : 0,
            month: experience.secondaryMonth ? experience.secondaryMonth : 0,
            type: mandatoryType ? "MANDATORY" : "GOOD_TO_HAVE",
          },
        ],
        ...registerData,
      };
    }
    if (secondaryVisible.one) {
      if (secondaryInfo.secondarySkill0) {
        const pushData = registerData.secondarySkill.push({
          ClientSecondarySkill: secondaryInfo.secondarySkill0,
          year: experience.secondaryYear0 ? experience.secondaryYear0 : 0,
          month: experience.secondaryMonth0 ? experience.secondaryMonth0 : 0,
          type: mandatoryType0 ? "MANDATORY" : "GOOD_TO_HAVE",
        });
        registerData = {
          secondarySkill: pushData,
          ...registerData,
        };
      }
    }
    if (secondaryVisible.two) {
      if (secondaryInfo.secondarySkill1) {
        const pushData = registerData.secondarySkill.push({
          ClientSecondarySkill: secondaryInfo.secondarySkill1,
          year: experience.secondaryYear1 ? experience.secondaryYear1 : 0,
          month: experience.secondaryMonth1 ? experience.secondaryMonth1 : 0,
          type: mandatoryType1 ? "MANDATORY" : "GOOD_TO_HAVE",
        });
        registerData = {
          secondarySkill: pushData,
          ...registerData,
        };
      }
    }

    if (
      verifyBuildRequirementSkill(error, setError, information, visible) &
      !result
    ) {
      if (skillRef.current) {
        skillRef.current.setAttribute("disabled", "disabled");
      }
      setLoader(true);
      var valueArr = registerData.primarySkill.map(function (item) {
        return item.ClientPrimarySkill.toLowerCase();
      });
      var isDuplicate = valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) !== idx;
      });
      if (registerData.secondarySkill) {
        var valueSecArr = registerData.secondarySkill.map(function (item) {
          return item.ClientSecondarySkill.toLowerCase();
        });
        var isSecDuplicate = valueSecArr.some(function (item, idx) {
          return valueSecArr.indexOf(item) !== idx;
        });
        var isBothDuplicate = registerData.primarySkill.filter((o1) =>
          registerData.secondarySkill.some(
            (o2) =>
              o1.ClientPrimarySkill.toLowerCase() ===
              o2.ClientSecondarySkill.toLowerCase()
          )
        );
      }
      if (isDuplicate) {
        document.getElementsByClassName("primaryerr")[0].innerText =
          "Duplicate primary skills are not allowed";
        skillRef.current.removeAttribute("disabled");
        setLoader(false);
      } else if (isSecDuplicate) {
        document.getElementsByClassName("primaryerr")[0].innerText =
          "Duplicate secondary skills are not allowed";
        skillRef.current.removeAttribute("disabled");
        setLoader(false);
      } else if (isBothDuplicate && isBothDuplicate.length !== 0) {
        document.getElementsByClassName("primaryerr")[0].innerText =
          "Duplicate skills are not allowed";
        skillRef.current.removeAttribute("disabled");
        setLoader(false);
      } else {
        const response = isrecruiterCandidate
          ? recpostBuildRequirementSkill(token, registerData, id)
          : isFreelance
            ? freepostBuildRequirementSkill(token, registerData, id)
            : postBuildRequirementSkill(token, registerData, id);
        response.then((res) => {
          if (res.status === 200) {
            setRedirect(true);
          } else {
            skillRef.current.removeAttribute("disabled");
            setLoader(false);
          }
        });
      }
    }
  }

  function experienceFunction(event, setExperience) {
    const { name, value } = event.currentTarget;
    setExperience((state) => {
      return { ...state, [name]: value };
    });
  }

  function primeExperienceFunction(event, setPrimeExperience) {
    const { name, value } = event.currentTarget;
    setPrimeExperience((state) => {
      return { ...state, [name]: value };
    });
  }

  function CreatePrimary() {
    setVisible((state) => {
      if (state.one === false && state.two) return { ...state, one: true };
      else if (state.two === false && state.three)
        return { ...state, two: true };
      else if (state.three === false && state.four)
        return { ...state, three: true };
      else return { ...state, four: true };
    });
  }

  function CreateSecondary() {
    setSecondaryVisible((state) => {
      if (!state.one) return { ...state, one: true };
      if (!state.two) return { ...state, two: true };
      if (!state.three) return { ...state, three: true };
      return state;
    });
  }
  // useEffect(() => {
  //   updateStep(2); // Mark this step as visited
  // }, [updateStep]);
  useEffect(() => {
    let primData = {};
    let primExp = {};
    if (!visible.one) {
      primData = { ...primData, primarySkill0: "" };
      primExp = { ...primExp, month0: "", year0: "" };
    }
    if (!visible.two) {
      primData = { ...primData, primarySkill1: "" };
      primExp = { ...primExp, month1: "", year1: "" };
    }
    if (!visible.three) {
      primData = { ...primData, primarySkill2: "" };
      primExp = { ...primExp, month2: "", year2: "" };
    }
    if (!visible.four) {
      primData = { ...primData, primarySkill3: "" };
      primExp = { ...primExp, month3: "", year3: "" };
    }
    setInformation({ ...information, ...primData });
    setExperience({ ...experience, ...primExp });
  }, [visible]);

  useEffect(() => {
    async function callItSelf() {
      try {
        if (refId) {
          const response = isrecruiterCandidate
            ? await recgetrequirementParsingData(token, refId)
            : isFreelance
              ? await freegetrequirementParsingData(token, refId)
              : await getrequirementParsingData(token, refId)
          if (response.status === 200) {
            let payload = await response.json();
            payload = payload.payload.parsedData;
            try {
              setParsedSkillData(
                payload,
                information,
                setInformation,
                secondaryInfo,
                setSecondaryInfo,
                visible,
                setVisible,
                secondaryVisible,
                setSecondaryVisible,
              );
            } catch { }
          }
        } else {
          const response = isrecruiterCandidate
            ? await recgetRequirementInfo(token, id)
            : isFreelance
              ? await freegetRequirementInfo(token, id)
              : await getRequirementInfo(token, id);
          if (response.status === 200) {
            let payload = await response.json();
            payload = payload.payload.requirement;
            if (payload.ClientPrimarySkills.length !== 0) {
              setNavigation(true);
              document.getElementsByClassName("hidebtn")[0].style.display =
                "none";
            }
            try {
              setSkillData(
                payload,
                information,
                setInformation,
                secondaryInfo,
                setSecondaryInfo,
                experience,
                setExperience,
                primeExperience,
                setPrimeExperience,
                visible,
                setVisible,
                secondaryVisible,
                setSecondaryVisible,
                setMandatoryType,
                setMandatoryType0,
                setMandatoryType1
              );
            } catch { }
            if (
              payload.AcademicCertificates[0].academicsQualification.length !== 0
            ) {
              setSecurityNavigation(true);
            }
            if (payload.securityClearanceRequired.length !== 0) {
              setDescriptionNavigation(true);
            }
          }
        }
      } catch { }
    }
    callItSelf()
  }, []);

  function submitEnabled() {
    try {
      document.getElementsByClassName("hidebtn")[0].style.display = "block";
      setNavigation(false);
      setSecurityNavigation(false);
      setDescriptionNavigation(false);
    } catch { }
  }

  function callBoth(event) {
    jobRequirementSkill(event, setInformation, setError);
    submitEnabled();
  }
  function callExperience(event) {
    primeExperienceFunction(event, setPrimeExperience);
    submitEnabled();
  }
  function callSecondary(event) {
    secondarySkill(event, setSecondaryInfo, setSecondaryError);
    submitEnabled();
  }
  function callSecondaryExperience(event) {
    experienceFunction(event, setExperience);
    submitEnabled();
  }

  const history = useHistory();

  // const navigateToStep = (stepNumber) => {
  //   updateStep(stepNumber);

  //   switch (stepNumber) {
  //     case 1:
  //       history.push(`${props.link[6]}/${id}`);
  //       break;
  //     case 2:
  //       history.push(`${props.link[1]}/${id}`);
  //       break;
  //     case 3:
  //       history.push(`${props.link[2]}/${id}`);
  //       break;
  //     case 4:
  //       history.push(`${props.link[3]}/${id}`);
  //       break;
  //     case 5:
  //       history.push(`${props.link[4]}/${id}`);
  //       break;
  //     default:
  //       break;
  //   }
  // };
  // console.log(props.link)
  // const navigateToStep = (stepNumber) => {
  //   // updateStep(stepNumber); 

  //   const step = steps.find((s) => s.id === stepNumber);

  //   if (step && step.linkIndex !== null) {
  //     const link = props.link[step.linkIndex];
  //     if (link) {
  //       history.push(`${link}/${id}`);
  //     }
  //   }
  // };

  const checkNextStep = (index) => {
    // if (visitedSteps.includes(index + 1)) {
    const step = steps.find((s) => s.id === index + 1);

    if (step && step.linkIndex !== null) {
      const link = props.link[step.linkIndex];
      if (link) {
        history.push(`${link}/${id}`);
      }
    }
    // }
  };
  return (
    <div className={"col jobRequirement2-padding-bottom"} >
      <form className={"form-group"} onSubmit={handleClick}>
        {/* <div className={"row pl-5"}>
          <div className={"container-fluid container1 pl-5"}>
            <ul className={"progressbar mt-5 pr-5"}>
              <li className={"active"}>
                <img
                  src="../../../icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li>
                <img
                  src="../../../icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div> */}

        <div className={"row mx-lg-5 mx-1 pl-lg-5 mt-lg-5 resumealignment pl-3 pl-lg-0"}>
          <div className="step-container pl-lg-4">
            {steps.map((step, index) => (
              <div key={step.id}
                className={`step-arrow bordered-step-arrow ${index < 2 ? 'colored-content' : ''}`}
                style={{ backgroundColor: step.color }} onClick={() => {
                  if (step.linkIndex !== null) {
                    checkNextStep(index);
                  }
                }}>
                {step.name}
              </div>
            ))}
          </div>
        </div>
        {/* <div className={"row mx-lg-5 pl-lg-5  resumealignment"}>
          <div className={"step-container pl-4"}>
            {steps.map((step, index) => (
              <div
                key={step.id}
                className={`step-arrow bordered-step-arrow ${index < 2 ? 'colored-content' : ''}`}
                style={{ backgroundColor: step.color }}
                onClick={() => {
                  if (step.linkIndex !== null) {
                    checkNextStep(index);
                  }
                }}
              >
                <label className="font-weight-500 font-size-14px colored-primary-black">
                  {step.name}
                </label>
              </div>
            ))}
          </div>
        </div> */}

        {/* <div className={"row mx-lg-5 mx-0 pl-lg-5 pl-0   resumealignment"}>
          <div className={"col-lg-2 pb-3 pl-4"}>
            <Link to={`${props.link[6]}/${id}`}>
              <div
                className={"pt-2 pl-2 pr-2 jobRequirment1-header-design-container-style text-center border-radius-10px background-colored-primary-white"}
              >
                <label
                  className="font-weight-500 font-size-14px colored-primary-black "
                >
                  Requirement
                  <br />
                  info
                </label>
                <br />
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <div
              className={"pt-3 pb-0 pl-3 pr-3 jobRequirment1-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

            >
              <div className={"row"}>
                <div className={"col-lg pb-2"}>
                  <label
                    className="font-weight-500 font-size-14px colored-primary-black"

                  >
                    Skill
                  </label>
                </div>
              </div>
              <div className={"row pl-0"}>
                <div className={"col-lg pl-1"}>
                  <div
                    className="jobRequirment1-Requirement-info-border background-colored-sky-blue"

                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className={"col-lg-2 pl-3 pb-3"}>
            {navigation ? (
              <Link to={`${props.link[2]}/${id}`}>
                <div
                  className={"pt-2 pl-2 pr-2 jobRequirment1-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

                >
                  <label
                    className="font-weight-500 font-size-14px colored-Silver-gray"
                  >
                    Academics
                    <br />& Certificate{" "}
                  </label>
                  <br />
                </div>
              </Link>
            ) : (
              <div
                className={"pt-2 pl-2 pr-2 jobRequirment1-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

              >
                <label
                  className="font-weight-500 font-size-14px colored-Silver-gray"
                >
                  Academics
                  <br />& Certificate{" "}
                </label>
                <br />
              </div>
            )}
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            {securityNavigation ? (
              <Link to={`${props.link[3]}/${id}`}>
                <div
                  className={"pt-2 pl-2 pr-2 jobRequirment1-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

                >
                  <label
                    className="font-weight-500 font-size-14px colored-Silver-gray"
                  >
                    Security Clearance
                  </label>
                </div>
              </Link>
            ) : (
              <div
                className={"pt-2 pl-2 pr-2 jobRequirment1-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

              >
                <label
                  className="font-weight-500 font-size-14px colored-Silver-gray"
                >
                  Security Clearance
                </label>
              </div>
            )}
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            {descriptionNavigation ? (
              <Link to={`${props.link[4]}/${id}`}>
                <div
                  className={"pt-2 pl-2 pr-2 jobRequirment1-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

                >
                  <label
                    className="font-weight-500 font-size-14px colored-Silver-gray"
                  >
                    Job
                    <br />
                    Description
                  </label>
                </div>
              </Link>
            ) : (
              <div
                className={"pt-2 pl-2 pr-2 jobRequirment1-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

              >
                <label
                  className="font-weight-500 font-size-14px colored-Silver-gray"

                >
                  Job
                  <br />
                  Description
                </label>
              </div>
            )}
          </div>
        </div> */}
        <div className={"pl-1 pr-lg-5 mr-lg-5 pb-2"}>
          <div className={"row px-lg-5 pr-lg-5 mx-lg-3 pt-4 pl-3 pl-lg-0"}>
            <div className={"w-100 pt-4 pl-lg-5 pl-3 contentholder"}>
              <div className="mb-4 d-inline-block font-weight-semibold font-size-26px" >
                Skills
              </div>
              <div className={"row pr-5"}>
                <div className={"col-lg-3"}>
                  <label
                    htmlFor={"pskill"}
                    className={"dashboardforminputtext"}
                  >
                    Primary skill<span className="colored-Bright-red">*</span>
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Type Here"}
                    id={"pskill"}
                    className={
                      "form-control dashboardforminputfield shadow-none"
                    }
                    name={"primarySkill"}
                    value={information.primarySkill}
                    style={{
                      border: `${error.primarySkill ? "1px solid red" : ""}`,
                    }}
                    onChange={callBoth}
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {error.primarySkill}
                  </font>
                </div>
                <div className={"col-lg-5"}>
                  <label htmlFor={"pexp"} className={"dashboardforminputtext"}>
                    Years of experience
                  </label>
                  <div
                    className={"d-flex flex-row"}

                  >
                    <select
                      className={
                        "form-control form-select dropgray dashboardforminputfield shadow-none mr-2 mt-0 border-radius-05rem height-27rem"
                      }
                      id={"pexp"}
                      name={"year"}
                      value={primeExperience.year}
                      onChange={callExperience}

                    >
                      <option value={""}>Year</option>
                      <GetPrimaryYear />
                    </select>
                    <select
                      className={
                        "form-control form-select dropgray1 dashboardforminputfield shadow-none mt-0 border-radius-05rem height-27rem"
                      }
                      id={"pexp"}
                      name={"month"}
                      value={primeExperience.month}
                      onChange={callExperience}
                    >
                      <option value={""}>Month</option>
                      <GetPrimaryMonth />
                    </select>
                  </div>
                </div>
              </div>
              {visible.one && visible.two && visible.three && visible.four ? (
                <div className={"row pt-3"}>
                  <div className={"col-md"}></div>
                </div>
              ) : (
                <div className={"row"}>
                  <div className={"col-md"}>
                    <div
                      className={"btn offset-sm-6 badge border-0 pl-5 newskill colored-Deep-violet"}

                      onClick={CreatePrimary}
                    >
                      +Add More
                    </div>
                  </div>
                </div>
              )}
              <div className={"row"}>
                <div className={"col-md-12"}>
                  {/*duplicate here*/}
                  {visible.one && (
                    <div className={"row pr-5"}>
                      <div className={"col-lg-3"}>
                        <label
                          htmlFor={"pskill"}
                          className={"dashboardforminputtext"}
                        >
                          Primary skill<span className="colored-Bright-red">*</span>
                        </label>
                        <input
                          type={"text"}
                          placeholder={"Type Here"}
                          id={"pskill"}
                          value={information.primarySkill0}
                          style={{
                            border: `${error.primarySkill0 ? "1px solid red" : ""
                              }`,
                          }}
                          className={
                            "form-control dashboardforminputfield shadow-none"
                          }
                          name={"primarySkill0"}
                          onChange={callBoth}
                        />
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.primarySkill0}
                        </font>
                      </div>
                      <div className={"col-lg-5"}>
                        <label
                          htmlFor={"pexp"}
                          className={"dashboardforminputtext"}
                        >
                          Years of experience
                        </label>
                        <div
                          className={"d-flex flex-row"}

                        >
                          <select
                            className={
                              "form-control form-select dropgray dashboardforminputfield  shadow-none mr-3 mt-0 border-radius-05rem height-27rem"
                            }
                            id={"pexp"}
                            name="year0"
                            value={primeExperience.year0}
                            onChange={callExperience}

                          >
                            <option value={""}>Year</option>
                            <GetPrimaryYear />
                          </select>
                          <select
                            className={
                              "form-control form-select dropgray1 dashboardforminputfield shadow-none mt-0 border-radius-05rem height-27rem"
                            }
                            id={"pexp"}
                            name="month0"
                            value={primeExperience.month0}
                            onChange={callExperience}

                          >
                            <option value={""}>Month</option>
                            <GetPrimaryMonth />
                          </select>
                        </div>
                      </div>
                      <div
                        className={"btn offset-sm-7 badge border-0 cancelskill padding-left-13rem"}
                        onClick={() => {
                          submitEnabled();
                          setVisible((state) => {
                            return { ...state, one: false };
                          });
                        }}

                      >
                        cancel
                      </div>
                    </div>
                  )}
                  {/*duplicate here*/}
                  {/*duplicate here*/}
                  {visible.two && (
                    <div className={"row pr-5"}>
                      <div className={"col-lg-3"}>
                        <label
                          htmlFor={"pskill"}
                          className={"dashboardforminputtext"}
                        >
                          Primary skill<span className="colored-Bright-red">*</span>
                        </label>
                        <input
                          type={"text"}
                          placeholder={"Type Here"}
                          id={"pskill"}
                          value={information.primarySkill1}
                          style={{
                            border: `${error.primarySkill1 ? "1px solid red" : ""
                              }`,
                          }}
                          className={
                            "form-control dashboardforminputfield shadow-none"
                          }
                          name={"primarySkill1"}
                          onChange={callBoth}
                        />
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.primarySkill1}
                        </font>
                      </div>
                      {/*duplicate here*/}
                      <div className={"col-lg-5"}>
                        <label
                          htmlFor={"pexp"}
                          className={"dashboardforminputtext"}
                        >
                          Years of experience
                        </label>
                        <div
                          className={"d-flex flex-row"}

                        >
                          <select
                            className={
                              "form-control form-select dashboardforminputfield dropgray shadow-none mr-3 mt-0 border-radius-05rem height-27rem"
                            }
                            id={"pexp"}
                            name="year1"
                            value={primeExperience.year1}
                            onChange={callExperience}

                          >
                            <option value={""}>Year</option>
                            <GetPrimaryYear />
                          </select>
                          <select
                            className={
                              "form-control form-select dashboardforminputfield dropgray1 shadow-none mt-0 border-radius-05rem height-27rem"
                            }
                            id={"pexp"}
                            name="month1"
                            value={primeExperience.month1}
                            onChange={callExperience}

                          >
                            <option value={""}>Month</option>
                            <GetPrimaryMonth />
                          </select>
                        </div>
                      </div>
                      <div
                        className={"btn offset-sm-7 badge border-0 cancelskill padding-left-13rem"}
                        onClick={() => {
                          submitEnabled();
                          setVisible((state) => {
                            return { ...state, two: false };
                          });
                        }}

                      >

                        cancel
                      </div>
                    </div>
                  )}
                  {/*duplicate here*/}
                  {/*duplicate here*/}
                  {visible.three && (
                    <div className={"row pr-5"}>
                      <div className={"col-lg-3"}>
                        <label
                          htmlFor={"pskill"}
                          className={"dashboardforminputtext"}
                        >
                          Primary skill<span className="colored-Bright-red">*</span>
                        </label>
                        <input
                          type={"text"}
                          placeholder={"Type Here"}
                          id={"pskill"}
                          value={information.primarySkill2}
                          style={{
                            border: `${error.primarySkill2 ? "1px solid red" : ""
                              }`,
                          }}
                          className={
                            "form-control dashboardforminputfield shadow-none"
                          }
                          name={"primarySkill2"}
                          onChange={callBoth}
                        />
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.primarySkill2}
                        </font>
                      </div>
                      <div className={"col-lg-5"}>
                        <label
                          htmlFor={"pexp"}
                          className={"dashboardforminputtext"}
                        >
                          Years of experience
                        </label>
                        <div
                          className={"d-flex flex-row"}

                        >
                          <select
                            className={
                              "form-control form-select dashboardforminputfield dropgray shadow-none mr-3 mt-0 border-radius-05rem height-27rem"
                            }
                            id={"pexp"}
                            name="year2"
                            value={primeExperience.year2}
                            onChange={callExperience}

                          >
                            <option value={""}>Year</option>
                            <GetPrimaryYear />
                          </select>
                          <select
                            className={
                              "form-control form-select dashboardforminputfield dropgray1 shadow-none mt-0 border-radius-05rem height-27rem"
                            }
                            id={"pexp"}
                            name="month2"
                            value={primeExperience.month2}
                            onChange={callExperience}

                          >
                            <option value={""}>Month</option>
                            <GetPrimaryMonth />
                          </select>
                        </div>
                      </div>
                      <div
                        className={"btn offset-sm-7 badge border-0 cancelskill padding-left-13rem"}
                        onClick={() => {
                          submitEnabled();
                          setVisible((state) => {
                            return { ...state, three: false };
                          });
                        }}

                      >
                        cancel
                      </div>
                    </div>
                  )}
                  {/*duplicate here*/}
                  {/*duplicate here*/}
                  {visible.four && (
                    <div className={"row pr-5"}>
                      <div className={"col-lg-3"}>
                        <label
                          htmlFor={"pskill"}
                          className={"dashboardforminputtext"}
                        >
                          Primary skill<span className="colored-Bright-red">*</span>
                        </label>
                        <input
                          type={"text"}
                          placeholder={"Type Here"}
                          id={"pskill"}
                          value={information.primarySkill3}
                          style={{
                            border: `${error.primarySkill3 ? "1px solid red" : ""
                              }`,
                          }}
                          className={
                            "form-control dashboardforminputfield  shadow-none"
                          }
                          name={"primarySkill3"}
                          onChange={callBoth}
                        />
                        <font className={"dashboardforminputtext text-danger"}>
                          {error.primarySkill3}
                        </font>
                      </div>
                      <div className={"col-lg-5"}>
                        <label
                          htmlFor={"pexp"}
                          className={"dashboardforminputtext"}
                        >
                          Years of experience
                        </label>
                        <div
                          className={""}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <select
                            className={
                              "form-control form-select dashboardforminputfield dropgray shadow-none mr-3 mt-0 border-radius-05rem height-27rem"
                            }
                            id={"pexp"}
                            name="year3"
                            value={primeExperience.year3}
                            onChange={callExperience}

                          >
                            <option value={""}>Year</option>
                            <GetPrimaryYear />
                          </select>
                          <select
                            className={
                              "form-control form-select dashboardforminputfield dropgray1 shadow-none mt-0 border-radius-05rem height-27rem"
                            }
                            id={"pexp"}
                            name="month3"
                            value={primeExperience.month3}
                            onChange={callExperience}

                          >
                            <option value={""}>Month</option>
                            <GetPrimaryMonth />
                          </select>
                        </div>
                      </div>
                      <div
                        className={"btn offset-sm-7 badge border-0 cancelskill padding-left-13rem"}
                        onClick={() => {
                          submitEnabled();
                          setVisible((state) => {
                            return { ...state, four: false };
                          });
                        }}

                      >
                        cancel
                      </div>
                    </div>
                  )}
                  {/*duplicate here*/}
                </div>
              </div>
              <div className={"row pr-5"}>
                <div className={"col-lg-3"}>
                  <label
                    htmlFor={"sskill"}
                    className={"dashboardforminputtext"}
                  >
                    Secondary Skills
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Type Here"}
                    id={"sskill"}
                    className={
                      "form-control dashboardforminputfield shadow-none"
                    }
                    name={"secondarySkill"}
                    value={secondaryInfo.secondarySkill}
                    style={{
                      border: `${secondaryError.secondarySkill ? "1px solid red" : ""
                        }`,
                    }}
                    onChange={callSecondary}
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {secondaryError.secondarySkill}
                  </font>
                </div>
                <div className={"col-lg-5"}>
                  <label htmlFor={"YOF"} className={"dashboardforminputtext"}>
                    Years Of Experience
                  </label>
                  <div
                    className={"d-flex flex-row"}

                  >
                    <select
                      className={
                        "form-control form-select dropgray shadow-none dashboardforminputfield mr-3 mt-0 border-radius-05rem height-27rem"
                      }
                      id={"YOF"}
                      name={"secondaryYear"}
                      value={experience.secondaryYear}
                      onChange={callSecondaryExperience}
                    >
                      <option value={""}>Year</option>
                      <GetPrimaryYear />
                    </select>
                    <select
                      className={
                        "form-control form-select dropgray1 dashboardforminputfield shadow-none mt-0 border-radius-05rem height-27rem"
                      }
                      id={"YOF"}
                      name={"secondaryMonth"}
                      value={experience.secondaryMonth}
                      onChange={callSecondaryExperience}

                    >
                      <option value={""}>Month</option>
                      <GetPrimaryMonth />
                    </select>
                  </div>
                </div>
                <div className={"col-lg-4 form-inline pt-4 pl-4"}>
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio"
                    name="fieldstatus"
                    value="mandatory"
                    onClick={() => setMandatoryType(true)}
                    onChange={() => {
                      submitEnabled();
                    }}
                    checked={mandatoryType}
                  />
                  <label
                    className="dashboardforminputtext pl-2 pr-3"
                    htmlFor="customRadio"
                  >
                    Mandatory
                  </label>
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio0"
                    name="fieldstatus"
                    value="good"
                    onClick={() => setMandatoryType(false)}
                    onChange={() => {
                      submitEnabled();
                    }}
                    checked={!mandatoryType}
                  />
                  <label
                    className="dashboardforminputtext pl-2"
                    htmlFor="customRadio0"
                  >
                    Good to have
                  </label>
                </div>
              </div>
              {secondaryVisible.two && secondaryVisible.one ? (
                <div className={"row pt-3"}>
                  <div className={"col-md"}></div>
                </div>
              ) : (
                <div className={"row"}>
                  <div className={"col-md"}>
                    <div
                      className={
                        "btn offset-sm-6 pl-5 badge border-0  newskill"
                      }
                      onClick={CreateSecondary}
                    >
                      +Add More
                    </div>
                  </div>
                </div>
              )}
              <div className={"row"}>
                <div className={"col-md-12"}>
                  {secondaryVisible.one && (
                    <div className={"row pr-5"}>
                      <div className={"col-lg-3"}>
                        <label
                          htmlFor={"pskill"}
                          className={"dashboardforminputtext"}
                        >
                          Secondary skills
                        </label>
                        <input
                          type={"text"}
                          id={"pskill"}
                          className={
                            "form-control dashboardforminputfield  shadow-none"
                          }
                          name={"secondarySkill0"}
                          placeholder={"Type Here"}
                          value={secondaryInfo.secondarySkill0}
                          style={{
                            border: `${secondaryError.secondarySkill0
                              ? "1px solid red"
                              : ""
                              }`,
                          }}
                          onChange={callSecondary}
                        />
                        <font className={"dashboardforminputtext text-danger"}>
                          {secondaryError.secondarySkill0}
                        </font>
                      </div>
                      <div className={"col-lg-5"}>
                        <label
                          htmlFor={"pexp"}
                          className={"dashboardforminputtext"}
                        >
                          Years of experience
                        </label>
                        <div
                          className={"d-flex flex-row"}

                        >
                          <select
                            className={
                              "form-control form-select dropgray dashboardforminputfield shadow-none mr-3 mt-0 border-radius-05rem height-27rem"
                            }
                            id={"pexp"}
                            name={"secondaryYear0"}
                            value={experience.secondaryYear0}
                            onChange={callSecondaryExperience}

                          >
                            <option value={""}>Year</option>
                            <GetPrimaryYear />
                          </select>
                          <select
                            className={
                              "form-control form-select dropgray1 dashboardforminputfield shadow-none mt-0 border-radius-05rem height-27rem"
                            }
                            id={"pexp"}
                            name={"secondaryMonth0"}
                            value={experience.secondaryMonth0}
                            onChange={callSecondaryExperience}

                          >
                            <option value={""}>Month</option>
                            <GetPrimaryMonth />
                          </select>
                        </div>
                      </div>
                      <div className={"col-lg-4 form-inline pt-4 pl-4"}>
                        <input
                          type="radio"
                          className="resumeradio"
                          id="customRadio"
                          name="fieldstatus0"
                          value="mandatory"
                          onClick={() => setMandatoryType0(true)}
                          onChange={() => {
                            submitEnabled();
                          }}
                          checked={mandatoryType0}
                        />
                        <label
                          className="dashboardforminputtext pl-2 pr-3"
                          htmlFor="customRadio"
                        >
                          Mandatory
                        </label>
                        <input
                          type="radio"
                          className="resumeradio"
                          id="customRadio0"
                          name="fieldstatus0"
                          value="good"
                          onClick={() => setMandatoryType0(false)}
                          onChange={() => {
                            submitEnabled();
                          }}
                          checked={!mandatoryType0}
                        />
                        <label
                          className="dashboardforminputtext pl-2"
                          htmlFor="customRadio0"
                        >
                          Good to have
                        </label>
                      </div>
                      <div
                        className={
                          "btn offset-sm-7  badge border-0 cancelskill padding-left-13rem"
                        }
                        onClick={() =>
                          setSecondaryVisible((state) => {
                            return { ...state, one: false };
                          })
                        }

                      >
                        cancel
                      </div>
                    </div>
                  )}
                  {secondaryVisible.two && (
                    <div className={"row pr-5"}>
                      <div className={"col-lg-3"}>
                        <label
                          htmlFor={"pskill"}
                          className={"dashboardforminputtext"}
                        >
                          Secondary skills
                        </label>
                        <input
                          type={"text"}
                          id={"pskill"}
                          className={
                            "form-control dashboardforminputfield shadow-none"
                          }
                          name={"secondarySkill1"}
                          placeholder={"Type Here"}
                          value={secondaryInfo.secondarySkill1}
                          style={{
                            border: `${secondaryError.secondarySkill1
                              ? "1px solid red"
                              : ""
                              }`,
                          }}
                          onChange={callSecondary}
                        />
                        <font className={"dashboardforminputtext text-danger"}>
                          {secondaryError.secondarySkill1}
                        </font>
                      </div>
                      <div className={"col-lg-5"}>
                        <label
                          htmlFor={"pexp"}
                          className={"dashboardforminputtext"}
                        >
                          Years of experience
                        </label>
                        <div
                          className={"d-flex flex-row"}
                        >
                          <select
                            className={
                              "form-control form-select dropgray dashboardforminputfield shadow-none mr-3 mt-0 border-radius-05rem height-27rem"
                            }
                            id={"pexp"}
                            name={"secondaryYear1"}
                            value={experience.secondaryYear1}
                            onChange={callSecondaryExperience}

                          >
                            <option value={""}>Year</option>
                            <GetPrimaryYear />
                          </select>
                          <select
                            className={
                              "form-control form-select dropgray1  dashboardforminputfield shadow-none mt-0 border-radius-05rem height-27rem"
                            }
                            id={"pexp"}
                            name={"secondaryMonth1"}
                            value={experience.secondaryMonth1}
                            onChange={callSecondaryExperience}

                          >
                            <option value={""}>Month</option>
                            <GetPrimaryMonth />
                          </select>
                        </div>
                      </div>
                      <div className={"col-lg-4 form-inline pt-4 pl-4"}>
                        <input
                          type="radio"
                          className="resumeradio"
                          id="customRadio"
                          name="fieldstatus1"
                          value="mandatory"
                          onClick={() => setMandatoryType1(true)}
                          onChange={() => {
                            submitEnabled();
                          }}
                          checked={mandatoryType1}
                        />
                        <label
                          className="dashboardforminputtext pl-2 pr-3"
                          htmlFor="customRadio"
                        >
                          Mandatory
                        </label>
                        <input
                          type="radio"
                          className="resumeradio"
                          id="customRadio0"
                          name="fieldstatus1"
                          value="good"
                          onClick={() => setMandatoryType1(false)}
                          onChange={() => {
                            submitEnabled();
                          }}
                          checked={!mandatoryType1}
                        />
                        <label
                          className="dashboardforminputtext pl-2"
                          htmlFor="customRadio0"
                        >
                          Good to have
                        </label>
                      </div>
                      <div
                        className={
                          "btn offset-sm-7  badge border-0 cancelskill padding-left-13rem"
                        }
                        onClick={() =>
                          setSecondaryVisible((state) => {
                            return { ...state, two: false };
                          })
                        }

                      >
                        cancel
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row text-center" >
          <div className="col-md offset-sm-6">
            <label
              className={"dashboardforminputtext text-danger primaryerr"}
            ></label>
          </div>
        </div>
        <div className={"row hidebox pl-3 pl-lg-0"}>
          <div className={"col pt-2 pb-2 pr-lg-5"}>
            {navigation ? (
              <>
                {redirect ? (
                  <Redirect to={`${props.link[2]}/${id}`} />
                ) : (
                  <button
                    type="submit"
                    className={"btn offset-sm-9 offset-4 save hidebtn"}
                    ref={skillRef}
                  >
                    {loader ? (
                      <i
                        className="fa fa-refresh fa-spin jobRequirment1-loader-padding"

                      ></i>
                    ) : (
                      ""
                    )}
                    Save & Continue
                  </button>
                )}
              </>
            ) : (
              <>
                {redirect ? (
                  refId ? (
                    <Redirect to={`${props.link[2]}/${id}/${refId}`} />
                  ) : (
                    <Redirect to={`${props.link[2]}/${id}`} />
                  )
                ) : (
                  <button
                    type="submit"
                    className={"btn offset-sm-9 offset-4 save"}
                    ref={skillRef}
                  >
                    {loader ? (
                      <i
                        className="fa fa-refresh fa-spin jobRequirment1-loader-padding"
                      ></i>
                    ) : (
                      ""
                    )}
                    Save & Continue
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </form>
      <GoToTop />
    </div>
  );
};

export default React.memo(JobRequirment2);
