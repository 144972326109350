import { RECRUITERBUILDEXP } from "../../../ENVIRONMENT";

// api for submit recruiter candidate buildresume experience
async function postExperience(token, data, id) {
  const response = await fetch(RECRUITERBUILDEXP + `/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
  return response;
}
// api for recruiter candidate buildresume experience details
async function getExperience(token, id) {
  const response = await fetch(RECRUITERBUILDEXP + `/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { postExperience, getExperience };
