import { RECRUITERJOBUPLOAD,RECRUITERJOBUPLOADEDDATA } from "../../ENVIRONMENT.js";

function recrequirementParsing(token, data) {
  // api for candidate resume parsing
  const response = fetch(RECRUITERJOBUPLOAD, {
    method: "POST",
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
    body: JSON.stringify(data),
  });
  return response;
}

async function recgetrequirementParsingData(token,id) {
  // api for get data from client requirement parsing
  const response = await fetch(RECRUITERJOBUPLOADEDDATA + `/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { recrequirementParsing,recgetrequirementParsingData };
