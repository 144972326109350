import { AUTHENTICATION_URL } from "../ENVIRONMENT";

async function loginUser(userdetails) {
  // api for user login 
  const response = fetch(AUTHENTICATION_URL + "login", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userdetails),
  });
  return response;
}
export { loginUser };
