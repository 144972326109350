import { CLIENTMATCHINGJOBS} from "../ENVIRONMENT";

async function applicationSorting(token,currentPage,name,order) {
    // api for client application sorting
    const response = await fetch(CLIENTMATCHINGJOBS+`?limit=6&page=${currentPage}&${name}=${order}`, {
        headers: {
            'Content-Type': 'Application/json',
            'Authorization': `Bearer ${token ? token : sessionStorage.getItem('token')}`
        }
    })
    return response;
    
}

async function filterApplicationSorting(token,data,currentPage,name,order) {
    // api for client applicationfilter sorting
    const response = await fetch(CLIENTMATCHINGJOBS+`?id=${data}&limit=6&page=${currentPage}&${name}=${order}`, {
        headers: {
            'Content-Type': 'Application/json',
            'Authorization': `Bearer ${token ? token : sessionStorage.getItem('token')}`
        }
    })
    return response;
    
}

async function otherApplicationSorting(token,data,card,currentPage,name,order) {
    // api for getClient details
    const response = await fetch(CLIENTMATCHINGJOBS+`?id=${data}&card=${card}&limit=6&page=${currentPage}&${name}=${order}`, {
        headers: {
            'Content-Type': 'Application/json',
            'Authorization': `Bearer ${token ? token : sessionStorage.getItem('token')}`
        }
    })
    return response;
}

async function filterStatusSorting(token,card,currentPage,name,order){
    const response = await fetch(CLIENTMATCHINGJOBS+`?card=${card}&limit=6&page=${currentPage}&${name}=${order}`, {
        headers: {
            'Content-Type': 'Application/json',
            'Authorization': `Bearer ${token ? token : sessionStorage.getItem('token')}`
        }
    })
    return response;
}

export { applicationSorting , filterApplicationSorting , otherApplicationSorting , filterStatusSorting };