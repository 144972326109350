import React, { useEffect, useState } from "react";
import MessageBox from "./MessageBox";
import MessageBody from "./MessageBody";
import GoToTop from "../components/common/GoToTop";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sendMessageList, singleMessageList } from "../api/common/messageList";
import { useMediaQuery } from 'react-responsive';


function Message(props) {
  const isMobileOrTab = useMediaQuery({ query: '(max-width: 1150px)' });
  const dispatch = useDispatch();
  dispatch({ type: "newuserfalse" });
  const role = props.homelocation.split("/")[1];
  // return a message page
  try {
    document.getElementById("replacetitleimg").innerText = "Message";
  } catch { }
  const param = useParams();
  // const uuid = 11;
  const uuid = param.id;

  const token = useSelector((state) => state.token);
  const [information, setInformation] = useState();
  const [sending, setSending] = useState(false);
  const [item, setItem] = useState([]);

  //get list of message
  useEffect(() => {
    async function callUserDetails() {
      // try {
      if (uuid) {
        const response = await singleMessageList(token, uuid);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload.rows;
          const totalRecord = data.payload.count;
          if (totalRecord !== 0) {
            let dataSort = payload.sort(function compare(a, b) {
              var dateA = new Date(a.createdAt);
              var dateB = new Date(b.createdAt);
              return dateA - dateB;
            });
            setItem(dataSort);
          }
        }
      }
      // } catch {}
    }
    callUserDetails();
  }, [param.id]);
  //send message
  const handleSend = async () => {
    setSending(true);
    let data = {
      message: information,
    };
    const response = await sendMessageList(token, uuid, data);
    if (response.status === 200) {
      const response = await singleMessageList(token, uuid);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload.rows && data.payload.rows.reverse();
        const totalRecord = data.payload.count;
        setSending(false);
        if (totalRecord !== 0) {
          let dataSort = payload.sort(function compare(a, b) {
            var dateA = new Date(a.createdAt);
            var dateB = new Date(b.createdAt);
            return dateA - dateB;
          });
          setItem(dataSort);
        }
      }
      setInformation("");
    }
  };
  return (
    <div className={"row my-4 mx-lg-4 mx-1 w-100 message-container-height"} >
      <div className={"col-lg-4 col-12"}>
        {/* <div className={"row"}>
          <div className={"col pt-2  pb-3 rowborder"}>
          </div>
        </div> */}
        <div className={"row pl-3 pl-lg-0"}>
          <div
            className={"col message-container-message-box-css"}

          >
            {/* messageboxborder */}
            <MessageBox role={role} />
          </div>
        </div>
      </div>
      {uuid && (

        <div className={"col-lg-8 col-12 bg-white border-radius-10px light-box-shadow mt-1"}>
          <>
            <div className="">
              <div className="col-10 mt-3">
                <div className={"row"}>
                  <div className={"col-lg-2 col-5"}>
                    <figure className="story_shape mb-0">
                      <img
                        src="/icon/common/darkprofile.png"
                        alt="person on a tour"
                        className="story_img message-figureCaption-css"
                      // style={{ transform: "scale(0.9)" }}
                      />
                      <figcaption className="story_caption">
                        Aa
                        {/* {data.from !== null && data.from.firstName.charAt(0)}
                            {data.from !== null && data.from.lastName.charAt(0)} */}
                      </figcaption>
                    </figure>
                  </div>
                  <div className={"col"}>
                    <div className={"row ml-0 h-100 align-items-center"}>
                      <div className={"col-lg-8 col-7"}>
                        <label className={"heading mb-0"}>
                          Akankhya Subudhi
                          {/* {data.from !== null && data.from.firstName}&nbsp;&nbsp;
                          {data.from !== null && data.from.lastName} */}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="message-hr-Top-border-width" />
            </div>
            {/* // )} */}
            <div
              id={"myDiv"}
              className="message-message-body-container-css"
            // style={{ overflowY: "auto", height: "500px", overflowX: "hidden" }}
            >
              <MessageBody item={item} />
            </div>
          </>

          {uuid && (
            <div
              className={`row col-12 mt-3 pr-0  ${!isMobileOrTab ? 'message-message-inputbox-and-container' : ''} `}
            // style={{ position: "sticky", bottom: "0px" }}
            >
              <div className={"col-10 col-lg-11"}>
                <textarea
                  className={"form-control resumeforminputfield shadow-none font-size-14px mb-3 message-message-inputbox-css"}
                  // ref={textareaRef}
                  placeholder="Write your message"
                  id="comment"
                  value={information}
                  onChange={(event) => setInformation(event.target.value)}
                // style={{
                //   paddingTop: "10px",
                //   resize: "none",
                //   overflow: "hidden",
                //   backgroundColor: 'rgba(177,175,255,0.49)'
                // }}
                />
              </div>
              <div className={"col-2 col-lg-1 "}>
                {sending ? (
                  <button
                    type={"button"}
                    className={"btn pt-3 pb-3 px-3"}
                    onClick={handleSend}
                    style={{ background: '#E8EAEF', borderRadius: '50%' }}
                    disabled
                  >
                    <img src="/icon/common/Vector (3).png" alt="" />
                  </button>
                ) : (
                  <button
                    type={"button"}
                    className={"btn  pt-3 pb-3 px-3"}
                    onClick={handleSend}
                    style={{ background: '#E8EAEF', borderRadius: '50%' }}
                  >
                    <img src="/icon/common/Vector (3).png" alt="" />
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      <GoToTop />
    </div>
  );
}

export default React.memo(Message);
