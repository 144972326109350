import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { viewMore } from "../function/common/viewmore";
import { clientRequirementDetils } from "../api/client/clientrequirementDetails";
import { recDashboardRequirment } from "../api/recruiter/client/dashboardRequirement";
import { freeDashboardRequirment } from "../api/freelance/client/dashboardRequirement";

const MatchingjobCard = () => {
  // return group of matching job table data which generated by data variable
  const [match, setMatch] = useState([]);
  const [record, setRecord] = useState();
  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);
  useEffect(() => {
    async function callUserDetails() {
      try {
        const response = isrecruiterCandidate
          ? await recDashboardRequirment(token, 1)
          : isFreelance
            ? await freeDashboardRequirment(token, 1)
            : await clientRequirementDetils(token);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload.slice(0, 3);
          const totalRecord = payload[payload.length - 1].totalRecord;
          if (totalRecord !== 0) {
            setMatch(payload);
          }
          setRecord(totalRecord);
        }
      } catch { }
    }
    callUserDetails();
  }, []);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const dispatch = useDispatch();
  dispatch({ type: "newuserfalse" });


  return (
    <>
      {record !== 0 ? (
        <div className={"row col-md-12"}>
          <div className={"col-md-12 pt-2 pl-3"}>
            <label
              className="font-weight-500 font-size-30px colored-lavender-gray"

            >
              Active Jobs
            </label>
          </div>
        </div>
      ) : (
        <div className={"row offset-sm-5 pt-5"}>
          <div className={"col-md-12 pt-2 pl-3"}>
            <label
              className="font-weight-500 font-size-30px colored-lavender-gray"
            >
              No Active Jobs
            </label>
          </div>
        </div>
      )}
      {
        match.map((match) => (
          <div className={"col-lg-4 pt-2"} key={match.uuid}>
            {match.uuid ? (
              <Link
                to={`/client/jobapply/${match.uuid}`}
                className={"matchingjobdata font-weight-600 font-size-20 "}

              >
                <div
                  className={"p-3 lastrowcontent background-colored-primary-white client-mathingjobcard-card-shadow"}

                >
                  <div className={"row"}>
                    <div className={"col-md-12"}>
                      <Link
                        to={`/client/jobapply/${match.uuid}`}
                        className={"matchingjobdata font-weight-600 font-size-20 "} style={{ fontSize: '1.2rem', color: 'black' }}

                      >
                        {match.jobTitle && match.jobTitle.toUpperCase()}
                      </Link>
                      <br />
                      <label className="mb-3  colored-Granite-gray font-weight-500 font-size-16px" >
                        {match.clientName}
                      </label>
                      <br />

                    </div>
                  </div>
                  <div className={"col-md-12 px-0 mb-4 font-size-14px colored-lavender-gray client-matchingjob-description-css"} style={{ color: 'black' }}>

                    {/* <div dangerouslySetInnerHTML={{ __html: match.description }} /> */}
                    {/* {match.description} */}
                    Number of openings available     {match.numberOfPosition}
                  </div>
                  <hr className="mt-0" />

                  <div className={"row"}>
                    <div className="col-md-6">
                      <label
                        className={"mr-4 font-weight-500 font-size-14px colored-Graphite-gray"}

                      >
                        ${match.rate}
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label className="float-right colored-Silver-mist font-size-12px font-weight-500" >
                        {new Date(match.createdAt).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </Link>
            ) : (
              ""
            )}
          </div>
        ))
      }
      {/* {match.map((match) => (
        <div className={"col-lg-4 pt-2"} key={match.uuid}>
          {match.uuid ? (
            <div
              className={"pb-1 lastrowcontent"}
              style={{
                background: "#FFFFFF",
                // boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px",
                fontFamily: "Roboto",
              }}
            >
              <div className="col-md-12">
                <div className={"row pt-2"} style={{ backgroundColor: ' rgb(126, 115, 181)', borderRadius: '10px 10px 0 0' }}>
                  <div className={"col-md-9 mx-2"}>
                    <Link
                      to={`/client/jobapply/${match.uuid}`}
                      className={"matchingjobdata"}
                      style={{ fontSize: 20, fontWeight: '600', color: '#FFFFFF' }}
                    >
                      {match.jobTitle && match.jobTitle.toUpperCase()}
                    </Link>
                    <br />
                    <label style={{ fontWeight: "500", fontSize: "16px", color: '#FFFFFF' }}>
                      {new Date(match.createdAt).toLocaleDateString(
                        "en-US",
                        options
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <hr style={{ marginTop: "0" }} />
              <div className={"row mx-3 my-2"}>
                <div className={"col-md-2"}>
                  <img
                    src={"/icon/common/money 1.svg"}
                    alt={"currency"}
                    className={"img-fluid"}
                  />
                </div>
                <div className={"col-md my-1"}>
                  <label
                    className={"mr-4"}
                    style={{
                      fontWeight: "600",
                      fontSize: "18px",
                      color: "#2E2E2E",
                    }}
                  >
                    ${match.rate}
                  </label>
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "18px",
                      color: "#989898",
                    }}
                    className={"ml-5"}
                  >
                    /{match.rateType && match.rateType.toLowerCase()}
                  </label>
                </div>
              </div>
              <div className={"row mx-3 my-2"}>
                <div className={"col-md-2"}>
                  <img
                    src={"/icon/common/map_2.svg"}
                    alt={"map"}
                    className={"img-fluid"}
                  />
                </div>
                <div className={"col-md my-1"}>
                  <label
                    className={"mr-5"}
                    style={{
                      fontWeight: "600",
                      fontSize: "18px",
                      color: "#2E2E2E",
                    }}
                  >
                    {match.location &&
                      match.location.charAt(0).toUpperCase() +
                      match.location.slice(1).toLowerCase()}
                  </label>
                </div>
              </div>
              <div className={"row mx-3 my-2"}>
                <div className={"col-md-2"}>
                  <img
                    src={"/icon/common/jobSuitcase.svg"}
                    alt={"Time"}
                    className={"img-fluid"}
                  />
                </div>
                <div className={"col-md my-1"}>
                  <label
                    className={"mr-5"}
                    style={{
                      fontWeight: "600",
                      fontSize: "18px",
                      color: "#2E2E2E",
                    }}
                  >
                    {match.employementType}
                  </label>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ))} */}

      {record !== 0 ? (
        <div className={"row pt-4 pb-0 col-md-12"}>
          <div className={"col-md"}>
            <Link
              to={"/client/requirements"}
              className={"btn float-right dashboardviewmorelink"}
              onClick={() => viewMore(dispatch)}
            >
              View more
            </Link>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default MatchingjobCard;

// {
//   match.map((match) => (
//     <div className={"col-lg-4 pt-2"} key={match.uuid}>
//       {match.uuid ? (
//         <div
//           className={"p-3 lastrowcontent"}
//           style={{
//             background: "#FFFFFF",
//             boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
//             borderRadius: "8px",
//             fontFamily: "poppins",
//           }}
//         >
//           <div className={"row"}>
//             <div className={"col-md-12"}>
//               <Link
//                 to={`/client/jobapply/${match.uuid}`}
//                 className={"matchingjobdata"}
//
//               >
//                 {match.jobTitle && match.jobTitle.toUpperCase()}
//               </Link>
//               <br />
//               <label className="mb-3" style={{ fontSize: "16px", color: '#818181', fontWeight: "500" }}>
//                 {match.clientName}
//               </label>
//               <br />

//             </div>
//           </div>
//           <div className={"col-md-12 px-0 mb-4"} style={{
//             fontSize: '14px',
//             display: '-webkit-box',
//             WebkitLineClamp: 2,
//             WebkitBoxOrient: 'vertical',
//             overflow: 'hidden',
//             textOverflow: 'ellipsis',
//             height: 'auto',
//             lineHeight: '1.2em',
//             maxHeight: '2.4em',
//           }}>
//             {match.description}
//           </div>
//           <hr style={{ marginTop: "0" }} />

//           <div className={"row"}>
//             <div className="col-md-6">
//               <label
//                 className={"mr-4"}
//                 style={{
//                   fontWeight: "500",
//                   fontSize: "14px",
//                   color: "#2E2E2E",
//                 }}
//               >
//                 ${match.rate}
//               </label>
//             </div>
//             <div className="col-md-6">
//               <label className="float-right" style={{ fontWeight: "500", fontSize: "12px", color: '#BABABA' }}>
//                 {new Date(match.createdAt).toLocaleDateString(
//                   "en-US",
//                   options
//                 )}
//               </label>
//             </div>
//           </div>
//         </div>
//       ) : (
//         ""
//       )}
//     </div>
//   ))
// }
