import { FREELANCECANDIDATEDETAILS } from "../../ENVIRONMENT";

// api for freelance candidatelist data
async function freecandidateListData(token, currentPage) {
  const response = await fetch(
    FREELANCECANDIDATEDETAILS + `?limit=6&page=${currentPage}`,
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    }
  );
  return response;
}

export { freecandidateListData };
