import {GETFREELANCE_RECRUITERUSERDETAILS} from '../ENVIRONMENT'

//api for get freelance userdetails
async function freegetDetails(token){
    const response = await fetch(GETFREELANCE_RECRUITERUSERDETAILS,{
        headers:{
            'Content-Type' : 'Application/json',
            'Authorization':`Bearer ${token?token:sessionStorage.getItem('token')}`
        }
    })
    return response;

}
export {freegetDetails}