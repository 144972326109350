import { RECRUITERCLIENTDOMAIN } from "../../ENVIRONMENT";

// api for recruiter domain list
function recdomainList(token) {
  const response = fetch(RECRUITERCLIENTDOMAIN, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
  return response;
}

export { recdomainList };
