import React from "react";
import { Link, useHistory } from 'react-router-dom';
import GoToTop from "../common/GoToTop";

const Setting = () => {
    // return setting page
    const history = useHistory();
    try {
        document.getElementById("dashboardfont").innerText = "settings";
        document.getElementById("replacetitleimg").innerText = "Settings";
    } catch { }
    const resopnseDeactivateProfile = () => {
        const defaultrole = document.getElementsByClassName("deactivatestatusClient")[0]
        defaultrole.checked = false;
    }
    const resopnseActivateProfile = () => {
        const defaultrole = document.getElementsByClassName("deactivatestatusClient")[0]
        defaultrole.checked = true;
    }
    const deactivateStatus = () => {
        const defaultrole = document.getElementsByClassName("deactivatestatusClient")[0]
        if (defaultrole.checked) {
            defaultrole.setAttribute('data-target', '#deactivateprofile')
        }
        else {
            defaultrole.setAttribute('data-target', '#activateprofile')
        }
    }
    return (
        <div className={"col mx--lg-5 mx-0 settings-container-height"} >
            <div className="row mt-5">
                <div className="col-md">
                    <i className="fas fa-arrow-left backicon" onClick={() => history.goBack()}></i>
                </div>
            </div>
            <div className={"col-lg-10"}>
                <div className={"row px-lg-5 px-0 pr-lg-5 pr-0 mx-lg-3 mx-0 pt-3"}>
                    <div className={"contentholder w-100 pt-3 pb-3 pl-5 pr-5"}>
                        <div className={"row pt-2 pr-5"}>
                            <div className={"col-md-3"}>
                                <label className="font-weight-600 font-size-16px colored-primary-black " >NOTIFICATION</label>
                            </div>
                            <div className={"col-md"}>
                                <hr />
                            </div>
                        </div>
                        <div className={"row pt-4"}>
                            <div className={"col-md-4 col-8"}>
                                <label className="font-weight-500 font-size-14px colored-Slate-gray" >Mobile Application</label>
                            </div>
                            <div className={"col-md-6 col-4"}>
                                <form>
                                    <div className={"custom-control custom-switch"}>
                                        <input type={"checkbox"} className={"custom-control-input"} id={"switch"} name={"example"} />
                                        <label className={"custom-control-label"} htmlFor={"switch"}></label>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className={"row pt-3"}>
                            <div className={"col-md-4 col-8"}>
                                <label className="font-weight-500 font-size-14px colored-Slate-gray">Matching Alerts</label>
                            </div>
                            <div className={"col-md-6 col-4"}>
                                <form>
                                    <div className={"custom-control custom-switch"}>
                                        <input type={"checkbox"} className={"custom-control-input"} id={"switch0"} name={"example"} />
                                        <label className={"custom-control-label"} htmlFor={"switch0"}></label>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className={"row pt-3"}>
                            <div className={"col-md-4 col-8"}>
                                <label className="font-weight-500 font-size-14px colored-Slate-gray">Visible to  everyone </label>
                            </div>
                            <div className={"col-md-6 col-4"}>
                                <form>
                                    <div className={"custom-control custom-switch"}>
                                        <input type={"checkbox"} className={"custom-control-input"} id={"switch1"} name={"example"} />
                                        <label className={"custom-control-label"} htmlFor={"switch1"}></label>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className={"row pt-3"}>
                            <div className={"col-md-4 col-8"}>
                                <label className="font-weight-500 font-size-14px colored-Slate-gray">Deactivate Profile</label>
                            </div>
                            <div className={"col-md-6 col-4 pb-3"}>
                                <form>
                                    <div className={"custom-control custom-switch"}>
                                        <input type={"checkbox"} className={"custom-control-input deactivatestatusClient"} id={"switch2"} name={"example"} data-toggle="modal" data-target="#deactivateprofile" onClick={deactivateStatus} />
                                        <label className={"custom-control-label"} htmlFor={"switch2"}></label>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal fade" id="deactivateprofile" data-backdrop={"static"}>
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-title pr-3 pt-3">
                                        <button type="button" className="close" data-dismiss="modal" onClick={resopnseDeactivateProfile}>&times;</button>
                                    </div>
                                    <div className="modal-body pl-5 ml-3">
                                        Do you want to deactivate your profile
                                    </div>
                                    <div className="row pt-3 pb-4 pl-5 ml-5">
                                        <div className="col-md-5">
                                            <button type="button" className="btn btn-success py-1" data-dismiss="modal">Yes</button>
                                        </div>
                                        <div className="col-md-3">
                                            <button type="button" className="btn btn-danger py-1" data-dismiss="modal" onClick={resopnseDeactivateProfile}>No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal" id="activateprofile" data-backdrop={"static"}>
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-title pr-3 pt-3">
                                        <button type="button" className="close" data-dismiss="modal" onClick={resopnseActivateProfile}>&times;</button>
                                    </div>
                                    <div className="modal-body pl-5 ml-3">
                                        Do you want to activate your profile
                                    </div>
                                    <div className="row pt-3 pb-4 pl-5 ml-5">
                                        <div className="col-md-5">
                                            <button type="button" className="btn btn-success py-1" data-dismiss="modal">Yes</button>
                                        </div>
                                        <div className="col-md-3">
                                            <button type="button" className="btn btn-danger py-1" data-dismiss="modal" onClick={resopnseActivateProfile}>No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-lg pr-5 pb-3"}>
                                <Link to={"/client/setting"} className={"btn float-right save"}>Save Changes</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <GoToTop />
        </div>
    )
}
export default React.memo(Setting);