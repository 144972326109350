import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { dashboardjobcount } from "../api/candidate/dashboardJobCount";
import { freelancejobcount } from "../api/freelance/candidate/freelanceJobCount";
import { recruiterjobcount } from "../api/recruiter/candidate/recruiterJobCount";
import { candidateRedirect } from "../function/candidate/candidateRedirect";
import { viewMore } from "../function/common/viewmore";

const CandidateDashcard = () => {
  // return dashboard card group which generated by data variable
  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);
  const dispatch = useDispatch();
  const [count, setCount] = useState({
    matchingJobs: "",
    interviewSchedule: "",
    jobOffer: "",
    shortlistedJobs: "",
    applicationSent: "",
    jobsInPipeline: "",
  });

  useEffect(() => {
    async function callUserDetails() {
      try {
        const response = isrecruiterCandidate
          ? await recruiterjobcount(token)
          : isFreelance
            ? await freelancejobcount(token)
            : await dashboardjobcount(token);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          setCount({
            ...count,
            matchingJobs: payload.matchingJobs,
            interviewSchedule: payload.interviewSchedule,
            jobOffer: payload.jobOffer,
            shortlistedJobs: payload.shortlistedJobs,
            applicationSent: payload.applicationSent,
            jobsInPipeline: payload.jobsInPipeline,
          });
        }
      } catch { }
    }
    callUserDetails();
  }, []);

  const data = [
    {
      count: count.matchingJobs,
      countColor: "#7E73B5",
      progressColor: "#7E73B5",
      icon: "/icon/common/suitcase2.png",
      iconColor: "#F6F4FF",
      message: "Matching Jobs",
    },
    {
      count: count.interviewSchedule,
      countColor: "#7E73B5",
      progressColor: "#7E73B5",
      icon: "/icon/common/suitcase2.png",
      iconColor: "#F6F4FF",
      message: "Interview Schedule",
    },
    {
      count: count.jobOffer,
      countColor: "#7E73B5",
      progressColor: "#7E73B5",
      icon: "/icon/common/suitcase2.png",
      iconColor: "#F6F4FF",
      message: "Job Offer",
    },
    {
      count: count.shortlistedJobs,
      countColor: "#7E73B5",
      progressColor: "#7E73B5",
      icon: "/icon/common/suitcase2.png",
      iconColor: "F6F4FF",
      message: "Shortlisted Jobs",
    },
    {
      count: count.applicationSent,
      countColor: "#7E73B5",
      progressColor: "#7E73B5",
      icon: "/icon/common/suitcase2.png",
      iconColor: "#F6F4FF",
      message: "Application sent",
    },
    {
      count: count.jobsInPipeline,
      countColor: "#7E73B5",
      progressColor: "#7E73B5",
      icon: "/icon/common/suitcase2.png",
      iconColor: "#F6F4FF",
      message: "Jobs in Pipeline",
    },
  ];

  return data.map((data, index) => (
    <div key={index} className="col-lg-4 col-md-6 col-sm-12 pt-4">
      {data.message === "Matching Jobs" ? (
        <Link to={"/candidate/matchjobs"} onClick={() => viewMore(dispatch)}>
          <div className="col-12 p-3 viewcontent background-colored-primary-white border-radius-10px candidate-dashcard-box-shadow">
            <div className="row">
              <div className="col-1 col-111 col-sm-1">
                <div
                  className="candidate-dashcard-width-height"
                  style={{
                    background: data.progressColor,
                  }}
                ></div>
              </div>

              <div className="col-8 col-81 col-sm-8 px-0">
                <div className="pb-1">
                  <Link
                    to={"/candidate/matchjobs"}
                    onClick={() => viewMore(dispatch)}
                  >
                    <label className="font-weight-normal font-size-20px colored-Lavender-slate">
                      {data.message}
                    </label>
                  </Link>
                </div>
                <label
                  className="mb-0 font-weight-600 font-size-32px"
                  style={{
                    color: data.countColor,
                  }}
                >
                  {data.count}
                </label>
                <br />
              </div>

              <div className="col-2 col-21 col-sm-2 my-4 pl-0">
                <div>
                  <span
                    className="candidate-dashcard-icon-padding-radius"
                    style={{ background: data.iconColor }}
                  >
                    <img src={data.icon} alt={"case"} height={24} width={30} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Link>
      ) : data.message === "Application sent" ? (
        <Link to={"/candidate/applications"} onClick={() => candidateRedirect(dispatch)}>
          <div className="col-12 p-3 viewcontent background-colored-primary-white border-radius-10px candidate-dashcard-box-shadow">
            <div className="row">
              <div className="col-1 col-111 col-sm-1">
                <div
                  className="candidate-dashcard-width-height"
                  style={{
                    background: data.progressColor,
                  }}
                ></div>
              </div>

              <div className="col-8 col-81 col-sm-8 px-0">
                <div className="pb-1">
                  <label className="font-weight-normal font-size-20px colored-Lavender-slate">
                    {data.message}
                  </label>
                </div>
                <label
                  className="mb-0 font-weight-600 font-size-32px"
                  style={{
                    color: data.countColor,
                  }}
                >
                  {data.count}
                </label>
                <br />
              </div>

              <div className="col-2 col-21 col-sm-2 my-4 pl-0">
                <div>
                  <span
                    className="candidate-dashcard-icon-padding-radius"
                    style={{ background: data.iconColor }}
                  >
                    <img src={data.icon} alt={"case"} height={24} width={30} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Link>
      ) : (
        <Link to={`/candidate/matchjobs?title=${data.message.split(" ")[0].toLowerCase()}`} onClick={() => viewMore(dispatch)}>
          <div className="col-12 p-3 viewcontent background-colored-primary-white border-radius-10px candidate-dashcard-box-shadow">
            <div className="row">
              <div className="col-1 col-111 col-sm-1">
                <div
                  className="candidate-dashcard-width-height border-radius-20px"
                  style={{
                    background: data.progressColor,
                  }}
                ></div>
              </div>

              <div className="col-8 col-81 col-sm-8 px-0">
                <div className="pb-1">
                  <Link to={`/candidate/matchjobs?title=${data.message.split(" ")[0].toLowerCase()}`} onClick={() => viewMore(dispatch)}>
                    <label className="font-weight-normal font-size-20px colored-Lavender-slate">
                      {data.message}
                    </label>
                  </Link>
                </div>
                <label
                  className="mb-0 font-weight-600 font-size-32px"
                  style={{
                    color: data.countColor,
                  }}
                >
                  {data.count}
                </label>
                <br />
              </div>

              <div className="col-2 col-21  col-sm-2 my-4 pl-0">
                <div>
                  <span
                    className="candidate-dashcard-icon-padding-radius"
                    style={{ background: data.iconColor }}
                  >
                    <img src={data.icon} alt={"case"} height={24} width={30} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Link>
      )}
    </div>
  ));
}
export default CandidateDashcard;
