import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Redirect } from "react-router";
import GoToTop from "../../common/GoToTop";
import { verifyJobPostingAcademic } from "../../../function/client/checkRequired";
import { jobRequirementAcademic } from "../../../function/client/validateField";
import { secondarySkill } from "../../../function/candidate/validateField";
import { useSelector } from "react-redux";
import { postBuildRequirementAcademic } from "../../../api/client/buildRequirementAcademic";
import { getRequirementInfo } from "../../../api/client/getRequirementInfo";
import { recpostBuildRequirementAcademic } from "../../../api/recruiter/client/BuildRequirement/buildRequirementAcademic";
import { freepostBuildRequirementAcademic } from "../../../api/freelance/client/BuildRequirement/buildRequirementAcademic";
import { recgetRequirementInfo } from "../../../api/recruiter/client/BuildRequirement/getRequirementInfo";
import { freegetRequirementInfo } from "../../../api/freelance/client/BuildRequirement/getRequirementInfo";
import { getrequirementParsingData } from "../../../api/client/requirementParsing";
import { recgetrequirementParsingData } from "../../../api/recruiter/client/requirementParsing";
import { freegetrequirementParsingData } from "../../../api/freelance/client/requirementParsing";
import { useHistory } from 'react-router-dom';
// import { useStepContext } from "../../../store-Jobrequirement/step-context";


const JobRequirment3 = (props) => {
  // return job requirement third page
  // const { currentStep, visitedSteps, updateStep } = useStepContext();

  const [loader, setLoader] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [visibility, setVisibility] = useState(true);
  const [veteranVisibility, setVeteranVisibility] = useState(true);
  const [information, setInformation] = useState({
    qualification: "",
    specialization: "",
  });
  const [error, setError] = useState({
    qualification: "",
    specialization: "",
  });

  const [secondaryInfo, setSecondaryInfo] = useState({
    certificateName: "",
    certificateName0: "",
    certificateName1: "",
    certificateName2: "",
  });

  const [secondaryError, setSecondaryError] = useState({
    certificateName: "",
    certificateName0: "",
    certificateName1: "",
    certificateName2: "",
  });

  const [secondaryVisible, setSecondaryVisible] = useState({
    one: false,
    two: false,
    three: false,
  });

  const [securityNavigation, setSecurityNavigation] = useState(false);
  const [descriptionNavigation, setDescriptionNavigation] = useState(false);

  const [mandatoryType, setMandatoryType] = useState(null);
  const [mandatoryType0, setMandatoryType0] = useState(null);
  const [mandatoryType1, setMandatoryType1] = useState(null);
  const [mandatoryType2, setMandatoryType2] = useState(null);

  try {
    document.getElementById("replacetitleimg").innerText = "Job Posting";
    document.getElementById("postjobid").style.display = "none";
  } catch { }

  const param = useParams();
  const id = param.id;
  const refId = param.refId;
  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);
  let academicRef = useRef();

  // const steps = [
  //   { id: 1, name: 'Step 1', color: '#3D007A' },
  //   { id: 2, name: 'Step 2', color: '#4B0082' },
  //   { id: 3, name: 'Step 3', color: '#7D26CD' },
  //   { id: 4, name: 'Step 4', color: 'transparent' },
  //   { id: 5, name: 'Step 5', color: 'transparent' },
  // ];
  const steps = [
    { id: 1, name: 'Step 1', color: '#3D007A', linkIndex: 6 },
    { id: 2, name: 'Step 2', color: '#4B0082', linkIndex: 1 },
    { id: 3, name: 'Step 3', color: '#7D26CD', linkIndex: null },
    { id: 4, name: 'Step 4', color: 'transparent', linkIndex: securityNavigation ? 3 : null },
    { id: 5, name: 'Step 5', color: 'transparent', linkIndex: descriptionNavigation ? 4 : null }
  ];
  function handleClick(event) {
    event.preventDefault();
    setSecondaryError((state) => {
      if (!secondaryVisible.one) state = { ...state, certificateName0: "" };
      if (!secondaryVisible.two) state = { ...state, certificateName1: "" };
      if (!secondaryVisible.three) state = { ...state, certificateName1: "" };
      return state;
    });

    const result = Object.keys(secondaryError)
      .map((key) => {
        return secondaryError[key] ? true : false;
      })
      .includes(true);

    if (!verifyJobPostingAcademic(error, setError, information, visibility))
      return;
    const resultVal = Object.values(error)
      .map((data) => {
        if (data) return true;
        return false;
      })
      .some((data) => {
        if (data) return true;
        return false;
      });

    if (!resultVal & !result) {
      if (academicRef.current) {
        academicRef.current.setAttribute("disabled", "disabled");
      }
      setLoader(true);
      let registrationValue = {
        clearanceRequired: visibility ? "YES" : "NO",
        academicsQualification: information.qualification,
        specialization: information.specialization,
        veteran: veteranVisibility,
      };
      if (secondaryInfo.certificateName) {
        registrationValue = {
          certificates: [
            {
              certificateName: secondaryInfo.certificateName,
              type: mandatoryType ? "MANDATORY" : "GOOD_TO_HAVE",
            },
          ],
          ...registrationValue,
        };
      }
      if (secondaryVisible.one) {
        if (secondaryInfo.certificateName0) {
          const pushData = registrationValue.certificates.push({
            certificateName: secondaryInfo.certificateName0,
            type: mandatoryType0 ? "MANDATORY" : "GOOD_TO_HAVE",
          });
          registrationValue = {
            certificates: pushData,
            ...registrationValue,
          };
        }
      }

      if (secondaryVisible.two) {
        if (secondaryInfo.certificateName1) {
          const pushData = registrationValue.certificates.push({
            certificateName: secondaryInfo.certificateName1,
            type: mandatoryType1 ? "MANDATORY" : "GOOD_TO_HAVE",
          });
          registrationValue = {
            certificates: pushData,
            ...registrationValue,
          };
        }
      }

      if (secondaryVisible.three) {
        if (secondaryInfo.certificateName2) {
          const pushData = registrationValue.certificates.push({
            certificateName: secondaryInfo.certificateName2,
            type: mandatoryType2 ? "MANDATORY" : "GOOD_TO_HAVE",
          });
          registrationValue = {
            certificates: pushData,
            ...registrationValue,
          };
        }
      }

      let filteredValue = {};
      Object.keys(registrationValue)
        .filter((key) => {
          const value = registrationValue[key];
          if (value || value === false) {
            return true;
          } else {
            return false;
          }
        })
        .map((key) => {
          filteredValue = { [key]: registrationValue[key], ...filteredValue };
        });
      const response = isrecruiterCandidate
        ? recpostBuildRequirementAcademic(token, filteredValue, id)
        : isFreelance
          ? freepostBuildRequirementAcademic(token, filteredValue, id)
          : postBuildRequirementAcademic(token, filteredValue, id);
      response.then((res) => {
        if (res.status === 200) {
          setRedirect(true);
        } else {
          academicRef.current.removeAttribute("disabled");
          setLoader(false);
        }
      });
    }
  }

  //get jobRequirement Academic Details
  useEffect(() => {
    // updateStep(3);
    async function callItSelf() {
      if (refId) {
        try {
          const response = isrecruiterCandidate
            ? await recgetrequirementParsingData(token, refId)
            : isFreelance
              ? await freegetrequirementParsingData(token, refId)
              : await getrequirementParsingData(token, refId);
          if (response.status === 200) {
            const data = await response.json();
            let visibleObject = {};
            let secondaryObject = {};
            const payload = data.payload.parsedData;
            if (payload.education.length !== 0) {
              setInformation({
                specialization: payload.education[0],
              });
            }
            if (payload.certificates[0]) {
              secondaryObject = {
                ...secondaryObject,
                certificateName: payload.certificates[0],
              };
            }
            if (payload.certificates[1]) {
              visibleObject = {
                ...visibleObject,
                one: true,
              };
              secondaryObject = {
                ...secondaryObject,
                certificateName0: payload.certificates[1],
              };
            }
            if (payload.certificates[2]) {
              visibleObject = {
                ...visibleObject,
                two: true,
              };
              secondaryObject = {
                ...secondaryObject,
                certificateName1: payload.certificates[2],
              };
            }
            if (payload.certificates[3]) {
              visibleObject = {
                ...visibleObject,
                three: true,
              };
              secondaryObject = {
                ...secondaryObject,
                certificateName2: payload.certificates[3],
              };
            }
            setSecondaryInfo({ ...secondaryInfo, ...secondaryObject });
            setSecondaryVisible({ ...secondaryVisible, ...visibleObject });
          }
        } catch { }
      } else {
        try {
          if (!information.qualification) {
            const response = isrecruiterCandidate
              ? await recgetRequirementInfo(token, id)
              : isFreelance
                ? await freegetRequirementInfo(token, id)
                : await getRequirementInfo(token, id);
            if (response.status === 200) {
              const data = await response.json();
              let visibleObject = {};
              let secondaryObject = {};
              const payload = data.payload.requirement;
              const academic = payload.AcademicCertificates[0];
              if (
                payload.AcademicCertificates[0].academicsQualification
                  .length !== 0
              ) {
                setSecurityNavigation(true);
                document.getElementsByClassName("hidebtn")[0].style.display =
                  "none";
              }
              if (academic.clearanceRequired === "NO") {
                setVisibility(false);
              } else {
                setVisibility(true);
              }
              setVeteranVisibility(data.payload.veteran)
              setInformation({
                qualification: academic.academicsQualification,
                specialization: academic.specialization,
              });
              if (academic.AcademicCertifications[0]) {
                const firstCertificate = academic.AcademicCertifications[0];
                secondaryObject = {
                  ...secondaryObject,
                  certificateName: firstCertificate.certificateName,
                };
                if (firstCertificate.type === "MANDATORY") {
                  setMandatoryType(true);
                } else {
                  setMandatoryType(false);
                }
              }
              if (academic.AcademicCertifications[1]) {
                const secondCertificate = academic.AcademicCertifications[1];
                visibleObject = {
                  ...visibleObject,
                  one: true,
                };
                secondaryObject = {
                  ...secondaryObject,
                  certificateName0: secondCertificate.certificateName,
                };
                if (secondCertificate.type === "MANDATORY") {
                  setMandatoryType0(true);
                } else {
                  setMandatoryType0(false);
                }
              }
              if (academic.AcademicCertifications[2]) {
                const thirdCertificate = academic.AcademicCertifications[2];
                visibleObject = {
                  ...visibleObject,
                  two: true,
                };
                secondaryObject = {
                  ...secondaryObject,
                  certificateName1: thirdCertificate.certificateName,
                };
                if (thirdCertificate.type === "MANDATORY") {
                  setMandatoryType1(true);
                } else {
                  setMandatoryType1(false);
                }
              }
              if (academic.AcademicCertifications[3]) {
                const fourthCertificate = academic.AcademicCertifications[3];
                visibleObject = {
                  ...visibleObject,
                  three: true,
                };
                secondaryObject = {
                  ...secondaryObject,
                  certificateName2: fourthCertificate.certificateName,
                };
                if (fourthCertificate.type === "MANDATORY") {
                  setMandatoryType2(true);
                } else {
                  setMandatoryType2(false);
                }
              }
              setSecondaryInfo({ ...secondaryInfo, ...secondaryObject });
              setSecondaryVisible({ ...secondaryVisible, ...visibleObject });
              if (payload.securityClearanceRequired.length !== 0) {
                setDescriptionNavigation(true);
              }
            }
          }
        } catch { }
      }
    }
    callItSelf();
  }, []);

  function CreateInputs() {
    setSecondaryVisible((state) => {
      if (!state.one) return { ...state, one: true };
      if (!state.two) return { ...state, two: true };
      if (!state.three) return { ...state, three: true };
      return state;
    });
  }

  function submitEnabled() {
    try {
      document.getElementsByClassName("hidebtn")[0].style.display = "block";
      setSecurityNavigation(false);
      setDescriptionNavigation(false);
    } catch { }
  }

  const history = useHistory();

  // const navigateToStep = (stepNumber) => {
  //   updateStep(stepNumber);

  //   switch (stepNumber) {
  //     case 1:
  //       history.push(`${props.link[6]}/${id}`);
  //       break;
  //     case 2:
  //       history.push(`${props.link[1]}/${id}`);
  //       break;
  //     case 3:
  //       history.push(`${props.link[2]}/${id}`);
  //       break;
  //     case 4:
  //       history.push(`${props.link[3]}/${id}`);
  //       break;
  //     case 5:
  //       history.push(`${props.link[4]}/${id}`);
  //       break;
  //     default:
  //       break;
  //   }
  // };
  // const navigateToStep = (stepNumber) => {
  //   // updateStep(stepNumber); 

  //   const step = steps.find((s) => s.id === stepNumber);

  //   if (step && step.linkIndex !== null) {
  //     const link = props.link[step.linkIndex];
  //     if (link) {
  //       history.push(`${link}/${id}`);
  //     }
  //   }
  // };
  const checkNextStep = (index) => {
    // if (visitedSteps.includes(index + 1)) {
    const step = steps.find((s) => s.id === index + 1);

    if (step && step.linkIndex !== null) {
      const link = props.link[step.linkIndex];
      if (link) {
        history.push(`${link}/${id}`);
      }
    }
    // }
  };
  return (
    <div className={"col JobRequirment3-container-padding-bottom"} >
      <form className={"form-group"} onSubmit={handleClick}>
        {/* <div className={"row pl-5"}>
          <div className={"container-fluid container1 pl-5"}>
            <ul className={"progressbar mt-5 pr-5"}>
              <li className={"active"}>
                <img
                  src="../../../icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li className={"active"}>
                <img
                  src="../../../icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li>
                <img
                  src="../../../icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div> */}

        <div className={"row mx-lg-5 mx-1 pl-lg-5 mt-lg-5 resumealignment pl-3 pl-lg-0"}>
          <div className="step-container pl-lg-4">
            {steps.map((step, index) => (
              <div key={step.id}
                className={`step-arrow bordered-step-arrow ${index < 3 ? 'colored-content' : ''}`}
                style={{ backgroundColor: step.color }} onClick={() => {
                  if (step.linkIndex !== null) {
                    checkNextStep(index);
                  }
                }}>
                {step.name}
              </div>
            ))}
          </div>
        </div>
        {/* <div className={"row mx-lg-5 pl-lg-5  resumealignment"}> */}
        {/* <div className={"step-container pl-4"}>
            {steps.map((step, index) => (
              <div
                key={step.id}
                className={`step-arrow bordered-step-arrow ${index < 2 ? 'colored-content' : ''}`}
                style={{ backgroundColor: step.color }}
                onClick={() => {
                  if (step.linkIndex !== null) {
                    checkNextStep(index);
                  }
                }}
              >
                <label className="font-weight-500 font-size-14px colored-primary-black">
                  {step.name}
                </label>
              </div>
            ))}
          </div>
        </div> */}
        {/* <div className={"row mx-lg-5 pl-lg-5  resumealignment"}>
          <div className={"col-lg-2 pb-3 pl-4"}>
            <Link to={`${props.link[6]}/${id}`}>
              <div
                className={"pt-2 pl-2 pr-2 jobRequirment3-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

              >
                <label
                  className="font-weight-500 font-size-14px colored-primary-black "

                >
                  Requirement
                  <br />
                  info
                </label>
                <br />
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link to={`${props.link[1]}/${id}`}>
              <div
                className={"pl-3 pb-2 pr-3 pt-3 jobRequirment3-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

              >
                <label
                  className="font-weight-500 font-size-14px colored-primary-black "
                >
                  Skill
                </label>
                <br />
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <div
              className={"pt-1 pl-2 pr-2 jobRequirment3-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

            >
              <div className={"row"}>
                <div className={"col-lg pb-0"}>
                  <label
                    className="font-weight-500 font-size-14px colored-primary-black "

                  >
                    Academics <br />& Certificate
                  </label>
                </div>
              </div>
              <div className={"row pl-0"}>
                <div className={"col-lg"}>
                  <div
                    className="jobRequirment3-Requirement-info-border background-colored-sky-blue"

                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className={"col-lg-2 pl-3 pb-3"}>
            {securityNavigation ? (
              <Link to={`${props.link[3]}/${id}`}>
                <div
                  className={"pt-2 pl-2 pr-2 jobRequirment3-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

                >
                  <label
                    className="font-weight-500 font-size-14px colored-Silver-gray"

                  >
                    Security Clearance
                  </label>
                </div>
              </Link>
            ) : (
              <div
                className={"pt-2 pl-2 pr-2 jobRequirment3-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

              >
                <label
                  className="font-weight-500 font-size-14px colored-Silver-gray"
                >
                  Security Clearance
                </label>
              </div>
            )}
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            {descriptionNavigation ? (
              <Link to={`${props.link[4]}/${id}`}>
                <div
                  className={"pt-2 pl-2 pr-2 jobRequirment3-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

                >
                  <label
                    className="font-weight-500 font-size-14px colored-Silver-gray"
                  >
                    Job
                    <br />
                    Description
                  </label>
                </div>
              </Link>
            ) : (
              <div
                className={"pt-2 pl-2 pr-2 jobRequirment3-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

              >
                <label
                  className="font-weight-500 font-size-14px colored-Silver-gray"
                >
                  Job
                  <br />
                  Description
                </label>
              </div>
            )}
          </div>
        </div> */}
        <div className={"pl-1 pr-lg-5 mr-lg-5 pb-3"}>
          <div className={"row px-lg-5 pr-lg-5 mx-lg-3 pt-3 pl-3 pl-lg-0"}>
            <div className={"w-100 pt-4 pl-lg-5 pl-3 pr-3 pr-lg-0 contentholder"}>
              <div className="mb-4 d-inline-block font-weight-semibold font-size-26px" >
                Education
              </div>
              <div className={"row"}>
                <div className={"col-md form-inline"}>
                  <label className={"resumeformquerytext pr-3"}>
                    Is US Degree Req?{" "}
                  </label>
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio"
                    name="degree"
                    value="yes"
                    onClick={() => setVisibility(true)}
                    checked={visibility}
                    onChange={() => {
                      submitEnabled();
                    }}
                  />
                  <label
                    className="dashboardforminputtext pl-2 pr-3"
                    htmlFor="customRadio"
                  >
                    Yes
                  </label>
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio0"
                    name="degree"
                    value="no"
                    onClick={() => setVisibility(false)}
                    checked={!visibility}
                    onChange={() => {
                      submitEnabled();
                    }}
                  />
                  <label
                    className="dashboardforminputtext pl-2"
                    htmlFor="customRadio0"
                  >
                    No
                  </label>
                </div>
              </div>
              <div className={"row pt-3 pb-2"}>
                <div className={"col-md form-inline"}>
                  <label className={"resumeformquerytext pr-4"}>Veteran </label>
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio"
                    name="veteran"
                    value="yes"
                    onClick={() => setVeteranVisibility(true)}
                    checked={veteranVisibility}
                    onChange={() => {
                      submitEnabled();
                    }}
                  />
                  <label
                    className="dashboardforminputtext pl-2 pr-3"
                    htmlFor="customRadio"
                  >
                    Yes
                  </label>
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio0"
                    name="veteran"
                    value="no"
                    onClick={() => setVeteranVisibility(false)}
                    checked={!veteranVisibility}
                    onChange={() => {
                      submitEnabled();
                    }}
                  />
                  <label
                    className="dashboardforminputtext pl-2"
                    htmlFor="customRadio0"
                  >
                    No
                  </label>
                </div>
              </div>
              <div id={"requiredDegree"}>
                <div className={"row pt-2"}>
                  <div className={"col-lg-4"}>
                    <label htmlFor={"equal"} className={"resumeforminputtext"}>
                      Academics Qualification
                      <span className="colored-Bright-red ">*</span>
                    </label>
                    <select
                      className={
                        "form-control resumeforminputfield form-select inputgray shadow-none qualification"
                      }
                      value={information.qualification}
                      id={"equal"}
                      name={"qualification"}
                      style={{
                        border: `${error.qualification ? "1px solid red" : ""}`,
                      }}
                      onChange={(event) => {
                        {
                          jobRequirementAcademic(
                            event,
                            error,
                            setError,
                            information,
                            setInformation
                          );
                        }
                        submitEnabled();
                      }}
                    >
                      <option value={""}>Select</option>
                      <option value={"ANY"}>Any</option>
                      <option value={"DOCTORATE"}>Doctorate</option>
                      <option value={"MASTERS"}>Masters</option>
                      <option value={"BACHELORS"}>Bachelors</option>
                      <option value={"ASSOCIATE"}>Associate</option>
                      <option value={"VOCATIONAL"}>Vocational</option>
                      <option value={"HIGH_SCHOOL"}>High School</option>
                    </select>
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.qualification}
                    </font>
                  </div>
                  <div className={"col-lg-4"}>
                    <label
                      htmlFor={"specialization"}
                      className={"resumeforminputtext"}
                    >
                      Specialization
                    </label>
                    <input
                      type={"text"}
                      placeholder={"Type Here"}
                      id={"specialization"}
                      name={"specialization"}
                      value={information.specialization}
                      style={{
                        border: `${error.specialization ? "1px solid red" : ""
                          }`,
                      }}
                      className={
                        "form-control resumeforminputfield shadow-none"
                      }
                      onChange={(event) => {
                        {
                          jobRequirementAcademic(
                            event,
                            error,
                            setError,
                            information,
                            setInformation
                          );
                        }
                        submitEnabled();
                      }}
                    />
                    <font className={"dashboardforminputtext text-danger"}>
                      {error.specialization}
                    </font>
                  </div>
                </div>
              </div>

              <div className={"row pt-2"}>
                <div className={"col-md"}>
                  <label
                    className="font-weight-600 font-size-1rem colored-primary-black "

                  >
                    Certificates
                  </label>
                </div>
              </div>
              <div className={"row pt-0"}>
                <div className={"col-lg-4"}>
                  <label htmlFor={"cname"} className={"resumeforminputtext"}>
                    Certificate Name
                  </label>
                  <input
                    type={"text"}
                    placeholder={"Type Here"}
                    id={"cname"}
                    className={"form-control resumeforminputfield shadow-none"}
                    name={"certificateName"}
                    value={secondaryInfo.certificateName}
                    style={{
                      border: `${secondaryError.certificateName ? "1px solid red" : ""
                        }`,
                    }}
                    onChange={(event) => {
                      secondarySkill(
                        event,
                        setSecondaryInfo,
                        setSecondaryError
                      );
                      submitEnabled();
                    }}
                  />
                  <font className={"dashboardforminputtext text-danger"}>
                    {secondaryError.certificateName}
                  </font>
                </div>
                <div className={"col-lg-4 form-inline pt-4 pl-5"}>
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio"
                    name="fieldstatus"
                    value="MANDOTARY"
                    onClick={() => setMandatoryType(true)}
                    onChange={() => {
                      submitEnabled();
                    }}
                    checked={mandatoryType}
                  />
                  <label
                    className="dashboardforminputtext pl-2 pr-3"
                    htmlFor="customRadio"
                  >
                    Mandatory
                  </label>
                  <input
                    type="radio"
                    className="resumeradio"
                    id="customRadio0"
                    name="fieldstatus"
                    value="GOOD_TO_HAVE"
                    onClick={() => setMandatoryType(false)}
                    onChange={() => {
                      submitEnabled();
                    }}
                    checked={!mandatoryType}
                  />
                  <label
                    className="dashboardforminputtext pl-2"
                    htmlFor="customRadio0"
                  >
                    Good to have
                  </label>
                </div>
              </div>
              {secondaryVisible.three &&
                secondaryVisible.two &&
                secondaryVisible.one ? (
                <div className={"row"}>
                  <div className={"col-md pt-3"}></div>
                </div>
              ) : (
                <div className={"row"}>
                  <div className={"col-md pb-0"}>
                    <div
                      className={"btn  offset-sm-7 border-0   newskill colored-Deep-violet pl-0"}

                      onClick={CreateInputs}
                    >
                      +Add More
                    </div>
                  </div>
                </div>
              )}
              <div className={"row pt-0"}>
                <div className={"col-md-12"}>
                  {/*duplicate here*/}
                  {secondaryVisible.one && (
                    <div className={"row"}>
                      <div className={"col-lg-4 pr-3"}>
                        <label
                          htmlFor={"cname"}
                          className={"dashboardforminputtext"}
                        >
                          Certificate Name
                        </label>
                        <input
                          type={"text"}
                          placeholder={"Type Here"}
                          id={"cname"}
                          className={
                            "form-control dashboardforminputfield shadow-none"
                          }
                          name={"certificateName0"}
                          value={secondaryInfo.certificateName0}
                          style={{
                            border: `${secondaryError.certificateName0
                              ? "1px solid red"
                              : ""
                              }`,
                          }}
                          onChange={(event) => {
                            secondarySkill(
                              event,
                              setSecondaryInfo,
                              setSecondaryError
                            );
                            submitEnabled();
                          }}
                        />
                        <font className={"dashboardforminputtext text-danger"}>
                          {secondaryError.certificateName0}
                        </font>
                      </div>
                      <div className={"col-lg-4 form-inline pt-4 pl-5"}>
                        <input
                          type="radio"
                          className="resumeradio"
                          id="customRadio"
                          name="fieldstatus0"
                          value="mandatory"
                          onClick={() => setMandatoryType0(true)}
                          onChange={() => {
                            submitEnabled();
                          }}
                          checked={mandatoryType0}
                        />
                        <label
                          className="dashboardforminputtext pl-2 pr-3"
                          htmlFor="customRadio"
                        >
                          Mandatory
                        </label>
                        <input
                          type="radio"
                          className="resumeradio"
                          id="customRadio0"
                          name="fieldstatus0"
                          value="good"
                          onClick={() => setMandatoryType0(false)}
                          onChange={() => {
                            submitEnabled();
                          }}
                          checked={!mandatoryType0}
                        />
                        <label
                          className="dashboardforminputtext pl-2"
                          htmlFor="customRadio0"
                        >
                          Good to have
                        </label>
                      </div>
                      <div
                        className={
                          "btn offset-sm-7 pl-  badge border-0 cancelskill"
                        }
                        onClick={() => {
                          submitEnabled();
                          setSecondaryVisible((state) => {
                            return { ...state, one: false };
                          });
                        }}
                      >
                        cancel
                      </div>
                    </div>
                  )}
                  {secondaryVisible.two && (
                    <div className={"row"}>
                      <div className={"col-lg-4 pr-3"}>
                        <label
                          htmlFor={"cname"}
                          className={"dashboardforminputtext"}
                        >
                          Certificate Name
                        </label>
                        <input
                          type={"text"}
                          placeholder={"Type Here"}
                          id={"cname"}
                          className={
                            "form-control dashboardforminputfield shadow-none"
                          }
                          name={"certificateName1"}
                          value={secondaryInfo.certificateName1}
                          style={{
                            border: `${secondaryError.certificateName1
                              ? "1px solid red"
                              : ""
                              }`,
                          }}
                          onChange={(event) => {
                            secondarySkill(
                              event,
                              setSecondaryInfo,
                              setSecondaryError
                            );
                            submitEnabled();
                          }}
                        />
                        <font className={"dashboardforminputtext text-danger"}>
                          {secondaryError.certificateName1}
                        </font>
                      </div>
                      <div className={"col-lg-4 form-inline pt-4 pl-5"}>
                        <input
                          type="radio"
                          className="resumeradio"
                          id="customRadio"
                          name="fieldstatus1"
                          value="mandatory"
                          onClick={() => setMandatoryType1(true)}
                          onChange={() => {
                            submitEnabled();
                          }}
                          checked={mandatoryType1}
                        />
                        <label
                          className="dashboardforminputtext pl-2 pr-3"
                          htmlFor="customRadio"
                        >
                          Mandatory
                        </label>
                        <input
                          type="radio"
                          className="resumeradio"
                          id="customRadio0"
                          name="fieldstatus1"
                          value="good"
                          onClick={() => setMandatoryType1(false)}
                          onChange={() => {
                            submitEnabled();
                          }}
                          checked={!mandatoryType1}
                        />
                        <label
                          className="dashboardforminputtext pl-2"
                          htmlFor="customRadio0"
                        >
                          Good to have
                        </label>
                      </div>
                      <div
                        className={
                          "btn offset-sm-7 pl-  badge border-0 cancelskill"
                        }
                        onClick={() => {
                          submitEnabled();
                          setSecondaryVisible((state) => {
                            return { ...state, two: false };
                          });
                        }}
                      >
                        cancel
                      </div>
                    </div>
                  )}
                  {secondaryVisible.three && (
                    <div className={"row"}>
                      <div className={"col-lg-4 pr-3"}>
                        <label
                          htmlFor={"cname"}
                          className={"dashboardforminputtext"}
                        >
                          Certificate Name
                        </label>
                        <input
                          type={"text"}
                          placeholder={"Type Here"}
                          id={"cname"}
                          className={
                            "form-control dashboardforminputfield shadow-none"
                          }
                          name={"certificateName2"}
                          value={secondaryInfo.certificateName2}
                          style={{
                            border: `${secondaryError.certificateName2
                              ? "1px solid red"
                              : ""
                              }`,
                          }}
                          onChange={(event) => {
                            secondarySkill(
                              event,
                              setSecondaryInfo,
                              setSecondaryError
                            );
                            submitEnabled();
                          }}
                        />
                        <font className={"dashboardforminputtext text-danger"}>
                          {secondaryError.certificateName2}
                        </font>
                      </div>
                      <div className={"col-lg-4 form-inline pt-4 pl-5"}>
                        <input
                          type="radio"
                          className="resumeradio"
                          id="customRadio"
                          name="fieldstatus2"
                          value="mandatory"
                          onClick={() => setMandatoryType2(true)}
                          onChange={() => {
                            submitEnabled();
                          }}
                          checked={mandatoryType2}
                        />
                        <label
                          className="dashboardforminputtext pl-2 pr-3"
                          htmlFor="customRadio"
                        >
                          Mandatory
                        </label>
                        <input
                          type="radio"
                          className="resumeradio"
                          id="customRadio0"
                          name="fieldstatus2"
                          value="good"
                          onClick={() => setMandatoryType2(false)}
                          onChange={() => {
                            submitEnabled();
                          }}
                          checked={!mandatoryType2}
                        />
                        <label
                          className="dashboardforminputtext pl-2"
                          htmlFor="customRadio0"
                        >
                          Good to have
                        </label>
                      </div>
                      <div
                        className={
                          "btn offset-sm-7 pl-  badge border-0 cancelskill"
                        }
                        onClick={() => {
                          submitEnabled();
                          setSecondaryVisible((state) => {
                            return { ...state, three: false };
                          });
                        }}
                      >
                        cancel
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"row hidebox pl-3 pl-lg-0"}>
          <div className={"col mt-2 pr-5"}>
            {securityNavigation ? (
              <>
                {redirect ? (
                  <Redirect to={`${props.link[3]}/${id}`} />
                ) : (
                  <button
                    type="submit"
                    className={"btn offset-sm-9 offset-4 save hidebtn"}
                    ref={academicRef}
                  >
                    {loader ? (
                      <i
                        className="fa fa-refresh fa-spin jobRequirment3-loader-padding"

                      ></i>
                    ) : (
                      ""
                    )}
                    Save & Continue
                  </button>
                )}
              </>
            ) : (
              <>
                {redirect ? (
                  refId ? (
                    <Redirect to={`${props.link[3]}/${id}/${refId}`} />
                  ) : (
                    <Redirect to={`${props.link[3]}/${id}`} />
                  )
                ) : (
                  <button
                    type="submit"
                    className={"btn offset-sm-9 offset-4 save"}
                    ref={academicRef}
                  >
                    {loader ? (
                      <i
                        className="fa fa-refresh fa-spin jobRequirment3-loader-padding"

                      ></i>
                    ) : (
                      ""
                    )}
                    Save & Continue
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </form>
      <GoToTop />
    </div>
  );
};

export default React.memo(JobRequirment3);
